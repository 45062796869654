<div class="delivery_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="row" fxLayoutGap="40px">
    <div class="panel">
        <div *ngIf="PropertyService.UserGroupId == 13" class="create_new_order" [routerLink]="['/delivery-new']">Создать
            заказ</div>


        <div *ngIf="PropertyService.UserGroupId == 13" class="delivery_courier" [routerLink]="['/delivery-courier']">
            Формирование
            доставки({{OrdersService.ObjectsCourierShip.Values.length}})</div>

        <div *ngIf="PropertyService.UserGroupId == 15" class="delivery_courier" [routerLink]="['/delivery-courier-view']">
            Активные развозы</div>


        <app-quick-access-panel (OnOpenOrder)="CloseFilter()" *ngIf="PropertyService.UserGroupId == 13" [list]="OrdersService.ObjectsInProgressShip.Values"></app-quick-access-panel>
        <app-quick-access-panel (OnOpenOrder)="CloseFilter()" *ngIf="PropertyService.UserGroupId == 15" [list]="OrdersService.ObjectsInPickShip.Values"></app-quick-access-panel>

    </div>
    <div class="content">





        <ibm-tabs class="tabs_wrapper" *ngIf="CurrentDelivery != null && PropertyService.UserGroupId == 13">
            <ibm-tab heading="Приём">
                <app-order-express-stage [(delivery)]="CurrentDelivery" [(deliveryOriginal)]="CurrentOriginalDelivery" [shippingArea]="CurrentDelivery.ShippingArea">
                </app-order-express-stage>
            </ibm-tab>
            <ibm-tab heading="Меню">
                <app-order-equipment-stage [(delivery)]="CurrentDelivery" [(deliveryOriginal)]="CurrentOriginalDelivery"></app-order-equipment-stage>
            </ibm-tab>
            <ibm-tab heading="История">
                <app-order-history-stage [(delivery)]="CurrentDelivery" [(deliveryOriginal)]="CurrentOriginalDelivery">
                </app-order-history-stage>
            </ibm-tab>
            <ibm-tab heading="Статистика" *ngIf="PropertyService.UserGroupId == 1">
                <app-order-general-stage [(delivery)]="CurrentDelivery" [(deliveryOriginal)]="CurrentOriginalDelivery">
                </app-order-general-stage>
            </ibm-tab>
        </ibm-tabs>

        <ibm-tabs class="tabs_wrapper" *ngIf="CurrentDelivery != null && PropertyService.UserGroupId == 15">

            <ibm-tab heading="Информация">
                <app-order-express-collector [(delivery)]="CurrentDelivery" [(deliveryOriginal)]="CurrentOriginalDelivery" [shippingArea]="CurrentDelivery.ShippingArea">
                </app-order-express-collector>
            </ibm-tab>

        </ibm-tabs>



        <div class="shadow_haze" [ngClass]="{'showed_filter': IsFilterOpened}" (click)="CloseFilter()"></div>
    </div>
</div>




<span *ngIf="CurrentDelivery!=null">
    <span *ngIf="CurrentDelivery.ShippingDate.getTime() > currentDate.getTime()">
        <div class="action_button" (click)="StartOrder()" *ngIf="((CurrentDelivery.StatusId == 2 || CurrentDelivery.StatusId == 1 
                || CurrentDelivery.StatusId == 17)  && (PropertyService.UserGroupId == 13 || PropertyService.UserGroupId == 1))">
            <div class="action_button_content">
                Отправить заказ сборщику
            </div>
        </div>
    </span>



    <div class="action_button" (click)="AddToKeeperOrder()" *ngIf="(CurrentDelivery.StatusId == 3 && (PropertyService.UserGroupId == 15 || PropertyService.UserGroupId == 1))">
        <div class="action_button_content">
            Подтвердить добавил в Keeper
        </div>
    </div>

    <div class="action_button" (click)="StartDeliveryOrder()" *ngIf="(CurrentDelivery.StatusId == 19 && (PropertyService.UserGroupId == 15 || PropertyService.UserGroupId == 1))">
        <div class="action_button_content">
            Подтвердить сбор заказа
        </div>
    </div>

    <div class="action_button" (click)="EndDeliveryOrder()" *ngIf="(CurrentDelivery.StatusId == 20 && (PropertyService.UserGroupId == 15 || PropertyService.UserGroupId == 1))">
        <div class="action_button_content">
            Отдать клиенту заказ
        </div>
    </div>


</span>



<span *ngIf="CurrentDelivery!=null">
    <span *ngIf="CurrentDelivery.ShippingDate.getTime() <= currentDate.getTime()">
        <div class="action_button disabled" *ngIf="(CurrentDelivery.StatusId == 2 || CurrentDelivery.StatusId == 1 
                            || CurrentDelivery.StatusId == 17)  && (PropertyService.UserGroupId == 13 || PropertyService.UserGroupId == 1)">
            <div class="action_button_content">
                Некорректное значение даты и времени
            </div>
        </div>
    </span>
</span>


<app-history-review-action [(delivery)]="CurrentDelivery"></app-history-review-action>








<ibm-modal size="sm" [open]="OrderNotesService.IsOpenedAddingNoteModal" (overlaySelected)="OrderNotesService.CloseModalAddingNote()">
    <ibm-modal-header (closeSelect)="OrderNotesService.CloseModalAddingNote()">Добавление заметки</ibm-modal-header>
    <section class="bx--modal-content">


        <ibm-label>
            Текст заметки
            <input ibmText [(ngModel)]="OrderNotesService.AddedNoteComment">
        </ibm-label>

    </section>
    <ibm-modal-footer>
        <button class="bx--btn bx--btn--secondary" (click)="OrderNotesService.CloseModalAddingNote()">Отменить</button>
        <button class="bx--btn bx--btn--primary" (click)="OrderNotesService.AddModalAddingNote()" modal-primary-focus *ngIf="OrderNotesService.SelectedId==null">Добавить</button>
        <button class="bx--btn bx--btn--primary" (click)="OrderNotesService.EditModalAddingNote()" modal-primary-focus *ngIf="OrderNotesService.SelectedId!=null">Сохраниь</button>
    </ibm-modal-footer>
</ibm-modal>