<div class="order_courier_wrapper">
    <ibm-tile-group (selected)="selected($event)" [multiple]="true" fxLayoutGap="8px" fxLayout="column">
        <ibm-selection-tile *ngFor="let order of OrdersService.ObjectsCourierShip.Values" [value]="order.Id">


            <div class="order_header" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="order_name">Заказ №{{order.Id}}</div>
                <div class="order_time">{{order.ShippingDate | date:'HH:mm'}}</div>


            </div>

            <div class="order_adress" fxLayout="row" fxLayoutAlign="space-between center">

                <div>{{order.ShippingAdress}}</div>
                <div class="order_price">₴{{order.Total | number:'1.0-0'}}</div>
            </div>

            <div class="order_bottom" fxLayout="row" fxLayoutAlign="space-between start">

            </div>

        </ibm-selection-tile>
    </ibm-tile-group>
</div>