import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryService } from '../../../services/delivery.service';
import { OrderGroup } from '../../../models/store/order-group.model';
import { HeaderService } from '../../../services/header.service';
import { System } from '../../../models/tools/system.model';
import { OrdersService } from '../../../services/order/orders.service';

import { ConfirmationService } from '../../../services/confirmation.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

@Component({
  selector: 'app-courier-form',
  templateUrl: './courier-form.component.html',
  styleUrls: ['./courier-form.component.scss']
})
export class CourierFormComponent implements OnInit {


  public OrderGroupId: number = 0;
  public CurrentDelivery: OrderGroup = null;

  constructor(private route: ActivatedRoute, private router: Router,
    public HeaderService: HeaderService, public DeliveryService: DeliveryService,
    public OrdersService: OrdersService, public ModalAddingService: ModalAddingService) {




  }

  ngOnInit(): void {

    this.HeaderService.ClearAll();
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Доставка", "/deliveries");


    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });


    this.HeaderService.BackButton.Text = "Список доставки";
    this.HeaderService.BackButton.Link = "/courier-list";

    this.route.params.subscribe(params => {

      this.OrderGroupId = params['id'];
      this.HeaderService.WindowName = `Развоз №${this.OrderGroupId}`;
      this.HeaderService.AddBreadcrumb(`Развоз №${this.OrderGroupId}`, "/deliveries", true);


      if (this.DeliveryService.ActiveOrderGroups.length == 0) {
        this.DeliveryService.AddLoadDeliveryEvents(
          () => {
            this.CurrentDelivery = this.DeliveryService.ActiveOrderGroups.find(element => element.Id == Number.parseInt(params['id']));
          }
        );
      } else {
        this.CurrentDelivery = this.DeliveryService.ActiveOrderGroups.find(element => element.Id == Number.parseInt(params['id']));
      }

    });
  }

  StartDelivery() {
    this.DeliveryService.EditOrderGroup(this.CurrentDelivery, 3);

    this.CurrentDelivery.Orders.forEach(order => {
      if (order.StatusId != 5 && order.StatusId != 18) {
        order.StatusId = 15;
      }

    });
  }

  CancelDelivery() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.DeliveryService.EditOrderGroup(this.CurrentDelivery, 5, this.ModalAddingService.Fields[0].Value,);
      this.router.navigate(['/courier-list']);
    });



    let commentProduct = ModalFieldBuilder.BuildText("Причина отказа", 0, 200, "", [
      "Авария", "Поломка", "Не успел", "Пробка"
    ]);
    commentProduct.Required = true;
    commentProduct.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };

    this.ModalAddingService.Show(
      new ModalData("Отменить поездку", "", "Отменить", "Отмена"),
      [
        commentProduct,
      ]
    );

  }

}
