<div class="delivery_wrapper" fxLayout="row" fxLayoutGap="40px">

    <div class="filter">

    </div>
    <div class="content">
        <div class="list_wrapper">
            <ibm-structured-list>
                <ibm-list-header>
                    <ibm-list-column>Номер</ibm-list-column>
                    <ibm-list-column>Страница</ibm-list-column>
                    <ibm-list-column>Дата поступления</ibm-list-column>
                    <ibm-list-column>Статус</ibm-list-column>
                </ibm-list-header>



                <ibm-list-row *ngFor="let review of ReviewService.Reviews" class="review_item"
                    (click)="OpenReview($event, review)">
                    <ibm-list-column>
                        <span class="order_id">№{{review.Id}}</span>
                    </ibm-list-column>
                    <ibm-list-column class="order_status">
                        <span *ngIf="review.StatusId == 2">{{review.Status.Name}}</span>
                        <span *ngIf="review.StatusId == 1">{{review.Link}}</span>

                    </ibm-list-column>

                    <ibm-list-column class="order_info_column order_time">
                        <span>{{review.DateAdded | date:'dd.MM.yyyy hh:mm'}}</span>
                    </ibm-list-column>

                    <ibm-list-column class="order_info_column order_timer">Не рассмотрено</ibm-list-column>

                </ibm-list-row>


            </ibm-structured-list>

        </div>
    </div>
</div>