<div class="whats-news-wrapper">

    <div class="news-title">Общие изменения</div>
    <div class="news-list">
        <div *ngFor="let item of MainNews; index as i" class="news-item">
            <div class="news-item-title">{{i+1}}. {{item.text}}</div>
            <div class="news-item-description" *ngIf="item.description != ''">{{item.description}}</div>
        </div>
    </div>

    <div class="news-title">Обновления директора</div>
    <div class="news-list">
        <div *ngFor="let item of AdminNews; index as i" class="news-item">
            <div class="news-item-title">{{i+1}}. {{item.text}}</div>
            <div class="news-item-description" *ngIf="item.description != ''">{{item.description}}</div>
        </div>
    </div>

    <div class="news-title">Обновления банкетов</div>
    <div class="news-list">
        <div *ngFor="let item of BanquetNews; index as i" class="news-item">
            <div class="news-item-title">{{i+1}}. {{item.text}}</div>
            <div class="news-item-description" *ngIf="item.description != ''">{{item.description}}</div>
        </div>
    </div>

    <div class="news-title">Обновления доставки</div>
    <div class="news-list">
        <div *ngFor="let item of DeliveryNews; index as i" class="news-item">
            <div class="news-item-title">{{i+1}}. {{item.text}}</div>
            <div class="news-item-description" *ngIf="item.description != ''">{{item.description}}</div>
        </div>
    </div>





</div>