<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <div class="main_title">Итог (Заказ №{{delivery.Id}})</div>

    <div fxLayout="row" fxLayoutGap="32px">
        <ibm-dropdown label="Район" placeholder="Выбор района доставки">
            <ibm-dropdown-list [items]="items"></ibm-dropdown-list>
        </ibm-dropdown>
        <ibm-label>
            Адрес
            <input [(ngModel)]="delivery.ShippingAdress" ibmText placeholder="Адресс доставки">
        </ibm-label>
    </div>

    <app-separator></app-separator>

    <ibm-label>
        Телефон
        <input [(ngModel)]="delivery.Customer.Phone" ibmText placeholder="+380919199191">
    </ibm-label>

    <app-separator></app-separator>

    <ibm-label>
        Комментарий
        <textarea [(ngModel)]="delivery.Comment" ibmTextArea placeholder="Содержимое заметки"></textarea>
    </ibm-label>

    <app-separator></app-separator>

    <div class="block_title">Корзина</div>
    <app-order-cart [(products)]="delivery.Products" [(delivery)]="delivery"></app-order-cart>

    <app-separator></app-separator>

    <div class="block_title">Заметки кухни</div>
    <app-order-note [(notes)]="delivery.KitchenNotes" [(delivery)]="delivery" type="1"></app-order-note>

    <app-separator></app-separator>

    <div class="block_title">Заметки курьеру</div>
    <app-order-note [(notes)]="delivery.DeliveryNotes" [(delivery)]="delivery" type="2"></app-order-note>
</div>