import { Identifiable } from "../tools/identifiable.model";
import { BanquetArea } from "./banquet-area";

export class BanquetTableGroup extends Identifiable {

    constructor(id: number, name: string, count: number = 0) {
        super(id)
        this.Name = name;
        this.Count = count;
    }

    Name: string = "";
    Count: number = 0;
    Area: BanquetArea;

}