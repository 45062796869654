import { Identifiable } from "../tools/identifiable.model";

export class BanquetArea extends Identifiable {

    constructor(id: number, name: string) {
        super(id)
        this.Name = name;
    }

    Name: string = "";
}