<div class="menu_wrapper" fxLayout="column">
    <h2>Очаг Box</h2>

    <div class="menu_items_wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="32px">



        <ibm-tile [routerLink]="['/ochagbox-management']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Управление Очаг Box
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>





    </div>
</div>