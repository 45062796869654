import { Component, OnInit } from '@angular/core';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { BanquetZoneService } from '../../../../services/banquet/banquet-zone.service';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';
import { BanquetTable } from 'src/app/models/banquet/banquet-table';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { BanquetPaymentsService } from '../../../../services/banquet/banquet-payments.service';
import { NumberInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/number-invalid-filed-value.model';
import { StringInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/string-invalid-filed-value.model';
import { ToolsService } from '../../../../services/tools.service';

@Component({
  selector: 'app-banquet-main-editable',
  templateUrl: './banquet-main-editable.component.html',
  styleUrls: ['./banquet-main-editable.component.scss']
})
export class BanquetMainEditableComponent implements OnInit {

  public Area: Array<any> = [
    { content: "Основной зал", id: 1, selected: true },
    { content: "Малый зал", id: 2, selected: false },
    { content: "Караоке", id: 3, selected: false },
    { content: "Дом 1", id: 4, selected: false },
  ];

  public PaymentsSources: Array<any> = [
    { content: "Наличные", id: 1, selected: true },
    { content: "Карта", id: 2, selected: false },
    { content: "Терминал", id: 3, selected: false },
    { content: "Перевод", id: 4, selected: false },
  ];
  public PaymentsStreamSources: Array<any> = [
    { content: "Задаток", id: 1, selected: true },
    { content: "Программа", id: 2, selected: false },
  ];

  public IsBirthdayBoy: boolean = false;
  public IsNewlyweds: boolean = false;


  constructor(public BanquetsService: BanquetsService, public BanquetZoneService: BanquetZoneService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public ToolsService: ToolsService) { }

  ngOnInit(): void {
    this.BanquetsService.BanquetTypes.forEach(
      (element) => {
        element.selected = false;
        if (this.BanquetsService.ActiveBanquet.CategoryName == element.content) {
          element.selected = true;
        }
      }
    );

  }

  OnBanquetChange() {
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  OnKepperSendedChange(event) {
    this.BanquetsService.ActiveBanquet.KepperSended = event.checked;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  OnSliderKitchenSendedChange(event) {
    this.BanquetsService.ActiveBanquet.SliderKitchenSended = event.checked;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }


  ToggleKeeperSendedStatus() {
    this.BanquetsService.ActiveBanquet.KepperSended = !this.BanquetsService.ActiveBanquet.KepperSended;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  ToggleKitchenSendedStatus() {
    this.BanquetsService.ActiveBanquet.SliderKitchenSended = !this.BanquetsService.ActiveBanquet.SliderKitchenSended;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }


  OnBanquetCategoryChange(event) {

    this.BanquetsService.ActiveBanquet.CategoryName = event.item.content;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);

  }

  OnChangeTargetName() {
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  OnChangeHours(event) {
    if (event.value != null) {
      this.BanquetsService.ActiveBanquet.DateHour = event.value;
    }
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  OnChangeMinutes(event) {
    if (event.value != null) {
      this.BanquetsService.ActiveBanquet.DateMinutes = event.value;
    }
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  SelectedArea(event) {
    this.BanquetZoneService.SetActiveAreaForDropdown(event.item.id);
  }

  SelectedTable(event) {
    this.BanquetsService.ActiveBanquet.Tables = new Array<BanquetTable>();
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.BanquetsService.ActiveBanquet.Tables.push(new BanquetTable(element.id, element.content));
    }
    this.BanquetsService.ActiveBanquet.Area = this.BanquetZoneService.SelectedArea;




    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);

  }

  ChnageChildrenCount(event) {
    if (event.value != null) {
      this.BanquetsService.ActiveBanquet.Children = event.value;
      this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
    }
  }

  ChnageGuestsCount(event) {
    if (event.value != null) {
      this.BanquetsService.ActiveBanquet.Guests = event.value;
      this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
    }

  }

  OnChangeAlcohol(event) {
    this.BanquetsService.ActiveBanquet.Alcohol = event.checked;
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }

  OpenPaymentModal() {

    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetPaymentsService.AddPayment(
        this.ModalAddingService.Fields[0].Value,
        1,
        this.ModalAddingService.Fields[1].ValueId,
        this.ModalAddingService.Fields[2].Value,
        this.ModalAddingService.Fields[3].Value,
        this.ModalAddingService.Fields[4].Value,
        this.ModalAddingService.Fields[5].ValueId,
      );
    });





    let dateOfCardPayment = ModalFieldBuilder.BuildText("Дата перевода", 0, 200);
    dateOfCardPayment.IsShowed = false;
    dateOfCardPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };

    let dateStartOfTerminalPayment = ModalFieldBuilder.BuildText("Дата перевода c ", 0, 200);
    dateStartOfTerminalPayment.IsShowed = false;
    dateStartOfTerminalPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };
    let dateEndOfTerminalPayment = ModalFieldBuilder.BuildText("Дата перевода по", 0, 200);
    dateEndOfTerminalPayment.IsShowed = false;
    dateEndOfTerminalPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };



    let dropDown = ModalFieldBuilder.BuildTabs("Способ оплаты", this.PaymentsSources, "Наличные");
    dropDown.ValueId = 1;
    dropDown.OnChangeValue = (id) => {
      dropDown.ValueId = id;
      if (id == 2) {
        dateOfCardPayment.IsShowed = true;
        dateOfCardPayment.Required = true;

        dateStartOfTerminalPayment.IsShowed = false;
        dateStartOfTerminalPayment.Required = false;

        dateEndOfTerminalPayment.IsShowed = false;
        dateEndOfTerminalPayment.Required = false;

      } else if (id == 3) {
        dateOfCardPayment.IsShowed = false;
        dateOfCardPayment.Required = false;

        dateStartOfTerminalPayment.IsShowed = true;
        dateStartOfTerminalPayment.Required = true;

        dateEndOfTerminalPayment.IsShowed = true;
        dateEndOfTerminalPayment.Required = true;

      } else {
        dateOfCardPayment.IsShowed = false;
        dateOfCardPayment.Required = false;

        dateStartOfTerminalPayment.IsShowed = false;
        dateStartOfTerminalPayment.Required = false;

        dateEndOfTerminalPayment.IsShowed = false;
        dateEndOfTerminalPayment.Required = false;
      }
      this.ModalAddingService.CheckRequired();
    };



    let dropDownPaymentStream = ModalFieldBuilder.BuildTabs("Тип задатка", this.PaymentsStreamSources, "Задаток");
    dropDownPaymentStream.ValueId = 1;
    dropDownPaymentStream.OnChangeValue = (id) => {
      dropDownPaymentStream.ValueId = id;
      this.ModalAddingService.CheckRequired();
    };






    let sumPayment = ModalFieldBuilder.BuildNumber("Сумма платежа", 0, 100000, 0, [500, 600, 800, 1000, 1500, 2000]);
    sumPayment.Required = true;
    sumPayment.InvalidValues.push(new NumberInvalidFieldValue(0, "Нужно задать значение суммы платежа"));
    sumPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };


    this.ModalAddingService.Show(
      new ModalData(`Добавить платёж в банкет ${this.BanquetsService.ActiveBanquet.Id}`, "Добавление платежа", "Добавить", "Отмена"),
      [
        sumPayment,
        dropDown,
        dateStartOfTerminalPayment,
        dateEndOfTerminalPayment,
        dateOfCardPayment,
        dropDownPaymentStream
      ]
    );
  }


  DateChange(event) {
    let date = event[0];
    this.BanquetsService.ActiveBanquet.Date = new Date(date + " UTC");
    this.BanquetsService.SaveBanquet(this.BanquetsService.ActiveBanquet);
  }




}
