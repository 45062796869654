import { Injectable } from '@angular/core';
import { OnModalConfirmEvent, OnModalCancelEvent } from '../models/tools/events.model';


@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  private modalTitle: string = "Modal Title";
  public get ModalTitle(): string {
    return this.modalTitle;
  }

  private descriptionNotification: string = "";
  public get DescriptionNotification(): string {
    return this.descriptionNotification;
  }

  private modalText: string = "Modal text";
  public get ModalText(): string {
    return this.modalText;
  }

  private confirmText: string = "Modal text";
  public get ConfirmText(): string {
    return this.confirmText;
  }

  private cancelText: string = "Modal text";
  public get CancelText(): string {
    return this.cancelText;
  }

  private isOpened: boolean = false;
  public get IsOpened(): boolean {
    return this.isOpened;
  }

  private type: string = "empty";
  public get Type(): string {
    return this.type;
  }

  public Answer: any;



  private onConfirmEvents: Array<OnModalConfirmEvent> = new Array<OnModalConfirmEvent>();
  private onCancelEvents: Array<OnModalCancelEvent> = new Array<OnModalCancelEvent>();

  constructor() { }


  OpenModal(title: string, text: string, descriptionNotification: string, cancelText: string, confirmText: string, type: string = "empty") {
    this.modalTitle = title;
    this.modalText = text;

    this.confirmText = confirmText;
    this.cancelText = cancelText;
    this.descriptionNotification = descriptionNotification;

    this.isOpened = true;

    this.type = type;
  }

  AddConfirmEvent(event: OnModalConfirmEvent) {
    this.onConfirmEvents.push(event);
  }

  AddCancelEvent(event: OnModalCancelEvent) {
    this.onCancelEvents.push(event);
  }

  Reset() {
    this.onConfirmEvents = new Array<OnModalConfirmEvent>();
    this.onCancelEvents = new Array<OnModalCancelEvent>();
    this.isOpened = false;
    this.modalTitle = "";
    this.modalText = "";
    this.confirmText = "";
    this.cancelText = "";
    this.isOpened = false;
    this.type = "empty";

  }


  OnConfirm() {
    this.onConfirmEvents.forEach(event => {
      event(this.Answer);
    });
    this.Reset();
  }

  OnCancel() {
    this.onCancelEvents.forEach(event => {
      event(this.Answer);
    });
    this.Reset();
  }

}
