<div class="courier_list_wrapper" fxLayout="column" fxLayoutGap="16px">
    
    
    <div *ngFor="let delivery of DeliveryService.ActiveOrderGroups" class="courier_item" 
    [routerLink]="['/courier-open', delivery.id]">
        <div class="count_of_order">{{delivery.Orders.length}} адреса развоза</div>
        <div class="name">Развоз №{{delivery.Id}}</div>
        <div class="courier_bottom" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="time">{{delivery.TimeDifference}}</div>
            <svg ibmIconArrowRight size="16" class="arrow_icon"></svg>
        </div>
    </div>


    

</div>




<div class="shadow_haze" 
[ngClass]="{'showed_filter': DeliveryService.IsLoading}"
fxLayout="row" fxLayoutAlign="center center">
    <ibm-loading [isActive]="DeliveryService.IsLoading"></ibm-loading>
</div>