import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { DeliveryService } from '../../../services/delivery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { System } from '../../../models/tools/system.model';
import { ConfirmationService } from '../../../services/confirmation.service';
import { OrderGroup } from 'src/app/models/store/order-group.model';
import { PropertyService } from 'src/app/services/property.service';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';
import { CouriersService } from 'src/app/services/order/couriers.service';

@Component({
  selector: 'app-order-formation-courier',
  templateUrl: './order-formation-courier.component.html',
  styleUrls: ['./order-formation-courier.component.scss']
})
export class OrderFormationCourierComponent implements OnInit {


  public steps: Array<any> = [
    {
      id: 0,
      name: "Готовые заказы",
      state: "current",
    },
    {
      id: 1,
      name: "Группы",
      state: "complete",
    },

  ];
  public CurrentStep: any;
  public IsFilterOpened: boolean = false;
  public selectedIds: Array<any> = new Array<any>();
  public selectedGroupsIds: Array<any> = new Array<any>();
  public UngroupActive: number = 1;

  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    private route: ActivatedRoute, private router: Router,
    public DeliveryService: DeliveryService,
    public ConfirmationService: ConfirmationService,
    public PropertyService: PropertyService,
    public CouriersService: CouriersService) {
    this.CurrentStep = this.steps[0];
  }

  ngOnInit(): void {

    this.HeaderService.ClearAll();
    this.HeaderService.BackButton.Text = "Список доставки";
    this.HeaderService.BackButton.Link = "/deliveries";
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Заказ", "/deliveries");
    this.HeaderService.AddBreadcrumb("Формирование доставки", "", true);
    this.HeaderService.WindowName = "Формирование доставки";
    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });

    this.HeaderService.AddSubActions(new NavigationAction("Заказы", "collapse", () => {
      this.IsFilterOpened = !this.IsFilterOpened;
    }));
  }



  selected(event) {
    if (event.selected) {
      this.selectedIds.push(event.value);
    } else {
      this.selectedIds = this.selectedIds.filter(function (obj) {
        return obj !== event.value;
      });
    }
  }

  SelectTab(event) {
    this.UngroupActive = event;
  }


  OnGroupClick() {
    if (this.selectedIds.length > 0) {
      this.OrdersService.AddShipToGroup(this.selectedIds);
      this.selectedIds = new Array<any>();
    }
  }

  OnStartGroupClick() {


    this.ConfirmationService.Reset();

    this.ConfirmationService.AddConfirmEvent(
      (answer: string) => {
        if (this.selectedGroupsIds.length > 0) {
          this.DeliveryService.StartGroup(this.selectedGroupsIds, this.CouriersService.ActiveCourier);
          this.selectedGroupsIds = new Array<any>();
        }
      });


    let countOfOchagbox: number = 0;
    this.selectedGroupsIds.forEach(id => {
      let group: OrderGroup = this.DeliveryService.OrderGroups.find((group) => {
        return group.Id == id;
      });

      group.Orders.forEach(order => {
        if (order.Total > 600) {
          countOfOchagbox++;
        }
      });
    });

    let descriptionNotification: string = "";
    if (countOfOchagbox > 0) {
      descriptionNotification = `Не забудте положить ОЧАГBOX в ${countOfOchagbox} ${this.PropertyService.EndingWordOfNumber(countOfOchagbox, ["заказ", "заказа", "заказов"])}!`;
    }

    this.ConfirmationService.OpenModal(
      "Отправить заказы курьеру?",
      "Подтвердите, что вы хотите отправить заказ на распредиление доставки",
      descriptionNotification,
      "Отменить",
      "Начать");




  }

  OnClickProgressIndicator(step) {
    this.CurrentStep.state = "complete";
    this.CurrentStep = step;
    this.CurrentStep.state = "current";
  }

}
