import { Component, OnInit, Input } from '@angular/core';
import { Note } from '../../../../models/store/note.model';
import { Order } from '../../../../models/store/order.model';
import { HttpClient } from '@angular/common/http';
import { System } from '../../../../models/tools/system.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { OrderNotesService } from '../../../../services/order/order-notes.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-note',
  templateUrl: './order-note.component.html',
  styleUrls: ['./order-note.component.scss']
})
export class OrderNoteComponent implements OnInit {

  @Input('notes') Notes: Array<Note>;
  @Input('delivery') delivery: Order;
  @Input('type') orderTypeId: Order;
  @Input('showOnly') showOnly: boolean = false;

  public open: boolean = false;




  constructor(private http: HttpClient, public OrdersService: OrdersService,
    public OrderNotesService: OrderNotesService, public ModalAddingService: ModalAddingService,
  public PropertyService: PropertyService) { }

  ngOnInit() {
    this.OrderNotesService.Notes = this.Notes;
    this.OrderNotesService.delivery = this.delivery;
    this.OrderNotesService.orderTypeId = this.orderTypeId;
    this.OrderNotesService.showOnly = this.showOnly;
  }


  OpenModalEditingNote(note: Note) {
    this.OrderNotesService.IsOpenedAddingNoteModal = true;
    this.OrderNotesService.AddedNoteTitlte = note.Title;
    this.OrderNotesService.AddedNoteComment = note.Comment;
    this.OrderNotesService.SelectedId = note.Id;
    this.OrderNotesService.EditingNote = note;

    this.OrderNotesService.Notes = this.Notes;
    this.OrderNotesService.delivery = this.delivery;
    this.OrderNotesService.orderTypeId = this.orderTypeId;
    this.OrderNotesService.showOnly = this.showOnly;

  }

  OpenModalAddingNote() {
    this.OrderNotesService.IsOpenedAddingNoteModal = true;

    this.OrderNotesService.Notes = this.Notes;
    this.OrderNotesService.delivery = this.delivery;
    this.OrderNotesService.orderTypeId = this.orderTypeId;
    this.OrderNotesService.showOnly = this.showOnly;

  }

  RemoveNote(note) {
    
    this.ModalAddingService.Reset();
    this.ModalAddingService.OnConfirm = () => {
      this.ModalAddingService.Reset();
      this.OrderNotesService.RemoveNote(note);
      
      const index = this.Notes.indexOf(note, 0);
      if (index > -1) {
        this.Notes.splice(index, 1);
      }
    }
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(
      new ModalData("Удаление заметки", "", "Удалить", "Отмена"), [ ]
    );
  }







}
