import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'largeNumber'
})
export class LargeNumberPipe implements PipeTransform {
    
    constructor(private domSanitizer: DomSanitizer) {

    }

    transform(number: number) {
        let stringNumber = number.toString();
        if (number > 999 && number < 10000) {
            stringNumber = number.toString().substr(0,1) + " " + number.toString().substr(1);
        }
        if (number > 9999) {
            stringNumber = number.toString().substr(0,2) + " " + number.toString().substr(2);
        }
        return stringNumber;
        
    }
}