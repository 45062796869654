import { Component } from '@angular/core';
import { HeaderService } from './services/header.service';
import { OrdersService } from './services/order/orders.service';
import { PropertyService } from './services/property.service';
import { System } from './models/tools/system.model';
import { ActivatedRoute, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { OrderStatus } from './models/store/orser-status.model';
import { NotificationService } from './services/notification.service';
import { NotificationAlert } from './models/tools/notification.model';
import { EquipmentService } from './services/equipment.service';
import { BanquetCommentService } from './services/banquet/banquet-comment.service';
import { DeliveryService } from './services/delivery.service';
import { ConfirmationService } from './services/confirmation.service';
import { ReviewService } from './services/review.service';
import { BanquetsService } from './services//banquet/banquets.service';
import { BanquetPaymentsService } from './services/banquet/banquet-payments.service';
import { ModalAddingService } from './services/banquet/modal/modal-adding.service';
import { HttpClient } from '@angular/common/http';
import { BanquetZoneService } from './services/banquet/banquet-zone.service';
import { FieldType } from './models/tools/fields/field-types.enum';
import { OchagboxService } from './services/ochagbox.service';
import { MessagingService } from './services/messaging.service';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { OchagboxHistoryService } from './services/ochagbox-history.service';
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { AddressService } from './services/order/address.service';
import { ProductsService } from './services/products.service';
import { AuthorizationService } from './services/authorization.service';
import { CouriersService } from './services/order/couriers.service';

export class Message {

  constructor(public title: string, public body: string, public iconUrl: string) { }

}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {


  message;
  messages: Array<Message> = [];

  public get FieldType(): typeof FieldType {
    return FieldType;
  }

  keyword = 'name';

  private selectedCategory;
  public get SelectedCategory(): any {
    return this.selectedCategory;
  }
  public set SelectedCategory(category: any) {
    this.selectedCategory = category;
    this.ProductsService.ReloadProducts(category);
  }


  constructor(public OrdersService: OrdersService, public PropertyService: PropertyService,
    private route: ActivatedRoute, private router: Router, public NotificationService: NotificationService,
    public HeaderService: HeaderService, private EquipmentsService: EquipmentService,
    public DeliveryService: DeliveryService, public ConfirmationService: ConfirmationService,
    public reviewService: ReviewService, public BanquetsService: BanquetsService,
    private http: HttpClient, public BanquetPaymentsService: BanquetPaymentsService,
    public ModalAddingService: ModalAddingService, public BanquetCommentService: BanquetCommentService,
    public BanquetZoneService: BanquetZoneService, public ochagboxService: OchagboxService, public ochagboxHistoryService: OchagboxHistoryService,
    private messagingService: MessagingService, private msg: AngularFireMessaging,
    public AddressService: AddressService, public Produc: AddressService, public ProductsService: ProductsService,
    public AuthorizationService: AuthorizationService, public CouriersService: CouriersService
  ) {



    this.router.events
      .subscribe(
        (event: any) => {
          if (event instanceof NavigationStart) {

          }
        });


    AuthorizationService.ClearOnAuthorizationEvent();
    AuthorizationService.AddOnAuthorizationEvent(() => {
      this.InitializeAuthObjects();
    });


    HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      this.router.navigate(['/login']);
    });



    System.getInstance().Property = this.PropertyService;
    if (this.PropertyService.Token == "") {
      this.router.navigate(['/login']);
    } else {
      this.InitializeAuthObjects();
    }
  }

  InitializeAuthObjects() {
    this.AddressService.OnLoadAddressEvents.push(
      () => {
        this.CouriersService.LoadCouriers();
        this.ProductsService.LoadProducts();
        this.ProductsService.LoadCategories();
        this.OrdersService.LoadOrders();
        this.EquipmentsService.LoadEquipments();
        this.reviewService.LoadReviews();
        this.ochagboxService.LoadSupply();
        this.ochagboxHistoryService.LoadHistory();
        this.OrdersService.OnLoadShipEvents.push(
          () => {
            this.DeliveryService.LoadOrderGroup();
          }
        );
        if (this.PropertyService.userGroupId == 12) {
          //this.DeliveryService.LoadNewOrderGroup();
        }
      }
    );
    this.AddressService.LoadAddress();


    if (this.PropertyService.userGroupId == 14 || this.PropertyService.userGroupId == 1) {
      this.BanquetsService.OnLoadBanquetEvents.push(
        () => {
          this.BanquetPaymentsService.LoadPayments();
        }
      );

      this.BanquetsService.LoadBanquetType(
        () => {
          this.BanquetsService.LoadProgramDays(
            () => {
              this.BanquetsService.LoadBanquets();
              this.BanquetCommentService.LoadCommentType();
              this.BanquetZoneService.LoadAreas();
              this.BanquetZoneService.LoadTableGroups();
              this.BanquetZoneService.LoadTables();
            });
        }
      );





    }

    this.msg.requestToken.subscribe(token => {
      this.PropertyService.FbmToken = token;
      var request = "";
      request = `https://ochagzp.com.ua/index.php?route=api/v2/notification/RegisterFirebaseToken&token=${this.PropertyService.Token}&fbm_token=${token}`;
      this.http.get(request).subscribe(
        (data: any) => {

        });

    }, error => {
      //console.log(error);
    });
  }

  ngOnInit() {
    //this.messagingService.requestPermission();
    //this.messagingService.receiveMessage();
    //this.message = this.messagingService.currentMessage;

    //this.requestPermission();
    //this.listen();




    this.msg.onMessage((payload) => {
      let notification = payload.notification;
      this.messages.push({ title: notification.title, body: notification.body, iconUrl: notification.icon });

    });
  }


  listen() {

  }

  requestPermission() {

  }






  CloseAllMenu() {
    this.HeaderService.IsNotificationsListOpened = false;
    this.HeaderService.IsReviewOpened = false;
    this.HeaderService.IsUserOpened = false;


  }

  CloseReviewModal() {
    this.reviewService.OnCancel();
    this.HeaderService.IsReviewOpened = false;
  }

  SendReview() {
    this.reviewService.Send();
    this.HeaderService.IsReviewOpened = false;
  }











  ChangePaymentType(event) {
    this.BanquetPaymentsService.AddedPaymentsTypeId = event.item.id;
  }
  ChangePaymentSource(event) {
    this.BanquetPaymentsService.AddedPaymentsSourceId = event.item.id;
  }






  OpenAddPaymentModal() {
    this.BanquetPaymentsService.IsOpenedAddingModal = true;
  }

  CloseAddPaymentModal() {
    this.BanquetPaymentsService.IsOpenedAddingModal = false;
  }






  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.animation;
  }





  selectEvent(item) {
    this.ProductsService.SelectedAddedProduct = item.id;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  OpenModalAddingProduct() {
    this.ProductsService.IsOpenedAddingModal = true;
  }

  CloseModalAddingProduct() {
    this.ProductsService.IsOpenedAddingModal = false;
  }



}
