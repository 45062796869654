import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { Product } from '../../../../models/store/product.model';
import { Order } from '../../../../models/store/order.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { ConfirmationService } from '../../../../services/confirmation.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { NumberInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/number-invalid-filed-value.model';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.scss']
})
export class OrderCartComponent implements OnInit {

  @Input('products') products: Array<Product>;
  @Input('originals') originals: Array<Product> = new Array<Product>();
  @Input('delivery') delivery: Order;
  @Input('showOnly') showOnly: boolean = false;
  @Output() onAddedProduct = new EventEmitter<Product>();

  public SelectedAddedProduct: number = 1;
  public AddedCount: number = 1;

  @Input('open') open: boolean = false;







  constructor(public ProductsService: ProductsService, public DeliveryService: OrdersService,
    public ModalAddingService: ModalAddingService, public ConfirmationService: ConfirmationService,
    public PropertyService: PropertyService,) {

    this.ProductsService.AddModalAddingProduct = () => {
      this.AddModalAddingProduct();
    }

  }

  ngOnInit() {

    this.products.forEach(product => {
      //product.IsComparisonFromOriginal = 
    });
  }


  AddProduct(product) {
    product.count++;

    this.DeliveryService.ChangeProductCount(this.delivery, product);
  }

  RemoveProduct(product: Product) {

    this.ConfirmationService.AddConfirmEvent(
      (answer) => {
        this.DeliveryService.RemoveProduct(this.delivery, product);
      });
    this.ConfirmationService.OpenModal(
      "Подтвердить удаление",
      `Подтвердите, что вы хотите удалить продукт «${product.Name}»`,
      "",
      "Отменить",
      "Удалить");


  }

  MinusProduct(product) {
    if (product.count > 1) {
      product.count--;
      this.DeliveryService.ChangeProductCount(this.delivery, product);
    }
  }


  AddModalAddingProduct() {



    this.ProductsService.IsOpenedAddingModal = false;

    let addedProductId = this.ProductsService.SelectedAddedProduct;
    let addedProductName = this.ProductsService.GetProduct(this.ProductsService.SelectedAddedProduct).Name;
    let addedProductCount = this.ProductsService.AddedCount;
    let addedProductPrice = this.ProductsService.GetProduct(this.ProductsService.SelectedAddedProduct).Price;
    let addedProductComment = this.ProductsService.AddedComment;

    let AddedProduct = new Product(addedProductId, addedProductName, addedProductPrice, addedProductCount, addedProductComment);
    this.products.push(AddedProduct);


    this.DeliveryService.AddProduct(this.delivery, AddedProduct);

    this.ProductsService.AddedCount = 1;
    this.ProductsService.SelectedAddedProduct = 1;




  }

  OpenModalAddingProduct() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.AddModalAddingProduct();
      this.ModalAddingService.CheckRequired();
    });


    let productsAutocomplete = ModalFieldBuilder.BuildAutocomplete("Позиция меню",
      this.ProductsService.ProductsAutocomplete, "");
    productsAutocomplete.Required = true;
    productsAutocomplete.ValueId = null;
    productsAutocomplete.Value = "";
    productsAutocomplete.OnChangeValue = (event) => {
      productsAutocomplete.ValueId = event.id;
      productsAutocomplete.Value = event.name;
      this.ProductsService.SelectedAddedProduct = event.id;
      this.ModalAddingService.CheckRequired();
    };

    let categoryDropDown = ModalFieldBuilder.BuildDropdown("Категория меню", this.ProductsService.CategoriesDropDaown, "");
    categoryDropDown.ValueId = 1;
    categoryDropDown.OnChangeValue = (event) => {
      categoryDropDown.ValueId = event.item.id;
      this.ProductsService.ReloadProducts(event.item.id);
      this.ModalAddingService.CheckRequired();

    };

    let commentProduct = ModalFieldBuilder.BuildText("Комментарий", 0, 200);
    commentProduct.OnChangeValue = (event) => {
      this.ProductsService.AddedComment = event.target.value;
      this.ModalAddingService.CheckRequired();
    };

    let countProducts = ModalFieldBuilder.BuildNumber("Количество позиций", 0, 100000, 1, []);
    countProducts.Required = true;
    countProducts.InvalidValues.push(new NumberInvalidFieldValue(0, "Нужно задать значение количество позиций"));
    countProducts.OnChangeValue = (event) => {

      if (event.value != null) {
        countProducts.Value = event.value;
        this.ProductsService.AddedCount = event.value;
        this.ModalAddingService.CheckRequired();
      }


    };

    this.ModalAddingService.Show(
      new ModalData("Добавить позицию меню", "Добавить позицию", "Добавить", "Отмена"),
      [
        categoryDropDown,
        productsAutocomplete,
        countProducts,
        commentProduct,
      ]
    );


  }


  ComparisonResultFromOriginal(product: Product, answer: number): boolean {
    if (this.originals.length == 0) {
      return false;
    }
    let finded = this.originals.find(original => original.Id == product.Id);
    let result: number = 0;
    if (finded != null) {
      if (finded.Count == product.Count) {
        result = 1;
      } else {
        result = 2;
      }
    } else {
      result = 3;
    }
    return answer == result;
  }


  ComparisonResultFromActual(product: Product, answer: number): boolean {
    if (this.products.length == 0) {
      return false;
    }
    let finded = this.products.find(original => original.Id == product.Id);

    if (finded != null) {
      return false;
    } else {
      return true;
    }

  }

  OriginalByProduct(product: Product) {
    let finded = this.originals.find(original => original.Id == product.Id);
    return finded;
  }









}
