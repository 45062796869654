import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { HeaderService } from '../../../../services/header.service';
import { PropertyService } from '../../../../services/property.service';
import { BanquetPaymentsService } from '../../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { BanquetVaultService } from '../../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../../services/banquet/banquet-zone.service';
import { HttpClient } from '@angular/common/http';
import { Banquet } from 'src/app/models/banquet/banquet';
import { BanquetBuilder } from 'src/app/models/banquet/banquet-builder';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';

@Component({
  selector: 'banquet-tables-form',
  templateUrl: './banquet-tables-form.component.html',
  styleUrls: ['./banquet-tables-form.component.scss']
})
export class BanquetTablesFormComponent implements OnInit {


  AreaFields: Array<any> = new Array<any>();
  SelectedAreaId: number = 1;

  SelectedDate: Date = new Date();
  TomorrowDate: Date = new Date();
  BanquetDate: Date = new Date();

  SelectedTableGroup: Array<any> = Array<any>();

  Area: any;

  SelectedDropdownElement;

  @Input("time") BanquetTimeId: number = 2;
  @Input("id") BanquetId: number = -1;
  @Input("banquet") Banquet: Banquet;
  @Input("readonly") IsReadOnly: boolean = false;

  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService,
    private router: Router, private http: HttpClient,) {


    this.BanquetZoneService.AddOnAreaLoadedEvent(
      () => {
        this.BanquetZoneService.Areas.forEach(element => {
          this.AreaFields.push({ id: element.Id, content: element.Name, selected: false });
        });
        this.SelectedDropdownElement = this.AreaFields[0];
      }
    );


  }

  ngOnInit(): void {
    if (this.Banquet != null) {
      this.BanquetDate = new Date(this.Banquet.Date);
      this.SelectedDate = new Date(this.Banquet.Date);
      this.TomorrowDate = new Date(this.Banquet.Date);
      this.TomorrowDate.setDate(this.TomorrowDate.getDate() + 1);


      this.BanquetsService.LoadReservedTables(this.Banquet.Area.Id, this.Banquet.Date, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);

      this.Area = this.Banquet.Area.Name;
    }
  }



  DateChange(event) {

    this.SelectedDate = event[0];
    this.SelectedDate = new Date(this.SelectedDate);
    this.SelectedDate.setDate(this.SelectedDate.getDate());

    this.TomorrowDate = new Date(this.SelectedDate);
    this.TomorrowDate.setDate(this.TomorrowDate.getDate() + 1);

    this.BanquetsService.LoadReservedTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId);

    this.Banquet.Date = this.SelectedDate;
    this.Banquet.DateHour = 1;

    this.BanquetsService.SaveBanquet(this.Banquet);
    this.BanquetsService.ChangeDateBanquet(this.Banquet);



  }

  OnAreaChange(event) {
    this.SelectedDropdownElement = event["item"];
    this.SelectedAreaId = event["item"]["id"];
    let SelectedAreaContent = event["item"]["content"];
    this.Banquet.Area = new BanquetArea(this.SelectedAreaId, SelectedAreaContent);
    this.BanquetsService.LoadReservedTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId);
  }


  SelectGroupTable(table) {

    if (this.IsReadOnly) {
      return;
    }

    //this.Banquet.AddTable(table);

    if (table.status == "free") {
      this.BanquetsService.AddSelectReserveTableGroup(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, table.id, this.BanquetTimeId);
    }
    if (table.status == "selected") {
      this.BanquetsService.RemoveSelectReserveTableGroup(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, table.id, this.BanquetTimeId);
    }

  }

  SelectTable(table) {

    if (this.IsReadOnly) {
      this.BanquetsService.OpenBanquetById(table.event_id);
      return;
    }



    if (table.status == "free") {
      this.BanquetsService.AddSelectReserveTable(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, table.id, this.BanquetTimeId);
    }
    if (table.status == "selected") {
      this.BanquetsService.RemoveSelectReserveTable(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, table.id, this.BanquetTimeId);
    }
  }

  ReserveAllTables() {
    this.BanquetsService.ReserveAllTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId);
  }

  UnreserveAllTables() {
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId);
  }

  OnChangeHours(event) {
    if (event.value != null) {
      this.Banquet.DateHour = event.value;
    }
    this.BanquetsService.SaveBanquet(this.Banquet);
  }

  SetHours(hour: number) {
    this.Banquet.DateHour = hour;
    this.BanquetsService.SaveBanquet(this.Banquet);
  }

  OnChangeMinutes(event) {
    if (event.value != null) {
      this.Banquet.DateMinutes = event.value;
    }
    this.BanquetsService.SaveBanquet(this.Banquet);
  }

  SetMinutes(minutes: number) {
    this.Banquet.DateMinutes = minutes;
    this.BanquetsService.SaveBanquet(this.Banquet);
  }

  PrevArea() {
    let i = this.AreaFields.indexOf(this.SelectedDropdownElement);

    let len = this.AreaFields.length;
    let previous = this.AreaFields[(i + len - 1) % len];
    this.SelectedDropdownElement = previous;
    this.SelectedAreaId = previous.id;

    this.Area = previous.content;
    this.Banquet.Area = new BanquetArea(this.SelectedAreaId, previous.content);
    this.BanquetsService.LoadReservedTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId, false);
  }

  NextArea() {
    let i = this.AreaFields.indexOf(this.SelectedDropdownElement);

    let len = this.AreaFields.length;
    let next = this.AreaFields[(i + 1) % len];
    this.SelectedDropdownElement = next;
    this.SelectedAreaId = next.id;

    this.Area = next.content;
    this.Banquet.Area = new BanquetArea(this.SelectedAreaId, next.content);
    this.BanquetsService.LoadReservedTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId, false);
  }

  ToggleBanquetTime(timeId: number) {
    this.BanquetTimeId = timeId;
    this.BanquetsService.LoadReservedTables(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.BanquetId, this.BanquetTimeId);
    this.BanquetsService.RemoveAllTablesFromBanquet(this.SelectedAreaId, this.SelectedDate, this.TomorrowDate, this.Banquet, this.BanquetTimeId, false);
  }

}
