<div class="delivery_wrapper" [ngClass]="{'showed_filter': IsFilterOpened, 'show_only': PropertyService.UserGroupId != 1}" fxLayout="row" fxLayoutGap="40px">

    <div class="filter">
        <app-orders-standart-filter></app-orders-standart-filter>
    </div>
    <div class="content">


        <ibm-search size="sm" (valueChange)="valueChange($event)" (clear)="clear()" theme="light">
        </ibm-search>

        <div class="list_wrapper" *ngIf="PropertyService.UserGroupId == 13">
            <ibm-structured-list>
                <ibm-list-header>
                    <ibm-list-column>Заказ</ibm-list-column>
                    <ibm-list-column>Статус</ibm-list-column>

                    <ibm-list-column>Осталось времени</ibm-list-column>
                    <ibm-list-column>Поступления</ibm-list-column>

                    <ibm-list-column>Стоимость</ibm-list-column>
                    <ibm-list-column>Примечания</ibm-list-column>
                </ibm-list-header>



                <ibm-list-row *ngFor="let delivery of OrdersService.VisibleObjects.Values" class="delivery_item" [ngClass]="{'warning': delivery.IsDifferenceOverdue, 'complete': delivery.StatusId == 5, 'canceled': delivery.StatusId == 9, 'new': delivery.StatusId == 1}" (click)="OpenDelivery($event, delivery)">
                    <ibm-list-column>
                        <div class="name_wrapper" fxLayout="column">
                            <span class="order_id" style="margin-bottom: 4px;">№{{delivery.Id}}</span>
                            <span class="order_id">{{delivery.ShippingAdress}}</span>
                            <a [href]="'tel:+'+delivery.Customer.Phone" (click)="ClipboardService.Copy(delivery.Customer.Phone)" class="no-wrap">{{delivery.Customer.Phone}}</a>

                        </div>

                    </ibm-list-column>
                    <ibm-list-column class="order_status no-wrap" [ngClass]="{
                        'expectation': delivery.Status.Id==1, 
                        'processing': delivery.Status.Id==2,  
                        'confirmed': delivery.Status.Id==3, 
                        'formed_delivery': delivery.Status.Id==14, 
                        'received ': delivery.Status.Id==16, 
                        'delivered': delivery.Status.Id==15, 
                        'completed': delivery.Status.Id==5,
                        'keeper': delivery.Status.Id==19,
                        'pickup-ready': delivery.Status.Id==20
                    }">
                        {{delivery.Status.Name}}</ibm-list-column>

                    <ibm-list-column class="order_info_column order_timer">
                        {{delivery.TimeDifference}}
                    </ibm-list-column>

                    <ibm-list-column class="order_info_column order_time">
                        <span *ngIf="IsTimeOnly(delivery)">{{delivery.DateAdded | date:'HH:mm'}}</span>
                        <span *ngIf="IsDateOnly(delivery)">{{delivery.DateAdded | date:'dd.MM HH:mm'}}</span>
                        <span *ngIf="IsFullDateOnly(delivery)">{{delivery.DateAdded | date:'dd.MM.yyyy HH:mm'}}</span>
                    </ibm-list-column>


                    <ibm-list-column class="order_info_column">₴ {{delivery.total | number:'1.0-0'}}</ibm-list-column>
                    <ibm-list-column class="order_info_column" fxLayout="row" fxLayoutGap="8px">

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Оплата картой'" *ngIf="delivery.CardPayment">
                            <svg class="purchase_icon" [ngClass]="{'warning': delivery.CardPayment && !delivery.Paymented}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#purchase"></use>
                            </svg>
                        </ibm-tooltip-icon>

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Очаг BOX'" *ngIf="delivery.Total > 500">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#gift"></use>
                            </svg>
                        </ibm-tooltip-icon>

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Не требуется подтверждение'" *ngIf="delivery.IsNotCall">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#no-phone"></use>
                            </svg>
                        </ibm-tooltip-icon>


                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Указано точное время доставки'" *ngIf="delivery.IsReadinessShipping == false">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#date-time"></use>
                            </svg>
                        </ibm-tooltip-icon>




                    </ibm-list-column>
                </ibm-list-row>


            </ibm-structured-list>
            <button class="load_more_button" (click)="LoadMore()">Еще</button>

        </div>

        <div class="list_wrapper" *ngIf="PropertyService.UserGroupId == 15">
            <ibm-structured-list>
                <ibm-list-header>
                    <ibm-list-column>Заказ</ibm-list-column>
                    <ibm-list-column>Статус</ibm-list-column>

                    <ibm-list-column>Осталось времени</ibm-list-column>
                    <ibm-list-column>Поступления</ibm-list-column>

                    <ibm-list-column>Стоимость</ibm-list-column>
                    <ibm-list-column>Примечания</ibm-list-column>
                </ibm-list-header>



                <ibm-list-row *ngFor="let delivery of OrdersService.ObjectsInPickShip.Values" class="delivery_item" [ngClass]="{'warning': delivery.IsDifferenceOverdue, 'complete': delivery.StatusId == 5, 'canceled': delivery.StatusId == 9, 'new': delivery.StatusId == 1}" (click)="OpenDelivery($event, delivery)">
                    <ibm-list-column>
                        <div class="name_wrapper" fxLayout="column">
                            <span class="order_id">№{{delivery.Id}}</span>
                            <a [href]="'tel:+'+delivery.Customer.Phone" (click)="ClipboardService.Copy(delivery.Customer.Phone)" class="no-wrap">{{delivery.Customer.Phone}}</a>

                        </div>

                    </ibm-list-column>
                    <ibm-list-column class="order_status no-wrap" [ngClass]="{
                        'expectation': delivery.Status.Id==1, 
                        'processing': delivery.Status.Id==2,  
                        'confirmed': delivery.Status.Id==3, 
                        'formed_delivery': delivery.Status.Id==14, 
                        'received ': delivery.Status.Id==16, 
                        'delivered': delivery.Status.Id==15, 
                        'completed': delivery.Status.Id==5
                    }">
                        {{delivery.Status.Name}}</ibm-list-column>

                    <ibm-list-column class="order_info_column order_timer">
                        {{delivery.TimeDifference}}
                    </ibm-list-column>

                    <ibm-list-column class="order_info_column order_time">
                        <span *ngIf="IsTimeOnly(delivery)">{{delivery.DateAdded | date:'HH:mm'}}</span>
                        <span *ngIf="IsDateOnly(delivery)">{{delivery.DateAdded | date:'dd.MM HH:mm'}}</span>
                        <span *ngIf="IsFullDateOnly(delivery)">{{delivery.DateAdded | date:'dd.MM.yyyy HH:mm'}}</span>
                    </ibm-list-column>


                    <ibm-list-column class="order_info_column">₴ {{delivery.total | number:'1.0-0'}}</ibm-list-column>
                    <ibm-list-column class="order_info_column" fxLayout="row" fxLayoutGap="8px">

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Оплата картой'" *ngIf="delivery.CardPayment">
                            <svg class="purchase_icon" [ngClass]="{'warning': delivery.CardPayment && !delivery.Paymented}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#purchase"></use>
                            </svg>
                        </ibm-tooltip-icon>

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Очаг BOX'" *ngIf="delivery.Total > 500">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#gift"></use>
                            </svg>
                        </ibm-tooltip-icon>

                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Не требуется подтверждение'" *ngIf="delivery.IsNotCall">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#no-phone"></use>
                            </svg>
                        </ibm-tooltip-icon>


                        <ibm-tooltip-icon placement="bottom" alignment="end" [content]="'Указано точное время доставки'" *ngIf="delivery.IsReadinessShipping == false">
                            <svg class="purchase_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#date-time"></use>
                            </svg>
                        </ibm-tooltip-icon>




                    </ibm-list-column>
                </ibm-list-row>


            </ibm-structured-list>

        </div>



    </div>





</div>

<div class="shadow_haze" [ngClass]="{'showed_filter': IsFilterOpened || OrdersService.IsLoading, 'show_only': PropertyService.UserGroupId != 1}" fxLayout="row" fxLayoutAlign="center center" (click)="IsFilterOpened = false">
    <ibm-loading [isActive]="OrdersService.IsLoading"></ibm-loading>
</div>