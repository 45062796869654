import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { System } from 'src/app/models/tools/system.model';
import { HeaderService } from 'src/app/services/header.service';
import { PropertyService } from '../../../services/property.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit {

  constructor(private router: Router, public PropertyService: PropertyService,
  public HeaderService: HeaderService, private http: HttpClient,) { }

  ngOnInit(): void {
    
  }

  Logout() {
    System.getInstance().Property.Token = "";
    System.getInstance().Property.UserGroupId = 0;
    System.getInstance().Property.ClearCookies();
    this.HeaderService.IsUserOpened = false;
    this.router.navigate(['/login']);

    
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/notification/UnregisterFirebaseToken&token=${this.PropertyService.Token}&fbm_token=${this.PropertyService.FbmToken}`;
    this.http.get(request).subscribe(
      (data: any) => {

      });
  }

}
