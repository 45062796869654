import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { Product } from '../../../../models/store/product.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { NotificationService } from '../../../../services/notification.service';
import { ClipboardService } from '../../../../services/clipboard.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { Order } from '../../../../models/store/order.model';
import { Equipment } from '../../../../models/store/equipment.model';
import { AddressService } from 'src/app/services/order/address.service';
import { OrderStatus } from 'src/app/models/store/orser-status.model';
import { OrderDateManager } from 'src/app/models/store/order-date-manager';
import { PropertyService } from '../../../../services/property.service';
import { OrderHistory } from 'src/app/models/store/history.model';
import { OchagboxService } from '../../../../services/ochagbox.service';
import { OchagboxHistoryService } from 'src/app/services/ochagbox-history.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-express-collector',
  templateUrl: './order-express-collector.component.html',
  styleUrls: ['./order-express-collector.component.scss']
})
export class OrderExpressCollectorComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;
  @Input('shippingArea') shippingArea: any;



  constructor(public ProductsService: ProductsService, public OrdersService: OrdersService,
    public NotificationService: NotificationService, public ClipboardService: ClipboardService,
    public EquipmentsService: EquipmentService, public AddressService: AddressService,
    private changeDetectorRef: ChangeDetectorRef, public PropertyService: PropertyService,
    public OchagboxService: OchagboxService, public OchagboxHistoryService: OchagboxHistoryService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.UpdateOrderExpressInformation();
    this.route.params.subscribe(val => {
      this.UpdateOrderExpressInformation();
    });

  }

  UpdateOrderExpressInformation() {


  }

  TogglePaymentMethod() {

    if (this.delivery.ShippingMethod != "Самовывоз") {
      return;
    }


    if (this.delivery.PaymentMethod == "Оплата наличными") {
      this.delivery.CardPayment = true;
      this.delivery.PaymentMethod = "Оплата картой";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
    if (this.delivery.PaymentMethod == "Оплата картой") {
      this.delivery.CardPayment = false;
      this.delivery.PaymentMethod = "Терминал";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
    if (this.delivery.PaymentMethod == "Терминал") {
      this.delivery.CardPayment = false;
      this.delivery.PaymentMethod = "Без оплаты";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
    if (this.delivery.PaymentMethod == "Без оплаты") {
      this.delivery.CardPayment = false;
      this.delivery.PaymentMethod = "Оплата наличными";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
  }



  GetEquipmentInOrder(equipment: Equipment) {
    //this.delivery.E
    let finded = this.delivery.Equipments.find(deliveryEquipment => deliveryEquipment.Id == equipment.Id);
    if (finded == null) {
      return this.delivery.GloablEquipmentCount;
    } else {
      return finded.Count;
    }
  }

}
