export class BanquetFilter {

    private name: string = "";
    private price: number = -1;
    private banquetPaymentTypeId: number = 0;
    private banquetPaymentSourceId: number = 0;
    private banquetTableId: number = 0;
    private eventId: number = 0;
    private status: number = 0;
    private guests: number = 0;
    private deposit: number = 0;
    private children: number = 0;
    private alcohol: boolean = null;
    private confirmed: boolean = null;
    private categoryId: number = 0;

    private date: Array<Date> = null;
    private dateAdded: Array<Date> = null;
    private dateModified: Array<Date> = null;



    private customNumber: string = null;
    private depositOperation: string = null;
    private countGuestsOperation: string = null;
    private totalOperation: string = null;
    private childCountOperation: string = null;
    private isManagerCreated: boolean = false;
    private isCustomerCreated: boolean = false;
    private kitchenStatus: number = -1;
    private keeperStatus: number = -1;


    public SetFilterName(name: string) {
        this.name = name;
    }

    public SetFilterCustomNumber(customNumber: string) {
        this.customNumber = customNumber;
    }

    public SetDate(before: Date, after: Date) {
        this.date = Array<Date>();
        this.date.push(before);
        this.date.push(after);
    }

    public SetAddedDate(before: Date, after: Date) {
        this.dateAdded = Array<Date>();
        this.dateAdded.push(before);
        this.dateAdded.push(after);
    }

    public SetConfirmStatus(confirmed: boolean) {
        this.confirmed = confirmed;
    }

    public SetAlcoholStatus(alcohol: boolean) {
        this.alcohol = alcohol;
    }

    public SetPaymentType(paymentType: number) {
        this.banquetPaymentTypeId = paymentType;
    }
    
    
    public SetKeeperStatus(keeperStatus: number) {
        this.keeperStatus = keeperStatus;
    }
    public SetKitchenStatus(kitchenStatus: number) {
        this.kitchenStatus = kitchenStatus;
    }
    public SetTable(banquetTableId: number) {
        this.banquetTableId = banquetTableId;
    }



    public SetDepositOperation(depositOperation: string) {
        this.depositOperation = depositOperation;
    }

    public SetTotalOperation(totalOperation: string) {
        this.totalOperation = totalOperation;
    }

    public SetChildCountOperation(childCountOperation: string) {
        this.childCountOperation = childCountOperation;
    }

    public SetGuestsCountOperation(countGuestsOperation: string) {
        this.countGuestsOperation = countGuestsOperation;
    }



    public SetCountValue(guests: number) {
        this.guests = guests;
    }

    public SetChildCountValue(children: number) {
        this.children = children;
    }

    public SetTotalValue(price: number) {
        this.price = price;
    }

    public SetDepositValue(deposit: number) {
        this.deposit = deposit;
    }

    public SetCreateMethod(methid: number) {
        if (methid == 2) {
            this.isManagerCreated = true;
            this.isCustomerCreated = false;
        }
        if (methid == 3) {
            this.isManagerCreated = false;
            this.isCustomerCreated = true;
        }
    }



    public GetFilter(): Object {
        let filterObject: any = {};
        if (this.name != "") {
            filterObject.name = {
                value: this.name,
                "operation": "="
            }
        }
        if (this.customNumber != null && this.customNumber != "") {
            filterObject.custom_number = {
                value: this.customNumber,
                "operation": "="
            }
        }


        if (this.guests != 0 && this.countGuestsOperation != null) {
            filterObject.guests = {
                value: this.guests,
                "operation": this.countGuestsOperation
            }
        }
        if (this.children != 0 && this.childCountOperation != null) {
            filterObject.children = {
                value: this.children,
                "operation": this.childCountOperation
            }
        }
        if (this.price != -1 && this.totalOperation != null) {
            filterObject.price = {
                value: this.price,
                "operation": this.totalOperation
            }
        }


        
    
        if (this.keeperStatus != null && this.keeperStatus != -1) {
            filterObject.kepper_sended = {
                value: this.keeperStatus,
                "operation": "="
            }
        }
        if (this.kitchenStatus != null && this.kitchenStatus != -1) {
            filterObject.slider_kitchen_sended = {
                value: this.kitchenStatus,
                "operation": "="
            }
        }

        if (this.isManagerCreated) {
            filterObject.is_manager_created = true;
        }

        if (this.isCustomerCreated) {
            filterObject.is_customer_created = true;
        }


        if (this.confirmed != null) {
            filterObject.confirmed = {
                value: this.confirmed,
                "operation": "="
            }
        }
        if (this.alcohol != null) {
            filterObject.alcohol = {
                value: this.alcohol,
                "operation": "="
            }
        }
        if (this.banquetPaymentTypeId != null && this.banquetPaymentTypeId != 0) {
            filterObject.banquet_payment_type_id = {
                value: this.banquetPaymentTypeId,
                "operation": "="
            }
        }
        if (this.banquetTableId != 0) {
            filterObject.banquet_table_id = {
                value: this.banquetTableId,
                "operation": "="
            }
        }
        // if (this.banquetPaymentTypeId != null) {
        //     filterObject.banquet_payment_type_id = {
        //         value: this.banquetPaymentTypeId,
        //         "operation": "="
        //     }
        // }
        if (this.dateAdded != null) {
            let beforeDate = this.dateAdded[0];
            let afterDate = new Date(this.date[1]);
            afterDate.setDate(afterDate.getDate() + 1);
            filterObject.date_added = {
                before: beforeDate.getFullYear() + "-" + this.twoDigits(1 + beforeDate.getMonth()) + "-" + this.twoDigits(beforeDate.getDate()),
                after: afterDate.getFullYear() + "-" + this.twoDigits(1 + afterDate.getMonth()) + "-" + this.twoDigits(afterDate.getDate()),
            }
        }

        if (this.date != null && this.date[0] != null &&  this.date[1] != null ) {
            let beforeDate = this.date[0];
            let afterDate = new Date(this.date[1]);
            afterDate.setDate(afterDate.getDate() + 1);
            filterObject.date = {
                before: beforeDate.getFullYear() + "-" + this.twoDigits(1 + beforeDate.getMonth()) + "-" + this.twoDigits(beforeDate.getDate()),
                after: afterDate.getFullYear() + "-" + this.twoDigits(1 + afterDate.getMonth()) + "-" + this.twoDigits(afterDate.getDate()),
            }
        }

        filterObject.banquet_direction_type_id = {
            value: 2,
            "operation": "="
        }

        return filterObject;
    }

    twoDigits(d) {
        if (0 <= d && d < 10) return "0" + d.toString();
        if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
        return d.toString();
    }

}

