export class Equipment{
    private id: number;
    public get Id(): number{
        return this.id;
    }

    private name: string;
    public get Name(): string{
        return this.name;
    }
    public set Name(name: string){
        this.name = name;
    }


    private count: number;
    public get Count(): number{
        return this.count;
    }
    public set Count(count: number){
        this.count = count;
    }


    private isGlobal: boolean;
    public get IsGlobal(): boolean{
        return this.isGlobal;
    }
    public set IsGlobal(isGlobal: boolean){
        this.isGlobal = isGlobal;
    }



    constructor(id: number, name: string, count: number = 1, isGlobal: number = 0){
        this.id = id;
        this.name = name;
        this.count = count;
        if(isGlobal == 1){
            this.isGlobal = true;
        } else {
            this.isGlobal = false;
        }
        
    }

}