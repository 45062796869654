import { Component, OnInit, Input } from '@angular/core';
import { OrderHistory } from '../../../../models/store/history.model';
import { Order } from '../../../../models/store/order.model';
import { System } from '../../../../models/tools/system.model';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-general-stage-reading',
  templateUrl: './order-general-stage-reading.component.html',
  styleUrls: ['./order-general-stage-reading.component.scss']
})
export class OrderGeneralStageReadingComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  items: Array<any> = [
    { content: "Александровский район", id: 0 },
    { content: "Вознесеновский район", id: 1 },
    { content: "Днепровский район", id: 2 },
    { content: "Заводской район", id: 3 },
    { content: "Коммунарский район", id: 4 },
    { content: "Космический район", id: 5 },
    { content: "Хортицкий район", id: 6 },
    { content: "Шевченковский район", id: 7 },
  ];

  constructor(public PropertyService: PropertyService) { }

  ngOnInit() {
    let i = 0;
    this.delivery.Histories.forEach(history => {
      if (i > 0) {
        let date1 = history.DateAdded;
        let date2 = this.delivery.Histories[i - 1].DateAdded;
        let result = date1.getTime() - date2.getTime();
      }

      i++;



    });
  }

  onClose(event) {

  }

  selected(event) {

  }

  ToggleAccordion(history: OrderHistory) {
    history.IsOpened = !history.IsOpened;
  }

}
