import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from '../../../services/clipboard.service';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';
import { FloatingAction } from 'src/app/models/tools/floating-action.modal';


@Component({
  selector: 'app-banquet-list',
  templateUrl: './banquet-list.component.html',
  styleUrls: ['./banquet-list.component.scss']
})
export class BanquetListComponent implements OnInit {





  public banquets: Array<number> = [
    1, 2, 3, 4
  ];

  constructor(public HeaderService: HeaderService,
    public PropertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    public ClipboardService: ClipboardService, public BanquetsService: BanquetsService,
    public ModalAddingService: ModalAddingService) {
    
    }

  ngOnInit(): void {
    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Список банкетов";
    this.HeaderService.BackButton.Text = "Меню банкетов";
    this.HeaderService.BackButton.Link = "/banquet-menu";
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Банкеты", "/baquets", true);

    let addSubaction = new NavigationAction("Добавить банкет", "add", () => {
      this.router.navigate(['/banquet-adding']);
    });
    this.HeaderService.AddSubActions(addSubaction);


    let floatingAction = new FloatingAction("Добавить банкет", "add", () => {
      this.router.navigate(['/banquet-adding']);
    });
    this.HeaderService.AddFloatingAction(floatingAction);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });

    this.BanquetsService.ClearBqneuts();
    this.BanquetsService.LoadBanquets();

  }

  LoadMoreBanquet() {
    this.BanquetsService.LoadMoreBanquets();
  }

  OpenBanquet(banquet) {
    this.PropertyService.IsLoadingCircleShowed = true;
    setTimeout(() => {
      this.BanquetsService.OpenBanquet(banquet);
    }, 20);
    
  }

  AddBanquet() {
    this.router.navigate(['/banquet-adding']);
  }

}
