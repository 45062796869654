import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderAddress } from 'src/app/models/store/order-address.model';
import { PropertyService } from '../property.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public Addresses: Array<OrderAddress> = new Array<OrderAddress>();

  public OnLoadAddressEvents: Array<any> = new Array<any>();

  constructor(private http: HttpClient, private property: PropertyService,) {

  }

  LoadAddress() {
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order/LoadAddress&token=${this.property.Token}`);

    this.http.get(request).subscribe(
      (data: any) => {

        data.body.data.objects.data.forEach(element => {
          this.Addresses.push(new OrderAddress(element["delivery_price_id"], element["name"], element["min_price"]));
        });

        this.OnLoadAddressEvents.forEach(event => {
          event();
        });

      }
    );
  }

}
