<div class="payment_wrapper"
    [ngClass]="{'showed_filter': IsFilterOpened, 'show_only': PropertyService.UserGroupId != 1}" fxLayout="row"
    fxLayoutGap="40px">

    <div class="filter">


        <div class="unsigned-block">
            <div class="unsigned-title">Текущее количество</div>
            <div class="unsigned-value">{{OchagboxService.TotalCount - OchagboxHistoryService.Objects.Values.length}}
            </div>
        </div>

        <div class="unsigned-detail-block income">
            <div class="unsigned-detail-title">Подарили Очаг BOX</div>
            <div class="unsigned-detail-value">{{OchagboxHistoryService.Objects.Values.length}}</div>
        </div>

        <!-- <div class="unsigned-detail-block cost">
            <div class="unsigned-detail-title">Неподтверждённые списания</div>
            <div class="unsigned-detail-value">1₴</div>
        </div> -->

    </div>


    <div class="content">
        <div class="list_wrapper">
            <ibm-structured-list>
                <ibm-list-header>
                    <ibm-list-column>№</ibm-list-column>
                    <ibm-list-column>Количество</ibm-list-column>
                    <ibm-list-column>Описание</ibm-list-column>
                    <ibm-list-column>Дата операции</ibm-list-column>
                </ibm-list-header>

                <ibm-list-row *ngFor="let ochagbox of OchagboxService.VisibleObjects.Values" class="supply_item"
                    [ngClass]="{'admission': ochagbox.Count > 0, 'expenses': ochagbox.Count < 0}">
                    <ibm-list-column>{{ochagbox.Id}}</ibm-list-column>
                    <ibm-list-column class="supply_value">{{ochagbox.Count}}</ibm-list-column>
                    <ibm-list-column class="supply_name">{{ochagbox.Description}}</ibm-list-column>
                    <ibm-list-column>{{ochagbox.DateAdded | date:'dd.MM.yy'}}</ibm-list-column>
                </ibm-list-row>

            </ibm-structured-list>
            <button class="load_more_button" (click)="LoadMore()">Еще</button>
        </div>
    </div>
</div>

<div class="shadow_haze"
    [ngClass]="{'showed_filter': IsFilterOpened || OchagboxService.IsLoading, 'show_only': PropertyService.UserGroupId != 1}"
    fxLayout="row" fxLayoutAlign="center center" (click)="IsFilterOpened = false">
    <ibm-loading [isActive]="OchagboxService.IsLoading"></ibm-loading>
</div>