import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { BanquetVaultService } from '../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { HttpClient } from '@angular/common/http';
import { Banquet } from 'src/app/models/banquet/banquet';
import { BanquetBuilder } from 'src/app/models/banquet/banquet-builder';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';

@Component({
  selector: 'app-banquet-adding-form',
  templateUrl: './banquet-adding-form.component.html',
  styleUrls: ['./banquet-adding-form.component.scss']
})
export class BanquetAddingFormComponent implements OnInit {


  AreaFields: Array<any> = new Array<any>();
  SelectedAreaId: number = 1;

  SelectedDate: Date = new Date();
  TomorrowDate: Date = new Date();

  SelectedTableGroup: Array<any> = Array<any>();

  BanquetId: number = -1;
  EmptyBanquet: Banquet;

  public IsDisabledCreateButton: boolean = true;

  public IsCreatingBanquet: boolean = false;

  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService,
    private router: Router, private http: HttpClient,
  ) {

    this.TomorrowDate.setDate(this.TomorrowDate.getDate() + 1);
  }

  ngOnInit(): void {

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Добавление банкета";
    this.HeaderService.BackButton.Text = "Банкеты";
    this.HeaderService.BackButton.Link = "/banquets";
    this.HeaderService.ClearBreadcrumb();
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Банкеты", "/baquets");
    this.HeaderService.AddBreadcrumb("Добавление банкета", "/baquets", true);


    this.CreateEmptyBanquet();

  }

  CreateEmptyBanquet() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet/addBanquet&token=${this.PropertyService.Token}`;

    let banquetData = {
      data: {
        name: `Банкет`,
        category_name: "День Рождения",
        category_id: "1",
        price: "0",
        guests: "0",
        date: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
        target: "",
        target_second: "",
        contact_firstname: "",
        custom_number: "",
        contact_lastname: "",
        contact_email: "test.email@gmail.com",
        contact_phone: "",
        children: "0",
        banquet_direction_type_id: "2",
        status: "2"
      }
    }


    this.http.post(request, banquetData).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {

          this.BanquetId = data["body"]["data"]["objects"]["banquetId"];


          this.BanquetsService.GetBanquet(data["body"]["data"]["objects"]["banquetId"]).subscribe(
            (dataOrder) => {
              this.EmptyBanquet = this.BanquetsService.Builder.Build(dataOrder["data"]);
              this.EmptyBanquet.Area = new BanquetArea(1, "Основний зал");
              this.EmptyBanquet.CategoryName == 'День Рождения';
            }
          );


        }
      });
  }





  OnChangeFileds() {

    if (this.EmptyBanquet.ContactCustomer.FirstName.length > 1 &&
      this.EmptyBanquet.ContactCustomer.LastName.length > 1 &&
      this.EmptyBanquet.ContactCustomer.Phone.length > 3) {
      this.IsDisabledCreateButton = false;
    } else {
      this.IsDisabledCreateButton = true;
    }

    this.BanquetsService.SaveBanquet(this.EmptyBanquet);
  }





  Create() {
    if (this.IsDisabledCreateButton) {
      return;
    }
    this.EmptyBanquet.Status = true;

    console.log(this.EmptyBanquet);

    //return;
    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/reserveTable&token=${this.PropertyService.Token}&banquet_id=${this.BanquetId}`).subscribe(
      (data: any) => {
        this.BanquetsService.SaveBanquet(this.EmptyBanquet, (dataSave) => {

          this.IsCreatingBanquet = true;
          this.BanquetsService.ClearBqneuts();
          this.BanquetsService.LoadBanquets(
            [() => {
              this.IsCreatingBanquet = false;
              this.router.navigate([`/banquet/${this.BanquetId}`]);
            }]
          );



        });
      });



  }

  @ViewChild('firstnameInput') firstnameInput: ElementRef;
  @ViewChild('phoneInput') phoneInput: ElementRef;

  keytab(element: ElementRef) {
    let elementHTML = element.nativeElement; // get the sibling element

    if (elementHTML == null)  // check if its null
      return;
    else
      elementHTML.focus();   // focus if not null
  }
  blur(event) {
    event.target.blur()
  }



}
