import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {

  public DeliveryNews: Array<any> = new Array<any>();
  public BanquetNews: Array<any> = new Array<any>();
  public AdminNews: Array<any> = new Array<any>();
  public MainNews: Array<any> = new Array<any>();

  constructor(private route: ActivatedRoute, private router: Router,
    public HeaderService: HeaderService, private http: HttpClient) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.http.get(`assets/whats-new/version-${id}.json`).subscribe(
        (data) => {
          this.HeaderService.WindowName = "Обновление " + data["version"];
          this.HeaderService.ClearAll();
          this.HeaderService.BackButton.Text = "Меню";
          this.HeaderService.BackButton.Link = "/menu";


          data["delivery_items"].forEach(item => {
            this.DeliveryNews.push({
              text: item["text"],
              description: item["description"]
            });
          });


          data["banquet_items"].forEach(item => {
            this.BanquetNews.push({
              text: item["text"],
              description: item["description"]
            });
          });


          data["main_items"].forEach(item => {
            this.MainNews.push({
              text: item["text"],
              description: item["description"]
            });
          });


          data["admin_items"].forEach(item => {
            this.AdminNews.push({
              text: item["text"],
              description: item["description"]
            });
          });


        }
      );
    });
  }

}
