import { Vendor } from "./vendor.service";

export class BaseService {
    public Page: number = 0;
    public PageOfVisible: number = 0;

    public IsLoading: boolean = false;
    public IsLoaded: boolean = false;
    public IsFirstLoaded: boolean = true;

    public Objects: Vendor = new Vendor();
    public ObjectsOriginal: Vendor = new Vendor();
    public VisibleObjects: Vendor = new Vendor();


    public RemoveObjectById(id: number) {
        let removingObject = this.Objects.Values.find((payment) => {
            return payment.Id == id;
        });
        let index = this.Objects.Values.indexOf(removingObject, 0);
        if (index > -1) {
            this.Objects.Values.splice(index, 1);

        }

        removingObject = this.ObjectsOriginal.Values.find((payment) => {
            return payment.Id == id;
        });
        index = this.ObjectsOriginal.Values.indexOf(removingObject, 0);
        if (index > -1) {
            this.ObjectsOriginal.Values.splice(index, 1);

        }


        removingObject = this.VisibleObjects.Values.find((payment) => {
            return payment.Id == id;
        });
        index = this.VisibleObjects.Values.indexOf(removingObject, 0);
        if (index > -1) {
            this.VisibleObjects.Values.splice(index, 1);

        }
    }
}