export class ModalData {

    Title: string = "";
    Text: string = "";
    Confirmation: string = "";
    Cancel: string = "";

    constructor(title: string, text: string, confirmation: string, cancel: string) {
        this.Title = title;
        this.Text = text;
        this.Confirmation = confirmation;
        this.Cancel = cancel;
    }
}