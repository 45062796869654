import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';
import { BanquetTable } from 'src/app/models/banquet/banquet-table';
import { PropertyService } from '../property.service';

@Injectable({
  providedIn: 'root'
})
export class BanquetZoneService {

  public Areas: Array<any> = new Array<any>();
  public TableGroups: Array<any> = new Array<any>();
  public Tables: Array<any> = new Array<any>();

  public IsAreaLoaded: boolean = false;
  public OnAreaLoaded: Array<any> = new Array<any>();


  public IsTableLoaded: boolean = false;
  public OnTableLoaded: Array<any> = new Array<any>();

  public SelectedTable: Array<BanquetTable> = Array<BanquetTable>();
  public SelectedArea: BanquetArea;

  private areasDropdown: Array<any> = Array<any>();
  get AreasDropdown(): Array<any> {
    return this.areasDropdown;
  }

  private tableGroupsDropdown: Array<any> = Array<any>();
  get TableGroupsDropdown(): Array<any> {
    return this.tableGroupsDropdown;
  }

  private tablesDropdown: Array<any> = Array<any>();
  get TablesDropdown(): Array<any> {
    return this.tablesDropdown;
  }

  public AddOnAreaLoadedEvent(event) {
    this.OnAreaLoaded.push(event);
    if (this.IsAreaLoaded) {
      event();
    }
  }

  private UpdateAreasDropdown(areas: Array<any>) {
    areas.forEach(area => {
      this.areasDropdown.push({ content: area.Name, id: area.Id, selected: false },);
    });
  }

  private UpdateTableGroupsDropdown(tableGroups: Array<any>) {
    tableGroups.forEach(area => {
      this.tableGroupsDropdown.push({ content: area.Name, id: area.Id, selected: false },);
    });
  }

  private UpdateTablesDropdown(tables: Array<any>) {
    this.tablesDropdown = new Array<any>();
    tables.forEach(table => {
      this.tablesDropdown.push({ content: table.Name, id: table.Id, selected: false },);
    });
  }

  public SetActiveAreaForDropdown(id: number) {

    this.SelectedArea = this.Areas.find(
      (area) => {
        return area.Id == id;
      }
    );

    let activeTables: Array<any> = new Array<any>();
    let tableGroups: Array<any> = this.TableGroups.filter(
      (tableGroup) => {
        return tableGroup.AreaId == id;
      }
    );

    for (let index = 0; index < tableGroups.length; index++) {
      const element = tableGroups[index];
      this.Tables.filter(
        (area) => {
          return area.BanquetTableGroupId == element.Id;
        }
      ).forEach((table) => {
        activeTables.push(table);
      })

    }

    this.UpdateTablesDropdown(activeTables);
    this.ApplySelectedTables();
  }

  public SetSelectedArea(area: BanquetArea) {
    this.areasDropdown.forEach(areaDropdown => {
      areaDropdown.selected = false;
    });
    if (area != null) {
      this.areasDropdown.forEach(
        (areaDropdown) => {
          if (areaDropdown.id == area.Id) {
            areaDropdown.selected = true;
          }
        }
      );
    }

  }

  public SetSelectedTables(tables: Array<BanquetTable>) {
    this.SelectedTable = tables;
    this.ApplySelectedTables();
  }

  public ApplySelectedTables() {
    this.tablesDropdown.forEach(tableDropdown => {
      tableDropdown.selected = false;
    });
    this.tablesDropdown.forEach(tableDropdown => {
      let findedTable = this.SelectedTable.find((table) => {
        return tableDropdown.id == table.Id;
      });

      if (findedTable != null) {
        tableDropdown.selected = true;
      }
    });
  }



  constructor(private http: HttpClient, private property: PropertyService,) {

  }

  public LoadAreas() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/area/get&token=${this.property.Token}`;

    this.http.post(request, []).subscribe(
      (data: any) => {
        data["body"]["data"]["objects"]["data"].forEach(element => {
          this.Areas.push({
            Id: element["area_id"],
            Name: element["name"]
          });
        });

        this.OnAreaLoaded.forEach(event => {
          event();
        });

        this.IsAreaLoaded = true;
        this.UpdateAreasDropdown(this.Areas);
      });
  }

  public LoadTableGroups() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/group/get&token=${this.property.Token}`;

    this.http.post(request, []).subscribe(
      (data: any) => {
        data["body"]["data"]["objects"]["data"].forEach(element => {
          this.TableGroups.push({
            Id: element["banquet_table_group_id"],
            AreaId: element["banquet_area_id"],
            Name: element["name"]
          });
        });

        this.UpdateTableGroupsDropdown(this.TableGroups);
      });
  }

  public LoadTables() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/table/get&token=${this.property.Token}`;

    this.http.post(request, []).subscribe(
      (data: any) => {
        data["body"]["data"]["objects"]["data"].forEach(element => {
          this.Tables.push({
            Id: element["banquet_table_id"],
            Name: element["name"],
            BanquetTableGroupId: element["banquet_table_group_id"]
          });
        });

        this.OnTableLoaded.forEach(event => {
          event();
        });

        this.IsTableLoaded = true;

        this.UpdateTablesDropdown(this.Tables);
      });
  }


  AddArea(name: string) {
    let zoneData =
    {
      data: {
        "name": name
      }
    }
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/area/add&token=${this.property.Token}`;
    this.http.post(request, zoneData).subscribe(
      (data: any) => {

        this.Areas.push({
          Id: data["body"]["data"]["objects"]["data"]["area_id"],
          Name: data["body"]["data"]["objects"]["data"]["name"]
        });
      });
  }

  DeleteArea(id: number) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/area/delete&id=${id}&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data) => {
        this.Areas = this.Areas.filter(obj => obj.Id !== id);
      }
    );
  }



  AddTableGroup(name: string, areaId: string, count: string) {
    let zoneData =
    {
      data: {
        "name": name,
        "banquet_area_id": areaId,
        "count": count
      }
    }
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/group/add&token=${this.property.Token}`;
    this.http.post(request, zoneData).subscribe(
      (data: any) => {
        this.TableGroups.push({
          Id: data["body"]["data"]["objects"]["data"]["banquet_table_group_id"],
          Name: data["body"]["data"]["objects"]["data"]["name"],
          Count: data["body"]["data"]["objects"]["data"]["count"],
        });
      });
  }

  DeleteTableGroup(id: number) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/table-group/delete&id=${id}&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data) => {
        this.TableGroups = this.TableGroups.filter(obj => obj.Id !== id);
      }
    );
  }



  AddTable(name: string, tableGroupId: string) {
    let zoneData =
    {
      data: {
        "name": name,
        "banquet_table_group_id": tableGroupId,
      }
    }
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/table/add&token=${this.property.Token}`;
    this.http.post(request, zoneData).subscribe(
      (data: any) => {
        this.Tables.push({
          Id: data["body"]["data"]["objects"]["data"]["banquet_table_id"],
          Name: data["body"]["data"]["objects"]["data"]["name"],
        });
      });
  }

  DeleteTable(id: number) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/table/delete&id=${id}&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data) => {
        this.Tables = this.Tables.filter(obj => obj.Id !== id);
      }
    );
  }






}
