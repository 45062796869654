<div class="settings_wrapper" [ngClass]="{'showed_menu': IsMenuOpened}" fxLayout="row" fxLayoutGap="40px">

    <div class="panel">
        <app-settings-menu (OnChangeActiveMenu)="OnChangeActiveMenu($event)"></app-settings-menu>
    </div>
    <div class="content">
        <app-settings-equipment *ngIf="ActiveMenu==1"></app-settings-equipment>
        <app-banquet-area *ngIf="ActiveMenu==2"></app-banquet-area>
        <app-banquet-table-group *ngIf="ActiveMenu==3"></app-banquet-table-group>
        <app-banquet-table *ngIf="ActiveMenu==4"></app-banquet-table>
    </div>
</div>