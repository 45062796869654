import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { BanquetVaultService } from '../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { ToolsService } from '../../../services/tools.service';
import { HttpClient } from '@angular/common/http';
import { Banquet } from 'src/app/models/banquet/banquet';
import { BanquetBuilder } from 'src/app/models/banquet/banquet-builder';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';
import { BanquetPaymentHistory } from 'src/app/models/banquet/banquet-payment-history';
import { Vendor } from 'src/app/services/vendor.service';
import { NavigationAction } from 'src/app/models/navigation/navigation-action.model';

@Component({
  selector: 'app-banquet-keeper-list',
  templateUrl: './banquet-keeper-list.component.html',
  styleUrls: ['./banquet-keeper-list.component.scss']
})
export class BanquetKeeperListComponent implements OnInit {


  BanquetId: number = -1;
  EmptyBanquet: Banquet;
  IsFilterOpened: boolean = false;

  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService,
    private router: Router, private http: HttpClient, public ToolsService: ToolsService
  ) {


  }

  ngOnInit(): void {
    this.BanquetVaultService.LoadVault(1, true, new Date("2021-05-21 00:00:00".replace(/-/g, "/")), new Date("2021-05-22 00:00:00".replace(/-/g, "/")));

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Активация капера";
    this.HeaderService.BackButton.Text = "Меню банкетов";
    this.HeaderService.BackButton.Link = "/banquet-menu";


    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню банкетов", "/banquet-menu");
    this.HeaderService.AddBreadcrumb("Активация капера", "/banquet-transactions", true);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });

    let filterSubaction = new NavigationAction("Фильтр", "collapse", () => {
      this.IsFilterOpened = !this.IsFilterOpened;
    });
    filterSubaction.IsMobile = true;
    filterSubaction.IsDesktop = false;
    this.HeaderService.AddSubActions(filterSubaction);

    let builder: BanquetBuilder = new BanquetBuilder([]);
    this.EmptyBanquet = builder.BuildEmptyBanquet();
    this.BanquetId = -10;

  }

  TransactionBanquetDate: Date = null;

  OnDateChange(date: Date) {
    this.TransactionBanquetDate = date;
    this.FIlterPayments();
  }

  FIlterPayments() {

    let BufferArray: Vendor = new Vendor();

    this.BanquetPaymentsService.DateFilteredObjects = new Array<BanquetPaymentHistory>();
    this.BanquetPaymentsService.VisibleObjects.Clear();

    this.BanquetPaymentsService.Objects.Values.forEach(
      (payment: BanquetPaymentHistory) => {
        let isBanquetDateChecked = this.CheckBanquetDateForFilter(payment);
        let isTransferBanquetDateChecked = false;

        if ((isBanquetDateChecked || isTransferBanquetDateChecked)) {
          BufferArray.PushToEnd(payment);
          this.BanquetPaymentsService.DateFilteredObjects.push(payment);
        }

      }
    );


    this.BanquetPaymentsService.VisibleObjects = BufferArray;

    this.BanquetPaymentsService.RecalculateFilteredUnsigned(this.BanquetPaymentsService.DateFilteredObjects);
    this.BanquetPaymentsService.RecalculateFilteredGuests(this.BanquetPaymentsService.DateFilteredObjects);
  }


  CheckBanquetDateForFilter(payment: BanquetPaymentHistory) {
    if (payment.Banquet == null) {
      return false;
    } else {
      return payment.Banquet.Date.getDate() == this.TransactionBanquetDate.getDate()
        && payment.Banquet.Date.getMonth() == this.TransactionBanquetDate.getMonth()
        && payment.Banquet.Date.getFullYear() == this.TransactionBanquetDate.getFullYear();
    }

  }

  LoadMoreBanquet() {
    this.BanquetsService.LoadMoreBanquets();
  }


  ToggleKeeperStatus(banquet: Banquet) {
    if (banquet.KepperSended == false) {
      this.BanquetsService.OpenModalConfirmKeeperStatus(banquet);
    } else {
      this.BanquetsService.OpenModalUnconfirmKeeperStatus(banquet);
    }
  }



}
