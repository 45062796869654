import { Injectable } from '@angular/core';
import { NotificationAlert, NotificationAlertType } from '../models/tools/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public Notifications: Array<NotificationAlert> = new Array<NotificationAlert>();

  constructor() { }

  Add(title: string, text: string, date: Date,  type: NotificationAlertType, badgeCount: number = 0) {
    let NewNotification: NotificationAlert = new NotificationAlert();
    NewNotification.Title = title;
    NewNotification.Text = text;
    NewNotification.AddedDate = date;
    NewNotification.Type = type;
    NewNotification.BadgeCount = badgeCount;
    this.Notifications.push(NewNotification);
    NewNotification.IsDisplayed = true;
    NewNotification.OnHide = () => {
      const index = this.Notifications.indexOf(NewNotification, 0);
      if (index > -1) {
        this.Notifications.splice(index, 1);
      }
    };

    setTimeout(
      () => {
        NewNotification.IsShowed = true;
      }, 240);
    setTimeout(
      () => {
        const index = this.Notifications.indexOf(NewNotification, 0);
        if (index > -1) {
          this.Notifications.splice(index, 1);
        }
      }, 6200
    )
  }

  playAudio(){
    let audio = new Audio();
    audio.src = "../../assets/audio/notification-1.mp3";
    audio.load();
    audio.play();
  }

}
