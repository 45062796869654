<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none">


  <symbol id="terminal" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>calculator</title>
    <path d="M26,4V28H6V4H26m0-2H6A2,2,0,0,0,4,4V28a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V4A2,2,0,0,0,26,2Z" />
    <rect x="9" y="23" width="2" height="2" />
    <rect x="21" y="23" width="2" height="2" />
    <rect x="9" y="18" width="2" height="2" />
    <rect x="21" y="18" width="2" height="2" />
    <rect x="9" y="13" width="2" height="2" />
    <rect x="15" y="23" width="2" height="2" />
    <rect x="15" y="18" width="2" height="2" />
    <rect x="15" y="13" width="2" height="2" />
    <rect x="21" y="13" width="2" height="2" />
    <rect x="9" y="7" width="14" height="3" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="kitchen" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>restaurant</title>
    <rect x="9" y="2" width="2" height="10" />
    <path d="M14,11a4,4,0,0,1-8,0V2H4v9a6,6,0,0,0,5,5.91V30h2V16.91A6,6,0,0,0,16,11V2H14Z" />
    <path d="M22,2H21V30h2V20h3a2,2,0,0,0,2-2V8A5.78,5.78,0,0,0,22,2Zm4,16H23V4.09c2.88.56,3,3.54,3,3.91Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="fingerprint" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>fingerprint-recognition</title>
    <path d="M7,5.21a.77.77,0,0,1-.46-1.38A15.46,15.46,0,0,1,16,1c2.66,0,6.48.45,9.5,2.62a.77.77,0,0,1,.18,1.07.78.78,0,0,1-1.08.17A15,15,0,0,0,16,2.53,14,14,0,0,0,7.5,5.05.74.74,0,0,1,7,5.21Z" transform="translate(0)" />
    <path d="M28.23,12.26a.78.78,0,0,1-.63-.33C25.87,9.49,22.78,6.24,16,6.24a14,14,0,0,0-11.63,5.7.77.77,0,0,1-1.07.17A.76.76,0,0,1,3.15,11,15.54,15.54,0,0,1,16,4.71c5.61,0,9.81,2.08,12.84,6.34a.77.77,0,0,1-.19,1.07A.79.79,0,0,1,28.23,12.26Z" transform="translate(0)" />
    <path d="M12.28,31a.78.78,0,0,1-.72-.49.75.75,0,0,1,.44-1c4.37-1.68,7-5.12,7-9.21a2.8,2.8,0,0,0-3-3c-1.86,0-2.76,1-3,3.35a4.27,4.27,0,0,1-4.52,3.83,4.27,4.27,0,0,1-4.32-4.59A11.71,11.71,0,0,1,16,8.39a12,12,0,0,1,12,11.93,18.66,18.66,0,0,1-1.39,6.5.78.78,0,0,1-1,.41.76.76,0,0,1-.41-1,17.25,17.25,0,0,0,1.27-5.91A10.45,10.45,0,0,0,16,9.92a10.18,10.18,0,0,0-10.38,10,2.77,2.77,0,0,0,2.79,3.06,2.74,2.74,0,0,0,3-2.48c.36-3.11,1.89-4.69,4.56-4.69a4.31,4.31,0,0,1,4.52,4.56c0,4.74-3,8.72-8,10.63A.92.92,0,0,1,12.28,31Z" transform="translate(0)" />
    <path d="M19.77,30.28a.81.81,0,0,1-.52-.2.76.76,0,0,1,0-1.08,12.63,12.63,0,0,0,3.54-8.68c0-1.56-.48-6.65-6.7-6.65a6.83,6.83,0,0,0-4.94,1.87A6.17,6.17,0,0,0,9.32,20a.77.77,0,0,1-.77.76h0A.76.76,0,0,1,7.78,20,7.73,7.73,0,0,1,10,14.46a8.34,8.34,0,0,1,6-2.32c6.08,0,8.24,4.4,8.24,8.18A14.09,14.09,0,0,1,20.34,30,.75.75,0,0,1,19.77,30.28Z" transform="translate(0)" />
    <path d="M8.66,27.74a14.14,14.14,0,0,1-1.56-.09.76.76,0,1,1,.17-1.52c2.49.28,4.45-.16,5.84-1.32a6.37,6.37,0,0,0,2.12-4.53.75.75,0,0,1,.82-.71.78.78,0,0,1,.72.81A7.89,7.89,0,0,1,14.09,26,8.2,8.2,0,0,1,8.66,27.74Z" transform="translate(0)" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="right-arrow" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <polygon points="18 6 16.57 7.393 24.15 15 4 15 4 17 24.15 17 16.57 24.573 18 26 28 16 18 6" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="arrow-left" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <polygon points="14 26 15.41 24.59 7.83 17 28 17 28 15 7.83 15 15.41 7.41 14 6 4 16 14 26" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="left-top-arrow" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>y-axis</title>
    <path d="M8,26V7.8281l2.5859,2.586L12,9,7,4,2,9l1.4141,1.4141L6,7.8281V26a2.0023,2.0023,0,0,0,2,2H28V26Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="transfered" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>event-schedule</title>
    <path d="M21,30a8,8,0,1,1,8-8A8,8,0,0,1,21,30Zm0-14a6,6,0,1,0,6,6A6,6,0,0,0,21,16Z" />
    <polygon points="22.59 25 20 22.41 20 18 22 18 22 21.59 24 23.59 22.59 25" />
    <path d="M28,6a2,2,0,0,0-2-2H22V2H20V4H12V2H10V4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2h4V26H6V6h4V8h2V6h8V8h2V6h4v6h2Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="card" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6Zm0,2v3H4V8ZM4,24V13H28V24Z" transform="translate(0 0)" />
    <rect x="6" y="20" width="10" height="2" />
  </symbol>

  <symbol id="notification" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <path d="M28.7071,19.293,26,16.5859V13a10.0136,10.0136,0,0,0-9-9.9492V1H15V3.0508A10.0136,10.0136,0,0,0,6,13v3.5859L3.2929,19.293A1,1,0,0,0,3,20v3a1,1,0,0,0,1,1h7v.7768a5.152,5.152,0,0,0,4.5,5.1987A5.0057,5.0057,0,0,0,21,25V24h7a1,1,0,0,0,1-1V20A1,1,0,0,0,28.7071,19.293ZM19,25a3,3,0,0,1-6,0V24h6Zm8-3H5V20.4141L7.707,17.707A1,1,0,0,0,8,17V13a8,8,0,0,1,16,0v4a1,1,0,0,0,.293.707L27,20.4141Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="add" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <polygon points="17,15 17,8 15,8 15,15 8,15 8,17 15,17 15,24 17,24 17,17 24,17 24,15 " />
    <rect class="st0" width="32" height="32" />
  </symbol>

  <symbol id="view" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>view</title>
    <path d="M30.94,15.66A16.69,16.69,0,0,0,16,5,16.69,16.69,0,0,0,1.06,15.66a1,1,0,0,0,0,.68A16.69,16.69,0,0,0,16,27,16.69,16.69,0,0,0,30.94,16.34,1,1,0,0,0,30.94,15.66ZM16,25c-5.3,0-10.9-3.93-12.93-9C5.1,10.93,10.7,7,16,7s10.9,3.93,12.93,9C26.9,21.07,21.3,25,16,25Z" transform="translate(0 0)" />
    <path d="M16,10a6,6,0,1,0,6,6A6,6,0,0,0,16,10Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z" transform="translate(0 0)" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="view-off" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>view--off</title>
    <path d="M5.24,22.51l1.43-1.42A14.06,14.06,0,0,1,3.07,16C5.1,10.93,10.7,7,16,7a12.38,12.38,0,0,1,4,.72l1.55-1.56A14.72,14.72,0,0,0,16,5,16.69,16.69,0,0,0,1.06,15.66a1,1,0,0,0,0,.68A16,16,0,0,0,5.24,22.51Z" />
    <path d="M12,15.73a4,4,0,0,1,3.7-3.7l1.81-1.82a6,6,0,0,0-7.33,7.33Z" />
    <path d="M30.94,15.66A16.4,16.4,0,0,0,25.2,8.22L30,3.41,28.59,2,2,28.59,3.41,30l5.1-5.1A15.29,15.29,0,0,0,16,27,16.69,16.69,0,0,0,30.94,16.34,1,1,0,0,0,30.94,15.66ZM20,16a4,4,0,0,1-6,3.44L19.44,14A4,4,0,0,1,20,16Zm-4,9a13.05,13.05,0,0,1-6-1.58l2.54-2.54a6,6,0,0,0,8.35-8.35l2.87-2.87A14.54,14.54,0,0,1,28.93,16C26.9,21.07,21.3,25,16,25Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="filter" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>filter</title>
    <path d="M18,28H14a2,2,0,0,1-2-2V18.41L4.59,11A2,2,0,0,1,4,9.59V6A2,2,0,0,1,6,4H26a2,2,0,0,1,2,2V9.59A2,2,0,0,1,27.41,11L20,18.41V26A2,2,0,0,1,18,28ZM6,6V9.59l8,8V26h4V17.59l8-8V6Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="collapse-categories" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>collapse-categories</title>
    <rect x="14" y="25" width="14" height="2" />
    <polygon points="7.17 26 4.59 28.58 6 30 10 26 6 22 4.58 23.41 7.17 26" />
    <rect x="14" y="15" width="14" height="2" />
    <polygon points="7.17 16 4.59 18.58 6 20 10 16 6 12 4.58 13.41 7.17 16" />
    <rect x="14" y="5" width="14" height="2" />
    <polygon points="7.17 6 4.59 8.58 6 10 10 6 6 2 4.58 3.41 7.17 6" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="renew" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>renew</title>
    <path d="M12,10H6.78A11,11,0,0,1,27,16h2A13,13,0,0,0,6,7.68V4H4v8h8Z" />
    <path d="M20,22h5.22A11,11,0,0,1,5,16H3a13,13,0,0,0,23,8.32V28h2V20H20Z" />
    <g id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;">
      <rect class="cls-1" width="32" height="32" />
    </g>
  </symbol>

  <symbol id="trash-can" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>trash-can</title>
    <rect x="12" y="12" width="2" height="12" />
    <rect x="18" y="12" width="2" height="12" />
    <path d="M4,6V8H6V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V8h2V6ZM8,28V8H24V28Z" />
    <rect x="12" y="2" width="8" height="2" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="logout" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>logout</title>
    <path d="M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z" />
    <polygon points="20.586 20.586 24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="review" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>warning</title>
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z" transform="translate(0 0)" />
    <rect x="15" y="8" width="2" height="11" />
    <path d="M16,22a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,22Z" transform="translate(0 0)" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="information" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <polygon points="17 22 17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22" />
    <path d="M16,8a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,8Z" />
    <path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="close-filled" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        opacity: 0;
      }

      .st1 {
        fill: none;
      }
    </style>
    <path d="M16,2C8.2,2,2,8.2,2,16s6.2,14,14,14s14-6.2,14-14S23.8,2,16,2z M21.4,23L16,17.6L10.6,23L9,21.4l5.4-5.4L9,10.6L10.6,9  l5.4,5.4L21.4,9l1.6,1.6L17.6,16l5.4,5.4L21.4,23z" />
    <polyline id="inner-path" class="st0" points="14.4,16 9,10.6 10.6,9 16,14.4 21.4,9 23,10.6 17.6,16 23,21.4 21.4,23 16,17.6   10.6,23 9,21.4 14.4,16 " />
    <rect id="_Transparent_Rectangle_" class="st1" width="32" height="32" />
  </symbol>



  <symbol id="checkmark-filled" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2ZM14,21.5908l-5-5L10.5906,15,14,18.4092,21.41,11l1.5957,1.5859Z" />
    <polygon id="inner-path" class="cls-1" points="14 21.591 9 16.591 10.591 15 14 18.409 21.41 11 23.005 12.585 14 21.591" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="arrow-down" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <polygon points="16,22 6,12 7.4,10.6 16,19.2 24.6,10.6 26,12 " />
    <rect id="_x3C_Transparent_Rectangle_x3E_" class="st0" width="32" height="32" />
  </symbol>

  <symbol id="arrow-up" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <polygon points="16,10 26,20 24.6,21.4 16,12.8 7.4,21.4 6,20 " />
    <rect id="_x3C_Transparent_Rectangle_x3E_" class="st0" width="32" height="32" />
  </symbol>

  <symbol id="arrow-right-up" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>arrow--up-right</title>
    <polygon points="10 6 10 8 22.59 8 6 24.59 7.41 26 24 9.41 24 22 26 22 26 6 10 6" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="caret-up" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <title>caret--right</title>
    <polygon points="8,20 16,10 24,20 " />
    <rect class="st0" width="32" height="32" />
  </symbol>

  <symbol id="caret-down" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <title>caret--down</title>
    <polygon points="24,12 16,22 8,12 " />
    <rect id="_Transparent_Rectangle_" class="st0" width="32" height="32" />
  </symbol>

  <symbol id="edit" viewBox="0 0 32 32">
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <title>edit</title>
    <rect x="2" y="26" width="28" height="2" />
    <path d="M25.4,9c0.8-0.8,0.8-2,0-2.8c0,0,0,0,0,0l-3.6-3.6c-0.8-0.8-2-0.8-2.8,0c0,0,0,0,0,0l-15,15V24h6.4L25.4,9z M20.4,4L24,7.6  l-3,3L17.4,7L20.4,4z M6,22v-3.6l10-10l3.6,3.6l-10,10H6z" />
    <rect id="_Transparent_Rectangle_" class="st0" width="32" height="32" />
  </symbol>

  <symbol id="face-wink" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>face--wink</title>
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z" />
    <path d="M20.5,11A2.5,2.5,0,1,0,23,13.5,2.5,2.5,0,0,0,20.5,11Z" />
    <rect x="8" y="13" width="6" height="2" />
    <path d="M16,24a8,8,0,0,0,6.85-3.89l-1.71-1a6,6,0,0,1-10.28,0l-1.71,1A8,8,0,0,0,16,24Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="face-neutral" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>face--neutral</title>
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z" />
    <path d="M11.5,11A2.5,2.5,0,1,0,14,13.5,2.5,2.5,0,0,0,11.5,11Z" />
    <path d="M20.5,11A2.5,2.5,0,1,0,23,13.5,2.5,2.5,0,0,0,20.5,11Z" />
    <rect x="10" y="20" width="12" height="2" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="face-dissatisfied" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>face--dissatisfied</title>
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z" transform="translate(0)" />
    <path d="M11.5,11A2.5,2.5,0,1,0,14,13.5,2.5,2.5,0,0,0,11.5,11Z" transform="translate(0)" />
    <path d="M20.5,11A2.5,2.5,0,1,0,23,13.5,2.5,2.5,0,0,0,20.5,11Z" transform="translate(0)" />
    <path d="M16,19a8,8,0,0,0-6.85,3.89l1.71,1a6,6,0,0,1,10.28,0l1.71-1A8,8,0,0,0,16,19Z" transform="translate(0)" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="purchase" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>purchase</title>
    <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6Zm0,2v3H4V8ZM4,24V13H28V24Z" transform="translate(0 0)" />
    <rect x="6" y="20" width="10" height="2" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="no-phone" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>phone--off</title>
    <path d="M9.19,18.56A25.66,25.66,0,0,1,5,6.08V6A1,1,0,0,1,6,5h5.28l1.5,3.77L9.94,11.64l.06.48a13,13,0,0,0,1.46,4.17l1.46-1.46a9.34,9.34,0,0,1-.84-2.52l2.13-2.15A2,2,0,0,0,14.65,8L13.13,4.26A2,2,0,0,0,11.27,3H5.76A3,3,0,0,0,3,6.23,28,28,0,0,0,7.79,20Z" />
    <path d="M27.77,18.86,24,17.35a2,2,0,0,0-2.17.41l-2.17,2.15A9.17,9.17,0,0,1,15.45,18L30,3.41,28.59,2,2,28.59,3.41,30l7-7c3.38,3.18,8.28,5.62,15.39,6H26a3,3,0,0,0,3-3V20.72A2,2,0,0,0,27.77,18.86ZM27,26v.06a1,1,0,0,1-1.06.94c-6.51-.37-11-2.54-14.11-5.42L14,19.44A10.77,10.77,0,0,0,19.88,22l.48.06,2.87-2.85L27,20.72Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="date-time" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>event-schedule</title>
    <path d="M21,30a8,8,0,1,1,8-8A8,8,0,0,1,21,30Zm0-14a6,6,0,1,0,6,6A6,6,0,0,0,21,16Z" />
    <polygon points="22.59 25 20 22.41 20 18 22 18 22 21.59 24 23.59 22.59 25" />
    <path d="M28,6a2,2,0,0,0-2-2H22V2H20V4H12V2H10V4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2h4V26H6V6h4V8h2V6h8V8h2V6h4v6h2Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>

  <symbol id="gift" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>gift</title>
    <path d="M26,10H23.2383A4.4867,4.4867,0,0,0,16,4.7065,4.4873,4.4873,0,0,0,8.7617,10H6a2.0025,2.0025,0,0,0-2,2v4a2.0025,2.0025,0,0,0,2,2V28a2.0025,2.0025,0,0,0,2,2H24a2.0025,2.0025,0,0,0,2-2V18a2.0025,2.0025,0,0,0,2-2V12A2.0025,2.0025,0,0,0,26,10ZM17,7.5A2.5,2.5,0,1,1,19.5,10H17ZM12.5,5A2.503,2.503,0,0,1,15,7.5V10H12.5a2.5,2.5,0,0,1,0-5ZM6,12h9v4H6Zm2,6h7V28H8ZM24.0012,28H17V18h7ZM17,16V12h9l.0012,4Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>


  <symbol id="person" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <path d="M16,8a5,5,0,1,0,5,5A5,5,0,0,0,16,8Zm0,8a3,3,0,1,1,3-3A3.0034,3.0034,0,0,1,16,16Z" />
    <path d="M16,2A14,14,0,1,0,30,16,14.0158,14.0158,0,0,0,16,2ZM10,26.3765V25a3.0033,3.0033,0,0,1,3-3h6a3.0033,3.0033,0,0,1,3,3v1.3765a11.8989,11.8989,0,0,1-12,0Zm13.9925-1.4507A5.0016,5.0016,0,0,0,19,20H13a5.0016,5.0016,0,0,0-4.9925,4.9258,12,12,0,1,1,15.985,0Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>



  <symbol id="warning-filled" viewBox="0 0 32 32">
    <rect id="Transparent_Rectangle" class="st0" width="32" height="32" />
    <path id="Compound_Path" d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14C30,8.3,23.7,2,16,2z M14.9,8h2.2v11h-2.2V8z M16,25  c-0.8,0-1.5-0.7-1.5-1.5S15.2,22,16,22c0.8,0,1.5,0.7,1.5,1.5S16.8,25,16,25z" />
    <path id="inner-path" class="st1" d="M17.5,23.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S15.2,22,16,22  C16.8,22,17.5,22.7,17.5,23.5z M17.1,8h-2.2v11h2.2V8z" />
  </symbol>

  <symbol id="close" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-close {
          fill: #000000;
        }

        .cls-2 {
          fill: none;
        }
      </style>
    </defs>
    <title>close</title>
    <polygon class="cls-close" points="24 9.4 22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4" />
    <rect class="cls-2" width="32" height="32" />
  </symbol>


  <symbol id="vert-sync" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />
  </symbol>


  <symbol id="copy" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </symbol>

  <symbol id="character" viewBox="0 0 32 32">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
      </style>
    </defs>
    <title>user</title>
    <path d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,16,2Z" />
    <path d="M26,30H24V25a5,5,0,0,0-5-5H13a5,5,0,0,0-5,5v5H6V25a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z" />
    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
  </symbol>



</svg>



<li *ngFor="let message of messages">
  <h3>{{message.title}}</h3>
  <p>
    {{message.body}}
  </p>
  <img [src]="message.iconUrl" alt="message-icon">
</li>



<div class="content_wrapper">
  <app-header *ngIf="PropertyService.Token!=''"></app-header>
  <app-subheader *ngIf="PropertyService.Token!=''"></app-subheader>


  <!-- *ngIf="OrdersService.IsLoaded" -->
  <main [ngClass]="{'bx--content main_wrapper': PropertyService.Token!=''}" class="">
    <router-outlet></router-outlet>
  </main>
  <div class="shadow_haze showed_filter centered" *ngIf="PropertyService.Token!='' && ( ( (BanquetsService.IsLoading && BanquetsService.IsLoaded == false) && (DeliveryService.IsLoading && DeliveryService.isLoaded == false)) || PropertyService.IsLoadingCircleShowed) ">
    <ibm-loading></ibm-loading>
  </div>

</div>




<!-- <div class="authentication_wrapper" *ngIf="PropertyService.Token==''">
  <router-outlet></router-outlet>
</div> -->


<div class="notifications_wrapper" fxLayout="column" fxLayoutGap="8px">

  <div class="notification_wrapper" *ngFor="let notification of NotificationService.Notifications" [ngClass]="{
		'showed': notification.IsShowed,
		'displayed': notification.IsShowed,
		'default': notification.Type == 0,
		'emerald': notification.Type == 1,
		'carmine': notification.Type == 2,
		'amber': notification.Type == 3
	}" fxLayout="row" fxLayoutAlign="start start">
    <div class="notification_status">
      <svg *ngIf="notification.Type == 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
        <use xlink:href="#warning-filled"></use>
      </svg>
      <svg *ngIf="notification.Type == 2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
        <use xlink:href="#close-filled"></use>
      </svg>
      <svg *ngIf="notification.Type == 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
        <use xlink:href="#checkmark-filled"></use>
      </svg>
    </div>
    <div class="notification_content">
      <div class="notification_title">{{notification.Title}}</div>
      <div class="notification_text">{{notification.Text}}</div>
      <div class="notification_date">{{notification.AddedDate | date:'dd.MM HH:mm' }}</div>
    </div>
    <div class="notification_close" (click)="notification.Hide()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
        <use xlink:href="#close"></use>
      </svg>
    </div>
  </div>

</div>


<div class="shadow_haze" [ngClass]="{'showed_filter': HeaderService.IsNotificationsListOpened || HeaderService.IsReviewOpened || HeaderService.IsUserOpened}" (click)="CloseAllMenu()"></div>





<app-notifications-list class="notification_list" [ngClass]="{'opened': HeaderService.IsNotificationsListOpened}">
</app-notifications-list>
<app-review-actions class="review_actions" [ngClass]="{'opened': HeaderService.IsReviewOpened}"></app-review-actions>
<app-user-actions class="user_actions" [ngClass]="{'opened': HeaderService.IsUserOpened}"></app-user-actions>

<app-floating-actions></app-floating-actions>



<ibm-modal size="sm" [open]="reviewService.IsAddedModalOpened" (overlaySelected)="CloseReviewModal()">
  <ibm-modal-header (closeSelect)="CloseReviewModal()">
    <span *ngIf="reviewService.ReviewStatusId == 1">Добавление общего отзыва</span>
    <span *ngIf="reviewService.ReviewStatusId == 2">Добавление отзыва по ссылке</span>

  </ibm-modal-header>
  <section class="bx--modal-content">

    <p>Комментарий</p>
    <textarea style="max-height: 40vh;" ibmTextArea [rows]="2" [cols]="2" [(ngModel)]="reviewService.Comment" aria-label="textarea"></textarea>
  </section>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="CloseReviewModal()">Отменить</button>
    <button class="bx--btn bx--btn--primary" (click)="SendReview()" modal-primary-focus>Отправить</button>
  </ibm-modal-footer>
</ibm-modal>

<ibm-modal size="sm" [open]="ConfirmationService.IsOpened" (overlaySelected)="ConfirmationService.OnCancel()">
  <ibm-modal-header (closeSelect)="ConfirmationService.OnCancel()">{{ConfirmationService.ModalTitle}}
  </ibm-modal-header>
  <section class="bx--modal-content">
    <p>{{ConfirmationService.ModalText}}</p>
    <p class="confirmation-notification">{{ConfirmationService.DescriptionNotification}}</p>




    <ibm-number *ngIf="ConfirmationService.Type == 'number'" label="Новая цена" [(ngModel)]="ConfirmationService.Answer" min="0">
    </ibm-number>

    <ibm-label *ngIf="ConfirmationService.Type == 'cancelMessage'">
      Причина отмены
      <input [(ngModel)]="ConfirmationService.Answer" ibmText placeholder="Причина отмены">
    </ibm-label>


  </section>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="ConfirmationService.OnCancel()">{{ConfirmationService.CancelText}}</button>
    <button class="bx--btn bx--btn--primary" (click)="ConfirmationService.OnConfirm()" modal-primary-focus>{{ConfirmationService.ConfirmText}}</button>
  </ibm-modal-footer>
</ibm-modal>

<ibm-modal size="sm" [open]="BanquetPaymentsService.IsOpenedRemovingModal" (overlaySelected)="BanquetPaymentsService.CloseModalRemovePayment()">


  <ibm-modal-header (closeSelect)="BanquetPaymentsService.CloseModalRemovePayment()">
    <p class="bx--modal-header__label bx--type-delta">Операция с транзакцией</p>
    <p class="bx--modal-header__heading bx--type-beta">Удалить оплату?</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="BanquetPaymentsService.CloseModalRemovePayment()">Отменить</button>
    <button class="bx--btn bx--btn--primary" (click)="BanquetPaymentsService.ApplyModalRemovePayment()" modal-primary-focus>Удалить</button>
  </ibm-modal-footer>
</ibm-modal>






<ibm-modal size="sm" [open]="BanquetPaymentsService.IsOpenedManyConfirmingModal" (overlaySelected)="BanquetPaymentsService.CloseModalConfirmManyPayment()">
  <ibm-modal-header (closeSelect)="BanquetPaymentsService.CloseModalConfirmManyPayment()">
    <p class="bx--modal-header__label bx--type-delta">Подтверждение операции с транзакцией</p>
    <p class="bx--modal-header__heading bx--type-beta">Подписать все неподписанные оплаты?</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="BanquetPaymentsService.CloseModalConfirmManyPayment()">Отменить</button>
    <button class="bx--btn bx--btn--primary" (click)="BanquetPaymentsService.ApplyModalConfirmManyPayment()" modal-primary-focus>Подписать</button>
  </ibm-modal-footer>
</ibm-modal>




<ibm-modal size="sm" [open]="BanquetPaymentsService.IsOpenedFilterConfirmingModal" (overlaySelected)="BanquetPaymentsService.CloseModalConfirmFilteredPayment()">
  <ibm-modal-header (closeSelect)="BanquetPaymentsService.CloseModalConfirmFilteredPayment()">
    <p class="bx--modal-header__label bx--type-delta">Подтверждение операции с транзакцией</p>
    <p class="bx--modal-header__heading bx--type-beta">Подписать все неподписанные оплаты?</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="BanquetPaymentsService.CloseModalConfirmFilteredPayment()">Отменить</button>
    <button class="bx--btn bx--btn--primary" (click)="BanquetPaymentsService.ApplyModalConfirmFilteredPayment()" modal-primary-focus>Подписать</button>
  </ibm-modal-footer>
</ibm-modal>







<ibm-modal size="sm" [open]="BanquetPaymentsService.IsOpenedConfirmingModal" (overlaySelected)="BanquetPaymentsService.CloseModalConfirmPayment()">
  <ibm-modal-header (closeSelect)="BanquetPaymentsService.CloseModalConfirmPayment()">
    <p class="bx--modal-header__label bx--type-delta">Подтверждение операции с транзакцией</p>
    <p class="bx--modal-header__heading bx--type-beta">Подписать оплату?</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="BanquetPaymentsService.CloseModalConfirmPayment()">Отменить</button>
    <button class="bx--btn bx--btn--primary" (click)="BanquetPaymentsService.ApplyModalConfirmPayment()" modal-primary-focus>Подписать</button>
  </ibm-modal-footer>
</ibm-modal>

<ibm-modal size="sm" [open]="BanquetPaymentsService.IsOpenedUnconfirmingModal" (overlaySelected)="BanquetPaymentsService.CloseModalUnconfirmPayment()">
  <ibm-modal-header (closeSelect)="BanquetPaymentsService.CloseModalUnconfirmPayment()">
    <p class="bx--modal-header__label bx--type-delta">Подтверждение операции с транзакцией</p>
    <p class="bx--modal-header__heading bx--type-beta">Отменить подпись оплаты?</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="BanquetPaymentsService.CloseModalUnconfirmPayment()">Не
      отменять</button>
    <button class="bx--btn bx--btn--primary" (click)="BanquetPaymentsService.ApplyModalUnconfirmPayment()" modal-primary-focus>Отменить подпись</button>
  </ibm-modal-footer>
</ibm-modal>



<ibm-modal size="lg" [open]="ModalAddingService.IsOpened" (overlaySelected)="ModalAddingService.OnCancel()">


  <ibm-modal-header (closeSelect)="ModalAddingService.OnCancel()">
    <p class="bx--modal-header__label bx--type-delta">{{ModalAddingService.ModalTitle}}</p>
    <p class="bx--modal-header__heading bx--type-beta">{{ModalAddingService.ModalText}}</p>
  </ibm-modal-header>
  <div class="bx--modal-content">
    <div class="adding-field" *ngFor="let field of ModalAddingService.Fields" [ngClass]="{'hidden': field.IsShowed == false}">

      <ibm-number *ngIf="field.Type == FieldType.Number" [label]="field.Title" [(ngModel)]="field.Value" [min]="field.Min" [max]="field.Max" [invalid]="field.IsInvalid" [invalidText]="field.InvalidText" (change)="field.OnChangeValue($event)">
      </ibm-number>

      <div *ngIf="field.Type == FieldType.Number" fxLayout="row wrap" fxLayoutGap="4px" style="margin-top: 12px">
        <ibm-tag type="cool-gray" *ngFor="let defaultValue of field.DefaultValues" (click)="field.Value = defaultValue;ModalAddingService.CheckRequired()">
          {{defaultValue}}</ibm-tag>
      </div>



      <ibm-label *ngIf="field.Type == FieldType.Text" class="text-filed-wrapper" [ngClass]="{'hidden': field.IsShowed == false}">
        {{field.Title}}
        <input [(ngModel)]="field.Value" ibmText [placeholder]="field.Title" (change)="field.OnChangeValue($event)">
      </ibm-label>

      <div *ngIf="field.Type == FieldType.Text" fxLayout="row wrap" fxLayoutGap="4px" style="margin-top: 12px">
        <ibm-tag type="cool-gray" *ngFor="let defaultValue of field.DefaultValues" (click)="field.Value = defaultValue;ModalAddingService.CheckRequired()">
          {{defaultValue}}</ibm-tag>
      </div>

      <ibm-dropdown *ngIf="field.Type == FieldType.Dropdown" [label]="field.Title" [placeholder]="field.Name" value="content" theme="light" (selected)="field.OnChangeValue($event)" [(ngModel)]="field.Value">
        <ibm-dropdown-list [items]="field.Values"></ibm-dropdown-list>
      </ibm-dropdown>



      <ibm-label *ngIf="field.Type == FieldType.Autocomplete">
        {{field.Title}}
        <div class="ng-autocomplete">
          <ng-autocomplete [data]="field.Values" [searchKeyword]="keyword" (selected)='field.OnChangeValue($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </ibm-label>


      <div class="tabs_wrapper" *ngIf="field.Type == FieldType.Tabs">
        <div class="tabs_title">{{field.Title}}</div>
        <div class="tabs_list">
          <div class="tab_item_wrapper" *ngFor="let tab of field.Values" [ngClass]="{'active': field.ValueId==tab.id}" (click)="field.OnChangeValue(tab.id)">
            <div class="tab_item">
              {{tab.content}}
            </div>
          </div>
        </div>
      </div>

      <ibm-date-picker dateFormat="d.m.Y" *ngIf="field.Type == FieldType.Date" [label]="field.Title" [(ngModel)]="field.Value">
      </ibm-date-picker>

      <div *ngIf="field.Type == FieldType.Time" class="time_picker" fxLayout="row" fxLayoutGap="12px">
        <ibm-number label="Часы" min="0" max="23" [(ngModel)]="field.Value">
        </ibm-number>
        <ibm-number label="Минуты" min="0" max="59" [(ngModel)]="field.AdditionalValue">
        </ibm-number>
      </div>

    </div>

  </div>


  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary" (click)="ModalAddingService.OnCancel()">{{ModalAddingService.CancelText}}</button>
    <button class="bx--btn bx--btn--primary" [disabled]="!ModalAddingService.IsRequired" (click)="ModalAddingService.OnConfirm()" modal-primary-focus>{{ModalAddingService.ConfirmText}}</button>
  </ibm-modal-footer>
</ibm-modal>