import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { BanquetVaultService } from '../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { ToolsService } from '../../../services/tools.service';
import { HttpClient } from '@angular/common/http';
import { Banquet } from 'src/app/models/banquet/banquet';
import { BanquetBuilder } from 'src/app/models/banquet/banquet-builder';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';

@Component({
  selector: 'app-banquet-slider-kitchen',
  templateUrl: './banquet-slider-kitchen.component.html',
  styleUrls: ['./banquet-slider-kitchen.component.scss']
})
export class BanquetSliderKitchenComponent implements OnInit {



  BanquetId: number = -1;
  EmptyBanquet: Banquet;


  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService,
    private router: Router, private http: HttpClient, public ToolsService: ToolsService
  ) {


  }

  ngOnInit(): void {
    this.BanquetVaultService.LoadVault(1, true, new Date("2021-05-21 00:00:00".replace(/-/g, "/")), new Date("2021-05-22 00:00:00".replace(/-/g, "/")));

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Активация капера";
    this.HeaderService.BackButton.Text = "Меню банкетов";
    this.HeaderService.BackButton.Link = "/banquet-menu";


    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню банкетов", "/banquet-menu");
    this.HeaderService.AddBreadcrumb("Активация капера", "/banquet-transactions", true);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });

    let builder: BanquetBuilder = new BanquetBuilder([]);
    this.EmptyBanquet = builder.BuildEmptyBanquet();
    this.BanquetId = -10;

  }

  LoadMoreBanquet() {
    this.BanquetsService.LoadMoreBanquets();
  }

  ToggleKitchenStatus(banquet: Banquet) {
    if (banquet.SliderKitchenSended == false) {
      this.BanquetsService.OpenModalConfirmKitchenStatus(banquet);
    } else {
      this.BanquetsService.OpenModalUnconfirmKitchenStatus(banquet);
    }
  }


}
