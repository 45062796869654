import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banquet-products-reading',
  templateUrl: './banquet-products-reading.component.html',
  styleUrls: ['./banquet-products-reading.component.scss']
})
export class BanquetProductsReadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
