import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging, updates: SwUpdate, push: SwPush) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

    // updates.available.subscribe(_ => updates.activateUpdate().then(() => {
    //   console.log('reload for update');
    //   document.location.reload();
    // }));
    // push.messages.subscribe(msg => console.log('push message', msg));
    // push.notificationClicks.subscribe(click => console.log('notification click', click));


    // navigator.serviceWorker.getRegistration().then(
    //   (registration: ServiceWorkerRegistration) => {
    //     //console.log(registration);
    //     if (registration) {
    //       console.log("Service Worker Registration founded");
    //       this.angularFireMessaging.useServiceWorker(registration);
    //     } else {
    //       console.log("Service Worker Registration not founded");
    //     }

    //   });



  }


  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }


  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })


  }

}
