<div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start"
    fxLayoutGap.lt-md="8px">
    <button ibmButton="primary" (click)="OpenModalAddingEquipment()">Добавить инвентарь</button>
    <button ibmButton="primary" [disabled]="selectedIds.length == 0" (click)="RemoveEquipment()">Удалить</button>
</div>





<div class="equipment_list_wrapper">
    <ibm-tile-group (selected)="selected($event)" [multiple]="true">
        <ibm-selection-tile *ngFor="let equipment of EquipmentService.Equipments" [value]="equipment.Id"
            class="equipment_wrapper">
            <div>
                <div>{{equipment.Name}}</div>
            </div>
        </ibm-selection-tile>
    </ibm-tile-group>
</div>








<ibm-modal size="sm" [open]="AddingEquipment" (overlaySelected)="CloseModalAddingEquipment()">
    <ibm-modal-header (closeSelect)="CloseModalAddingEquipment()">Edit account name</ibm-modal-header>
    <section class="bx--modal-content">

        Название содержимого
        <input [(ngModel)]="NewEquipmentName" ibmText placeholder="Ложки, тарелки">
    </section>
    <ibm-modal-footer>
        <button class="bx--btn bx--btn--secondary" (click)="CloseModalAddingEquipment()">Отменить</button>
        <button class="bx--btn bx--btn--primary" (click)="AddModalAddingEquipment()"
            modal-primary-focus>Добавить</button>
    </ibm-modal-footer>
</ibm-modal>