import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ochagbox-received',
  templateUrl: './ochagbox-received.component.html',
  styleUrls: ['./ochagbox-received.component.scss']
})
export class OchagboxReceivedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
