import { Component, OnInit, Input } from '@angular/core';
import { OrdersService } from '../../../../services/order/orders.service';
import { Order } from '../../../../models/store/order.model';

@Component({
  selector: 'app-order-delivery-stage',
  templateUrl: './order-delivery-stage.component.html',
  styleUrls: ['./order-delivery-stage.component.scss']
})
export class OrderDeliveryStageComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  items: Array<any> = [
    { content: "Александровский район", id: 0 },
    { content: "Вознесеновский район", id: 1 },
    { content: "Днепровский район", id: 2 },
    { content: "Заводской район", id: 3 },
    { content: "Коммунарский район", id: 4 },
    { content: "Космический район", id: 5 },
    { content: "Хортицкий район", id: 6 },
    { content: "Шевченковский район", id: 7 },
  ];


  constructor(public OrderService: OrdersService) { }

  ngOnInit() {
    this.ShippingPresentDate.push(this.delivery.ShippingDate);
  }



  public Hours: number = 0;
  public Minutes: number = 0;
  public Readiness: boolean = false;
  public ShippingDate: Date = new Date();
  public ShippingPresentDate: Array<Date> = new Array<Date>();



  OnChnageReadiness(event) {
    this.Readiness = event.checked;
    this.delivery.Readiness = event.checked;
    this.OrderService.SaveOrder(this.delivery);
  }

  OnChangeShippingDate(event) {
    this.ShippingDate = event[0];
    this.ShippingDate.setHours(this.Hours);
    this.ShippingDate.setMinutes(this.Minutes);

    this.delivery.ShippingDate = this.ShippingDate;
    this.OrderService.SaveOrder(this.delivery);

  }


  OnChangeHours(event) {
    if (event.value > 23) {
      this.Hours = 23;
    } else {

    }
    this.ShippingDate.setHours(event.value);

    this.delivery.ShippingDate = this.ShippingDate;
    this.OrderService.SaveOrder(this.delivery);

  }

  OnChangeMinutes(event) {
    if (event.value > 59) {
      this.Minutes = 59;
    } else {

    }

    this.ShippingDate.setMinutes(event.value);

    this.delivery.ShippingDate = this.ShippingDate;
    this.OrderService.SaveOrder(this.delivery);

  }

  SetDeliveryTime(hour: number, minute: number) {

    this.ShippingDate.setHours(new Date().getHours() + hour);
    this.ShippingDate.setMinutes(new Date().getMinutes() + minute);

    this.delivery.ShippingHour = new Date().getHours() + hour;
    this.delivery.ShippingMinutes = new Date().getMinutes() + minute;
  }

}
