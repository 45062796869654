import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../models/store/order.model';
import { OrdersService } from '../../../services/order/orders.service';

@Component({
  selector: 'app-history-review-action',
  templateUrl: './history-review-action.component.html',
  styleUrls: ['./history-review-action.component.scss']
})
export class HistoryReviewActionComponent implements OnInit {

  @Input() delivery: Order;

  public IsFormOpened: boolean = false;
  public StatusId: number = 1;
  public Comment: string = "";

  constructor(public OrdersService: OrdersService) { }

  ngOnInit(): void {
  }

  OpenForm() {
    this.IsFormOpened = true;
  }

  CloseForm() {
    this.IsFormOpened = false;
  }

  ToggleForm() {
    this.IsFormOpened = !this.IsFormOpened;
  }

  SetStatusId(statusId) {
    this.StatusId = statusId;
  }




  public AddReview() {
    this.OrdersService.AddReview(this.delivery.Id, this.Comment, this.StatusId,
      (review) => {
        this.delivery.PastOrders.find(order => order.Id == this.delivery.Id).Reviews.push(
          {
            Id: review["order_reviews_id"],
            Comment: review["comment"],
            StatusId: review["order_reviews_type_id"],
            AddedDate: review["date_added"],
            Name: review["name"]
          }
        );
      });


    this.Comment = "";
    this.StatusId = 1;
    this.IsFormOpened = false;

  }


}
