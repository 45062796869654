import { Account } from '../account/account.model';
import { Product } from '../store/product.model';
import { Identifiable } from '../tools/identifiable.model';
import { BanquetArea } from './banquet-area';
import { BanquetComment } from './banquet-comment';
import { BanquetCustomer } from './banquet-customer';
import { BanquetPaymentHistory } from './banquet-payment-history';
import { BanquetTable } from './banquet-table';

export class Banquet extends Identifiable {



    private name: string;
    public get Name(): string {
        return this.name;
    }
    public set Name(name: string) {
        this.name = name;
    }


    private categoryName: string;
    public get CategoryName(): string {
        return this.categoryName;
    }
    public set CategoryName(categoryName: string) {
        this.categoryName = categoryName;
    }

    private customNumber: string;
    public get CustomNumber(): string {
        return this.customNumber;
    }
    public set CustomNumber(customNumber: string) {
        this.customNumber = customNumber;
    }


    // private price: number;
    // public get Price(): number {
    //     return this.price;
    // }
    // public set Price(price: number) {
    //     this.price = price;
    // }


    private guests: number;
    public get Guests(): number {
        return this.guests;
    }
    public set Guests(guests: number) {
        this.guests = guests;
    }


    private deposit: number;
    public get Deposit(): number {
        return this.deposit;
    }
    public set Deposit(deposit: number) {
        this.deposit = deposit;
    }


    private status: boolean;
    public get Status(): boolean {
        return this.status;
    }
    public set Status(status: boolean) {
        this.status = status;
    }

    private kepperSended: boolean;
    public get KepperSended(): boolean {
        return this.kepperSended;
    }
    public set KepperSended(kepperSended: boolean) {
        this.kepperSended = kepperSended;
    }

    private sliderKitchenSended: boolean;
    public get SliderKitchenSended(): boolean {
        return this.sliderKitchenSended;
    }
    public set SliderKitchenSended(sliderKitchenSended: boolean) {
        this.sliderKitchenSended = sliderKitchenSended;
    }

    private paymentStatus: string;
    public get PaymentStatus(): string {
        return this.paymentStatus;
    }
    public set PaymentStatus(paymentStatus: string) {
        this.paymentStatus = paymentStatus;
    }


    private confirmed: boolean;
    public get Confirmed(): boolean {
        return this.confirmed;
    }
    public set Confirmed(confirmed: boolean) {
        this.confirmed = confirmed;
    }


    private alcohol: boolean;
    public get Alcohol(): boolean {
        return this.alcohol;
    }
    public set Alcohol(alcohol: boolean) {
        this.alcohol = alcohol;
    }


    private license: boolean;
    public get License(): boolean {
        return this.license;
    }
    public set License(license: boolean) {
        this.license = license;
    }


    private date: Date;
    public get Date(): Date {
        return this.date;
    }
    public set Date(date: Date) {
        this.date = date;
    }


    public get DateHour(): number {

        return this.date.getHours();
    }
    public set DateHour(hour: number) {
        if (hour > 23) {
            this.date.setMinutes(23);
        } else {
            this.date.setHours(hour);
        }
    }


    public get DateMinutes(): number {
        return this.date.getMinutes();
    }
    public set DateMinutes(minute: number) {
        if (minute > 59) {
            this.date.setMinutes(59);
        } else {
            this.date.setMinutes(minute);
        }
    }






    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }

    private dateConfirmed: Date | null;
    public get DateConfirmed(): Date | null {
        return this.dateConfirmed;
    }
    public set DateConfirmed(dateConfirmed: Date | null) {
        this.dateConfirmed = dateConfirmed;
    }


    private target: string;
    public get Target(): string {
        return this.target;
    }
    public set Target(target: string) {
        this.target = target;
    }


    private targetSecond: string = "";
    public get TargetSecond(): string {
        return this.targetSecond;
    }
    public set TargetSecond(targetSecond: string) {
        this.targetSecond = targetSecond;
    }


    private comment: string;
    public get Comment(): string {
        return this.comment;
    }
    public set Comment(comment: string) {
        this.comment = comment;
    }


    private depositName: string;
    public get DepositName(): string {
        return this.depositName;
    }
    public set DepositName(depositName: string) {
        this.depositName = depositName;
    }

    private isProgramDay: boolean = false;
    public get IsProgramDay(): boolean {
        return this.isProgramDay;
    }
    public set IsProgramDay(isProgramDay: boolean) {
        this.isProgramDay = isProgramDay;
    }

    private programSum: number = 0;
    public get ProgramSum(): number {
        return this.programSum;
    }
    public set ProgramSum(programSum: number) {
        this.programSum = programSum;
    }


    // private activated: number;
    // public get Activated(): number {
    //     return this.activated;
    // }
    // public set Activated(activated: number) {
    //     this.activated = activated;
    // }


    // private weight: number;
    // public get Weight(): number {
    //     return this.weight;
    // }
    // public set Weight(weight: number) {
    //     this.weight = weight;
    // }


    private directionTypeId: number;
    public get DirectionTypeId(): number {
        return this.directionTypeId;
    }
    public set DirectionTypeId(directionTypeId: number) {
        this.directionTypeId = directionTypeId;
    }


    private children: number;
    public get Children(): number {
        return this.children;
    }
    public set Children(children: number) {
        this.children = children;
    }


    private timeId: number;
    public get TimeId(): number {
        return this.timeId;
    }
    public set TimeId(timeId: number) {
        this.timeId = timeId;
    }


    private creator: Account = null;
    public get Creator(): Account {
        return this.creator;
    }
    public set Creator(creator: Account) {
        this.creator = creator;
    }



    private contactCustomer: BanquetCustomer = new BanquetCustomer(0);
    public get ContactCustomer(): BanquetCustomer {
        return this.contactCustomer;
    }

    private payments: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();

    public set Payments(payments: Array<BanquetPaymentHistory>) {
        this.payments = payments;
    }

    public get Payments(): Array<BanquetPaymentHistory> {
        return this.payments;
    }



    private comments: Array<BanquetComment> = new Array<BanquetComment>();

    public set Comments(comments: Array<BanquetComment>) {
        this.comments = comments;
    }

    public get Comments(): Array<BanquetComment> {
        return this.comments;
    }




    private tables: Array<BanquetTable> = new Array<BanquetTable>();

    public set Tables(tables: Array<BanquetTable>) {
        this.tables = tables;
    }

    public get Tables(): Array<BanquetTable> {
        return this.tables;
    }


    private showerListTables: Array<BanquetTable> = new Array<BanquetTable>();

    public set ShowerListTables(showerListTables: Array<BanquetTable>) {
        this.showerListTables = showerListTables;
    }

    public get ShowerListTables(): Array<BanquetTable> {
        return this.showerListTables;
    }

    public AddTable(banquet: BanquetTable) {
        this.tables.push(banquet);
        if (this.showerListTables.length < 4) {
            this.showerListTables.push(banquet);
        } else {
            this.IsAllTablesShowed = false;
        }
    }




    private products: Array<Product> = new Array<Product>();

    public set Products(products: Array<Product>) {
        this.products = products;
    }

    public get Products(): Array<Product> {
        return this.products;
    }


    public IsAllTablesShowed: boolean = true;





    private area: BanquetArea;
    public set Area(area: BanquetArea) {
        this.area = area;
    }

    public get Area(): BanquetArea {
        return this.area;
    }

    public PaymentTotal: number = 0;

    GetSumPayment(): number {
        this.PaymentTotal = 0;
        this.Payments.forEach(payment => {
            if (payment.Stream == 1) {
                if (payment.IsIncome) {
                    this.PaymentTotal += Number(payment.Value);
                } else {
                    this.PaymentTotal -= Number(payment.Value);
                }
            }
        });
        return this.PaymentTotal;
    }

    GetSumProgramPayment(): number {
        let paymentTotal: number = 0;

        this.Payments.forEach(payment => {
            if (payment.Stream == 2) {
                if (payment.IsIncome) {
                    paymentTotal += Number(payment.Value);
                } else {
                    paymentTotal -= Number(payment.Value);
                }
            }
        });
        return paymentTotal;
    }


    public GetPayment(id: number) {
        let removingPayment = this.Payments.find((payment) => {
            return payment.Id == id;
        });

        return removingPayment;
    }

    public RemovePayment(id: number) {
        let removingPayment = this.GetPayment(id);
        let index = this.Payments.indexOf(removingPayment, 0);
        if (index > -1) {
            this.Payments.splice(index, 1);
        }
    }

    public ConfirmPayment(id: number) {
        let payment = this.GetPayment(id);
        payment.Confirmed = true;
        payment.ConfirmedTransfer = true;
    }

    public UnconfirmPayment(id: number) {
        let payment = this.GetPayment(id);
        payment.Confirmed = false;
    }

    public ConfirmTransformPayment(id: number) {
        let payment = this.GetPayment(id);
        payment.ConfirmedTransfer = true;
    }




}