<div class="delivery_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="row" fxLayoutGap="40px">

    <div class="panel">
        <app-quick-access-panel *ngIf="PropertyService.UserGroupId == 13" [list]="OrdersService.ObjectsInProgressShip.Values"></app-quick-access-panel>
    </div>
    <div class="content">





        <ibm-tabs>
            <ibm-tab heading="Заказы" (selected)="SelectTab(1)">
                <app-courier-ungroup-list [(selected)]="selectedIds"></app-courier-ungroup-list>
            </ibm-tab>
            <ibm-tab heading="Развозы" (selected)="SelectTab(2)">
                <app-courier-group-list [(selected)]="selectedGroupsIds"></app-courier-group-list>
            </ibm-tab>
            <ibm-tab heading="В пути" (selected)="SelectTab(3)">
                <app-courier-active-group-list>
                </app-courier-active-group-list>
            </ibm-tab>
        </ibm-tabs>




        <div class="shadow_haze" [ngClass]="{'showed_filter': IsFilterOpened}"></div>

    </div>
</div>


<div class="action_button" *ngIf="UngroupActive == 1" [ngClass]="{'disable': selectedIds.length==0}" (click)="OnGroupClick()">
    <div class="action_button_content">
        Сгруппировать
    </div>
</div>
<div class="action_button" *ngIf="UngroupActive == 2" [ngClass]="{'disable': selectedGroupsIds.length==0}" (click)="OnStartGroupClick()">
    <div class="action_button_content">
        Начать
    </div>
</div>