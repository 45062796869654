import { Component, HostBinding } from '@angular/core';
import { HeaderService } from '../../../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  // adds padding to the top of the document, so the content is below the header
  @HostBinding('class.bx--header') headerClass = true;

  constructor(public HeaderService: HeaderService) { }

}
