import { ReviewStatus } from './review-status.model';

export class Review{

    private id: number;
    public get Id(): number{
        return this.id;
    }

    private name: string;
    public get Name(): string{
        return this.name;
    }
    public set Name(name: string){
        this.name = name;
    }

    private comment: string;
    public get Comment(): string{
        return this.comment;
    }
    public set Comment(comment: string){
        this.comment = comment;
    }

    private link: string;
    public get Link(): string{
        return this.link;
    }
    public set Link(link: string){
        this.link = link;
    }

    private dateAdded: Date;
    public get DateAdded(): Date{
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date){
        this.dateAdded = dateAdded;
    }

    private statusId: number;
    public get StatusId(): number{
        return this.statusId;
    }
    public set StatusId(statusId: number){
        this.statusId = statusId;
    }

    public get Status(): ReviewStatus{
        return ReviewStatus.Get(this.statusId);
    }


    constructor(id: number, name: string, comment: string, dateAdded: Date, statusId: any, link: string){
        this.id = id;
        this.name = name;
        this.comment = comment;
        this.dateAdded = dateAdded;

        this.statusId = statusId;
        this.link = link;
    }


}