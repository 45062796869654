<div class="banquet_list_wrapper">
    <!-- <div class="adding_new_banquet">
        <div class="add_new_banquet_button" (click)="AddBanquet()">Добавить новый банкет</div>
    </div> -->

    <div class="banquet_filter_wrapper">
        <app-banquet-filter></app-banquet-filter>
    </div>

    <div fxLayout="column" fxLayoutGap="40px" *ngIf="BanquetsService.IsLoaded">
        <app-banquet-card *ngFor="let banquet of BanquetsService.VisibleObjects.Values" [banquet]="banquet"
            (click)="OpenBanquet(banquet)" class="{{banquet.PaymentStatus}}">
        </app-banquet-card>
    </div>

    <button class="load_more_button" (click)="LoadMoreBanquet()">Еще</button>
</div>

