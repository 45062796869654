<div class="background_wrapper" fxLayout="row" fxLayoutAlign="center center">
    <img class="background_image" src="../../../../assets/images/background.png" alt="">

    <div id="login_wrapper" class="login_wrapper" fxLayout="column" fxLayoutAlign="space-between stretch">

        <div class="top_wrapper">

            <div class="login_title login_padding">Вход</div>
            <div class="login_subtitle login_padding">Нет аккаунта? Обратитесь к администратору ресторана.</div>

            <div class="line"></div>
            <div class="forms_wrapper">

                <div class="form_wrapper" [ngStyle]="{'transform': 'translateX(' + (ActiveIndex * -100) + '%)'}">
                    <div class="login_lable login_padding">Имя пользователя</div>
                    <input class="login_input login_padding" type="text" placeholder="Ваши данные"
                        [(ngModel)]="Username">
                </div>
                <div class="form_wrapper" [ngStyle]="{'transform': 'translateX(' + ((ActiveIndex - 1) * -100) + '%)'}">
                    <div class="login_lable login_padding">Пароль</div>
                    <input class="login_input login_padding" type="password" placeholder="Ваши данные"
                        [(ngModel)]="Password">
                </div>
            </div>
            <div class="line"></div>

            <div class="errors_wrapper login_padding" [ngClass]="{'showed': IsError}" fxLayout="column"
                fxLayoutGap="8px">
                <div class="error" *ngFor="let error of Errors">
                    {{error}}
                </div>
            </div>
        </div>

        <div class="bottom_float_wrapper">
            <div class="bottom_wrapper" fxLayout="row" fxLayoutAlign="space-between stretch">
                <div class="bottom_wrapper_left" fxLayout="row" fxLayoutAlign="start center">
                    <button class="back_button" [ngClass]="{'active': ActiveIndex==1}"
                        (click)="OnBackButtonClick()">Назад</button>
                </div>
                <div class="bottom_wrapper_right">
                    <button ibmButton="primary" class="login_button" (click)="OnNextButtonClick()">

                        <span *ngIf="IsCheckingData==false">{{NextButttonText}}</span>
                        <ibm-loading *ngIf="IsCheckingData" isActive="true" size="sm"></ibm-loading>

                    </button>
                </div>
            </div>
        </div>
    </div>
</div>