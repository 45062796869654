import { Component, OnInit } from '@angular/core';
import { DeliveryService } from '../../../services/delivery.service';
import { HeaderService } from '../../../services/header.service';

@Component({
  selector: 'app-courier-active-group-view-list',
  templateUrl: './courier-active-group-view-list.component.html',
  styleUrls: ['./courier-active-group-view-list.component.scss']
})
export class CourierActiveGroupViewListComponent implements OnInit {

  constructor(public HeaderService: HeaderService, public DeliveryService: DeliveryService) { }

  ngOnInit(): void {
    this.HeaderService.ClearAll();
    this.HeaderService.WindowName = "Активные развозы";
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Активные развозы", "", true);
  }

}
