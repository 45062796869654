import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../../models/store/order.model';
import { OrdersService } from '../../../../services/order/orders.service';

@Component({
  selector: 'app-order-history-stage-reading',
  templateUrl: './order-history-stage-reading.component.html',
  styleUrls: ['./order-history-stage-reading.component.scss']
})
export class OrderHistoryStageReadingComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;


  public Comment: string = "";
  public StatusReviewId: number = 1;

  public isWarning(order: Order): boolean {

    let finded = order.Reviews.find(element => element.StatusId == 1);
    if (finded == null) {
      return false;
    } else {
      return true;
    }


  }

  constructor(public OrdersService: OrdersService) { }

  ngOnInit(): void {

  }


}
