export class InvalidFieldValue {
    protected value: any;
    public Error: string = "Неверное значение";

    public SetValue(value: number) {
        this.value = value;
    }

    public GetValue(): number {
        return this.value;
    }
}