import { Component, OnInit, Input } from '@angular/core';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-general-stage',
  templateUrl: './order-general-stage.component.html',
  styleUrls: ['./order-general-stage.component.scss']
})
export class OrderGeneralStageComponent implements OnInit {

  @Input('delivery') delivery: any;
  @Input('deliveryOriginal') deliveryOriginal: any;

  constructor(public PropertyService: PropertyService) { }

  ngOnInit() {
  }


}
