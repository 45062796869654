import { Identifiable } from '../tools/identifiable.model';
import { OrderStatus } from './orser-status.model';

export class OrderHistory extends Identifiable{
    
    private orderId: number;
    public get OrderId(): number{
        return this.orderId;
    }
    public set OrderId(orderId: number){
        this.orderId = orderId;
    }

    
    private orderStatusId: number;
    public get OrderStatusId(): number{
        return this.orderStatusId;
    }
    public set OrderStatusId(orderStatusId: number){
        this.orderStatusId = orderStatusId;
    }

    
    private comment: string;
    public get Comment(): string{
        return this.comment;
    }
    public set Comment(comment: string){
        this.comment = comment;
    }

    public get Status(): OrderStatus{
        return OrderStatus.Get(this.orderStatusId);
    }


    private dateAdded: Date;
    public get DateAdded(): Date{
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date){
        this.dateAdded = dateAdded;
    }


    public IsOpened: boolean = false;


    constructor(id: number, orderId: number, comment: string, orderStatusId: number, dateAdded: Date){
        super(id);
        this.orderId = orderId;
        this.orderStatusId = orderStatusId;
        this.dateAdded = dateAdded;
        this.comment = comment;
        //this.dateAdded.setHours( this.dateAdded.getHours() - 3 );
    }
}