<div class="delivery_list" fxLayout="column" fxLayoutGap="12px">

    <div class="delivery_item" *ngFor="let delivery of OrderList" [ngClass]="{
        'active': delivery.id == CurrentId,
        'expectation': (delivery.Status.Id==1 || delivery.Status.Id==17),  
        'processing': delivery.Status.Id==2,  
        'confirmed': delivery.Status.Id==3, 
        'formed_delivery': delivery.Status.Id==14, 
        'received ': delivery.Status.Id==16, 
        'delivered': delivery.Status.Id==15, 
        'completed': delivery.Status.Id==5,
        'keeper': delivery.Status.Id==19,
        'pickup-ready': delivery.Status.Id==20
    }" [routerLink]="[ '/delivery', delivery.id ]" (click)="OpenOrder()">

        <app-order-panel-card [ngClass]="{
        'active': delivery.id == CurrentId,
        'expectation': (delivery.Status.Id==1 || delivery.Status.Id==17), 
        'processing': delivery.Status.Id==2,  
        'confirmed': delivery.Status.Id==3, 
        'formed_delivery': delivery.Status.Id==14, 
        'received ': delivery.Status.Id==16, 
        'delivered': delivery.Status.Id==15, 
        'completed': delivery.Status.Id==5,
        'keeper': delivery.Status.Id==19,
        'pickup-ready': delivery.Status.Id==20
    }" [delivery]="delivery"></app-order-panel-card>

    </div>
</div>