import { Component, Input, OnInit } from '@angular/core';
import { Banquet } from 'src/app/models/banquet/banquet';
import { ToolsService } from '../../../../services/tools.service';

@Component({
  selector: 'app-banquet-card',
  templateUrl: './banquet-card.component.html',
  styleUrls: ['./banquet-card.component.scss']
})
export class BanquetCardComponent implements OnInit {


  @Input() banquet: Banquet;

  constructor(public ToolsService: ToolsService) { }

  ngOnInit(): void {

  }

  ActivateAllTablesShowing(event, banquet) {
    event.stopPropagation();
    banquet.IsAllTablesShowed = true;
  }



}
