import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { NavigationAction } from 'src/app/models/navigation/navigation-action.model';

@Component({
  selector: 'app-banquet-form',
  templateUrl: './banquet-form.component.html',
  styleUrls: ['./banquet-form.component.scss']
})
export class BanquetFormComponent implements OnInit {

  public BanquetId: string = "";
  public IsFilterOpened: boolean = false;

  public PaymentsSources: Array<any> = [
    { content: "Наличные", id: 1, selected: true },
    { content: "Карта", id: 2, selected: false },
    { content: "Терминал", id: 3, selected: false },
    { content: "Перевод", id: 4, selected: false },
  ];

  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    private router: Router,
  ) {


  }

  ngOnInit(): void {



    this.route.params.subscribe(params => {

      this.BanquetId = params['id'];

      this.HeaderService.ClearActions();
      this.HeaderService.ClearSubActions();
      this.HeaderService.ClearAll();
      if (this.BanquetsService.IsLoaded) {

        console.log("1");

        console.log("started loading banquet data");
        this.BanquetsService.OpenBanquetById(Number(this.BanquetId));
        console.log("ended loading banquet data");


        let addSubaction = new NavigationAction("Добавить банкет", "add", () => {
          this.router.navigate(['/banquet-adding']);
        });
        this.HeaderService.AddSubActions(addSubaction);

        let canceleSubaction = new NavigationAction("Вернуть залоги", "trash", () => {
          this.RemoveAllTransaction();
        });
        this.HeaderService.AddSubActions(canceleSubaction);

        this.HeaderService.AddAction("person", () => {
          this.HeaderService.IsUserOpened = true;
        });



        if (this.BanquetsService.ActiveBanquet != null) {
          this.HeaderService.WindowName = this.BanquetsService.ActiveBanquet.Name;
        }
        this.HeaderService.BackButton.Text = "Банкеты";
        this.HeaderService.BackButton.Link = "/banquets";
        this.HeaderService.ClearBreadcrumb();

        this.HeaderService.AddBreadcrumb("Меню", "/menu");
        this.HeaderService.AddBreadcrumb("Банкеты", "/banquets");
        this.HeaderService.AddBreadcrumb("Банкеты", "/banquets", true);


      } else {

        console.log("2");

        this.BanquetsService.OnLoadBanquetEvents.push(() => {
          this.BanquetsService.OpenBanquetById(Number(this.BanquetId));



          let addSubaction = new NavigationAction("Добавить банкет", "add", () => {
            this.router.navigate(['/banquet-adding']);
          });
          this.HeaderService.AddSubActions(addSubaction);

          let canceleSubaction = new NavigationAction("Вернуть залоги", "trash", () => {
            this.RemoveAllTransaction();
          });
          this.HeaderService.AddSubActions(canceleSubaction);

          this.HeaderService.WindowName = this.BanquetsService.ActiveBanquet.Name;
          this.HeaderService.BackButton.Text = "Банкеты";
          this.HeaderService.BackButton.Link = "/banquets";
          this.HeaderService.ClearBreadcrumb();
          this.HeaderService.AddBreadcrumb("Меню", "/menu");
          this.HeaderService.AddBreadcrumb("Банкеты", "/banquets");
          this.HeaderService.AddBreadcrumb("Банкеты", "/banquets", true);

          this.HeaderService.AddAction("person", () => {
            this.HeaderService.IsUserOpened = true;
          });
        });
      }


    });

    this.PropertyService.IsLoadingCircleShowed = false;

  }


  Create() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetsService.AddBanquet(
        this.ModalAddingService.Fields[0].Value,
        this.ModalAddingService.Fields[1].Value,
        this.ModalAddingService.Fields[2].Value,
        this.ModalAddingService.Fields[3].Value,
      );
    });

    this.ModalAddingService.Show(
      new ModalData("Добавить банкет", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildText("Имя", 0, 100000),
        ModalFieldBuilder.BuildText("Фамилия", 0, 100000),
        ModalFieldBuilder.BuildDatePicker("Дата проведения"),
        ModalFieldBuilder.BuildText("Телефон", 0, 100000),
      ]
    );
  }


  SaveBanquet() {
    this.router.navigate(['/banquets']);
  }


  RemoveAllTransaction() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetPaymentsService.AddPayment(
        this.BanquetsService.ActiveBanquet.GetSumPayment(),
        2,
        this.ModalAddingService.Fields[0].ValueId,
        "",
        "",
        "",
        1
      );
    });

    let dropDown = ModalFieldBuilder.BuildDropdown("Способ оплаты", this.PaymentsSources, "Наличные");
    dropDown.ValueId = 1;

    let removeAllTransactionModel = new ModalData("Вернуть все транзакции", "", "Вернуть", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(
      removeAllTransactionModel,
      [
        dropDown,
      ]
    );
  }






}
