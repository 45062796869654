import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { BanquetVaultService } from '../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { HttpClient } from '@angular/common/http';
import { Banquet } from 'src/app/models/banquet/banquet';
import { BanquetBuilder } from 'src/app/models/banquet/banquet-builder';
import { BanquetArea } from 'src/app/models/banquet/banquet-area';

@Component({
  selector: 'app-banquet-free-tables',
  templateUrl: './banquet-free-tables.component.html',
  styleUrls: ['./banquet-free-tables.component.scss']
})
export class BanquetFreeTablesComponent implements OnInit {


  BanquetId: number = -1;
  EmptyBanquet: Banquet;


  constructor(private route: ActivatedRoute, public BanquetsService: BanquetsService,
    public HeaderService: HeaderService, public PropertyService: PropertyService,
    public ModalAddingService: ModalAddingService, public BanquetPaymentsService: BanquetPaymentsService,
    public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService,
    private router: Router, private http: HttpClient,
  ) {


  }

  ngOnInit(): void {
    this.BanquetVaultService.LoadVault(1, true, new Date("2021-05-21 00:00:00".replace(/-/g, "/")), new Date("2021-05-22 00:00:00".replace(/-/g, "/")));

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Умный свод";
    this.HeaderService.BackButton.Text = "Меню банкетов";
    this.HeaderService.BackButton.Link = "/banquet-menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню банкетов", "/banquet-menu");
    this.HeaderService.AddBreadcrumb("Свод", "/banquet-transactions", true);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });

    let builder: BanquetBuilder = new BanquetBuilder([]);
    this.EmptyBanquet = builder.BuildEmptyBanquet();
    this.BanquetId = -10;

  }




}
