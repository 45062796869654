import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { System } from '../../models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationAction } from '../../models/navigation/navigation-action.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public ActiveMenu: number = 1;
  public IsMenuOpened: boolean = false;

  constructor(public HeaderService: HeaderService,
    private route: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {
    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Настройки";
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Настройки", "/settings", true);


    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });

    this.HeaderService.AddSubActions(new NavigationAction("Меню", "collapse", () => {
      this.IsMenuOpened = !this.IsMenuOpened;
    }));
  }

  OnChangeActiveMenu(menuId: number) {
    this.ActiveMenu = menuId;
  }

}
