<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <div class="main_title">Итог (Заказ №{{delivery.Id}})</div>


    <div fxLayout="row" fxLayoutGap="32px">
        <ibm-label>
            Район
            <p>«В разработке»</p>
        </ibm-label>
        <ibm-label>
            Адрес
            <p>{{delivery.ShippingAdress}}</p>
        </ibm-label>
    </div>

    <app-separator></app-separator>

    <div class="main_title">История</div>

    <div class="accordion">
        <div *ngFor="let history of delivery.Histories; let i = index" class="accordion_item_wrapper"
            [ngClass]="{'opened': history.IsOpened}">
            <div class="accordion_item" (click)="ToggleAccordion(history)" fxLayout="row" fxLayoutAlign="start center"
                fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap.lt-md="8px">
                <div class="history_name">
                    {{history.Status.Name}}
                </div>
                <div class="history_date">
                    {{history.DateAdded | date:"HH:mm:ss"}}
                </div>
                <div class="history_item_more_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#arrow-down"></use>
                    </svg>
                </div>
            </div>
            <div class="accordion_content">
                <div class="accordion_content_title">Общая продолжительность</div>

                <div class="accordion_content_text">
                    <span *ngIf="i==0">
                        {{ history.DateAdded - delivery.DateAdded + PropertyService.GetTimezoneOffset() | date:"H:mm:ss"
                        }}
                    </span>
                    <span *ngIf="i>0">
                        {{ history.DateAdded - delivery.Histories[i-1].DateAdded + PropertyService.GetTimezoneOffset() |
                        date:"H:mm:ss" }}
                    </span>

                </div>

                <div class="accordion_content_title">Времени от поступления</div>
                <div class="accordion_content_text">{{ history.DateAdded - delivery.DateAdded +
                    PropertyService.GetTimezoneOffset() | date:"H:mm:ss" }}</div>
            </div>
        </div>
    </div>



    <app-separator></app-separator>



</div>