<div class="delivery_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="row" fxLayoutGap="40px">

    <div class="panel">

        <div class="delivery_courier" [routerLink]="['/delivery-courier']">Формирование
            доставки({{OrdersService.ObjectsCourierShip.Values.length}})</div>
        <app-quick-access-panel></app-quick-access-panel>

    </div>
    <div class="content">



        <div class="block_wrapper">
            <div class="form_wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="32px">
                <div class="form_item">
                    <div class="tabs_wrapper">
                        <div class="tabs_title">Метод доставки</div>
                        <div class="tabs_list">
                            <div class="tab_item_wrapper" *ngFor="let tab of shippingMethods" [ngClass]="{'active': newOrder.ShippingMethod==tab.id}" (click)="OnChangeShippingMethod(tab.id)">
                                <div class="tab_item">
                                    {{tab.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form_item">
                    <div class="tabs_wrapper">
                        <div class="tabs_title">Метод оплаты</div>
                        <div class="tabs_list">
                            <div class="tab_item_wrapper" *ngFor="let tab of paymentMethods" [ngClass]="{'active': newOrder.PaymentMethod==tab.id}" (click)="OnChangePaymentMethod(tab.id)">
                                <div class="tab_item">
                                    {{tab.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
                <div class="form_item" *ngIf="newOrder.ShippingMethod == 'Бесплатная доставка'">
                    <ibm-label>
                        Адрес
                        <input [(ngModel)]="newOrder.ShippingAdress" (change)="OnChangeValue()" ibmText theme="light">
                    </ibm-label>
                </div>
                <div class="form_item">
                    <ibm-label>
                        Телефон
                        <input [(ngModel)]="newOrder.Customer.Phone" (change)="OnChangeValue()" ibmText theme="light">
                    </ibm-label>
                </div>
            </div>






            <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px" *ngIf="newOrder.ShippingMethod == 'Бесплатная доставка'">
                <div class="form_item">
                    <ibm-label>
                        Подъезд
                        <input [(ngModel)]="newOrder.Entrance" ibmText theme="light">
                    </ibm-label>
                </div>
                <div class="form_item">
                    <ibm-label>
                        Квартира
                        <input [(ngModel)]="newOrder.Floor" ibmText theme="light">
                    </ibm-label>
                </div>
            </div>

            <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px" *ngIf="newOrder.ShippingMethod == 'Бесплатная доставка'">
                <div class="form_item">
                    <ibm-label>
                        Этаж
                        <input [(ngModel)]="newOrder.Apartment" ibmText theme="light">
                    </ibm-label>
                </div>
                <div class="form_item">
                    <ibm-label>
                        Код
                        <input [(ngModel)]="newOrder.HomeCode" ibmText theme="light">
                    </ibm-label>
                </div>
            </div>



        </div>


        <div class="block_wrapper">
            <div fxLayout="column" fxLayoutGap="24px">
                <ibm-label>
                    Комментарий
                    <input [(ngModel)]="newOrder.Comment" ibmText theme="light" placeholder="Комментарий">
                </ibm-label>


            </div>
        </div>


        <div class="block_wrapper">
            <button class="button_light" (click)="OpenModalAddingProduct()">Добавить блюдо</button>
            <!-- <button *ngIf="showOnly==false" class="button_light" (click)="OpenModalAddingProduct()">Добавить блюдо</button> -->
            <div class="checkout_accordion">



                <div class="product_wrapper" *ngFor="let product of newOrder.Products">
                    <div class="checkout_item" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="8px">
                        <div class="checkout_item_name">{{product.Name}}</div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="checkout_item_count" fxLayout="row" fxLayoutAlign="start center">
                                <span class="count_wrapper">{{product.Count}}</span>
                                <span *ngIf="showOnly">шт</span>
                                <div class="count_buttons" *ngIf="showOnly==false">
                                    <div class="count_buttons_up" (click)="AddProduct(product)" fxLayout="row" fxLayoutAlign="start end">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                            <use xlink:href="#caret-up"></use>
                                        </svg>
                                    </div>
                                    <div class="count_buttons_down" (click)="MinusProduct(product)" fxLayout="row" fxLayoutAlign="start start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                            <use xlink:href="#caret-down"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="checkout_item_price_per_one">₴ {{product.Price * 1}}</div>
                            <div class="checkout_item_price">₴ {{product.Price * product.Count}}</div>
                        </div>
                        <div class="checkout_item_more_icon" (click)="RemoveProduct(product)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#trash-can"></use>
                            </svg>
                        </div>
                    </div>

                    <div class="product_comment" *ngIf="product.Comment != ''">
                        {{product.Comment}}
                    </div>



                </div>

            </div>
        </div>

        <div class="block_wrapper">
            <div class="total_price">Общая стоимость: {{Total | number:'1.0-0'}}₴</div>
        </div>


        <div class="block_wrapper" fxLayout="column" fxLayoutGap="12px">
            <ibm-date-picker dateFormat="d.m.Y" language="ru" label="Дата доставки" theme="light" (valueChange)="OnChangeShippingDate($event)" [(ngModel)]="ShippingPresentDate">
            </ibm-date-picker>

            <div class="tags_wrapper" fxLayout="row wrap" fxLayoutGap="8px">
                <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 2}" (click)="SetDeliveryTime(2)">По готовности</span>
            </div>

            <div class="other_time_tag">
                <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 6}" (click)="SetDeliveryTime(6)">Выбрать конкретное время</span>
            </div>

            <div class="time_title">Время готовности заказа</div>

            <div class="time_picker" fxLayout="row" *ngIf="ActiveDeliveryTimeMode != 6">
                <p>{{newOrder.OrderReadyHour | twotime}}:{{newOrder.OrderReadyMinutes | twotime}}</p>
            </div>

            <div class="time_picker" fxLayout="row" fxLayoutGap="8px" *ngIf="ActiveDeliveryTimeMode == 6">
                <ibm-number label="Часы" (change)="OnChangeOrderReadyHours($event)" [(ngModel)]="newOrder.OrderReadyHour" theme="light">
                </ibm-number>
                <ibm-number label="Минуты" (change)="OnChangeOrderReadyMinutes($event)" [(ngModel)]="newOrder.OrderReadyMinutes" theme="light">
                </ibm-number>
            </div>

            <div class="time_title">Время доставки</div>

            <div class="time_picker" fxLayout="row" *ngIf="ActiveDeliveryTimeMode != 6">
                <p>{{newOrder.ShippingHour | twotime}}:{{newOrder.ShippingMinutes | twotime}}</p>
            </div>

            <div class="time_picker" fxLayout="row" fxLayoutGap="8px" *ngIf="ActiveDeliveryTimeMode == 6">
                <ibm-number label="Часы доставки" (change)="OnChangeHours($event)" [(ngModel)]="newOrder.ShippingHour" theme="light">
                </ibm-number>
                <ibm-number label="Минуты доставки" (change)="OnChangeMinutes($event)" [(ngModel)]="newOrder.ShippingMinutes" theme="light">
                </ibm-number>
            </div>
        </div>




        <button ibmButton="primary" [disabled]="IsInvalid" size="normal" (click)="AddOrder()">Добавить заказ</button>

    </div>
</div>