
import { Banquet } from './banquet';
import { BanquetPaymentHistory } from './banquet-payment-history';

export class BanquetPaymentHistoryBuilder {

    private banquetPaymentHistory: BanquetPaymentHistory;

    constructor() {

    }

    Build(jsonObject): BanquetPaymentHistory {
        this.banquetPaymentHistory = new BanquetPaymentHistory(jsonObject["banquet_payment_history_id"]);
        this.BuildValue(jsonObject);
        this.BuildConfirmed(jsonObject);
        this.BuildConfirmaedTransfer(jsonObject);
        this.BuildDateAdded(jsonObject);
        this.BuildDateCurrentBanquet(jsonObject);
        this.BuildName(jsonObject);
        this.BuildIsIncome(jsonObject);
        this.BuildBanquetId(jsonObject);
        this.BuildBanquetContact(jsonObject);
        this.BuildPaymentSourceId(jsonObject);
        this.BuildManager(jsonObject);
        this.BuildCardDate(jsonObject);
        this.BuildStream(jsonObject);

        return this.banquetPaymentHistory;
    }
    BuildStream(jsonObject: any) {
        switch (jsonObject["banquet_payment_stream_id"]) {
            case "1":
                this.banquetPaymentHistory.Stream = 1;
                break;
            case "2":
                this.banquetPaymentHistory.Stream = 2;
                break;
        }

        
    }

    private BuildMinimal(jsonObject): BanquetPaymentHistory {
        let minimalBanquet = new BanquetPaymentHistory(jsonObject["banquet_payment_history_id"]);
        minimalBanquet.Value = jsonObject["value"];
        minimalBanquet.Confirmed = jsonObject["confirmed"];
        minimalBanquet.DateAdded = new Date(jsonObject["date_added"].replace(/-/g, "/"));

        return minimalBanquet;
    }


    private BuildName(jsonObject) {
        if (jsonObject["type"] != null) {
            this.banquetPaymentHistory.Name = jsonObject["type"]["name"];
        }
    }

    private BuildBanquetContact(jsonObject) {
        if (jsonObject["banquet"] != null) {
            this.banquetPaymentHistory.BanquetContact = jsonObject["banquet"]["contact_firstname"] + " " + jsonObject["banquet"]["contact_lastname"];
        }
    }

    private BuildBanquetId(jsonObject) {
        if (jsonObject["banquet_id"] != null) {
            this.banquetPaymentHistory.BanquetId = jsonObject["banquet_id"];
        }
    }

    private BuildIsIncome(jsonObject) {
        if (jsonObject["type"] != null) {
            //this.banquetPaymentHistory.IsIncome = jsonObject["type"]["is_income"];
            this.banquetPaymentHistory.IsIncome = jsonObject["type"]["is_income"] == 1 ? true : false;
        }
    }

    private BuildValue(jsonObject) {
        this.banquetPaymentHistory.Value = jsonObject["value"];
    }

    private BuildConfirmed(jsonObject) {
        this.banquetPaymentHistory.Confirmed = jsonObject["confirmed"] == 1 ? true : false;
    }

    private BuildConfirmaedTransfer(jsonObject) {
        this.banquetPaymentHistory.ConfirmedTransfer = jsonObject["confirmed_transfer"] == 1 ? true : false;
    }

    private BuildDateAdded(jsonObject) {
        this.banquetPaymentHistory.DateAdded = new Date(jsonObject["date_added"].replace(/-/g, "/"));
    }

    private BuildDateCurrentBanquet(jsonObject) {
        this.banquetPaymentHistory.DateCurrentBanquet = new Date(jsonObject["date_current_banquet"].replace(/-/g, "/"));
    }

    private BuildCardDate(jsonObject) {
        this.banquetPaymentHistory.CardDate = jsonObject["card_date_text"];
    }

    private BuildPaymentSourceId(jsonObject) {
        this.banquetPaymentHistory.PaymentSourceId = jsonObject["banquet_payment_source_id"];
    }

    private BuildManager(jsonObject) {
        if (jsonObject["user"] != null) {
            this.banquetPaymentHistory.Manager.FirstName = jsonObject["user"]["firstname"];
            this.banquetPaymentHistory.Manager.LastName = jsonObject["user"]["lastname"];
        }

    }


}


// addedBanquetPaymentHistory.Name = banquetPayments["name"];
// addedBanquetPaymentHistory.Value = banquetPayments["value"];
// addedBanquetPaymentHistory.DateAdded = new Date(banquetPayments["date_added"]);
// addedBanquetPaymentHistory.Confirmed = banquetPayments["confirmed"];
// addedBanquetPaymentHistory.IsIncome = banquetPayments["is_income"];

// addedBanquetPaymentHistory.PaymentTypeId = banquetPayments["banquet_payment_type_id"];
// addedBanquetPaymentHistory.PaymentSourceId = banquetPayments["banquet_payment_source_id"];