<ibm-header brand="Очаг" name="[Доставка]">
    <ibm-header-navigation>
        <!-- <ibm-header-item routerLink="catalog">Catalog</ibm-header-item>
		<ibm-header-item routerLink="docs">Docs</ibm-header-item>
		<ibm-header-item routerLink="support">Support</ibm-header-item>
		<ibm-header-menu title="Manage">
			<ibm-header-item routerLink="link1">Link 1</ibm-header-item>
			<ibm-header-item>Link 2</ibm-header-item>
			<ibm-header-item>Link 3</ibm-header-item>
		</ibm-header-menu> -->
    </ibm-header-navigation>
    <ibm-header-global>




        <ibm-header-action title="action" *ngFor="let action of HeaderService.Actions" (click)="action.Action(action)">



            <svg *ngIf="action.Icon == 'logout'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#logout"></use>
            </svg>

            <svg *ngIf="action.Icon == 'add'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#add"></use>
            </svg>

            <svg *ngIf="action.Icon == 'view'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#view"></use>
            </svg>
            <svg *ngIf="action.Icon == 'viewOff'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#view-off"></use>
            </svg>
            <svg *ngIf="action.Icon == 'filter'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#filter"></use>
            </svg>
            <svg *ngIf="action.Icon == 'collapse'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#collapse-categories"></use>
            </svg>

            <svg *ngIf="action.Icon == 'update'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#renew"></use>
            </svg>
            <svg *ngIf="action.Icon == 'trash'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#trash-can"></use>
            </svg>
            <svg *ngIf="action.Icon == 'review'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#review"></use>
            </svg>
            <svg *ngIf="action.Icon == 'person'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#person"></use>
            </svg>

            <div class="action" *ngIf="action.Icon == 'noty'">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#notification"></use>
                </svg>
                <div class="badge_count" *ngIf="action.BadgeCount > 0">{{action.BadgeCount}}</div>
            </div>
        </ibm-header-action>

    </ibm-header-global>
</ibm-header>