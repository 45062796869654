<ibm-label *ngIf="showOnly==false">
    Текст заметки
    <input ibmText theme="light" [(ngModel)]="OrderNotesService.AddedNoteComment" autocomplete="off">
</ibm-label>

<button *ngIf="showOnly==false" class="button_light add_note_button"
    (click)="OrderNotesService.AddModalAddingNote()">Добавить
    заметку</button>


<div class="notes" *ngIf="Notes.length > 0">

    <div class="note_wrapper" *ngFor="let note of Notes;index as i;">
        <div class="note_name">{{note.Comment}}</div>
        <div class="note_wrapper_actions">
            <svg (click)="OpenModalEditingNote(note)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <use xlink:href="#edit"></use>
            </svg>
            <svg style="margin-left: 8px;" (click)="RemoveNote(note)" xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" viewBox="0 0 32 32">
                <use xlink:href="#trash-can"></use>
            </svg>
        </div>
    </div>

</div>

<div class="empry_wrapper" *ngIf="Notes.length == 0">
    <h2>Заметок нет!</h2>
</div>