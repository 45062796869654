<div class="block_wrapper">
    <app-order-cart [(products)]="delivery.Products" [(originals)]="deliveryOriginal == null ? [] : deliveryOriginal.Products" [(delivery)]="delivery">
    </app-order-cart>

    <div class="total_price">Общая стоимость: {{delivery.Total | number:'1.0-0'}}₴</div>

    <!-- <ibm-number class="equipment-picker" label="Общее количество приборов" min="0" theme="light"
        [value]="delivery.GloablEquipmentCount" (change)="OnChangeGlobalCount($event)">
    </ibm-number> -->
</div>

<div class="block_wrapper">
    <div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
        <!-- <ibm-number label="Общее количество приборов" min="0" [value]="delivery.GloablEquipmentCount"
            (change)="OnChangeGlobalCount($event)" theme="light">
        </ibm-number> -->

        <div fxLayout="row" fxLayoutGap="60px" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px">

            <div *ngFor="let equipment of EquipmentsService.Equipments">
                <ibm-checkbox [checked]="CheckEquipmentInOrder(equipment)" (change)="OnDisabledChnage($event, equipment)">
                    {{equipment.Name}}
                </ibm-checkbox>
                <ibm-number label="Количество" min="0" [value]="GetEquipmentInOrder(equipment)" [disabled]="!CheckEquipmentInOrder(equipment)" (change)="OnChangeEquipmentCount($event, equipment)" theme="light">
                </ibm-number>
            </div>
        </div>
    </div>
</div>