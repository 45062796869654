import { Identifiable } from '../tools/identifiable.model';

export class Note extends Identifiable{
    private title: string;
    public get Title(): string{
        return this.title;
    }
    public set Title(title: string){
        this.title = title;
    }

    private comment: string;
    public get Comment(): string{
        return this.comment;
    }
    public set Comment(comment: string){
        this.comment = comment;
    }

    constructor(id: number, title: string, comment: string){
        super(id);
        this.title = title;
        this.comment = comment;
    }
}