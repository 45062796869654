import { Product } from './product.model';

export class ProductBuilder {

    private product: Product;

    constructor() {

    }


    // id: element["order_id"],
    // date: element["date_added"],
    // customer: {
    //     name: element[""],
    //     phone: element["telephone"],
    //     email: element["email"]
    // },
    // shippingAdress: element["shipping_address_1"],
    // paymentMethod: element["payment_method"],
    // orderStatus: element["order_status_id"],
    // dateAdded: element["date_added"],
    // total: element["total"],
    // comment: element["comment"],



    Build(jsonObject): Product {
        this.product = new Product(jsonObject["product_id"]);

        this.BuildName(jsonObject);
        this.BuildPrice(jsonObject);
        this.BuildCount(jsonObject);
        this.BuildIsWeightable(jsonObject);
        this.BuildComment(jsonObject);

        return this.product;
    }

    private BuildName(jsonObject) {
        this.product.Name = jsonObject["name"];
    }
    private BuildPrice(jsonObject) {
        this.product.Price = jsonObject["price"];
    }
    private BuildCount(jsonObject) {
        this.product.Count = jsonObject["count"];
    }
    private BuildComment(jsonObject) {
        this.product.Comment = jsonObject["comment"];
    }
    private BuildIsWeightable(jsonObject) {
        this.product.IsWeightable = jsonObject["price_suffix"] != null || jsonObject["price_suffix"] != '' ? true : false;
    }





}