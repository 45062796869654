import { Injectable } from '@angular/core';
import { Product } from '../models/store/product.model';
import { HttpClient } from '@angular/common/http';
import { PropertyService } from './property.service';
import { OrdersService } from './order/orders.service';
import { ProductBuilder } from '../models/store/product-builder.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public Products: Array<Product> = Array<Product>();
  public Categories: Array<any> = new Array<any>();
  public CategoriesDropDaown: Array<any> = new Array<any>();
  public ProductsAutocomplete: Array<any> = Array<any>();

  public IsOpenedAddingModal: boolean = false;
  public SelectedAddedProduct: number = 1;
  public AddedCount: number = 1;
  public AddedComment: string = "";


  constructor(private http: HttpClient, private property: PropertyService,
    public OrdersService: OrdersService) {
    // this.LoadProducts();
    // this.LoadCategories();
  }



  GetProduct(id) {
    return this.Products.find(product => product.Id == id);
  }

  AddModalAddingProduct: any = () => { };


  ReloadProducts(category) {

    let request = `https://ochagzp.com.ua/index.php?route=api/v2/order-product/LoadProductByCategory&token=${this.property.Token}&category=${category}`;



    this.http.get(request).subscribe(
      (data) => {
        let builder: ProductBuilder = new ProductBuilder();
        this.Products = new Array<Product>();
        this.ProductsAutocomplete.splice(0, this.ProductsAutocomplete.length);
        data["data"].forEach(product => {
          let productBuilded: Product = builder.Build(product);
          this.Products.push(productBuilded);

          this.ProductsAutocomplete.push(
            {
              id: productBuilded.Id,
              name: productBuilded.Name,
            }
          );
        });
      });
  }

  LoadCategories() {
    let request = `https://ochagzp.com.ua/index.php?route=api/v2/order/LoadCategories&token=${this.property.Token}`;

    this.CategoriesDropDaown.push({
      id: 0,
      content: "Все",
      selected: true
    });

    this.http.get(request).subscribe(
      (data) => {
        data["data"].forEach(category => {
          this.Categories.push({
            Id: category["category_id"],
            Name: category["name"],
          });

          this.CategoriesDropDaown.push({
            id: category["category_id"],
            content: category["name"],
            selected: false
          });

        });
      });
  }

  LoadProducts() {
    let request = `https://ochagzp.com.ua/index.php?route=api/v2/order-product/LoadProducts&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data) => {
        let builder: ProductBuilder = new ProductBuilder();
        data["data"].forEach(product => {
          let productBuilded: Product = builder.Build(product);
          this.Products.push(productBuilded);
          this.ProductsAutocomplete.push(
            {
              id: productBuilded.Id,
              name: productBuilded.Name,
            }
          );

        });
      });
  }



}
