import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  constructor(public HeaderService: HeaderService, public OrderService: OrdersService,
  private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {

  }

  OpenOrder(id) {
    this.HeaderService.HideAllElements();
    this.router.navigate([`/delivery/${id}`]);
  }

}
