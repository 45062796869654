import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/models/store/order.model';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-history-stage',
  templateUrl: './order-history-stage.component.html',
  styleUrls: ['./order-history-stage.component.scss']
})
export class OrderHistoryStageComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  constructor(public PropertyService: PropertyService,) { }

  ngOnInit(): void {
  }

}
