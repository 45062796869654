import { Component, Input, OnInit } from '@angular/core';

import { PropertyService } from '../../../services/property.service';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';


@Component({
  selector: 'app-statistics-panel',
  templateUrl: './statistics-panel.component.html',
  styleUrls: ['./statistics-panel.component.scss']
})
export class StatisticsPanelComponent implements OnInit {

  @Input("date") TransactionBanquetDate: Date = null;

  constructor(public BanquetPaymentsService: BanquetPaymentsService,
    public PropertyService: PropertyService,
    public BanquetsService: BanquetsService,) { }

  ngOnInit(): void {

  }

}
