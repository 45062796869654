<div class="courier_list_wrapper" fxLayout="column" fxLayoutGap="8px" *ngIf="CurrentDelivery != null">


    <div class="courier_item_wrapper" *ngFor="let order of CurrentDelivery.Orders; let i = index" fxLayout="row"
        fxLayoutGap="8px" fxLayoutAlign="stretch start">
        <div class="courier_item_number">{{i+1 | twotime}}</div>


        <div class="courier_item" [ngClass]="{'ended': order.StatusId==5, 'canceled': order.StatusId==18}"
            [routerLink]="['/courier-open-order/' + OrderGroupId + '/' + order.Id]">
            <div class="order_header" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="order_name">Заказ №{{order.Id}} | {{order.StatusId}}</div>
                <div class="order_price">₴{{order.Total | number}}</div>

            </div>


            <div class="order_adress" fxLayout="row" fxLayoutAlign="space-between start">
                <div>{{order.ShippingAdress}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" class="purchase_icon"
                    [ngClass]="{'warning': order.CardPayment && !order.Paymented}" *ngIf="order.CardPayment">
                    <use xlink:href="#purchase"></use>
                </svg>
            </div>

            <div class="order_bottom" fxLayout="row" fxLayoutAlign="space-between start">
                <div class="order_delivery_time">

                    <div class="order_time">{{order.ShippingDate | date:'dd.MM.yy h:mm'}}</div>
                    <div class="order_time">{{order.TimeDifference}}</div>
                </div>
                <div class="order_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#arrow-right-up"></use>
                    </svg>
                </div>
            </div>
        </div>


    </div>




</div>

<div class="action_button" (click)="StartDelivery()" *ngIf="CurrentDelivery.StatusId == 4">
    <div class="action_button_content">
        Начать доставку
    </div>
</div>
<div class="action_button danger" (click)="CancelDelivery()" *ngIf="CurrentDelivery.StatusId == 3">
    <div class="action_button_content">
        Отменить доставку
    </div>
</div>