import { Component, Input, OnInit } from '@angular/core';
import { Tabs } from '../tabs/tabs.component';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class Tab implements OnInit {

  @Input('tabTitle') title: string;
  @Input() active = false;

  ngOnInit(): void {
  }

}
