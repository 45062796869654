<div class="comments-wrapper" fxLayout="column" fxLayoutGap="16px">
    <button ibmButton="primary" (click)="OpenAddingBanquetTableModal()">Добавить</button>
    <div *ngFor="let area of BanquetZoneService.Tables">
        <div class="banquet-table-item">
            <div class="banquet-table-item__type">
                <div>{{area.Name}}</div>
                <div class="banquet-table-item__actions" (click)="OpenModalRemoveBanquetTable(area.Id)">
                    <img src="../../../../assets/icons/trash.svg">
                </div>
            </div>
        </div>
    </div>
</div>