import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { DeliveryService } from '../../../services/delivery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../services/confirmation.service';
import { PropertyService } from '../../../services/property.service';
import { ProductsService } from '../../../services/products.service';
import { OrderNotesService } from '../../../services/order/order-notes.service';
import { Product } from 'src/app/models/store/product.model';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { NumberInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/number-invalid-filed-value.model';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { StringInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/string-invalid-filed-value.model';
import { OrderDateManager } from 'src/app/models/store/order-date-manager';

@Component({
  selector: 'app-order-new',
  templateUrl: './order-new.component.html',
  styleUrls: ['./order-new.component.scss']
})
export class OrderNewComponent implements OnInit {


  public ActiveDeliveryTimeMode: number = 6;

  public TimeModes: Array<any> = [
    { hour: 0, minute: 30 },
    { hour: 1, minute: 0 },
    { hour: 1, minute: 30 },
    { hour: 1, minute: 40 },
    { hour: 2, minute: 0 },
    { hour: 2, minute: 30 },
  ];

  public ShippingDate: Date = new Date();
  public ShippingPresentDate: Array<Date> = new Array<Date>();
  public OrderDateManager: OrderDateManager = new OrderDateManager();

  shippingMethods: Array<any> = [
    { content: "Самовывоз", id: "Самовывоз", selected: false },
    { content: "Доставка", id: "Бесплатная доставка", selected: false },
  ];
  paymentMethods: Array<any> = [
    { content: "Наличные", id: "Оплата наличными", selected: false },
    { content: "Картой", id: "Оплата картой", selected: false },
  ];

  public newOrder: any = {
    ShippingAdress: "",
    Comment: "",
    Area: 0,
    PaymentMethod: "Оплата наличными",
    ShippingMethod: "Бесплатная доставка",
    Entrance: "",
    Floor: "",
    Apartment: "",
    HomeCode: "",
    Customer: {
      Phone: ""
    },
    Products: [],

    OrderReadyHour: 0,
    OrderReadyMinutes: 0,
    ShippingHour: 0,
    ShippingMinutes: 0,
    ShippingDate: new Date(),
    ShippingPresentDate: new Date(),
    IsReadinessShipping: false

  };
  public IsFilterOpened: boolean = false;
  public IsInvalid: boolean = true;
  public Total: number = 0;


  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    public DeliveryService: DeliveryService,
    private route: ActivatedRoute,
    private router: Router,
    public PropertyService: PropertyService,
    public ProductsService: ProductsService,
    public OrderNotesService: OrderNotesService,
    public ModalAddingService: ModalAddingService,
    public ConfirmationService: ConfirmationService,) {


    this.newOrder.Address = "";
    this.newOrder.Phone = "";
    this.newOrder.Comment = "";
    this.newOrder.Area = "Запорожье";

    //OrdersService.ObjectsCourierShip


  }

  ngOnInit(): void {
    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Создание заказа";
    this.HeaderService.BackButton.Text = "Список заказов";
    this.HeaderService.BackButton.Link = "/deliveries";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Создание заказа", "/deliveries", true);

    this.ProductsService.AddModalAddingProduct = () => {
      this.AddModalAddingProduct();
    };

    this.ShippingPresentDate = new Array<Date>();
    this.ShippingPresentDate.push(this.newOrder.ShippingDate);

    if (this.newOrder.IsReadinessShipping) {
      this.ActiveDeliveryTimeMode = 2;
    } else {
      this.ActiveDeliveryTimeMode = 6;
    }
  }

  selected(event) {
    this.newOrder.Area = event.item.id;

  }

  AddOrder() {
    if (this.IsInvalid == false) {
      this.OrdersService.AddOrder(this.newOrder);
    }

  }

  OnChangeShippingMethod(id) {
    this.newOrder.ShippingMethod = id;
    this.ValidateValues();
  }

  OnChangeCardPayment(event) {
    this.newOrder.CardPayment = event.checked;
    if (this.newOrder.CardPayment == false) {
      this.newOrder.Paymented = false;
    }
  }

  OnChangePaymentMethod(id) {
    this.newOrder.CardPayment = (id == "Оплата картой");
    this.newOrder.PaymentMethod = id;
    this.OrdersService.SaveOrder(this.newOrder);
  }

  OnChangeValue() {
    this.ValidateValues();
  }



  ValidateValues() {
    this.IsInvalid = false;
    if (this.newOrder.Customer.Phone.length < 6) {
      this.IsInvalid = true;
    }
    if (this.newOrder.ShippingMethod == "Бесплатная доставка") {
      if (this.newOrder.ShippingAdress.length < 3) {
        this.IsInvalid = true;
      }
    }
    if (this.newOrder.Products.length == 0) {
      this.IsInvalid = true;
    }
  }

  AddModalAddingProduct() {
    this.ProductsService.IsOpenedAddingModal = false;

    let addedProductId = this.ProductsService.SelectedAddedProduct;
    let addedProductName = this.ProductsService.GetProduct(this.ProductsService.SelectedAddedProduct).Name;
    let addedProductCount = this.ProductsService.AddedCount;
    let addedProductPrice = this.ProductsService.GetProduct(this.ProductsService.SelectedAddedProduct).Price;
    let addedProductComment = this.ProductsService.AddedComment;

    let AddedProduct = new Product(addedProductId, addedProductName, addedProductPrice, addedProductCount, addedProductComment);
    this.newOrder.Products.push(AddedProduct);

    this.ProductsService.AddedCount = 1;
    this.ProductsService.AddedComment = "";
    this.ProductsService.SelectedAddedProduct = 1;

    this.UpdateTotalNewOrder();
    this.ValidateValues();

  }

  OpenModalAddingProduct() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.AddModalAddingProduct();
      this.ModalAddingService.CheckRequired();
    });


    let productsAutocomplete = ModalFieldBuilder.BuildAutocomplete("Позиция меню",
      this.ProductsService.ProductsAutocomplete, "");
    productsAutocomplete.Required = true;
    productsAutocomplete.ValueId = null;
    productsAutocomplete.Value = "";
    productsAutocomplete.OnChangeValue = (event) => {
      productsAutocomplete.ValueId = event.id;
      productsAutocomplete.Value = event.name;
      this.ProductsService.SelectedAddedProduct = event.id;
      this.ModalAddingService.CheckRequired();
    };

    let categoryDropDown = ModalFieldBuilder.BuildDropdown("Категория меню", this.ProductsService.CategoriesDropDaown, "");
    categoryDropDown.ValueId = 1;
    categoryDropDown.OnChangeValue = (event) => {
      categoryDropDown.ValueId = event.item.id;
      this.ProductsService.ReloadProducts(event.item.id);
      this.ModalAddingService.CheckRequired();

    };

    let commentProduct = ModalFieldBuilder.BuildText("Комментарий", 0, 200);
    commentProduct.OnChangeValue = (event) => {
      this.ProductsService.AddedComment = event.target.value;
      this.ModalAddingService.CheckRequired();
    };

    let countProducts = ModalFieldBuilder.BuildNumber("Количество позиций", 0, 100000, 1, []);
    countProducts.Required = true;
    countProducts.InvalidValues.push(new NumberInvalidFieldValue(0, "Нужно задать значение количество позиций"));
    countProducts.OnChangeValue = (event) => {
      countProducts.Value = event.value;
      this.ProductsService.AddedCount = event.value;
      this.ModalAddingService.CheckRequired();
    };

    this.ModalAddingService.Show(
      new ModalData("Добавить позицию меню", "", "Добавить", "Отмена"),
      [
        categoryDropDown,
        productsAutocomplete,
        countProducts,
        commentProduct,
      ]
    );


  }



  RemoveProduct(product: Product) {

    this.ConfirmationService.AddConfirmEvent(
      (answer) => {
        let findedElementIndex = this.newOrder.Products.findIndex(element => element.Id == product.Id);
        this.newOrder.Products.splice(findedElementIndex, 1);
        this.UpdateTotalNewOrder();
        this.ValidateValues();
      });
    this.ConfirmationService.OpenModal(
      "Подтвердить удаление",
      `Подтвердите, что вы хотите удалить продукт «${product.Name}»`,
      "",
      "Отменить",
      "Удалить");
  }

  UpdateTotalNewOrder() {
    this.Total = 0;
    this.newOrder.Products.forEach(product => {
      this.Total += product.Price * product.Count;
    });
  }













  OnChangeShippingDate(event) {
    let date = event[0];
    this.newOrder.ShippingDate = new Date(date + " UTC");
    this.UpdateShippingDateTimeFromCurrentDate();
    this.OrdersService.SaveOrder(this.newOrder);
  }

  OnChangeHours(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 23) {
      this.newOrder.ShippingHour = 23;
    } else {
      this.newOrder.ShippingHour = event.value;
    }

  }

  OnChangeMinutes(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 59) {
      this.newOrder.ShippingMinutes = 59;
    } else {
      this.newOrder.ShippingMinutes = event.value;
    }
  }





  OnChangeOrderReadyHours(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 23) {
      this.newOrder.OrderReadyHour = 23;
    } else {
      this.newOrder.OrderReadyHour = event.value;
    }
  }


  OnChangeOrderReadyMinutes(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 59) {
      this.newOrder.OrderReadyMinutes = 59;
    } else {
      this.newOrder.OrderReadyMinutes = event.value;
    }
  }











  UpdateShippingDateTimeFromAddedDate(hours: number = 0, minutes: number = 0) {
    let newDate = this.OrderDateManager.
      GenerateDifferenceDateFromAddedDate(this.newOrder, hours, minutes);

    let newDateReady = this.OrderDateManager.
      GenerateDifferenceDateFromAddedDate(this.newOrder, hours, minutes - 30);


    this.UpdateShippingDateTime(newDate);
    this.UpdateOrderReadyDateTime(newDateReady);


  }

  UpdateShippingDateTimeFromCurrentDate(hours: number = 0, minutes: number = 0) {
    let newDate = this.OrderDateManager.
      GenerateDifferenceDateFromCurrentDate(this.newOrder, hours, minutes);

    let newDateReady = this.OrderDateManager.
      GenerateDifferenceDateFromCurrentDate(this.newOrder, hours, minutes - 30);

    this.UpdateShippingDateTime(newDate);
    this.UpdateOrderReadyDateTime(newDateReady);
  }

  UpdateShippingDateTime(newDate: Date) {
    this.ShippingDate = newDate;

    this.newOrder.ShippingHour = newDate.getHours();
    this.newOrder.ShippingMinutes = newDate.getMinutes();
    this.newOrder.ShippingDate = newDate;

    this.ShippingPresentDate = new Array<Date>();
    this.ShippingPresentDate.push(this.newOrder.ShippingDate);
  }

  UpdateOrderReadyDateTime(newDate: Date) {
    this.newOrder.OrderReadyHour = newDate.getHours();
    this.newOrder.OrderReadyMinutes = newDate.getMinutes();
  }

  SetDeliveryTime(ActiveDeliveryTimeMode: number) {
    this.ActiveDeliveryTimeMode = ActiveDeliveryTimeMode;
    if (ActiveDeliveryTimeMode < 6) {
      let hours = this.TimeModes[ActiveDeliveryTimeMode].hour;
      let minutes = this.TimeModes[ActiveDeliveryTimeMode].minute;
      this.UpdateShippingDateTimeFromCurrentDate(hours, minutes);
      this.newOrder.IsReadinessShipping = true;
    } else {
      this.newOrder.IsReadinessShipping = false;
    }

  }

}
