<div class="filter_wrapper" fxLayout="column" fxLayoutGap="24px" [ngClass]="{'hiddened': IsMinimizeFilter}">


    <div class="inputs" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px">
        <ibm-search theme="light" placeholder="Поиск по названию или имени" [(ngModel)]="Name"
            (valueChange)="FilterNameChange($event)">
        </ibm-search>
    </div>
    <div class="inputs" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px">
        <ibm-dropdown label="Статус кипера" placeholder="Выбор типа" value="content" theme="light"
            (selected)="FilterKeeperStatus($event)">
            <ibm-dropdown-list [items]="KeeperStatusItems"></ibm-dropdown-list>
        </ibm-dropdown>
        <ibm-dropdown label="Статус бегунка" placeholder="Выбор типа" value="content" theme="light"
            (selected)="FilterKitchenStatus($event)">
            <ibm-dropdown-list [items]="KitchenStatusItems"></ibm-dropdown-list>
        </ibm-dropdown>
        <ibm-dropdown label="Статус залога" placeholder="Выбор статуса" value="content" theme="light"
            (selected)="FilterConfirmStatus($event)" [(ngModel)]="ConfirmStatus">
            <ibm-dropdown-list [items]="ConfirmItems"></ibm-dropdown-list>
        </ibm-dropdown>
    </div>
</div>


<div class="filter_row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px"
    [ngClass]="{'hiddened': IsMinimizeFilter}">

    <div class="filter_wrapper" fxLayout="column" fxLayoutGap="8px">
        <div class="filter_wrapper__title">Поиск по номеру</div>
        <div class="inputs" fxLayout="row" fxLayoutGap="12px">
            <ibm-label>
                Номер банкета
                <input ibmText theme="light" (change)="FilterCustomNumber($event)">
            </ibm-label>
        </div>
    </div>

    <div class="filter_wrapper" fxLayout="column" fxLayoutGap="8px">
        <div class="filter_wrapper__title">Дата мероприятия</div>
        <div class="inputs" fxLayout="row" fxLayoutGap="12px">
            <ibm-date-picker dateFormat="d.m.Y" theme="light" label="Дата проведения" language="ru"
                (valueChange)="FilterDateChange($event)">
            </ibm-date-picker>
        </div>
    </div>

    <!-- <div class="filter_wrapper" fxLayout="column" fxLayoutGap="8px">
        <div class="filter_wrapper__title">Сумма залога</div>
        <div class="inputs" fxLayout="row" fxLayoutGap="12px">
            <ibm-dropdown label="Операция" placeholder="Операция" value="content" theme="light"
                (selected)="FilterTotalOperation($event)" [(ngModel)]="TotalOperation">
                <ibm-dropdown-list [items]="OperationItems"></ibm-dropdown-list>
            </ibm-dropdown>
            <ibm-number label="Количество" theme="light" min="0" (change)="FilterTotalPrice($event)">
            </ibm-number>
        </div>
    </div> -->

</div>






<!-- <div class="filter_row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px"
    [ngClass]="{'hiddened': IsMinimizeFilter}">
    <div class="filter_wrapper" fxLayout="column" fxLayoutGap="8px">
        <div class="filter_wrapper__title">Дата мероприятия</div>
        <div class="inputs" fxLayout="row" fxLayoutGap="12px">
            <ibm-date-picker dateFormat="d.m.Y" theme="light" label="Дата проведения" language="ru"
                (valueChange)="FilterDateChange($event)">
            </ibm-date-picker>
        </div>
    </div>
</div> -->



<div class="actions" fxLayout="row" fxLayoutGap="12px">

    <div>
        <button ibmButton="ghost" size="normal" (click)="ToggleMinimizeFilter()">
            {{IsMinimizeFilter ? "Развернуь фильтр" : "Свернуть фильтр"}}
        </button>
    </div>
    <div class="main-actions" [ngClass]="{'hiddened': IsMinimizeFilter}">
        <button ibmButton="ghost" size="normal" (click)="ClearFilter()">Сбросить</button>
        <button ibmButton="primary" size="normal" (click)="UploadFilteredBanquet()">Применить</button>
    </div>
</div>

<h2 *ngIf="BanquetsService.VisibleObjects.Values.length == 0 && BanquetsService.IsLoading == false">Банкеты по данному
    запросу не найдены</h2>