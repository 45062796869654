import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyService } from './property.service';
import { OrderGroup } from '../models/store/order-group.model';
import { Order } from '../models/store/order.model';
import { OrderBuilder } from '../models/store/order-builder.model';
import { OrdersService } from './order/orders.service';
import { NotificationService } from './notification.service';
import { NotificationAlertType } from '../models/tools/notification.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from './header.service';
import { ORDER_STATUS_ALERTED, ORDER_STATUS_NEW, ORDER_STATUS_OPENED, ORDER_STATUS_STARTED } from '../models/tools/order-statuses.const';
import { OrderStatus } from '../models/store/orser-status.model';







@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  private orderGroups: Array<OrderGroup> = new Array<OrderGroup>();
  public get OrderGroups(): Array<OrderGroup> {
    return this.orderGroups;
  }

  private addedOrderGroups: Array<OrderGroup> = new Array<OrderGroup>();
  public get AddedOrderGroups(): Array<OrderGroup> {
    return this.addedOrderGroups;
  }

  private startedOrderGroups: Array<OrderGroup> = new Array<OrderGroup>();
  public get StartedOrderGroups(): Array<OrderGroup> {
    return this.startedOrderGroups;
  }

  private activeOrderGroups: Array<OrderGroup> = new Array<OrderGroup>();
  public get ActiveOrderGroups(): Array<OrderGroup> {
    return this.activeOrderGroups;
  }


  public IsLoading: boolean = false;
  public isLoaded: boolean = false;
  public IsFirstLoaded: boolean = true;

  public LastOrderGroupDate: string = "";

  private onLoadDeliveryEvents: Array<any> = new Array<any>();

  public LastNotificationId: number = 0;

  public AddLoadDeliveryEvents(event: any) {
    this.onLoadDeliveryEvents.push(event);
    if (this.isLoaded) {
      event();
    }
  }



  constructor(private http: HttpClient, private property: PropertyService,
    public OrdersService: OrdersService, public NotificationService: NotificationService,
    private route: ActivatedRoute, private router: Router, public HeaderService: HeaderService,) {

    this.OrdersService.AddOrderGroupAddedEvent(
      (groupId: number, orders: Array<Order>) => {
        let addingGroup: OrderGroup = new OrderGroup(groupId, ORDER_STATUS_ALERTED, new Date(), null, orders);
        this.orderGroups.unshift(addingGroup);
        this.activeOrderGroups.unshift(addingGroup);
      }
    );


    this.OrdersService.AddOrderGroupEndedEvent(
      (groupId: number) => {
        this.router.navigate(['courier-list']);

        let findedElementIndex = this.OrderGroups.findIndex(element => element.Id == groupId);
        this.OrderGroups.splice(findedElementIndex, 1);

        findedElementIndex = this.StartedOrderGroups.findIndex(element => element.Id == groupId);
        this.StartedOrderGroups.splice(findedElementIndex, 1);

        findedElementIndex = this.ActiveOrderGroups.findIndex(element => element.Id == groupId);
        this.ActiveOrderGroups.splice(findedElementIndex, 1);

      }
    );

    this.OrdersService.OnLoadShipEvents.push(
      () => {
        let data = this.bufferData;
        if (data["error"] != null) {

        } else {
          if (data["data"] != null) {
            data["data"].forEach(orderGroup => {
              let builder: OrderBuilder = new OrderBuilder();
              let addedOrders: Array<Order> = new Array<Order>();
              if (orderGroup["orders"].length > 0) {
                orderGroup["orders"].forEach(orderInGroup => {
                  let findedOrder: any = this.OrdersService.Objects.Values.find(order => order.Id == orderInGroup["order_id"]);
                  if (findedOrder != null) {
                    findedOrder = builder.Build(orderInGroup);
                  }
                  addedOrders.push(findedOrder);
                });
              }

              let addingGroup: OrderGroup = new OrderGroup(orderGroup["order_group_id"], orderGroup["order_group_status_id"], orderGroup["date_added"], orderGroup["courier"], addedOrders);
              this.orderGroups.unshift(addingGroup);

              if (addingGroup.StatusId == ORDER_STATUS_NEW) {
                this.orderGroups.unshift(addingGroup);
              }

              if (addingGroup.StatusId == ORDER_STATUS_OPENED ||
                addingGroup.StatusId == ORDER_STATUS_STARTED ||
                addingGroup.StatusId == ORDER_STATUS_ALERTED) {
                this.activeOrderGroups.unshift(addingGroup);
              }

              if (addingGroup.StatusId == ORDER_STATUS_OPENED) {
                this.addedOrderGroups.unshift(addingGroup);
              }

              if (addingGroup.StatusId == ORDER_STATUS_STARTED) {
                this.startedOrderGroups.unshift(addingGroup);
              }
            });
          }
        }
      }
    );


  }

  public LoadOrderGroup() {


    if (this.IsLoading) {
      setTimeout(() => {
        this.LoadOrderGroup();
      }, 2000);
      return;
    }

    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/order-group/LoadMoreOrderGroup&token=${this.property.Token}`;

    this.orderGroups = new Array<OrderGroup>();
    this.addedOrderGroups = new Array<OrderGroup>();
    this.startedOrderGroups = new Array<OrderGroup>();
    this.activeOrderGroups = new Array<OrderGroup>();

    this.IsLoading = true;
    this.http.get(request).subscribe(
      (data: any) => {



        this.orderGroups = new Array<OrderGroup>();
        let builder: OrderBuilder = new OrderBuilder();

        if (data["data"].length > 0) {
          this.LastOrderGroupDate = data["data"][0]["date_started"];
        }

        data["data"].forEach(orderGroup => {
          let addedOrders: Array<Order> = new Array<Order>();
          if (orderGroup["orders"].length > 0) {
            orderGroup["orders"].forEach(orderInGroup => {
              let findedOrder: any = this.OrdersService.Objects.Values.find(order => order.Id == orderInGroup["order_id"]);

              if (findedOrder == null) {
                findedOrder = builder.Build(orderInGroup);
              }
              if (findedOrder != null) {
                addedOrders.push(findedOrder);
              }

            });
          }
          let addingGroup: OrderGroup = new OrderGroup(orderGroup["order_group_id"], orderGroup["order_group_status_id"], orderGroup["date_added"], orderGroup["courier"], addedOrders);
          if (addingGroup.StatusId == ORDER_STATUS_NEW) {
            this.orderGroups.push(addingGroup);
          }
          if (addingGroup.StatusId == ORDER_STATUS_OPENED ||
            addingGroup.StatusId == ORDER_STATUS_STARTED ||
            addingGroup.StatusId == ORDER_STATUS_ALERTED) {
            this.activeOrderGroups.push(addingGroup);
          }
          if (addingGroup.StatusId == ORDER_STATUS_OPENED) {
            this.addedOrderGroups.push(addingGroup);
          }
          if (addingGroup.StatusId == ORDER_STATUS_STARTED) {
            this.startedOrderGroups.push(addingGroup);
          }


        });

        this.LastNotificationId = data["last_notification_id"];

        if (this.IsFirstLoaded) {
          setInterval(() => {
            this.LoadNewOrderGroup();
          }, 3000);
          setInterval(() => {
            this.LoadNewOrders();
          }, 3000);
        }



        this.onLoadDeliveryEvents.forEach(event => {
          event();
        });
        this.IsLoading = false;
        this.IsFirstLoaded = false;

      }
    );
  }

  public bufferData: any = [];

  public LoadNewOrderGroup() {


    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/order-group/LoadNewOrderGroup&token=${this.property.Token}&last_date=${this.LastOrderGroupDate}`;



    this.http.get(request).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {



          let builder: OrderBuilder = new OrderBuilder();
          if (data["data"].length > 0) {
            this.LastOrderGroupDate = data["data"][0]["date_started"];
          }

          data["data"].forEach(orderGroup => {

            let addedOrders: Array<Order> = new Array<Order>();
            if (orderGroup["orders"].length > 0) {
              orderGroup["orders"].forEach(orderInGroup => {
                let findedOrder: any = this.OrdersService.Objects.Values.find(order => order.Id == orderInGroup["order_id"]);

                if (findedOrder != null) {
                  findedOrder = builder.Build(orderInGroup);
                }
                addedOrders.push(findedOrder);
              });
            }
            let addingGroup: OrderGroup = new OrderGroup(orderGroup["order_group_id"], orderGroup["order_group_status_id"], orderGroup["date_added"], orderGroup["courier"], addedOrders);
            if (addingGroup.StatusId == ORDER_STATUS_NEW) {
              this.orderGroups.unshift(addingGroup);
            }
            if (addingGroup.StatusId == ORDER_STATUS_OPENED ||
              addingGroup.StatusId == ORDER_STATUS_STARTED ||
              addingGroup.StatusId == ORDER_STATUS_ALERTED) {
              this.activeOrderGroups.unshift(addingGroup);
            }
            if (addingGroup.StatusId == ORDER_STATUS_OPENED) {
              this.addedOrderGroups.unshift(addingGroup);
            }
            if (addingGroup.StatusId == ORDER_STATUS_STARTED) {
              this.startedOrderGroups.unshift(addingGroup);
            }

          });



          //this.HeaderService.UpdateBadgeCount("noty", this.NewOrdersNotification.length);

        }

      }
    );
  }


  public LoadNewOrders() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/notification/LoadDeliveryGroupManagerNotifications&token=${this.property.Token}&last_id=${this.LastNotificationId}`;


    this.http.get(request).subscribe(
      (data: any) => {

        if (data["error"] != null) {

        } else {

          let editstatusgroupNotifications = data["notifications"]["editstatusgroup"];

          if (editstatusgroupNotifications["orders"] != undefined) {
            if (editstatusgroupNotifications["orders"].length > 0) {
              editstatusgroupNotifications["orders"].forEach(orderGroup => {


                let findedOrder: any = this.ActiveOrderGroups.find((order: OrderGroup) => order.Id == orderGroup["order_group_id"]);

                if (findedOrder != null) {
                  findedOrder.StatusId = orderGroup["order_group_status_id"];


                  if (findedOrder.StatusId == 3) {
                    findedOrder.Status = "В пути";
                  }

                  if (findedOrder.StatusId == 4) {
                    findedOrder.Status = "Ожидает";
                  }

                  if (findedOrder.StatusId == 5) {
                    let index = this.ActiveOrderGroups.indexOf(findedOrder, 0);
                    if (index > -1) {
                      this.ActiveOrderGroups.splice(index, 1);
                    }
                  }
                }


              });


            }
            this.LastNotificationId = data["last_id"];
          }

        }
      });

  }


  public Ungroup(delivery: OrderGroup) {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-group/UngroupOrderGroup&token=${this.property.Token}&delivery=${delivery.Id}`);
    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          let findedElementIndex = this.AddedOrderGroups.findIndex(element => element.Id == delivery.Id);
          this.AddedOrderGroups.splice(findedElementIndex, 1);

          findedElementIndex = this.OrderGroups.findIndex(element => element.Id == delivery.Id);
          this.OrderGroups.splice(findedElementIndex, 1);

          delivery.Orders.forEach(element => {

            this.OrdersService.ObjectsCourierShip.PushToEnd(element);
          });
        }
      }
    );
  }

  public StartGroup(orders: Array<string>, ActiveCourier: any) {
    let ordersIds: string = "";
    orders.forEach(order => {
      ordersIds += (order + " ");

    });
    ordersIds = ordersIds.substring(0, ordersIds.length - 1)
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-group/StartOrderGroup&token=${this.property.Token}&orders=${ordersIds}&courier_id=${ActiveCourier.user_id}`);
    this.http.get(request).subscribe(
      (data) => {

        let currentDate: Date = new Date();
        this.LastOrderGroupDate = currentDate.getFullYear() + "-" + this.twoDigits(1 + currentDate.getMonth()) + "-" + this.twoDigits(currentDate.getDate()) + " " + this.twoDigits(currentDate.getHours()) + ":" + this.twoDigits(currentDate.getMinutes()) + ":" + this.twoDigits(currentDate.getSeconds());


        if (data["status"] == "700") {
          orders.forEach(removedOrderId => {
            let findedElementIndex = this.OrderGroups.findIndex(element => element.Id == Number.parseInt(removedOrderId));

            this.OrderGroups[findedElementIndex].Orders.forEach(element => {
              element.StatusId = OrderStatus.Get(16).Id;
            });

            this.OrderGroups.splice(findedElementIndex, 1);
          });
          //this.NotificationService.Add(`Развоз отправлен курьеру`, NotificationAlertType.Carmine);
        }
      }
    );
  }


  public EditOrderGroup(OrderGroup: OrderGroup, StatusId: number, Comment: string = "") {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-group/EditOrderGroupStatus&token=${this.property.Token}&order_group_id=${OrderGroup.Id}&order_group_status_id=${StatusId}&comment=${Comment}`);



    this.http.get(request).subscribe(
      (data) => {
        OrderGroup.StatusId = StatusId;
      }
    );
  }


  private twoDigits(d) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
    return d.toString();
  }

}
