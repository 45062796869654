import { Component, OnInit } from '@angular/core';
import { ReviewService } from '../../../services/review.service';

@Component({
  selector: 'app-review-actions',
  templateUrl: './review-actions.component.html',
  styleUrls: ['./review-actions.component.scss']
})
export class ReviewActionsComponent implements OnInit {

  constructor(public reviewService: ReviewService) { }

  ngOnInit(): void {

  }

  OpenAddedModal() {
    this.reviewService.OpenAddingModal(1);
  }
  OpenAddedModalByLink() {
    this.reviewService.OpenAddingModal(2);
  }

}
