export class FloatingAction{
    Text: string = "";
    Icon: string = "";
    Action: any = () => { };
    IsDesktop: boolean = true;
    IsMobile: boolean = true;
    Tooltip: string = "";


    constructor(Text: string, Icon: string, Action: any) {
        this.Text = Text;
        this.Icon = Icon;
        this.Action = Action;

    }
}