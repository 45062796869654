<div class="payment_wrapper"
    [ngClass]="{'showed_filter': IsFilterOpened, 'show_only': PropertyService.UserGroupId != 1}" fxLayout="row"
    fxLayoutGap="40px">

    <div class="filter">
        <app-statistics-panel [date]="TransactionBanquetDate"></app-statistics-panel>
    </div>

    <div class="content">
        <div class="banquet-vault-list-wrapper" *ngIf="BanquetsService.IsLoaded">

            <div class="banquet_filter_wrapper">
                <app-banquet-filter (dateChange)="OnDateChange($event)"></app-banquet-filter>
            </div>

            <div class="list_wrapper">
                <ibm-structured-list>
                    <ibm-list-header>
                        <ibm-list-column>№</ibm-list-column>
                        <ibm-list-column>Фамилия</ibm-list-column>
                        <ibm-list-column>Дата</ibm-list-column>
                        <ibm-list-column>Время</ibm-list-column>
                        <ibm-list-column>Гости</ibm-list-column>
                        <ibm-list-column>Менеджер</ibm-list-column>
                        <ibm-list-column>Стол</ibm-list-column>
                        <ibm-list-column>Комментарий</ibm-list-column>
                    </ibm-list-header>


                    <ibm-list-row class="banquet_row {{banquet.PaymentStatus}}"
                        *ngFor="let banquet of BanquetsService.VisibleObjects.Values" (click)="OpenBanquet(banquet)">




                        <ibm-list-column>{{banquet.Id}}</ibm-list-column>
                        <ibm-list-column>{{banquet.ContactCustomer.LastName}}</ibm-list-column>
                        <ibm-list-column>{{banquet.Date| date:'dd.MM'}} ({{banquet.TimeId == 1 ? "День" : "Вечер"}})
                        </ibm-list-column>
                        <ibm-list-column>{{banquet.Date| date:'HH:mm'}}</ibm-list-column>
                        <ibm-list-column>

                            <div>Гости: {{banquet.Guests}} {{ToolsService.NumericalDeclensions(banquet.Children,
                                ['чел.', 'чел.', 'чел.'])}}</div>
                            <div>Дети: {{banquet.Children}} {{ToolsService.NumericalDeclensions(banquet.Children,
                                ['реб.', 'реб.', 'дет.'])}}</div>




                        </ibm-list-column>


                        <ibm-list-column>
                            <div *ngFor="let payment of banquet.Payments">
                                {{payment.Manager.LastName}}
                            </div>

                        </ibm-list-column>

                        <ibm-list-column>


                            <div *ngIf="!banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa"
                                fxLayoutGap="4px">
                                <div class="banquet_table" *ngFor="let table of banquet.ShowerListTables">
                                    <div>{{table.Name}}</div>
                                </div>
                                <div class="banquet_table_show" (click)="ActivateAllTablesShowing($event, banquet)">
                                    Показать
                                    все
                                </div>
                            </div>

                            <div *ngIf="banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa"
                                fxLayoutGap="4px">
                                <div class="banquet_table" *ngFor="let table of banquet.Tables">
                                    <div>{{table.Name}}</div>
                                </div>
                            </div>


                        </ibm-list-column>
                        <ibm-list-column class="banquet_comment">{{banquet.Comment}}</ibm-list-column>
                    </ibm-list-row>
                </ibm-structured-list>
            </div>



            <button class="load_more_button" (click)="LoadMoreBanquet()">Еще</button>


        </div>
    </div>



</div>