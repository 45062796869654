<button class="button_light adding_product_button" *ngIf="showOnly==false && PropertyService.UserGroupId == 13" (click)="OpenModalAddingProduct()">Добавить
    блюдо</button>
<!-- <button *ngIf="showOnly==false" class="button_light" (click)="OpenModalAddingProduct()">Добавить блюдо</button> -->
<div class="checkout_accordion">



    <div class="product_wrapper" *ngFor="let product of products">
        <div class="checkout_item" [ngClass]="{'new_item': ComparisonResultFromOriginal(product, 3),'edited_item': ComparisonResultFromOriginal(product, 2)}" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="8px">
            <div class="checkout_item_name">{{product.Name}}</div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="checkout_item_count" fxLayout="row" fxLayoutAlign="start center">
                    <span class="count_wrapper">{{product.Count}}</span>
                    <span *ngIf="showOnly || delivery.StatusId >= 3">шт</span>
                    <div class="count_buttons" *ngIf="showOnly==false && PropertyService.UserGroupId == 13">
                        <div class="count_buttons_up" (click)="AddProduct(product)" fxLayout="row" fxLayoutAlign="start end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#caret-up"></use>
                            </svg>
                        </div>
                        <div class="count_buttons_down" (click)="MinusProduct(product)" fxLayout="row" fxLayoutAlign="start start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#caret-down"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="checkout_item_price_per_one">₴ {{product.Price * 1}}</div>
                <div class="checkout_item_price">₴ {{product.Price * product.Count}}</div>
            </div>
            <div class="checkout_item_more_icon" *ngIf="showOnly==false && PropertyService.UserGroupId == 13" (click)="RemoveProduct(product)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#trash-can"></use>
                </svg>
            </div>
        </div>


        <!-- <div class="old_item" *ngIf="ComparisonResultFromOriginal(product, 2)" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="8px">
            <div class="checkout_item_name"></div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="checkout_item_count" fxLayout="row" fxLayoutAlign="start center">
                    <span class="count_wrapper">{{OriginalByProduct(product).Count}}</span>
                    <span *ngIf="showOnly || delivery.StatusId > 3">шт</span>
                </div>
                <div class="checkout_item_price_per_one"></div>
                <div class="checkout_item_price"></div>
            </div>
        </div> -->

        <div class="product_comment" *ngIf="product.Comment != ''">
            {{product.Comment}}
        </div>



    </div>

    <!-- <div class="original_wrapper" *ngFor="let product of originals">
        <div class="checkout_item remove_item" *ngIf="ComparisonResultFromActual(product, 3)" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="8px">
            <div class="checkout_item_name">{{product.Name}}</div>

            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="checkout_item_count" fxLayout="row" fxLayoutAlign="start center">
                    <span class="count_wrapper">{{product.Count}}</span>
                    <span *ngIf="showOnly">шт</span>
                    <div class="count_buttons" *ngIf="showOnly==false">
                        <div class="count_buttons_up" (click)="AddProduct(product)" fxLayout="row" fxLayoutAlign="start end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#caret-up"></use>
                            </svg>
                        </div>
                        <div class="count_buttons_down" (click)="MinusProduct(product)" fxLayout="row" fxLayoutAlign="start start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#caret-down"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="checkout_item_price_per_one">₴ {{product.Price * 1}}</div>
                <div class="checkout_item_price">₴ {{product.Price * product.Count}}</div>
            </div>


            <div class="checkout_item_more_icon" *ngIf="showOnly==false" (click)="RemoveProduct(product)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#trash-can"></use>
                </svg>
            </div>
        </div>
    </div> -->
</div>