export class ReviewStatus{
    
    private id: number;
    private name: string;

    public get Id(): number{
        return this.id;
    }
    public get Name(): string{
        return this.name;
    }



    static Get(id: any): ReviewStatus{
        
        let status: ReviewStatus;
        
        switch ( Number.parseInt(id) ) {
            case 1:
                status = new ReviewStatus(id, "По ссылке");
                break;
            case 2:
                status = new ReviewStatus(id, "Общий");
                break;
        }

        return status;
    }

    private constructor(id: number, name: string){
        this.id = id;
        this.name = name;
    }

}