import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { CookieService } from 'ngx-cookie-service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { BanquetListComponent } from './pages/banquet/banquet-list/banquet-list.component';
import { BanquetCardComponent } from './components/banquet-components/banquet/banquet-card/banquet-card.component';
import { BanquetFilterComponent } from './components/banquet-components/banquet/banquet-filter/banquet-filter.component';
import { TableModule, NFormsModule, DialogModule, SearchModule, ButtonModule, PaginationModule, StructuredListModule, DatePickerModule, FileUploaderModule, ModalModule, ProgressIndicatorModule, NumberModule, HeaderModule, BreadcrumbModule, TilesModule, DropdownModule, AccordionModule, LoadingModule, SelectModule, NotificationModule, TabsModule, TimePickerModule, TagModule, LinkModule } from 'carbon-components-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuComponent } from './pages/menu/menu.component';
import { HeaderComponent } from './components/navigation/header/header.component';
import { SubheaderComponent } from './components/navigation/subheader/subheader.component';
import { BanquetFormComponent } from './pages/banquet/banquet-form/banquet-form.component';
import { BanquetQuickAccessPanelComponent } from './components/banquet-components/banquet/banquet-quick-access-panel/banquet-quick-access-panel.component';
import { BanquetPanelCardComponent } from './components/banquet-components/banquet/banquet-panel-card/banquet-panel-card.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CourierListComponent } from './pages/courier/courier-list/courier-list.component';
import { CourierFormComponent } from './pages/courier/courier-form/courier-form.component';
import { CourierDeliveryFormComponent } from './pages/courier/courier-delivery-form/courier-delivery-form.component';
import { EquipmentComponent } from './components/settings/equipment/equipment.component';
import { MenuSettingsComponent } from './components/settings/menu/menu.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderNewComponent } from './pages/order/order-new/order-new.component';
import { OrderFormComponent } from './pages/order/order-form/order-form.component';
import { OrderFormationCourierComponent } from './pages/order/order-formation-courier/order-formation-courier.component';
import { OrdersStandartFilterComponent } from './components/order-components/orders-standart-filter/orders-standart-filter.component';
import { CourierGroupListComponent } from './components/courier-components/courier-group-list/courier-group-list.component';
import { CourierUngroupListComponent } from './components/courier-components/courier-ungroup-list/courier-ungroup-list.component';
import { CourierStartedListComponent } from './components/courier-components/courier-started-list/courier-started-list.component';
import { ReviewsListComponent } from './pages/reviews/reviews-list/reviews-list.component';
import { ReviewsFormComponent } from './pages/reviews/reviews-form/reviews-form.component';
import { OrderPanelCardComponent } from './components/panels/order-panel-card/order-panel-card.component';
import { QuickAccessPanelComponent } from './components/panels/quick-access-panel/quick-access-panel.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HistoryReviewActionComponent } from './components/tools/history-review-action/history-review-action.component';
import { OrderDeliveryStageEditableComponent } from './components/order-components/order-editable/order-delivery-stage-editable/order-delivery-stage-editable.component';
import { OrderEquipmentStageEditableComponent } from './components/order-components/order-editable/order-equipment-stage-editable/order-equipment-stage-editable.component';
import { OrderGeneralStageEditableComponent } from './components/order-components/order-editable/order-general-stage-editable/order-general-stage-editable.component';
import { OrderMainStageEditableComponent } from './components/order-components/order-editable/order-main-stage-editable/order-main-stage-editable.component';
import { OrderDeliveryStageReadingComponent } from './components/order-components/order-reading/order-delivery-stage-reading/order-delivery-stage-reading.component';
import { OrderGeneralStageReadingComponent } from './components/order-components/order-reading/order-general-stage-reading/order-general-stage-reading.component';
import { OrderHistoryStageReadingComponent } from './components/order-components/order-reading/order-history-stage-reading/order-history-stage-reading.component';
import { OrderMainStageReadingComponent } from './components/order-components/order-reading/order-main-stage-reading/order-main-stage-reading.component';
import { OrderCartComponent } from './components/order-components/order/order-cart/order-cart.component';
import { OrderDeliveryStageComponent } from './components/order-components/order/order-delivery-stage/order-delivery-stage.component';
import { OrderEquipmentStageComponent } from './components/order-components/order/order-equipment-stage/order-equipment-stage.component';
import { OrderGeneralStageComponent } from './components/order-components/order/order-general-stage/order-general-stage.component';
import { OrderGroupComponent } from './components/order-components/order/order-group/order-group.component';
import { OrderKitchenStageComponent } from './components/order-components/order/order-kitchen-stage/order-kitchen-stage.component';
import { OrderMainStageComponent } from './components/order-components/order/order-main-stage/order-main-stage.component';
import { OrderNoteComponent } from './components/order-components/order/order-note/order-note.component';
import { NotificationsListComponent } from './components/tools/notifications-list/notifications-list.component';
import { ReviewActionsComponent } from './components/tools/review-actions/review-actions.component';
import { SeparatorComponent } from './components/tools/separator/separator.component';
import { SafeUrlPipe } from './models/tools/safe-url.pipe';
import { TwoTimePipe } from './models/tools/two-time.pipe';
import { BanquetMainReadingComponent } from './components/banquet-components/banquet-reading/banquet-main-reading/banquet-main-reading.component';
import { BanquetPaymentReadingComponent } from './components/banquet-components/banquet-reading/banquet-payment-reading/banquet-payment-reading.component';
import { BanquetMainEditableComponent } from './components/banquet-components/banquet-editable/banquet-main-editable/banquet-main-editable.component';
import { BanquetMenuComponent } from './pages/banquet/banquet-menu/banquet-menu.component';
import { BanquetTransactionListComponent } from './pages/banquet/banquet-transaction-list/banquet-transaction-list.component';
import { BanquetDiscountsReadingComponent } from './components/banquet-components/banquet-reading/banquet-discounts-reading/banquet-discounts-reading.component';
import { BanquetCommentReadingComponent } from './components/banquet-components/banquet-reading/banquet-comment-reading/banquet-comment-reading.component';
import { BanquetAreaComponent } from './components/settings/banquet-area/banquet-area.component';
import { BanquetTableGroupComponent } from './components/settings/banquet-table-group/banquet-table-group.component';
import { BanquetTableComponent } from './components/settings/banquet-table/banquet-table.component';

import localeDeAt from '@angular/common/locales/ru';
import { BanquetVaultComponent } from './pages/banquet/banquet-vault/banquet-vault.component';
import { BanquetVaultFilterComponent } from './components/banquet-components/banquet/banquet-vault-filter/banquet-vault-filter.component';
import { BanquetVaultService } from './services/banquet/banquet-vault.service';
import { BanquetProductsReadingComponent } from './components/banquet-components/banquet-reading/banquet-products-reading/banquet-products-reading.component';
import { OrderExpressStageComponent } from './components/order-components/order/order-express-stage/order-express-stage.component';
import { BanquetMainComponent } from './components/banquet-components/banquet/banquet-main/banquet-main.component';
import { OchagboxMenuComponent } from './pages/ochagbox/ochagbox-menu/ochagbox-menu.component';
import { OchagboxReceivedComponent } from './pages/ochagbox/ochagbox-received/ochagbox-received.component';
import { OchagboxManagementComponent } from './pages/ochagbox/ochagbox-management/ochagbox-management.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BanquetAddingFormComponent } from './pages/banquet/banquet-adding-form/banquet-adding-form.component';
import { BanquetTablesFormComponent } from './components/banquet-components/banquet/banquet-tables-form/banquet-tables-form.component';
import { BanquetFreeTablesComponent } from './pages/banquet/banquet-free-tables/banquet-free-tables.component';
import { BanquetSliderKitchenComponent } from './pages/banquet/banquet-slider-kitchen/banquet-slider-kitchen.component';
import { BanquetKeeperListComponent } from './pages/banquet/banquet-keeper-list/banquet-keeper-list.component';
import { UserActionsComponent } from './components/tools/user-actions/user-actions.component';
import { LargeNumberPipe } from './models/tools/large-number.pipe';
import { OrderDateTimePickerComponent } from './components/order-components/order/order-date-time-picker/order-date-time-picker.component';
import { OrderHistoryStageComponent } from './components/order-components/order/order-history-stage/order-history-stage.component';
import { OrderHistoryStageEditableComponent } from './components/order-components/order-editable/order-history-stage-editable/order-history-stage-editable.component';
import { FloatingActionsComponent } from './components/tools/floating-actions/floating-actions.component';
import { Tabs } from './components/tools/tabs/tabs.component';
import { Tab } from './components/tools/tab/tab.component';
import { StatisticsPanelComponent } from './components/banquet-components/statistics-panel/statistics-panel.component';
import { WhatsNewComponent } from './pages/information/whats-new/whats-new.component';
import { SmartReviewsComponent } from './pages/admin/smart-reviews/smart-reviews.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { OrderExpressCollectorComponent } from './components/order-components/order/order-express-collector/order-express-collector.component';
import { CourierActiveGroupListComponent } from './components/courier-components/courier-active-group-list/courier-active-group-list.component';
import { CourierActiveGroupViewListComponent } from './components/courier-components/courier-active-group-view-list/courier-active-group-view-list.component';

registerLocaleData(localeDeAt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BanquetListComponent,
    BanquetCardComponent,
    BanquetFilterComponent,
    MenuComponent,
    HeaderComponent,
    SubheaderComponent,
    BanquetFormComponent,
    BanquetQuickAccessPanelComponent,
    BanquetPanelCardComponent,
    SettingsComponent,
    CourierListComponent,
    CourierFormComponent,
    CourierDeliveryFormComponent,
    EquipmentComponent,

    MenuSettingsComponent,
    EquipmentComponent,
    OrderListComponent,
    OrderNewComponent,
    OrderFormComponent,
    OrderFormationCourierComponent,
    OrdersStandartFilterComponent,
    CourierGroupListComponent,
    CourierUngroupListComponent,
    CourierStartedListComponent,
    ReviewsListComponent,
    ReviewsFormComponent,
    OrderPanelCardComponent,
    QuickAccessPanelComponent,
    HistoryReviewActionComponent,
    OrderDeliveryStageEditableComponent,
    OrderEquipmentStageEditableComponent,
    OrderGeneralStageEditableComponent,
    OrderMainStageEditableComponent,
    OrderDeliveryStageReadingComponent,
    OrderGeneralStageReadingComponent,
    OrderHistoryStageReadingComponent,
    OrderMainStageReadingComponent,
    OrderCartComponent,
    OrderDeliveryStageComponent,
    OrderEquipmentStageComponent,
    OrderGeneralStageComponent,
    OrderGroupComponent,
    OrderKitchenStageComponent,
    OrderMainStageComponent,
    OrderNoteComponent,
    NotificationsListComponent,
    ReviewActionsComponent,
    SeparatorComponent,


    SafeUrlPipe,
    TwoTimePipe,
    LargeNumberPipe,
    BanquetMainReadingComponent,
    BanquetPaymentReadingComponent,
    BanquetMainEditableComponent,
    BanquetMenuComponent,
    BanquetTransactionListComponent,
    BanquetDiscountsReadingComponent,
    BanquetCommentReadingComponent,
    BanquetAreaComponent,
    BanquetTableGroupComponent,
    BanquetTableComponent,
    BanquetVaultComponent,
    BanquetVaultFilterComponent,
    BanquetProductsReadingComponent,
    OrderExpressStageComponent,
    BanquetMainComponent,
    OchagboxMenuComponent,
    OchagboxReceivedComponent,
    OchagboxManagementComponent,
    BanquetAddingFormComponent,
    BanquetTablesFormComponent,
    BanquetFreeTablesComponent,
    BanquetSliderKitchenComponent,
    BanquetKeeperListComponent,
    UserActionsComponent,
    OrderDateTimePickerComponent,
    OrderHistoryStageComponent,
    OrderHistoryStageEditableComponent,
    FloatingActionsComponent,

    Tab,
    Tabs,
    StatisticsPanelComponent,
    WhatsNewComponent,
    SmartReviewsComponent,
    OrderExpressCollectorComponent,
    CourierActiveGroupListComponent,
    CourierActiveGroupViewListComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    AutocompleteLibModule,
    ReactiveFormsModule,

    TableModule,
    NFormsModule,
    DialogModule,
    SearchModule,
    ButtonModule,
    PaginationModule,
    StructuredListModule,
    DatePickerModule,
    FileUploaderModule,
    ModalModule,
    ProgressIndicatorModule,
    NumberModule,
    HeaderModule,
    BreadcrumbModule,
    TilesModule,
    DropdownModule,
    AccordionModule,
    LoadingModule,
    ModalModule,
    SelectModule,
    NotificationModule,
    TabsModule,
    TimePickerModule,
    TagModule,
    LinkModule,


    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),

    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    GoogleChartsModule,


  ],
  providers: [
    { provide: LOCALE_ID, useValue: "ru-RU" },
    CookieService,
    BanquetVaultService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
