import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { System } from '../../../models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryService } from '../../../services/delivery.service';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';

@Component({
  selector: 'app-courier-list',
  templateUrl: './courier-list.component.html',
  styleUrls: ['./courier-list.component.scss']
})
export class CourierListComponent implements OnInit {

  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    private route: ActivatedRoute, private router: Router,
    public DeliveryService: DeliveryService) { }

  ngOnInit() {

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Список доставок";
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Доставка", "/deliveries", true);


    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);


    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });


    this.HeaderService.AddSubActions(new NavigationAction("Обновить", "update", () => {
      this.DeliveryService.LoadOrderGroup();
    }));



  }

}
