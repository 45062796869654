<div class="custom_number">№{{banquet.Id}}<span *ngIf="banquet.CustomNumber != null && banquet.CustomNumber != ''"> |
    </span>{{banquet.CustomNumber}}</div>

<div class="banquet-card-row">
    <div class="banquet-card">
        <div class="banquet-card__description">Заказчик</div>
        <div class="banquet-card__value bold">{{banquet.ContactCustomer.FirstName}} {{banquet.ContactCustomer.LastName}}
        </div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Телефон</div>
        <div class="banquet-card__value">{{banquet.ContactCustomer.Phone}}</div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Тип мероприятия</div>
        <div class="banquet-card__value">{{banquet.CategoryName == "" ? "—" : banquet.CategoryName}}
        </div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Залог</div>
        <div class="banquet-card__value">{{banquet.GetSumPayment()}}₴ — {{banquet.DepositName}}
        </div>
    </div>
    <!-- <div class="banquet-card">
        <div class="banquet-card__description">Дата подтверждения</div>
        <div class="banquet-card__value">{{banquet.DateConfirmed == null ? '—' : banquet.DateConfirmed |
            date:'dd.MM.yy'}}
        </div>
    </div> -->
    <div class="banquet-card" *ngIf="banquet.CategoryName == 'День Рождения'">
        <div class="banquet-card__description">Имя именинника</div>
        <div class="banquet-card__value">{{banquet.Target == '' ? banquet.ContactCustomer.FirstName + ' ' +
            banquet.ContactCustomer.LastName : banquet.Target}}</div>
    </div>
    <div class="banquet-card" *ngIf="banquet.CategoryName == 'Свадьба'">
        <div class="banquet-card__description">Жених и невеста</div>
        <div class="banquet-card__value">{{banquet.Target}}
            {{banquet.TargetSecond}}</div>
    </div>






</div>
<div class="banquet-card-row">


    <div class="banquet-card">
        <div class="banquet-card__description">Дата мероприятия</div>
        <div class="banquet-card__value">{{banquet.Date| date:'dd.MM.yy'}} ({{banquet.TimeId == 1 ? "День" :
            "Вечер"}})
        </div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Время мероприятия</div>
        <div class="banquet-card__value">{{banquet.Date| date:'HH:mm:ss'}}
        </div>
    </div>

    <div class="banquet-card">
        <div class="banquet-card__description">Гостей</div>
        <div class="banquet-card__value">{{banquet.Guests}} {{ToolsService.NumericalDeclensions(banquet.Children,
            ['человек', 'человека', 'человек'])}}
        </div>

        <div *ngIf="banquet.IsProgramDay">
            <div *ngIf="banquet.Guests * banquet.ProgramSum > banquet.GetSumProgramPayment()"
                class="program_transaction negative">
                Программа: –{{banquet.Guests *
                banquet.ProgramSum -
                banquet.GetSumProgramPayment()}}₴
            </div>

            <div *ngIf="banquet.Guests * banquet.ProgramSum <= banquet.GetSumProgramPayment()"
                class="program_transaction positive">
                Программа: Оплачено ({{banquet.GetSumProgramPayment()}}₴)
            </div>
        </div>

        <div *ngIf="banquet.Date.getMonth() + 1 == 12 && banquet.Date.getDate() == 31">
            <div *ngIf="banquet.Guests * 400 > banquet.GetSumProgramPayment()" class="program_transaction negative">
                Программа: –{{banquet.Guests *
                400 -
                banquet.GetSumProgramPayment()}}₴
            </div>

            <div *ngIf="banquet.Guests * 400 <= banquet.GetSumProgramPayment()" class="program_transaction positive">
                Программа: Оплачено ({{banquet.GetSumProgramPayment()}}₴)
            </div>
        </div>


    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Детей</div>
        <div class="banquet-card__value">{{banquet.Children}} {{ToolsService.NumericalDeclensions(banquet.Children,
            ['ребёнок', 'ребёнка', 'детей'])}}
        </div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Комментарий</div>
        <div class="banquet-card__value">{{banquet.Comment == "" ? "—" : banquet.Comment}}
        </div>
    </div>
    <!-- <div class="banquet-card">
        <div class="banquet-card__description">Алкоголь</div>
        <div class="banquet-card__value">{{ banquet.Alcohol ? "Свой алкоголь" : "Без своего алкоголя" }}
        </div>
    </div> -->
    <!-- <div class="banquet-card">
        <div class="banquet-card__description">Сумма банкета</div>
        <div class="banquet-card__value">{{banquet.Price}}₴
        </div>
    </div> -->
    <!-- <div class="banquet-card">
        <div class="banquet-card__description">Дата получения</div>
        <div class="banquet-card__value">{{banquet.DateAdded | date:'dd.MM.yy'}}
        </div>
    </div> -->



</div>
<div class="banquet-card-row">






    <div class="banquet-card">
        <div class="banquet-card__description">Зал</div>
        <div class="banquet-card__value">
            <div fxLayout="row">
                <div *ngIf="banquet.Area != null" class="banquet_area_chips">{{banquet.Area.Name}}</div>
                <span *ngIf="banquet.Area == null">—</span>
            </div>

        </div>
    </div>
    <div class="banquet-card">
        <div class="banquet-card__description">Стол</div>
        <div class="banquet-card__value">
            <div *ngIf="!banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa" fxLayoutGap="4px">
                <div class="banquet_table" *ngFor="let table of banquet.ShowerListTables">
                    <div>{{table.Name}}</div>
                </div>
                <div class="banquet_table_show" (click)="ActivateAllTablesShowing($event, banquet)">Показать все
                </div>
            </div>

            <div *ngIf="banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa" fxLayoutGap="4px">
                <div class="banquet_table" *ngFor="let table of banquet.Tables">
                    <div>{{table.Name}}</div>
                </div>
            </div>

        </div>
    </div>

    <div class="banquet-card">
        <div class="banquet-card__description">Создатель</div>
        <div class="banquet-card__value">{{banquet.Creator == null ? "—" : banquet.Creator.lastname}}
        </div>
    </div>


    <div class="banquet-card">
        <div class="banquet-card__description">Платежи</div>
        <div class="banquet-card__value">
            <div *ngIf="banquet.Payments.length > 0">
                <div *ngFor="let payment of banquet.Payments">
                    {{payment.Manager.LastName}}
                </div>
            </div>
            <div *ngIf="banquet.Payments.length == 0">—</div>
        </div>
    </div>

    <div class="banquet-card">
        <div class="banquet-card__description">Подтверждение банкета</div>
        <div class="banquet-card__value">

            <div fxLayout="row" fxLayoutGap="8px">
                <svg *ngIf="banquet.Confirmed" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon confirmed">
                    <use xlink:href="#fingerprint"></use>
                </svg>
                <svg *ngIf="!banquet.Confirmed" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon unconfirmed">
                    <use xlink:href="#fingerprint"></use>
                </svg>

                <svg *ngIf="banquet.KepperSended" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon confirmed">
                    <use xlink:href="#terminal"></use>
                </svg>
                <svg *ngIf="!banquet.KepperSended" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon unconfirmed">
                    <use xlink:href="#terminal"></use>
                </svg>

                <svg *ngIf="banquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon confirmed">
                    <use xlink:href="#kitchen"></use>
                </svg>
                <svg *ngIf="!banquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 32 32" class="confirmed-icon unconfirmed">
                    <use xlink:href="#kitchen"></use>
                </svg>
            </div>


        </div>
    </div>



</div>