import { Component, OnInit } from '@angular/core';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ConfirmationService } from '../../../services/confirmation.service';;
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

@Component({
  selector: 'app-banquet-table-group',
  templateUrl: './banquet-table-group.component.html',
  styleUrls: ['./banquet-table-group.component.scss']
})
export class BanquetTableGroupComponent implements OnInit {

  constructor(public BanquetZoneService: BanquetZoneService,
    public ConfirmationService: ConfirmationService, public ModalAddingService: ModalAddingService,) { }

  ngOnInit(): void {

  }

  OpenAddingBanquetTableGroupModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetZoneService.AddTableGroup(
        this.ModalAddingService.Fields[1].Value,
        this.ModalAddingService.Fields[0].Value,
        this.ModalAddingService.Fields[2].Value,
      );
    });

    this.ModalAddingService.Show(
      new ModalData("Добавить группу столов", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildDropdown("Зал", this.BanquetZoneService.AreasDropdown),
        ModalFieldBuilder.BuildText("Название", 0, 100000),
        ModalFieldBuilder.BuildNumber("Количество мест", 0, 100000, 0, []),
      ]
    );
  }

  OpenModalRemoveBanquetTableGroup(id: number) {
    this.ConfirmationService.Reset();
    this.ConfirmationService.OpenModal("Удалить группу столов?", "Вы уверены, что хотите удалить группу столов?", "", "Нет", "Удалить");
    this.ConfirmationService.AddConfirmEvent((answer: string) => {
      this.BanquetZoneService.DeleteTableGroup(id);
    });
  }

}
