<div class="actions_wrapper" fxLayoutGap="16px">
    <div class="action" *ngFor="let action of HeaderService.FloatingActions" (click)="action.Action()">
        <div class="action_icon">
            <svg fxShow="true" fxShow.lt-md="false" *ngIf="action.Icon == 'add'" xmlns="http://www.w3.org/2000/svg"
                width="28" height="28" viewBox="0 0 32 32">
                <use xlink:href="#add"></use>
            </svg>
            <svg fxShow="false" fxShow.lt-md="true" *ngIf="action.Icon == 'add'" xmlns="http://www.w3.org/2000/svg"
                width="52" height="52" viewBox="0 0 32 32">
                <use xlink:href="#add"></use>
            </svg>
        </div>
        <div class="action_text">{{action.Text}}</div>
    </div>
</div>