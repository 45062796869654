import { Identifiable } from '../tools/identifiable.model';
import { Banquet } from './banquet';
import { BanquetCustomer } from './banquet-customer';

export class BanquetPaymentHistory extends Identifiable {


    private name: string;
    public get Name(): string {
        return this.name;
    }
    public set Name(name: string) {
        this.name = name;
    }


    private banquetContact: string;
    public get BanquetContact(): string {
        return this.banquetContact;
    }
    public set BanquetContact(banquetContact: string) {
        this.banquetContact = banquetContact;
    }


    private value: number;
    public get Value(): number {
        return this.value;
    }
    public set Value(value: number) {
        this.value = value;
    }


    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }


    private dateCurrentBanquet: Date;
    public get DateCurrentBanquet(): Date {
        return this.dateCurrentBanquet;
    }
    public set DateCurrentBanquet(dateCurrentBanquet: Date) {
        this.dateCurrentBanquet = dateCurrentBanquet;
    }


    private confirmed: boolean;
    public get Confirmed(): boolean {
        return this.confirmed;
    }
    public set Confirmed(confirmed: boolean) {
        this.confirmed = confirmed;
    }


    private confirmedTransfer: boolean;
    public get ConfirmedTransfer(): boolean {
        return this.confirmedTransfer;
    }
    public set ConfirmedTransfer(confirmedTransfer: boolean) {
        this.confirmedTransfer = confirmedTransfer;
    }

    private isIncome: boolean;
    public get IsIncome(): boolean {
        return this.isIncome;
    }
    public set IsIncome(isIncome: boolean) {
        this.isIncome = isIncome;
    }

    private isTransfered: boolean;
    public get IsTransfered(): boolean {
        return this.isTransfered;
    }
    public set IsTransfered(isTransfered: boolean) {
        this.isTransfered = isTransfered;
    }

    private paymentSourceId: number;
    public get PaymentSourceId(): number {
        return this.paymentSourceId;
    }
    public set PaymentSourceId(paymentSourceId: number) {
        this.paymentSourceId = paymentSourceId;
    }

    private paymentTypeId: number;
    public get PaymentTypeId(): number {
        return this.paymentTypeId;
    }
    public set PaymentTypeId(paymentTypeId: number) {
        this.paymentTypeId = paymentTypeId;
    }

    private stream: number;
    public get Stream(): number {
        return this.stream;
    }
    public set Stream(stream: number) {
        this.stream = stream;
        
        switch ( this.stream) {
            case 1:
                this.StreamName = "Задаток";
                break;
            case 2:
                this.StreamName = "Программа";
                break;
        
            default:
                this.StreamName = "Задаток";
                break;
        }
    }

    private streamName: string;
    public get StreamName(): string {
        return this.streamName;
    }
    public set StreamName(streamName: string) {
        this.streamName = streamName;
    }

    private banquetId: number;
    public get BanquetId(): number {
        return this.banquetId;
    }
    public set BanquetId(banquetId: number) {
        this.banquetId = banquetId;
    }



    private cardDate: string;
    public get CardDate(): string {
        return this.cardDate;
    }
    public set CardDate(cardDate: string) {
        this.cardDate = cardDate;
    }


    public Banquet: Banquet;


    public Manager: any = {
        FirstName: "",
        LastName: ""
    };


}