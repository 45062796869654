<div class="comments-wrapper" fxLayout="column" fxLayoutGap="16px">
    <button ibmButton="primary" (click)="OpenAddingBanquetAreaModal()">Добавить</button>
    <div *ngFor="let area of BanquetZoneService.Areas">
        <div class="banquet-area-item">
            <div class="banquet-area-item__type">
                <div>{{area.Name}}</div>
                <div class="banquet-area-item__actions" (click)="OpenModalRemoveBanquetArea(area.Id)">
                    <img src="../../../../assets/icons/trash.svg">
                </div>
            </div>
        </div>
    </div>
</div>