<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <div class="main_title">Доставка(Необязательно)</div>


    <div fxLayout="row" fxLayoutGap="32px" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px">
        <ibm-dropdown label="Район" placeholder="Выбор района доставки" (selected)="selected($event)"
            [(ngModel)]="delivery.ShippingArea" value="content">
            <ibm-dropdown-list [items]="items"></ibm-dropdown-list>
        </ibm-dropdown>
        <ibm-label>
            Адресс
            <input [(ngModel)]="delivery.ShippingAdress" ibmText placeholder="Адресс доставки">
        </ibm-label>
    </div>

    <app-separator></app-separator>

    <div class="tags_wrapper" fxLayout="row" fxLayoutGap="8px">
        <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 0}"
            (click)="SetDeliveryTime(0)">01:00</span>
        <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 1}"
            (click)="SetDeliveryTime(1)">01:30</span>
        <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 2}"
            (click)="SetDeliveryTime(2)">02:00</span>
        <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 3}"
            (click)="SetDeliveryTime(3)">02:30</span>
        <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 4}"
            (click)="SetDeliveryTime(4)">Другое</span>
    </div>



    <div class="dete_time_picker" *ngIf="!Readiness" fxLayout="column" fxLayoutGap="12px">
        <ibm-date-picker dateFormat="d.m.Y" label="Дата доставки" (valueChange)="OnChangeShippingDate($event)"
            [(ngModel)]="ShippingPresentDate">
        </ibm-date-picker>

        <div class="time_picker" fxLayout="row" *ngIf="ActiveDeliveryTimeMode != 4">
            <ibm-label>
                Время
                <p>{{delivery.ShippingHour | twotime}}:{{delivery.ShippingMinutes | twotime}}</p>
            </ibm-label>
        </div>




        <div class="time_picker" fxLayout="row" fxLayoutGap="12px" *ngIf="ActiveDeliveryTimeMode == 4">
            <ibm-number label="Часы" min="0" max="23" (change)="OnChangeHours($event)"
                [(ngModel)]="delivery.ShippingHour">
            </ibm-number>
            <ibm-number label="Минуты" min="0" max="59" (change)="OnChangeMinutes($event)"
                [(ngModel)]="delivery.ShippingMinutes">
            </ibm-number>
        </div>
    </div>







    <app-separator></app-separator>

    <app-order-note [(notes)]="delivery.DeliveryNotes" [(delivery)]="delivery" type="2"></app-order-note>

</div>