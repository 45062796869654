import { Component, OnInit } from '@angular/core';
import { BanquetVaultService } from '../../../../services/banquet/banquet-vault.service';
import { BanquetZoneService } from '../../../../services/banquet/banquet-zone.service';

@Component({
  selector: 'app-banquet-vault-filter',
  templateUrl: './banquet-vault-filter.component.html',
  styleUrls: ['./banquet-vault-filter.component.scss']
})
export class BanquetVaultFilterComponent implements OnInit {
  SelectedDate: Date = new Date();
  TomorrowDate: Date;

  SelectedAreaId: any = 1;
  IsAvaible: boolean = true;

  Area: any;

  AreaFields: Array<any> = new Array<any>();

  constructor(public BanquetZoneService: BanquetZoneService, public BanquetVaultService: BanquetVaultService) {

    this.BanquetZoneService.OnAreaLoaded.push(
      () => {
        this.BanquetZoneService.Areas.forEach(element => {
          this.AreaFields.push({ id: element.Id, content: element.Name, selected: false });
        });
      }
    );

  }

  ngOnInit(): void {

    this.TomorrowDate = new Date(this.SelectedDate);
    this.TomorrowDate.setDate(this.TomorrowDate.getDate() + 1);

  }

  FilterDateChange(event) {

    this.SelectedDate = event[0];
    this.TomorrowDate = new Date(this.SelectedDate);
    this.TomorrowDate.setDate(this.TomorrowDate.getDate() + 1);

    this.BanquetVaultService.LoadVault(this.SelectedAreaId, this.IsAvaible, this.SelectedDate, this.TomorrowDate);
  }

  OnAreaChange(event) {
    this.SelectedAreaId = event["item"]["id"];
    this.BanquetVaultService.LoadVault(this.SelectedAreaId, this.IsAvaible, this.SelectedDate, this.TomorrowDate);
  }

  OnAvaibleChange(event) {
    this.IsAvaible = event;
    this.BanquetVaultService.LoadVault(this.SelectedAreaId, this.IsAvaible, this.SelectedDate, this.TomorrowDate);
  }

}
