import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../models/store/order.model';
import { System } from '../../../models/tools/system.model';

@Component({
  selector: 'app-order-panel-card',
  templateUrl: './order-panel-card.component.html',
  styleUrls: ['./order-panel-card.component.scss']
})
export class OrderPanelCardComponent implements OnInit {

  @Input('delivery') delivery: Order;


  constructor() {

  }

  ngOnInit(): void {

  }

}
