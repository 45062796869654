import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyService } from '../property.service';
import { HeaderService } from '../header.service';
import { NotificationService } from '../notification.service';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class BanquetCommentService extends BaseService {



  public CommentTypes: Array<any> = new Array<any>();


  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router) {
    super();
  }

  LoadCommentType() {
    var request = `https://ochagzp.com.ua/index.php?route=api/common/comment-type/getCommentTypes&token=${this.property.Token}`;

    this.http.post(request, []).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {
          data["body"]["data"]["objects"]["data"].forEach(element => {
            this.CommentTypes.push({ content: element["type"], id: element["comment_type_id"], selected: false });
          });
          if (this.CommentTypes.length > 0) {
            this.CommentTypes[0].selected = true;
          }

        }
      }
    );
  }



}
