import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyService } from './property.service';
import { HeaderService } from './header.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { OchagboxHistory } from '../models/ochagbox/history.model';

@Injectable({
  providedIn: 'root'
})
export class OchagboxHistoryService extends BaseService {

  public TotalCount: number = 0;

  public OnLoadHistoryEvents: Array<any> = new Array<any>();

  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router) {
    super();
  }

  LoadHistory() {
    this.IsLoading = true;

    let request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/ochagbox-history/get&token=${this.property.Token}`;

    this.http.get(request).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {
          data["body"]["data"]["objects"]["data"].forEach(element => {
            let history = new OchagboxHistory(element["ochagbox_history_id"], element["order_id"], element["date_added"]);
            this.Objects.PushToEnd(history);
          });

          this.IsLoaded = true;
          this.IsLoading = false;
          this.Page++;
          this.LoadMoreHistory();
          this.OnLoadHistoryEvents.forEach(event => {
            event();
          });
        }
      },
      (data: any) => {
        console.log(data);
      }
    );


  }


  LoadMoreHistory() {
    if (this.IsLoading) {
      return;
    }

    if (this.Page === this.PageOfVisible / 10) {
      this.LoadHistory();
      return;
    }

    for (let index = this.PageOfVisible * 10; index < this.PageOfVisible * 10 + 10; index++) {
      if (this.Objects.Values[index] != null) {
        this.VisibleObjects.PushToEnd(this.Objects.Values[index]);
      }
    }
    this.PageOfVisible++;
  }
}
