import { NavigationActionStatus } from "./navigation-action-status.enum";

export class NavigationAction {
    Text: string = "";
    Icon: string = "";
    Action: any = () => { };
    IsDesktop: boolean = true;
    IsMobile: boolean = true;
    Tooltip: string = "";
    Status: NavigationActionStatus = NavigationActionStatus.Activated;


    constructor(Text: string, Icon: string, Action: any) {
        this.Text = Text;
        this.Icon = Icon;
        this.Action = Action;

    }
}