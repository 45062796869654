import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/store/order.model';

@Component({
  selector: 'app-quick-access-panel',
  templateUrl: './quick-access-panel.component.html',
  styleUrls: ['./quick-access-panel.component.scss']
})
export class QuickAccessPanelComponent implements OnInit {


  @Output() OnOpenOrder = new EventEmitter();
  public CurrentId: number = 0;

  @Input("list") OrderList: Array<Order> = new Array<Order>();

  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.CurrentId = Number.parseInt(params['id']);
    });
  }

  OpenOrder() {
    this.OnOpenOrder.emit();
  }

}
