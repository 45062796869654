<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <div class="main_title">Общие данные (Заказ №{{delivery.Id}})</div>

    <div class="block_title">Корзина</div>

    <app-order-cart [(products)]="delivery.Products" [(delivery)]="delivery"></app-order-cart>

    <div class="total_price">Общая стоимость: ₴{{delivery.Total | number:'1.0-0'}}</div>

    <app-separator></app-separator>

    <div class="form_wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="32px">
        <ibm-dropdown label="Район" placeholder="Выбор района доставки" (selected)="selected($event)"
            [(ngModel)]="delivery.ShippingArea" value="content">
            <ibm-dropdown-list [items]="items"></ibm-dropdown-list>
        </ibm-dropdown>
        <ibm-label>
            Адрес
            <input [(ngModel)]="delivery.ShippingAdress" ibmText placeholder="Адресс доставки"
                (change)="OnOrderChange()">
        </ibm-label>
    </div>

    <app-separator></app-separator>

    <div class="form_wrapper" fxLayout="column" fxLayoutGap="12px">
        <ibm-label>
            Телефон
            <input [(ngModel)]="delivery.Customer.Phone" ibmText placeholder="Первый номер" (change)="OnOrderChange()">
        </ibm-label>
        <button class="button_light" (click)="ClipboardService.Copy(delivery.Customer.Phone)">Копировать</button>
        <ibm-label>
            Второй телефон
            <input [(ngModel)]="delivery.Customer.SecondPhone" ibmText placeholder="Второй номер"
                (change)="OnOrderChange()">
        </ibm-label>
        <button class="button_light" (click)="ClipboardService.Copy(delivery.Customer.SecondPhone)">Копировать</button>
    </div>


    <app-separator></app-separator>

    <div class="form_wrapper" fxLayout="column" fxLayoutGap="32px">
        <div class="form_wrapper" fxLayout="column" fxLayoutGap="8px">
            <ibm-toggle label="Оплата картой" [(ngModel)]="delivery.CardPayment" (change)="OnChangeCardPayment($event)">
            </ibm-toggle>

            <ibm-checkbox *ngIf="delivery.CardPayment" [(ngModel)]="delivery.Paymented"
                (change)="OnChangePaymented($event)">
                Подтверждение оплаты
            </ibm-checkbox>
        </div>
        <ibm-label>
            Комментарий
            <textarea [(ngModel)]="delivery.Comment" ibmTextArea placeholder="Содержимое заметки" cols="120"
                class="comment" (change)="OnOrderChange()"></textarea>
        </ibm-label>
    </div>
</div>