<div class="menu_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="column">
    <h2>Банкетное меню</h2>

    <div class="menu_items_wrapper" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="32px">

        <ibm-tile [routerLink]="['/banquets']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Банкеты
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>

        <ibm-tile [routerLink]="['/banquet-transactions']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Задатки
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/banquet-keeper']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Кипер
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>



        <ibm-tile [routerLink]="['/banquet-slider-kitchen']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Бегунки на кухню
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/banquet-summary-vault']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Свод
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/banquet-free-tables']" class="menu_item" fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Свободные столы
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>



    </div>
</div>