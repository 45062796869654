import { Identifiable } from '../tools/identifiable.model';

export class OrderAddress extends Identifiable{
    private name: string;
    public get Name(): string{
        return this.name;
    }
    public set Name(name: string){
        this.name = name;
    }

    private price: string;
    public get Price(): string{
        return this.price;
    }
    public set Price(price: string){
        this.price = price;
    }

    constructor(id: number, name: string, price: string){
        super(id);
        this.name = name;
        this.price = price;
    }
}