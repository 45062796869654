<div class="banquet-card-block">
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Заказчик</div>
            <div class="banquet-card-item__value">
                {{BanquetsService.ActiveBanquet.ContactCustomer.FirstName}}
                {{BanquetsService.ActiveBanquet.ContactCustomer.LastName}}</div>
        </div>
    </div>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Тип мероприятия</div>
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.CategoryName}}</div>
        </div>
    </div>
</div>

<div class="banquet-card-block">
    <h5>Локация</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Зал</div>
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.Area == null ? "—" :
                BanquetsService.ActiveBanquet.Area.Name}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Стол</div>
            <div class="banquet-card-item__value">
                <div *ngFor="let table of BanquetsService.ActiveBanquet.Tables">
                    <div>{{table.Name}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="banquet-card-block">
    <h5>Дата мероприятия</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.Date | date:'d MMM
                y'}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.Date |
                date:'HH:mm:ss'}}</div>
        </div>
    </div>
</div>


<div class="banquet-card-block">
    <h5>Залог</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Залог</div>
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.Deposit}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Тип залога</div>
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DepositName}}</div>
        </div>
    </div>
</div>





<div class="comments">
    <div *ngFor="let comment of BanquetsService.ActiveBanquet.Comments">
        <div class="banquet-comment-item">
            <div class="banquet-comment-item__type">
                <div>{{comment.Type}}</div>
                <div class="banquet-area-item__actions" fxLayout="row" fxLayoutGap="8px">


                    <svg class="purchase_icon" (click)="OpenEditCommentModal(comment)"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#edit"></use>
                    </svg>
                    <img (click)="OpenModalRemoveComment(comment.Id)" src="../../../../assets/icons/trash.svg">


                </div>
            </div>

            <div class="banquet-comment-item__text">{{comment.Text}}</div>
        </div>
    </div>
</div>

<div class="banquet-card-block">
    <h5>Поступление заказа</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateAdded| date:'d
                MMM y'}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateAdded| date:'HH:mm:ss'}}</div>
        </div>
    </div>
</div>




<div class="banquet-card-block">
    <h5>Дата подтверждения</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateConfirmed == null
                ? '—' :
                (BanquetsService.ActiveBanquet.DateConfirmed | date:'d MMM
                y')}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateConfirmed == null
                ? '—' :
                (BanquetsService.ActiveBanquet.DateConfirmed|
                date:'HH:mm:ss')}}</div>
        </div>
    </div>
</div>