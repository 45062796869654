<div class="add_history_action" (click)="ToggleForm()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
        <use xlink:href="#face-wink"></use>
    </svg>
</div>
<div class="history_form_wrapper" [ngClass]="{'opened': IsFormOpened}" fxLayout="column">
    <div class="history_form_header">Комментарий к заказу</div>
    <div class="history_form">
        <ibm-label class="history_label">
            Оценка
            <div class="score_list_wrapper" fxLayout="row">
                <div class="score_wrapper" [ngClass]="{'active': StatusId == 1}" (click)="SetStatusId(1)">
                    <div class="score_title">Негативный</div>
                    <div class="score_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#face-dissatisfied"></use>
                        </svg>
                    </div>
                </div>
                <div class="score_wrapper" [ngClass]="{'active': StatusId == 2}" (click)="SetStatusId(2)">
                    <div class="score_title">Нейтральный</div>
                    <div class="score_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#face-neutral"></use>
                        </svg>
                    </div>
                </div>
                <div class="score_wrapper" [ngClass]="{'active': StatusId == 3}" (click)="SetStatusId(3)">
                    <div class="score_title">Позитивный</div>
                    <div class="score_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#face-wink"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </ibm-label>
        <ibm-label class="history_label">
            Комментарий
            <textarea [(ngModel)]="Comment" class="history_comment" ibmTextArea rows="4" cols="50"></textarea>
        </ibm-label>
    </div>
    <div class="history_form_footer" fxLayout="row" fxLayoutAlign="stretch start">
        <button _ngcontent-bto-c2249="" class="history_action bx--btn bx--btn--secondary"
            (click)="CloseForm()">Отменить</button>
        <button _ngcontent-bto-c2249="" modal-primary-focus="" class="history_action bx--btn bx--btn--primary"
            (click)="AddReview()">Добавить</button>
    </div>
</div>