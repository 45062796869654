import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { System } from '../../../models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../../../models/store/order.model';
import { PropertyService } from '../../../services/property.service';
import { ClipboardService } from '../../../services/clipboard.service';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  public IsFilterOpened: boolean = false;


  public IsTimeOnly(delivery: Order): boolean {
    return delivery.DateAdded.getDate() == new Date().getDate() && delivery.DateAdded.getFullYear() == new Date().getFullYear();
  }
  public IsDateOnly(delivery: Order): boolean {
    return delivery.DateAdded.getDate() != new Date().getDate() && delivery.DateAdded.getFullYear() == new Date().getFullYear();
  }
  public IsFullDateOnly(delivery: Order): boolean {
    return delivery.DateAdded.getFullYear() != new Date().getFullYear();
    
  }

  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    public PropertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    public ClipboardService: ClipboardService) {
    }

  ngOnInit() {
    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Список заказов";
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Заказы", "/deliveries", true);





    let filterSubaction = new NavigationAction("Фильтр", "filter", () => {
      this.IsFilterOpened = !this.IsFilterOpened;
    });
    filterSubaction.IsMobile = true;
    filterSubaction.IsDesktop = false;
    this.HeaderService.AddSubActions(filterSubaction);


    


    this.HeaderService.AddSubActions(new NavigationAction("Обновить", "update", () => {
      this.OrdersService.LoadOrders();
    }));


    let addOrderSubaction = new NavigationAction("Добавить", "add", () => {
      this.router.navigate(['/delivery-new']);
    });
    this.HeaderService.AddSubActions(addOrderSubaction);





    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    

    

    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });



  }

  valueChange(event) {
    this.OrdersService.Filter(event);
  }

  clear() {

  }

  OpenDelivery(event, delivery) {
    if (event.srcElement.localName != "a") {
      this.IsFilterOpened = true;
      setTimeout(
        () => {
          this.router.navigate([`/delivery/${delivery.id}`]);
        }, 50);

      
      
    }

  }

  LoadMore() {
    this.OrdersService.LoadMoreOrders();
  }


}
