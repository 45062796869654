export class Product {

    private id: number;
    public get Id(): number {
        return this.id;
    }

    private name: string;
    public get Name(): string {
        return this.name;
    }
    public set Name(name: string) {
        this.name = name;
    }


    private price: number;
    public get Price(): number {
        return this.price;
    }
    public set Price(price: number) {
        this.price = price;
    }

    private isWeightable: boolean;
    public get IsWeightable(): boolean {
        return this.isWeightable;
    }
    public set IsWeightable(isWeightable: boolean) {
        this.isWeightable = isWeightable;
    }


    private count: number;
    public get Count(): number {
        return this.count;
    }
    public set Count(count: number) {
        this.count = count;
    }


    private comment: string;
    public get Comment(): string {
        return this.comment;
    }
    public set Comment(comment: string) {
        this.comment = comment;
    }

    public IsComparisonFromOriginal: boolean = false;


    constructor(id: number, name: string = "", price: number = 0, count: number = 1, comment: string = "") {
        this.id = id;
        this.count = count;
        this.price = price;
        this.name = name.replace(/&quot;/g, '"');
        this.comment = comment;
    }


}