import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyService } from './property.service';
import { HeaderService } from './header.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { OchagboxSupply } from '../models/ochagbox/supply.model';

@Injectable({
  providedIn: 'root'
})
export class OchagboxService extends BaseService {


  public TotalCount: number = 0;

  public OnLoadSupplyEvents: Array<any> = new Array<any>();

  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router) {
    super();
  }

  LoadSupply() {
    this.IsLoading = true;

    let request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/ochagbox/get&token=${this.property.Token}`;


    this.http.get(request).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {
          data["body"]["data"]["objects"]["data"].forEach(element => {
            let supply = new OchagboxSupply(element["ochagbox_supply_id"], element["description"], element["count"], element["date_added"]);
            this.Objects.PushToEnd(supply);

            this.TotalCount += Number(supply.Count);
          });

          this.IsLoaded = true;
          this.IsLoading = false;
          this.Page++;
          this.LoadMoreSupply();
          this.OnLoadSupplyEvents.forEach(event => {
            event();
          });
        }
      }
    );


  }


  LoadMoreSupply() {
    if (this.IsLoading) {
      return;
    }

    if (this.Page === this.PageOfVisible / 10) {
      this.LoadSupply();
      return;
    }

    for (let index = this.PageOfVisible * 10; index < this.PageOfVisible * 10 + 10; index++) {
      if (this.Objects.Values[index] != null) {
        this.VisibleObjects.PushToEnd(this.Objects.Values[index]);
      }
    }
    this.PageOfVisible++;
  }

  AddSupply(description: string, count: string) {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/ochagbox/addOchagbox&token=${this.property.Token}`;
    let supplyData =
    {
      data: {
        "description": description,
        "count": count,
      }
    }

    this.http.post(request, supplyData).subscribe(
      (data: any) => {
        let element = data["body"]["data"]["objects"]["data"];
        let supply = new OchagboxSupply(element["ochagbox_supply_id"], element["description"], element["count"], element["date_added"]);
        this.Objects.PushToStart(supply);
        this.VisibleObjects.PushToStart(supply);
        this.TotalCount += Number(supply.Count);
      }
    );

  }


  RemoveSupply(description: string, count: string) {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/ochagbox/addOchagbox&token=${this.property.Token}`;
    let supplyData =
    {
      data: {
        "description": description,
        "count": Number(count) * -1,
      }
    }
    this.http.post(request, supplyData).subscribe(
      (data: any) => {
        let element = data["body"]["data"]["objects"]["data"];
        let supply = new OchagboxSupply(element["ochagbox_supply_id"], element["description"], element["count"], element["date_added"]);
        this.Objects.PushToStart(supply);
        this.VisibleObjects.PushToStart(supply);
        this.TotalCount += Number(supply.Count);
      }
    );

  }




}
