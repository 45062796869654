export enum NotificationAlertType {
    Default = 0,
    Emerald = 1,
    Carmine = 2,
    Amber = 3
}

export class NotificationAlert{
    public Title: string;
    public Text: string;
    public Type: NotificationAlertType = NotificationAlertType.Default;
    public IsShowed: boolean = false;
    public IsDisplayed: boolean = false;
    public AddedDate: Date;

    public BadgeCount: number = 0;
    
    public OnHide: any = () => {
        
    };

    Hide() {
        this.IsShowed = false;
        setTimeout(
            ()=>{
                this.IsDisplayed = false;
                this.OnHide();
            }, 240
        );
        
    }

    constructor(){
        setTimeout(
            ()=>{
                this.IsShowed = false;
                setTimeout(
                    ()=>{
                        this.IsDisplayed = false;
                    }, 240
                );
            }, 6000
        );
    }
}


