import { Component, OnInit, Input } from '@angular/core';
import { Order } from '../../../../models/store/order.model';
import { PropertyService } from '../../../../services/property.service';

@Component({
  selector: 'app-order-delivery-stage-reading',
  templateUrl: './order-delivery-stage-reading.component.html',
  styleUrls: ['./order-delivery-stage-reading.component.scss']
})
export class OrderDeliveryStageReadingComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  public IsDeliveryOriginalShowed: boolean = false;

  public OnDeliveryOriginalShowed(event) {
    this.IsDeliveryOriginalShowed = event.checked;
  }

  constructor(public PropertyService: PropertyService,) { }

  ngOnInit(): void {
  }

}
