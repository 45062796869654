<div class="history_wrapper" fxLayout="column" fxLayoutGap="16px">

    <div class="main_title">История</div>

    <div class="past_order" style="width: 500px">
        <ibm-accordion>
            <ibm-accordion-item [title]="titleWithContext"
                [context]="{ index: order.Id, price: order.Total, isWarning: isWarning(order) }"
                *ngFor="let order of delivery.PastOrders">

                <div>Дата заказа:</div>
                <div class="big_title">{{order.ShippingDate | date:'dd.MM.yy'}}</div>
                <div class="address">{{order.ShippingAdress}}</div>
                <div class="cancel_text">{{order.CancelMessage}}</div>

                <div class="review_wrapper" fxLayout="column" fxLayoutGap="8px" *ngFor="let review of order.Reviews;">
                    <div class="review_comment_header" fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="review_name">{{review.Name}}</div>
                        <div class="review_date">{{review.AddedDate | date:'dd.MM.yy'}}</div>
                    </div>
                    <div class="review_status">
                        <span *ngIf="review.StatusId == 1">Негативный</span>
                        <span *ngIf="review.StatusId == 2">Нейтральный</span>
                        <span *ngIf="review.StatusId == 3">Позитивный</span>

                    </div>
                    <div class="review_comment">{{review.Comment}}</div>
                </div>
            </ibm-accordion-item>
        </ibm-accordion>
    </div>

    <ng-template #titleWithContext let-index="index" let-price="price" let-isWarning="isWarning">
        <div class="title_wrapper bx--accordion__title" fxLayout="row" fxLayoutAlign="space-between start">
            <p>Заказ №{{ index }}</p>
            <span fxLayout="row" fxLayoutGap="8px">


                <svg class="information_icon" [ngClass]="{'warning': isWarning}" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#information"></use>
                </svg>
                <p>₴{{price | number:'1.0-0'}}</p>
            </span>

        </div>
    </ng-template>

</div>