<div class="couriers_list">
    <div class="courier_item" [ngClass]="{active: courier.user_id == CouriersService.ActiveCourier.user_id}"
        (click)="SelectCourier($event, courier)" *ngFor="let courier of CouriersService.Couriers">
        {{courier.firstname}}
        {{courier.lastname}}</div>
</div>

<ibm-tile-group (selected)="selected($event)" [multiple]="true" fxLayout="row wrap" fxLayoutAlign="start stretch"
    fxLayoutGap="8px">
    <ibm-selection-tile class="order_group_wrapper" *ngFor="let delivery of DeliveryService.OrderGroups"
        [value]="delivery.Id">

        <div class="order_group_header" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="order_group_name">Развоз №{{delivery.Id}}</div>
            <svg (click)="Ungroup($event, delivery);false" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 32 32">
                <use xlink:href="#trash-can"></use>
            </svg>
        </div>

        <div class="order_wrapper" *ngFor="let order of delivery.Orders">

            <div class="order_header" fxLayout="row" fxLayoutAlign="space-between start">
                <div>
                    <div class="order_name">Заказ №{{order.id}}</div>
                    <div class="order_adress">
                        <div>{{order.ShippingAdress}}</div>
                    </div>
                    <div class="order_adress">
                        <div>{{order.Total | number: '1.0-0'}}₴</div>
                    </div>
                </div>

                <div class="priority_arrows" fxLayout="column">

                    <svg (click)="UpPriority($event, order, delivery);false" xmlns="http://www.w3.org/2000/svg"
                        width="20" height="20" viewBox="0 0 32 32">
                        <use xlink:href="#arrow-up"></use>
                    </svg>

                    <svg (click)="DownPriority($event, order, delivery);false" xmlns="http://www.w3.org/2000/svg"
                        width="20" height="20" viewBox="0 0 32 32">
                        <use xlink:href="#arrow-down"></use>
                    </svg>

                </div>
            </div>



            <div class="order_bottom" fxLayout="row" fxLayoutAlign="space-between start">
                <div class="order_time">{{order.ShippingDate | date:'h:mm'}}</div>

                <select class="priority_select" [(ngModel)]="order.Priority"
                    (change)="SelectChange($event, order, delivery)">
                    <option *ngFor="let order of delivery.Orders; let i = index" class="priority_option" [value]="i+1">
                        {{delivery.Orders.length - i}}</option>
                </select>
            </div>



        </div>

    </ibm-selection-tile>
</ibm-tile-group>