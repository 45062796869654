import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../services/confirmation.service';
import { PropertyService } from '../../../services/property.service';
import { OrdersService } from '../../../services/order/orders.service';
import { ReviewService } from '../../../services/review.service';
import { Review } from '../../../models/tools/review.model';
import { System } from '../../../models/tools/system.model';

@Component({
  selector: 'app-reviews-form',
  templateUrl: './reviews-form.component.html',
  styleUrls: ['./reviews-form.component.scss']
})
export class ReviewsFormComponent implements OnInit {

  CurrentReview: any = null;

  constructor(public HeaderService: HeaderService,
    private route: ActivatedRoute, private router: Router,
    public ConfirmationService: ConfirmationService,
    public PropertyService: PropertyService,
    public OrdersService: OrdersService,
    public ReviewService: ReviewService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.CurrentReview = this.ReviewService.Reviews.find(element => element.Id == Number.parseInt(params['id']));


      if (this.CurrentReview != null) {
        this.HeaderService.WindowName = "Просмотр отзыва №" + this.CurrentReview.Id;
      } else {

        this.ReviewService.GetReview(params['id']).subscribe(
          (data) => {
            let element = data["data"];
            this.CurrentReview = new Review(
              element["review_id"],
              element["name"],
              element["comment"],
              element["date_added"],
              element["system_review_type_id"],
              element["link"]);
            this.HeaderService.WindowName = "Просмотр отзыва №" + this.CurrentReview.Id;
          }
        );
      }
    });


    this.HeaderService.ClearAll();

    this.HeaderService.BackButton.Text = "Список отзывов";
    this.HeaderService.BackButton.Link = "/reviews-list";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Отзывы", "/reviews-list");
    this.HeaderService.AddBreadcrumb("Просмотр отзыва", "", true);



    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
      //this.HeaderService.UpdateBadgeCount( "noty", 0 );
    });

    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });
  }


}
