import { InvalidFieldValue } from "../invalid-field-value.modal";

export class NumberInvalidFieldValue extends InvalidFieldValue {

    constructor(value: number, error: string) {
        super();
        this.Error = error;
        this.value = value;
    }

    
}