import { PropertyService } from '../../../app/services/property.service';

export class System {
    private static instance: System;

    private constructor() { }

    public static getInstance(): System {
        if (!System.instance) {
            System.instance = new System();
        }

        return System.instance;
    }

    public Property: PropertyService;
}