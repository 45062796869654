
import { Account } from '../account/account.model';
import { ProductBuilder } from '../store/product-builder.model';
import { Product } from '../store/product.model';
import { Banquet } from './banquet';
import { BanquetArea } from './banquet-area';
import { BanquetComment } from './banquet-comment';
import { BanquetPaymentHistory } from './banquet-payment-history';
import { BanquetTable } from './banquet-table';
import { BanquetTableGroup } from './banquet-table-group';

export class BanquetBuilder {




    public ProgramDates: Array<any> = [
        {
            fromMonth: 12,
            doMonth: 12,
            fromDay: 16,
            doDay: 31,
            programSum: 200
        },
        {
            fromMonth: 2,
            doMonth: 2,
            fromDay: 14,
            doDay: 14,
            programSum: 300
        },

    ];

    private banquet: Banquet;

    constructor(ProgramDates: Array<any>) {
        this.ProgramDates = ProgramDates;
    }


    // id: element["order_id"],
    // date: element["date_added"],
    // customer: {
    //     name: element[""],
    //     phone: element["telephone"],
    //     email: element["email"]
    // },
    // shippingAdress: element["shipping_address_1"],
    // paymentMethod: element["payment_method"],
    // orderStatus: element["order_status_id"],
    // dateAdded: element["date_added"],
    // total: element["total"],
    // comment: element["comment"],



    Build(jsonObject): Banquet {
        this.banquet = new Banquet(jsonObject["event_id"]);
        this.BuildCustomer(jsonObject);

        this.BuildName(jsonObject);
        this.BuildPrice(jsonObject);
        this.BuildGuests(jsonObject);
        this.BuildStatus(jsonObject);
        this.BuildDate(jsonObject);
        this.BuildDateAdded(jsonObject);
        this.BuildWeight(jsonObject);
        this.BuildActivated(jsonObject);
        this.BuildTarget(jsonObject);
        this.BuildTargetSecond(jsonObject);
        this.BuildLicense(jsonObject);
        this.BuildComment(jsonObject);
        this.BuildChildren(jsonObject);
        this.BuildAlcohol(jsonObject);
        this.BuildConfirmed(jsonObject);
        this.BuildDeposit(jsonObject);
        this.BuildDepositName(jsonObject);
        this.BuildDateConfirmed(jsonObject);
        this.BuildCategoryName(jsonObject);
        this.BuildPayments(jsonObject);
        this.BuildComments(jsonObject);
        this.BuildTables(jsonObject);
        this.BuildTimeId(jsonObject);
        this.BuildPaymentStatus(jsonObject);
        this.BuildKepperSended(jsonObject);
        this.BuildCustomNumber(jsonObject);
        this.BuildSliderKitchenSended(jsonObject);
        this.BuildCreator(jsonObject);

        this.banquet.GetSumPayment();

        return this.banquet;
    }


    BuildEmptyBanquet() {
        this.banquet = new Banquet(-10);
        this.BuildTables([]);
        this.banquet.Area = new BanquetArea(1, "Основной зал");
        this.banquet.Date = new Date();

        return this.banquet;
    }

    private BuildMinimal(jsonObject): Banquet {
        let minimalBanquet = new Banquet(jsonObject["order_id"]);

        minimalBanquet.ContactCustomer.FirstName = jsonObject["contact_firstname"];
        minimalBanquet.ContactCustomer.LastName = jsonObject["contact_lastname"];
        minimalBanquet.ContactCustomer.Email = jsonObject["contact_email"];
        minimalBanquet.ContactCustomer.Phone = jsonObject["contact_phone"];

        minimalBanquet.Name = jsonObject["name"];
        //minimalBanquet.Price = jsonObject["price"];
        minimalBanquet.Guests = jsonObject["guests"];
        minimalBanquet.Status = jsonObject["status"];
        minimalBanquet.Date = new Date(jsonObject["date"].replace(/-/g, "/"));
        minimalBanquet.DateAdded = new Date(jsonObject["date_added"].replace(/-/g, "/"));
        // minimalBanquet.Weight = jsonObject["weight"];
        // minimalBanquet.Activated = jsonObject["activated"];
        minimalBanquet.Target = jsonObject["target"];
        minimalBanquet.License = jsonObject["license"];
        minimalBanquet.Comment = jsonObject["comment"];
        minimalBanquet.Children = jsonObject["children"];
        minimalBanquet.Alcohol = jsonObject["alcohol"];
        minimalBanquet.CategoryName = jsonObject["category_name"];
        minimalBanquet.DateConfirmed = jsonObject["date_confirmed"] == null ? null : new Date(jsonObject["date_confirmed"].replace(/-/g, "/"));
        minimalBanquet.Confirmed = jsonObject["confirmed"] == null ? false : jsonObject["confirmed"];
        minimalBanquet.Deposit = jsonObject["deposit"] == null ? 0 : jsonObject["deposit"];
        minimalBanquet.DepositName = jsonObject["deposit_source_name"] == null ? 'Не установлен' : jsonObject["deposit_source_name"];
        // if (jsonObject["reviews"] != null) {
        //     jsonObject["reviews"].forEach(review => {
        //         minimalOrder.Reviews.push(
        //             {
        //                 Id: review["order_reviews_id"],
        //                 Comment: review["comment"],
        //                 StatusId: review["order_reviews_type_id"],
        //                 AddedDate: review["date_added"],
        //                 Name: review["name"]
        //             }
        //         );
        //     });
        // }


        return minimalBanquet;
    }


    private BuildName(jsonObject) {
        this.banquet.Name = jsonObject["name"];
    }

    private BuildCategoryName(jsonObject) {
        this.banquet.CategoryName = jsonObject["category_name"];
    }

    private BuildPrice(jsonObject) {
        //this.banquet.Price = jsonObject["price"];
    }

    private BuildGuests(jsonObject) {
        this.banquet.Guests = jsonObject["guests"];
    }

    private BuildStatus(jsonObject) {
        this.banquet.Status = jsonObject["status"];
    }

    private BuildCustomNumber(jsonObject) {
        this.banquet.CustomNumber = jsonObject["custom_number"];
    }

    private BuildPaymentStatus(jsonObject) {
        this.banquet.PaymentStatus = jsonObject["banquet_payment_status"];
    }

    private BuildDate(jsonObject) {
        this.banquet.Date = new Date(jsonObject["date"].replace(/-/g, "/"));

        this.ProgramDates.forEach(programDate => {
            if (
                this.banquet.Date.getMonth() + 1 >= programDate.fromMonth &&
                this.banquet.Date.getMonth() + 1 <= programDate.doMonth &&
                this.banquet.Date.getDate() >= programDate.fromDay &&
                this.banquet.Date.getDate() <= programDate.doDay) {
                this.banquet.IsProgramDay = true;
                // if (jsonObject["date"] == 3 || ) {
                    
                // }
                let bufferProgramSum = programDate.programSum;

                if (jsonObject["banquetTables"] != null) {
                    jsonObject["banquetTables"].forEach(banquetTable => {
                        if (banquetTable["banquet_table_id"] == 11 || banquetTable["area_id"] == 3) {
                            bufferProgramSum = 100;
                        }
                    })
                }

                this.banquet.ProgramSum = bufferProgramSum;
            }
        });

    }
    private BuildDateAdded(jsonObject) {
        this.banquet.DateAdded = new Date(jsonObject["date_added"].replace(/-/g, "/"));
    }
    private BuildDateConfirmed(jsonObject) {
        this.banquet.DateConfirmed = jsonObject["date_confirmed"] == null ? null : new Date(jsonObject["date_confirmed"].replace(/-/g, "/"));
    }

    private BuildWeight(jsonObject) {
        // this.banquet.Weight = jsonObject["weight"];
    }

    private BuildActivated(jsonObject) {
        // this.banquet.Activated = jsonObject["activated"];
    }

    private BuildTarget(jsonObject) {
        this.banquet.Target = jsonObject["target"];
    }

    private BuildTargetSecond(jsonObject) {
        this.banquet.TargetSecond = jsonObject["target_second"];
    }

    private BuildLicense(jsonObject) {
        this.banquet.License = jsonObject["license"] == "1" ? true : false;;
    }

    private BuildKepperSended(jsonObject) {
        this.banquet.KepperSended = jsonObject["kepper_sended"] == "1" ? true : false;;
    }

    private BuildSliderKitchenSended(jsonObject) {
        this.banquet.SliderKitchenSended = jsonObject["slider_kitchen_sended"] == "1" ? true : false;;
    }

    private BuildComment(jsonObject) {
        this.banquet.Comment = jsonObject["comment"];
    }

    private BuildTimeId(jsonObject) {
        this.banquet.TimeId = jsonObject["time_id"];
    }

    private BuildChildren(jsonObject) {
        this.banquet.Children = jsonObject["children"];
    }

    private BuildAlcohol(jsonObject) {
        this.banquet.Alcohol = jsonObject["alcohol"] == "1" ? true : false;;
    }
    private BuildDirectionTypeId(jsonObject) {
        this.banquet.DirectionTypeId = jsonObject["banquet_direction_type_id"];
    }


    private BuildConfirmed(jsonObject) {
        this.banquet.Confirmed = (jsonObject["confirmed"] == null || jsonObject["confirmed"] == 0) ? false : jsonObject["confirmed"];
    }

    private BuildDeposit(jsonObject) {
        this.banquet.Deposit = jsonObject["deposit"] == null ? 0 : jsonObject["deposit"];
    }

    private BuildDepositName(jsonObject) {
        this.banquet.DepositName = jsonObject["deposit_source_name"] == null ? 'Не установлен' : jsonObject["deposit_source_name"];
    }

    private BuildCustomer(jsonObject) {
        //console.log(jsonObject);
        this.banquet.ContactCustomer.FirstName = jsonObject["contact_firstname"];
        this.banquet.ContactCustomer.LastName = jsonObject["contact_lastname"];
        this.banquet.ContactCustomer.Email = jsonObject["contact_email"];
        this.banquet.ContactCustomer.Phone = jsonObject["contact_phone"];
    }


    // private BuildCardPayment(jsonObject) {
    //     if (jsonObject["card_payment"] == 1) {
    //         this.order.CardPayment = true;
    //     } else {
    //         this.order.CardPayment = false;
    //     }
    // }

    private BuildPayments(jsonObject) {
        if (jsonObject["banquetPayments"] != null) {
            jsonObject["banquetPayments"].forEach(banquetPayments => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );
                let addedBanquetPaymentHistory: BanquetPaymentHistory = new BanquetPaymentHistory(banquetPayments["banquet_payment_history_id"]);
                addedBanquetPaymentHistory.Name = banquetPayments["name"];
                addedBanquetPaymentHistory.Value = banquetPayments["value"];
                addedBanquetPaymentHistory.DateAdded = new Date(banquetPayments["date_added"].replace(/-/g, "/"));
                addedBanquetPaymentHistory.Confirmed = banquetPayments["confirmed"];
                addedBanquetPaymentHistory.IsIncome = banquetPayments["is_income"] == "1" ? true : false;
                addedBanquetPaymentHistory.BanquetId = banquetPayments["banquet_id"];

                addedBanquetPaymentHistory.Manager.FirstName = banquetPayments["firstname"];
                addedBanquetPaymentHistory.Manager.LastName = banquetPayments["lastname"];

                addedBanquetPaymentHistory.CardDate = banquetPayments["card_date_text"];


                addedBanquetPaymentHistory.PaymentTypeId = banquetPayments["banquet_payment_type_id"];
                addedBanquetPaymentHistory.PaymentSourceId = banquetPayments["banquet_payment_source_id"];
                addedBanquetPaymentHistory.Stream = banquetPayments["banquet_payment_stream_id"];

                this.banquet.Payments.push(addedBanquetPaymentHistory);
            });
        } else {
            this.banquet.Payments = [];
        }
    }

    private BuildComments(jsonObject) {
        if (jsonObject["banquetComments"] != null) {
            jsonObject["banquetComments"].forEach(banquetComment => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );
                let addedBanquetComment: BanquetComment = new BanquetComment(banquetComment["comment_id"]);
                addedBanquetComment.Text = banquetComment["text"];
                addedBanquetComment.Type = banquetComment["type"];
                addedBanquetComment.CommentTypeId = banquetComment["comment_type_id"];
                addedBanquetComment.DateAdded = new Date(banquetComment["date_added"].replace(/-/g, "/"));

                this.banquet.Comments.push(addedBanquetComment);
            });
        } else {
            this.banquet.Comments = [];
        }
    }

    private BuildTables(jsonObject) {
        if (jsonObject["banquetTables"] != null) {
            jsonObject["banquetTables"].forEach(banquetTable => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );
                let addedBanquetTable: BanquetTable = new BanquetTable(banquetTable["banquet_table_id"], banquetTable["table_name"]);
                addedBanquetTable.Name = banquetTable["table_name"];
                addedBanquetTable.Group = new BanquetTableGroup(banquetTable["banquet_table_group_id"], banquetTable["tablegroup_name"], banquetTable["count"]);
                addedBanquetTable.Group.Area = new BanquetArea(banquetTable["area_id"], banquetTable["area_name"]);

                this.banquet.AddTable(addedBanquetTable);
            });

            this.banquet.Area = this.banquet.Tables[0].Group.Area;
        } else {
            this.banquet.Tables = [];
        }

    }



    private BuildProducts(jsonObject) {
        if (jsonObject["banquetProducts"] != null) {
            jsonObject["banquetProducts"].forEach(banquetProduct => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );

                let builder: ProductBuilder = new ProductBuilder();

                let addedBanquetProduct: Product = builder.Build(banquetProduct);
                this.banquet.Products.push(addedBanquetProduct);
            });

        } else {
            this.banquet.Products = [];
        }

    }

    private BuildCreator(jsonObject) {
        if (jsonObject["banquet_user"] != null) {
            this.banquet.Creator = new Account();
            this.banquet.Creator.Id = jsonObject["banquet_user"]["user_id"];
            this.banquet.Creator.firstname = jsonObject["banquet_user"]["firstname"];
            this.banquet.Creator.lastname = jsonObject["banquet_user"]["lastname"];
            this.banquet.Creator.user_group_id = jsonObject["banquet_user"]["user_group_id"];
        }

    }






}