<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <app-order-cart [(products)]="delivery.Products" [(delivery)]="delivery"></app-order-cart>

    <div class="short_info_wrapper">

        <div class="short_info_row">
            <div class="short_info_item">
                <div class="short_info_lable">Статус</div>
                <div class="short_info_value">{{delivery.Status.Name}}</div>
            </div>
        </div>
        <div class="short_info_row">
            <div class="short_info_item" *ngIf="delivery.IsReadinessShipping == false">
                <div class="short_info_lable">Время готовности блюд</div>
                <div class="short_info_value bold">{{delivery.OrderReadyHour | twotime}}:{{delivery.OrderReadyMinutes |
                    twotime}}</div>
            </div>
            <div class="short_info_item" *ngIf="delivery.IsReadinessShipping == false">
                <div class="short_info_lable">Время доставки</div>
                <div class="short_info_value">{{delivery.ShippingHour | twotime}}:{{delivery.ShippingMinutes |
                    twotime}}</div>
            </div>
            <div class="short_info_item" *ngIf="delivery.IsReadinessShipping">
                <div class="short_info_lable">Время готовности блюд</div>
                <div class="short_info_value bold">По готовности</div>
            </div>
        </div>
        <div class="short_info_row">
            <div class="short_info_item">
                <div class="short_info_lable">Адрес</div>
                <div class="short_info_value">
                    {{delivery.ShippingAdress}}
                </div>
            </div>
        </div>
        <div class="short_info_row">
            <div class="short_info_item">
                <div class="short_info_lable">Заказов с номера</div>
                <div class="short_info_value">{{delivery.PastOrders.length}}</div>
            </div>
            <div class="short_info_item">
                <div class="short_info_lable">Сумма заказа</div>
                <div class="short_info_value">{{delivery.Total | number:'1.0-0'}}₴</div>
            </div>
        </div>
        <div class="short_info_row">
            <div class="short_info_item">
                <div class="short_info_lable">Метод доставки</div>
                <div class="short_info_value bold">
                    <div>
                        <span *ngIf="delivery.ShippingMethod == 'Бесплатная доставка'">Доставка</span>
                        <span *ngIf="delivery.ShippingMethod == 'Самовывоз'">Самовывоз</span>
                    </div>
                </div>
            </div>
            <div class="short_info_item" (click)="TogglePaymentMethod()">
                <div class="short_info_lable">Метод оплаты</div>
                <div class="short_info_value bold">
                    <div>
                        <span *ngIf="delivery.PaymentMethod == 'Оплата наличными'">Наличные</span>
                        <span *ngIf="delivery.PaymentMethod == 'Оплата картой'">Карта</span>
                        <span *ngIf="delivery.PaymentMethod == 'Терминал'">Терминал</span>
                        <span *ngIf="delivery.PaymentMethod == 'Без оплаты'">Без оплаты</span>
                    </div>
                    <div *ngIf="delivery.ShippingMethod == 'Самовывоз'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#vert-sync"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>



    </div>


    <div class="short_info_wrapper">

        <div class="short_info_row">
            <div class="short_info_item" *ngFor="let equipment of EquipmentsService.Equipments">
                <div class="short_info_lable">{{equipment.Name}}</div>
                <div class="short_info_value">{{GetEquipmentInOrder(equipment)}}</div>
            </div>

        </div>
    </div>






    <app-order-note [(notes)]="delivery.DeliveryNotes" [(delivery)]="delivery" type="1" showOnly="true"></app-order-note>


</div>