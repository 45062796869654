import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vendor } from '../vendor.service';
import { PropertyService } from '../property.service';
import { OrderBuilder } from '../../models/store/order-builder.model';
import { Order } from '../../models/store/order.model';
import { NotificationService } from '../notification.service';
import { HeaderService } from '../header.service';
import { NotificationAlert, NotificationAlertType } from '../../models/tools/notification.model';
import { Product } from '../../models/store/product.model';
import { OrderStatus } from '../../models/store/orser-status.model';
import { OnOrderGroupAddedEvent, OnOrderGroupEndedEvent } from '../../models/tools/events.model';
import { Identifiable } from '../../models/tools/identifiable.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { BaseService } from '../base.service';
import { DeliveryService } from '../delivery.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends BaseService {

  public Builder: OrderBuilder = new OrderBuilder();

  public ObjectsInPickShip: Vendor = new Vendor();
  public ObjectsInProgressShip: Vendor = new Vendor();
  public ObjectsCourierShip: Vendor = new Vendor();

  public NewOrdersNotification: Array<any> = new Array<any>();
  public CountOfNewOrders: number = 0;

  public ActiveShip: any;
  public IsFilterAfterUpload: boolean = false;

  public OnLoadShipEvents: Array<any> = new Array<any>();

  private onOrderGroupAddedEvents: Array<OnOrderGroupAddedEvent> = new Array<OnOrderGroupAddedEvent>();
  private onOrderGroupEndedEvents: Array<OnOrderGroupEndedEvent> = new Array<OnOrderGroupEndedEvent>();

  private NameFilter: string = "";
  private PhoneFilter: string = "";

  public GoodDescription: boolean = false;
  public BedDescription: boolean = false;

  public FilterStatus: Array<number> = new Array<number>();


  public LastNotificationId: number = 0;

  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router,) {
    super();
    setInterval(
      () => {
        this.Objects.Values.forEach(order => {
          (order as Order).GetCurrentTime();
        });
      }, 1000
    );
  }

  public AddOrder(order: Order) {



    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/order/AddOrder&token=${this.property.Token}`;


    let shippingDate = order.ShippingDate.getFullYear() + "-" + this.twoDigits(1 + order.ShippingDate.getMonth()) + "-" + this.twoDigits(order.ShippingDate.getDate()) + " " + this.twoDigits(order.ShippingHour) + ":" + this.twoDigits(order.ShippingMinutes) + ":00";


    let orderReadyDate = order.ShippingDate.getFullYear() + "-" + this.twoDigits(1 + order.ShippingDate.getMonth()) + "-" + this.twoDigits(order.ShippingDate.getDate()) + " " + this.twoDigits(order.OrderReadyHour) + ":" + this.twoDigits(order.OrderReadyMinutes) + ":00";




    let entity = {
      comment: order.Comment,
      telephone: order.Customer.Phone,
      shippingaddress: order.ShippingAdress,
      shippingarea: order.ShippingArea,
      shippingmethod: order.ShippingMethod,
      paymentmethod: order.PaymentMethod,
      entrance: order.Entrance,
      floor: order.Floor,
      apartment: order.Apartment,
      home_code: order.HomeCode,
      products: JSON.stringify(order.Products),

      orderreadydate: orderReadyDate,
      shippingdate: shippingDate,
      is_readiness_shipping: order.IsReadinessShipping ? 1 : 0,
    };


    var form_data = new FormData();
    for (var key in entity) {
      form_data.append(key, entity[key]);
    }
    this.http.post(request, form_data).subscribe(
      (data) => {
        if (data["error"] != null) {

        } else {
          let builder: OrderBuilder = new OrderBuilder();
          this.LastNotificationId = data["notification_id"];
          this.GetOrder(data["order_id"]).subscribe(
            (dataOrder) => {
              let buildingOrder: Order = builder.Build(dataOrder["body"]["data"]["objects"]["order"]);
              this.Objects.PushToStart(buildingOrder);
              this.VisibleObjects.PushToStart(buildingOrder);
              this.ObjectsInProgressShip.PushToStart(buildingOrder);
              this.ObjectsOriginal.PushToStart(builder.Build(dataOrder["body"]["data"]["objects"]["original"]));

              this.router.navigate([`/delivery/${data["order_id"]}`]);
            }
          );
        }
      });
  }

  private GetFilters() {
    let statusIds = "";
    if (this.FilterStatus.length > 0) {
      this.FilterStatus.forEach(status => {
        statusIds += (status + " ");
      });
      statusIds = statusIds.substring(0, statusIds.length - 1)
    }
    return statusIds;
  }

  public LoadOrders() {
    this.IsLoading = true;
    let statusIds: string = this.GetFilters();
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/order/LoadMoreOrders&token=${this.property.Token}&page=${this.Page}&search_filter=${this.NameFilter}&statusIds=${statusIds}&baddescription=${this.BedDescription ? 1 : 0}&gooddescription=${this.GoodDescription ? 1 : 0}`;

    this.http.get(request).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {
          //this.LastNotificationId = data["data"][0]["order_id"];

          data["data"].forEach(element => {


            let buildingOrder: Order = this.Builder.Build(element);


            this.Objects.PushToEnd(buildingOrder);

            if (buildingOrder.StatusId == 14 && buildingOrder.GroupId == 0) {
              this.ObjectsCourierShip.PushToEnd(buildingOrder);
            }
            if (buildingOrder.StatusId == 1 || buildingOrder.StatusId == 2
              || buildingOrder.StatusId == 3 || buildingOrder.StatusId == 17
              || buildingOrder.StatusId == 14 || buildingOrder.StatusId == 15
              || buildingOrder.StatusId == 16 || buildingOrder.StatusId == 19
              || buildingOrder.StatusId == 20) {
              this.ObjectsInProgressShip.PushToEnd(buildingOrder);
            }
            if (buildingOrder.StatusId == 3 || buildingOrder.StatusId == 19 || buildingOrder.StatusId == 20) {
              this.ObjectsInPickShip.PushToEnd(buildingOrder);
            }

          });

          data["originals"].forEach(element => {
            this.ObjectsOriginal.PushToEnd(this.Builder.Build(element));
          });

          this.LastNotificationId = data["last_notification_id"];

          if (this.IsFirstLoaded) {
            if (this.property.userGroupId == 13
              || this.property.userGroupId == 12
              || this.property.userGroupId == 15) {
              setInterval(() => {
                this.LoadNewOrders();
              }, 3000);
            }
          }

          this.IsLoaded = true;
          this.IsLoading = false;
          this.IsFirstLoaded = false;
          this.Page++;
          this.LoadMoreOrders();

          this.OnLoadShipEvents.forEach(event => {
            event();
          });


          if (this.IsFilterAfterUpload) {
            this.IsFilterAfterUpload = false;
            this.Filter(this.NameFilter);
          }


        }

      }
    );
  }

  public GetOrder(id): Observable<any> {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/order/GetOrder&token=${this.property.Token}&id=${id}`;
    return this.http.get(request);
  }

  public LoadMoreOrders() {
    if (this.IsLoading) {
      return;
    }

    if (this.Page === this.PageOfVisible / 5) {
      //this.Page++;
      this.LoadOrders();
      return;
    }
    for (let index = this.PageOfVisible * 10; index < this.PageOfVisible * 10 + 10; index++) {
      if (this.Objects.Values[index] != null) {
        this.VisibleObjects.PushToEnd(this.Objects.Values[index]);
      }
    }
    this.PageOfVisible++;
  }

  public LoadNewOrders() {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/v2/notification/LoadDeliveryManagerNotifications&token=${this.property.Token}&last_id=${this.LastNotificationId}`;

    this.http.get(request).subscribe(
      (data: any) => {

        this.LastNotificationId = data["last_id"];
        let newNotifications = data["notifications"]["new"];
        let editNotifications = data["notifications"]["edit"];
        let editStatusNotifications = data["notifications"]["editstatus"];
        let cancelManagerNotifications = data["notifications"]["cancelmanager"];
        let cancelCourierNotifications = data["notifications"]["cancelcourier"];





        //if (newNotifications["notifications"].length > 0) {
        newNotifications["notifications"].forEach(notificationData => {
          this.NotificationService.Add(notificationData["title"], notificationData["message"], new Date(notificationData["time"]), NotificationAlertType.Emerald);
          this.NotificationService.playAudio();
        });
        newNotifications["orders"].forEach(order => {
          let buildingOrder: Order = this.Builder.Build(order);
          //console.log(buildingOrder);
          this.Objects.PushToStart(buildingOrder);
          this.VisibleObjects.PushToStart(buildingOrder);

          if (buildingOrder.StatusId == 14 && buildingOrder.GroupId == 0) {
            this.ObjectsCourierShip.PushToStart(buildingOrder);
          }

          if (buildingOrder.StatusId == 1 || buildingOrder.StatusId == 2
            || buildingOrder.StatusId == 3 || buildingOrder.StatusId == 17
            || buildingOrder.StatusId == 14 || buildingOrder.StatusId == 15
            || buildingOrder.StatusId == 16 || buildingOrder.StatusId == 19
            || buildingOrder.StatusId == 20) {
            this.ObjectsInProgressShip.PushToStart(buildingOrder);
          }

          if (buildingOrder.StatusId == 3) {
            this.ObjectsInPickShip.PushToEnd(buildingOrder);
          }
        });

        newNotifications["originals"].forEach(element => {
          this.ObjectsOriginal.PushToStart(this.Builder.Build(element));
        });

        //}


        //if (editNotifications["notifications"].length > 0) {

        editNotifications["notifications"].forEach(notificationData => {
          console.log(notificationData["owner_id"], this.property.UserId);
          if (notificationData["owner_id"] != this.property.UserId) {
            //this.NotificationService.Add(notificationData["title"], notificationData["message"], new Date(notificationData["time"]), NotificationAlertType.Default);
            //this.NotificationService.playAudio();
          }
        });
        editNotifications["orders"].forEach(order => {
          let editedOrder: any = this.Objects.Values.find(element => element.Id == Number.parseInt(order['order_id']));
          this.Builder.UpdateOrder(editedOrder, order);

          // if (editedOrder.StatusId == 14 && editedOrder.GroupId == 0) {
          //   this.ObjectsCourierShip.PushToStart(editedOrder);
          // }





        });


        //}


        //if (editStatusNotifications["notifications"].length > 0) {
        editStatusNotifications["notifications"].forEach(notificationData => {
          if (notificationData["owner_id"] != this.property.UserId) {
            this.NotificationService.Add(notificationData["title"], notificationData["message"], new Date(notificationData["time"]), NotificationAlertType.Default);
            //this.NotificationService.playAudio();
          }
        });
        editStatusNotifications["orders"].forEach(order => {
          let editedOrder: any = this.Objects.Values.find(element => element.Id == Number.parseInt(order['order_id']));
          this.Builder.UpdateOrder(editedOrder, order);

          if (editedOrder.StatusId == 14 && editedOrder.GroupId == 0) {

            if (this.ObjectsCourierShip.Get(editedOrder.Id) == null) {
              this.ObjectsCourierShip.PushToStart(editedOrder);
            }


          }

          if (editedOrder.StatusId == 14) {
            let index = this.ObjectsInPickShip.Values.indexOf(editedOrder, 0);
            if (index > -1) {
              this.ObjectsInPickShip.Values.splice(index, 1);
            }
          }

          if (editedOrder.StatusId == 3) {
            this.ObjectsInPickShip.PushToEnd(editedOrder);
          }

          if (editedOrder.StatusId == 5) {
            let index = this.ObjectsInPickShip.Values.indexOf(editedOrder, 0);
            if (index > -1) {
              this.ObjectsInPickShip.Values.splice(index, 1);
            }
            index = this.ObjectsInProgressShip.Values.indexOf(editedOrder, 0);
            if (index > -1) {
              this.ObjectsInProgressShip.Values.splice(index, 1);
            }
          }
        });
        // }




        //if (cancelManagerNotifications["notifications"].length > 0) {
        cancelManagerNotifications["notifications"].forEach(notificationData => {
          this.NotificationService.Add(notificationData["title"], notificationData["message"], new Date(notificationData["time"]), NotificationAlertType.Carmine);
          this.NotificationService.playAudio();
        });
        cancelManagerNotifications["orders"].forEach(order => {
          let editedOrder: any = this.Objects.Values.find(element => element.Id == Number.parseInt(order['order_id']));
          this.Builder.UpdateOrder(editedOrder, order);
        });
        // }


        // if (cancelCourierNotifications["notifications"].length > 0) {
        cancelCourierNotifications["notifications"].forEach(notificationData => {
          this.NotificationService.Add(notificationData["title"], notificationData["message"], new Date(notificationData["time"]), NotificationAlertType.Carmine);
          this.NotificationService.playAudio();
        });
        cancelCourierNotifications["orders"].forEach(order => {
          let editedOrder: any = this.Objects.Values.find(element => element.Id == Number.parseInt(order['order_id']));
          this.Builder.UpdateOrder(editedOrder, order);
        });
        // }









        // if (data["data"].length > 0) {
        //   this.NotificationService.playAudio();
        //   this.LastOrderId = data["data"][0]["order_id"];
        // }

        // data["data"].forEach(element => {
        //   let buildingOrder: Order = this.Builder.Build(element);
        //   this.Objects.PushToStart(buildingOrder);
        //   this.VisibleObjects.PushToStart(buildingOrder);
        //   this.ObjectsInProgressShip.PushToStart(buildingOrder);
        //   this.NewOrdersNotification.push(
        //     {
        //       Title: "Новый заказ №" + buildingOrder.Id,
        //       Date: buildingOrder.ShippingDate,
        //       BadgeCount: 1,
        //       OrderId: buildingOrder.Id
        //     }
        //   );
        // });
        // data["originals"].forEach(element => {
        //   this.ObjectsOriginal.PushToEnd(this.Builder.Build(element));
        // });

        // this.HeaderService.UpdateBadgeCount("noty", this.NewOrdersNotification.length);


      }
    );
  }



  public Filter(searchString: string) {
    this.NameFilter = searchString;
    this.PhoneFilter = searchString;
    if (this.IsLoading) {
      this.IsFilterAfterUpload = true;
      return;
    }
    this.Page = 0;
    this.PageOfVisible = 0;
    this.Clear();
    this.LoadOrders();

  }

  public SetFilterGoodDescription(description) {
    this.Page = 0;
    this.PageOfVisible = 0;
    this.Clear();
    this.GoodDescription = description;
    this.LoadOrders();
  }

  public SetFilterBadDescription(description) {
    this.Page = 0;
    this.PageOfVisible = 0;
    this.Clear();
    this.BedDescription = description;
    this.LoadOrders();
  }

  public AddFilterStatus(statusId) {
    this.Page = 0;
    this.PageOfVisible = 0;
    this.Clear();
    this.FilterStatus.push(statusId);
    this.LoadOrders();
  }

  public RemoveFilterStatus(statusId) {
    this.Page = 0;
    this.PageOfVisible = 0;
    this.Clear();
    let findedElementIndex = this.FilterStatus.findIndex(element => element == statusId);
    this.FilterStatus.splice(findedElementIndex, 1);
    this.LoadOrders();
  }

  public Clear() {
    this.VisibleObjects.Clear();
    this.Objects.Clear();
    this.ObjectsCourierShip.Clear();
    this.ObjectsInProgressShip.Clear();
    this.ObjectsOriginal.Clear();
  }

  public SaveOrder(order: Order, IsNotify: boolean = true) {
    let shippingDate = order.ShippingDate.getFullYear() + "-" + this.twoDigits(1 + order.ShippingDate.getMonth()) + "-" + this.twoDigits(order.ShippingDate.getDate()) + " " + this.twoDigits(order.ShippingDate.getHours()) + ":" + this.twoDigits(order.ShippingDate.getMinutes()) + ":" + this.twoDigits(order.ShippingDate.getSeconds());
    let orderReadyDate = order.ShippingDate.getFullYear() + "-" + this.twoDigits(1 + order.ShippingDate.getMonth()) + "-" + this.twoDigits(order.ShippingDate.getDate()) + " " + this.twoDigits(order.OrderReadyDate.getHours()) + ":" + this.twoDigits(order.OrderReadyDate.getMinutes()) + ":" + this.twoDigits(order.ShippingDate.getSeconds());

    let request = `https://ochagzp.com.ua/index.php?route=api/v2/order/EditOrder&token=${this.property.Token}&IsNotify=${IsNotify == true ? 1 : 0}`;

    let entity = {
      comment: order.Comment,
      paymented: order.Paymented ? 1 : 0,
      telephone: order.Customer.Phone,
      second_telephone: order.Customer.SecondPhone,
      custom_name: order.Customer.CustomName,
      shippingaddress: order.ShippingAdress,
      shippingarea: order.ShippingArea,
      shippingmethod: order.ShippingMethod,
      paymentmethod: order.PaymentMethod,
      order_id: order.Id,
      priority: order.Priority,
      readiness: order.Readiness,
      entrance: order.Entrance,
      floor: order.Floor,
      apartment: order.Apartment,
      home_code: order.HomeCode,
      shippingdate: shippingDate,
      orderreadydate: orderReadyDate,
      total: order.Total,
      card_payment: order.CardPayment ? 1 : 0,
      is_readiness_shipping: order.IsReadinessShipping ? 1 : 0,
    };

    var form_data = new FormData();
    for (var key in entity) {
      form_data.append(key, entity[key]);
    }
    this.http.post(request, form_data).subscribe(
      (data) => {
        if (data["status"] == "700") {

        }
      }
    );
  }

  private twoDigits(d) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
    return d.toString();
  }

  public ChangeProductCount(order: Order, product: Product) {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-product/ChangeCountProduct&token=${this.property.Token}&order_id=${order.Id}&product_id=${product.Id}&count=${product.Count}`);

    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          order.Total = data["total"];
          let NewNotification: NotificationAlert = new NotificationAlert();
          NewNotification.Text = `Заказа №${order.Id} сохранён`;
          this.NotificationService.Notifications.push(NewNotification);
          NewNotification.IsShowed = true;
          setTimeout(
            () => {
              const index = this.NotificationService.Notifications.indexOf(NewNotification, 0);
              if (index > -1) {
                this.NotificationService.Notifications.splice(index, 1);
              }
            }, 4000
          )
        }
      }
    );
  }

  public RemoveProduct(order: Order, product: Product) {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-product/RemoveProduct&token=${this.property.Token}&order_id=${order.Id}&product_id=${product.Id}`);

    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          let findedElementIndex = order.Products.findIndex(element => element.Id == product.Id);
          order.Products.splice(findedElementIndex, 1);
          order.Total = data["total"];
          let NewNotification: NotificationAlert = new NotificationAlert();
          NewNotification.Text = `Заказа №${order.Id} сохранён`;
          this.NotificationService.Notifications.push(NewNotification);
          NewNotification.IsShowed = true;
          setTimeout(
            () => {
              const index = this.NotificationService.Notifications.indexOf(NewNotification, 0);
              if (index > -1) {
                this.NotificationService.Notifications.splice(index, 1);
              }
            }, 4000
          )
        }
      }
    );
  }

  public AddProduct(order: Order, product: Product) {



    let request = `https://ochagzp.com.ua/index.php?route=api/v2/order-product/AddProduct&token=${this.property.Token}&order_id=${order.Id}&product_id=${product.Id}&count=${product.Count}&price=${product.Price}&comment=${product.Comment}&name=${product.Name}`.replace(/['"]+/g, '');

    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          let NewNotification: NotificationAlert = new NotificationAlert();
          NewNotification.Text = `Заказа №${order.Id} сохранён`;
          this.NotificationService.Notifications.push(NewNotification);
          NewNotification.IsShowed = true;

          order.Total = data["total"];

          setTimeout(
            () => {
              const index = this.NotificationService.Notifications.indexOf(NewNotification, 0);
              if (index > -1) {
                this.NotificationService.Notifications.splice(index, 1);
              }
            }, 2000
          )
        }
      }
    );
  }

  public ChangeStatus(order: Order, status: OrderStatus, Comment: string = "") {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order/EditOrderStatus&token=${this.property.Token}&order_id=${order.Id}&order_status_id=${status.Id}&comment=${Comment}`);

    this.http.get(request).subscribe(
      (data) => {

        if (data["status"] == "700") {


          order.StatusId = status.Id;
          if (order.StatusId == 14) {
            this.ObjectsCourierShip.PushToEnd(order);
          }
          if (order.StatusId == 5) {
            order.EndedDate = new Date(0);

          }
          //this.NotificationService.Add(`Статус заказа №${order.Id} изменён`, NotificationAlertType.Emerald);



          order.UpdateTimer();

          if (data["order_group_id"] != null) {

            this.onOrderGroupEndedEvents.forEach(event => {
              event(data["order_group_id"]);
            });
          }
        }
      },
      (data) => {
        console.log(data);
      }
    );
  }

  public Cancel(order: Order, message: string) {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order/CancelOrder&token=${this.property.Token}&order_id=${order.Id}&order_status_id=${OrderStatus.Get(9).Id}&message=${message}`);

    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          order.StatusId = OrderStatus.Get(9).Id;
          order.UpdateTimer();
        }
      }
    );
  }

  encodeData(s: string): string {
    return s.replace(/\-/g, "%2D").replace(/\_/g, "%5F").replace(/\./g, "%2E").replace(/\!/g, "%21").replace(/\~/g, "%7E").replace(/\*/g, "%2A").replace(/\'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29");
  }


  public AddOrderGroupAddedEvent(event: OnOrderGroupAddedEvent) {
    this.onOrderGroupAddedEvents.push(event);
  }

  public AddOrderGroupEndedEvent(event: OnOrderGroupEndedEvent) {
    this.onOrderGroupEndedEvents.push(event);
  }

  public AddShipToGroup(orders: Array<string>) {


    let ordersIds: string = "";
    orders.forEach(order => {
      ordersIds += (order + " ");
    });
    ordersIds = ordersIds.substring(0, ordersIds.length - 1)


    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-group/AddOrderToOrderGroup&token=${this.property.Token}&orders=${ordersIds}`);

    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {

          let ordersForGroup: Array<Order> = new Array<Order>();

          orders.forEach(removedOrderId => {
            let findedElementIndex = this.ObjectsCourierShip.Values.findIndex(element => element.Id == Number.parseInt(removedOrderId));
            let findedElement: Identifiable = this.ObjectsCourierShip.Values.find(element => element.Id == Number.parseInt(removedOrderId));
            ordersForGroup.push(findedElement as Order);
            this.ObjectsCourierShip.Values.splice(findedElementIndex, 1);

          });

          this.NotificationService.Add(`Развоз №${data["group_id"]} сформирован`, `Разовз №${data["group_id"]} сформирован`, new Date(), NotificationAlertType.Amber);
          this.onOrderGroupAddedEvents.forEach(event => {
            event(data["group_id"], ordersForGroup);
          });

        }
      }
    );
  }

  public AddReview(orderId, comment, statusReviewId, onRecive) {

    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-note/AddOrderReview&token=${this.property.Token}`);


    let entity = {
      comment: comment,
      order_reviews_type_id: statusReviewId,
      order_id: orderId,
    };
    var form_data = new FormData();
    for (var key in entity) {
      form_data.append(key, entity[key]);
    }


    this.http.post(request, form_data).subscribe(
      (data) => {
        if (data["status"] == "700") {
          onRecive(data["review"]);
        }
      }
    );
  }

}
