import { Component, OnInit } from '@angular/core';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ConfirmationService } from '../../../services/confirmation.service';;
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

@Component({
  selector: 'app-banquet-table',
  templateUrl: './banquet-table.component.html',
  styleUrls: ['./banquet-table.component.scss']
})
export class BanquetTableComponent implements OnInit {

  constructor(public BanquetZoneService: BanquetZoneService,
    public ConfirmationService: ConfirmationService, public ModalAddingService: ModalAddingService,) { }

  ngOnInit(): void {

  }

  OpenAddingBanquetTableModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetZoneService.AddTable(
        this.ModalAddingService.Fields[1].Value,
        this.ModalAddingService.Fields[0].Value
      );
    });

    this.ModalAddingService.Show(
      new ModalData("Добавить стол", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildDropdown("Группа столов", this.BanquetZoneService.TableGroupsDropdown),
        ModalFieldBuilder.BuildText("Название", 0, 100000),
      ]
    );
  }

  OpenModalRemoveBanquetTable(id: number) {
    this.ConfirmationService.Reset();
    this.ConfirmationService.OpenModal("Удалить стол?", "Вы уверены, что хотите удалить стол?", "", "Нет", "Удалить");
    this.ConfirmationService.AddConfirmEvent((answer: string) => {
      this.BanquetZoneService.DeleteTable(id);
    });
  }

}
