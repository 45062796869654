import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../../models/store/product.model';

@Component({
  selector: 'app-order-kitchen-stage',
  templateUrl: './order-kitchen-stage.component.html',
  styleUrls: ['./order-kitchen-stage.component.scss']
})
export class OrderKitchenStageComponent implements OnInit {

  @Input('delivery') delivery: any;
  @Input('deliveryOriginal') deliveryOriginal: any;

  constructor() { }

  ngOnInit() {
  }

}
