export class Customer{

    private id: number;
    public get Id(): number{
        return this.id;
    }

    private name: string;
    public get Name(): string{
        return this.name;
    }
    public set Name(name: string){
        this.name = name;
    }


    private lastname: string;
    public get Lastname(): string{
        return this.lastname;
    }
    public set Lastname(lastname: string){
        this.lastname = lastname;
    }


    private customName: string;
    public get CustomName(): string{
        return this.customName;
    }
    public set CustomName(customName: string){
        this.customName = customName;
    }


    private phone: string;
    public get Phone(): string{
        return this.phone;
    }
    public set Phone(phone: string){
        this.phone = phone;
    }

    private secondPhone: string;
    public get SecondPhone(): string{
        return this.secondPhone;
    }
    public set SecondPhone(secondPhone: string){
        this.secondPhone = secondPhone;
    }


    private email: string;
    public get Email(): string{
        return this.email;
    }
    public set Email(email: string){
        this.email = email;
    }




    constructor(id: number, name: string = "", lastname: string = "", phone: string = "", secondPhone: string = "", email: string = ""){
        this.id = id;
        this.name = name;
        this.lastname = lastname;

        this.phone = phone.replace('+', '');
        this.secondPhone = secondPhone.replace('+', '');

        this.email = email;
    }


}