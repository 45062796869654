import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { Product } from '../../../../models/store/product.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { NotificationService } from '../../../../services/notification.service';
import { ClipboardService } from '../../../../services/clipboard.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { Order } from '../../../../models/store/order.model';
import { Equipment } from '../../../../models/store/equipment.model';
import { AddressService } from 'src/app/services/order/address.service';
import { OrderStatus } from 'src/app/models/store/orser-status.model';
import { OrderDateManager } from 'src/app/models/store/order-date-manager';
import { PropertyService } from '../../../../services/property.service';
import { OrderHistory } from 'src/app/models/store/history.model';
import { OchagboxService } from '../../../../services/ochagbox.service';
import { OchagboxHistoryService } from 'src/app/services/ochagbox-history.service';
import { registerPostInit } from 'echarts';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-express-stage',
  templateUrl: './order-express-stage.component.html',
  styleUrls: ['./order-express-stage.component.scss']
})
export class OrderExpressStageComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;
  @Input('shippingArea') shippingArea: any;
  areas: Array<any> = [];


  items: Array<any> = [
    { content: "Александровский район", id: "Александровский район", selected: false },
    { content: "Вознесеновский район", id: "Вознесеновский район", selected: false },
    { content: "Днепровский район", id: "Днепровский район", selected: false },
    { content: "Заводский район", id: "Заводский район", selected: false },
    { content: "Коммунарский район", id: "Коммунарский район", selected: false },
    { content: "Космический район", id: "Космический район", selected: false },
    { content: "Хортицкий район", id: "Хортицкий район", selected: false },
    { content: "Шевченковский район", id: "Шевченковский район", selected: false },
  ];

  public CardPayment: boolean = false;
  public Hours: number = 0;
  public Minutes: number = 0;
  public Readiness: boolean = false;

  public GlobalEquipmentCount: number = 0;


  constructor(public ProductsService: ProductsService, public OrdersService: OrdersService,
    public NotificationService: NotificationService, public ClipboardService: ClipboardService,
    public EquipmentsService: EquipmentService, public AddressService: AddressService,
    private changeDetectorRef: ChangeDetectorRef, public PropertyService: PropertyService,
    public OchagboxService: OchagboxService, public OchagboxHistoryService: OchagboxHistoryService,
    private route: ActivatedRoute) {


  }

  ngOnInit() {
    this.UpdateOrderExpressInformation();
    this.route.params.subscribe(val => {
      this.UpdateOrderExpressInformation();
    });
  }


  UpdateOrderExpressInformation() {
    this.AddressService.Addresses.forEach(
      (address) => {
        let selected = this.delivery.ShippingArea == address.Name;
        this.areas.push(
          { content: address.Name, id: address.Name, selected: selected },
        );
      }
    );
  }


  ToggleShippingMethod() {
    if (this.delivery.ShippingMethod == "Бесплатная доставка") {
      this.delivery.ShippingMethod = "Самовывоз";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
    if (this.delivery.ShippingMethod == "Самовывоз") {
      this.delivery.ShippingMethod = "Бесплатная доставка";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
  }


  TogglePaymentMethod() {
    if (this.delivery.PaymentMethod == "Оплата наличными") {
      this.delivery.CardPayment = true;
      this.delivery.PaymentMethod = "Оплата картой";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
    if (this.delivery.PaymentMethod == "Оплата картой") {
      this.delivery.CardPayment = false;
      this.delivery.PaymentMethod = "Оплата наличными";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }

    if (this.delivery.PaymentMethod == "Без оплаты" || this.delivery.PaymentMethod == "Терминал") {
      this.delivery.CardPayment = false;
      this.delivery.PaymentMethod = "Оплата наличными";
      this.OrdersService.SaveOrder(this.delivery);
      return;
    }
  }


  onClose(event) {

  }

  selected(event) {
    this.delivery.ShippingArea = event.item.id;
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangeAreas(event) {
    this.delivery.ShippingArea = event.item.id;
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnOrderChange() {
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangeCardPayment(event) {
    this.CardPayment = event.checked;
    this.delivery.CardPayment = event.checked;
    if (this.delivery.CardPayment == false) {
      this.delivery.Paymented = false;
    }
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangePaymented(event) {
    this.delivery.Paymented = event.checked;
    this.OrdersService.SaveOrder(this.delivery);
  }

  public UpdateOrderDate() {

  }

  OnChangeGlobalCount(event) {
    this.EquipmentsService.ChangeGloablCount(this.delivery, event.value);

    this.GlobalEquipmentCount = event.value;
    this.EquipmentsService.Equipments.forEach(equipment => {
      if (this.CheckEquipmentInOrder(equipment) == false) {
        this.EquipmentsService.ChangeCount(this.delivery, equipment, event.value, 1);
      }
    });
  }

  CheckEquipmentInOrder(equipment: Equipment) {
    let finded = this.delivery.Equipments.find(deliveryEquipment => deliveryEquipment.Id == equipment.Id);
    if (finded == undefined) {
      return false;
    } else {
      if (finded.IsGlobal) {
        return false;
      } else {
        return true;
      }
    }
  }


  ToggleAccordion(history: OrderHistory) {
    history.IsOpened = !history.IsOpened;
  }

  @ViewChild('lastnameInput') lastnameInput: ElementRef;
  @ViewChild('firstnameInput') firstnameInput: ElementRef;
  @ViewChild('apartmentInput') apartmentInput: ElementRef;
  @ViewChild('homeCodeInput') homeCodeInput: ElementRef;
  @ViewChild('floorInput') floorInput: ElementRef;
  @ViewChild('entranceInput') entranceInput: ElementRef;
  //@ViewChild('phoneInput') phoneInput: ElementRef;

  keytab(element: any) {
    let elementHTML = element.nativeElement; // get the sibling element

    if (elementHTML == null) {  // check if its null
      element.focus();
      return;

    } else {
      elementHTML.focus();   // focus if not null
    }

  }
  blur(event) {
    event.target.blur()
  }


}
