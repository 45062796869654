<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="24px">


    <div class="active_delivery_item" fxLayout="column" fxLayoutAlign="start stretch" *ngFor="let delivery of DeliveryService.ActiveOrderGroups">


        <div class="active_delivery_top" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="active_delivery_title">Развоз №{{delivery.Id}}</div>
            <div class="active_delivery_status" [ngClass]="{progress: delivery.StatusId==3}"></div>
        </div>

        <div class="active_delivery_courier" *ngIf="delivery.Courier != null">

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                <use xlink:href="#character"></use>
            </svg>

            {{delivery.Courier.firstname}}
            {{delivery.Courier.lastname}}
        </div>


        <div class="orders_list">

            <div class="order_wrapper" *ngFor="let order of delivery.Orders" [ngClass]="{ended: order.StatusId == 5, opened: order.IsToggleInGroup, iscard: order.CardPayment}" (click)="ToggleDelivery($event, order)">

                <div class="order_top" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="order_name">{{order.ShippingAdress}}</div>
                    <div class="order_time">{{order.ShippingHour | twotime}}:{{order.ShippingMinutes |
                        twotime}}</div>

                </div>
                <div class="order_middle" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="order_phone">{{order.Customer.Phone}}</div>
                    <div class="order_status">{{order.Status.Name}}</div>
                </div>

                <div class="order_toggle_block">
                    <div class="order_toggle_wrapper">

                        <a [routerLink]="'/delivery/' + order.Id">Открыть заказ</a>

                        <ibm-toggle *ngIf=" order.CardPayment" [onText]="'Оплата произведена'" [offText]="'Оплата не произведена'" (change)="OnChangePaymented($event, order)" [(ngModel)]="order.Paymented">
                        </ibm-toggle>
                    </div>
                </div>

                <div class="order_bottom" fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <svg *ngIf="order.CardPayment" class="purchase_icon" [ngClass]="{'warning': order.CardPayment && !order.Paymented}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#card"></use>
                        </svg>
                    </div>
                    <div>
                        <svg class="toggle-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#arrow-down"></use>
                        </svg>

                    </div>
                </div>




            </div>

        </div>
    </div>


</div>