import { Component, OnInit, Input } from '@angular/core';
import { EquipmentService } from '../../../../services/equipment.service';
import { Equipment } from '../../../../models/store/equipment.model';
import { Order } from '../../../../models/store/order.model';
import { OrdersService } from '../../../../services/order/orders.service';

@Component({
  selector: 'app-order-equipment-stage',
  templateUrl: './order-equipment-stage.component.html',
  styleUrls: ['./order-equipment-stage.component.scss']
})
export class OrderEquipmentStageComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  public GlobalEquipmentCount: number = 0;

  constructor(public EquipmentsService: EquipmentService, public OrdersService: OrdersService) { }

  ngOnInit() {

  }

  OnChangeGlobalCount(event) {

    if (event.value != null) {
      this.EquipmentsService.ChangeGloablCount(this.delivery, event.value, () => {
        this.OrdersService.SaveOrder(this.delivery);
      });
      this.GlobalEquipmentCount = event.value;
      this.EquipmentsService.Equipments.forEach(equipment => {
        if (this.CheckEquipmentInOrder(equipment) == false) {
          this.EquipmentsService.ChangeCount(this.delivery, equipment, event.value, 1, () => {
            this.OrdersService.SaveOrder(this.delivery);
          });
        }
      });
    }
  }

  CheckEquipmentInOrder(equipment: Equipment) {
    let finded = this.delivery.Equipments.find(deliveryEquipment => deliveryEquipment.Id == equipment.Id);
    if (finded == undefined) {
      return false;
    } else {
      if (finded.IsGlobal) {
        return false;
      } else {
        return true;
      }
    }
  }

  GetEquipmentInOrder(equipment: Equipment) {
    //this.delivery.E
    let finded = this.delivery.Equipments.find(deliveryEquipment => deliveryEquipment.Id == equipment.Id);
    if (finded == null) {
      return this.GlobalEquipmentCount;
    } else {
      return finded.Count;
    }
  }

  OnChangeEquipmentCount(event, equipment: Equipment) {
    if (event.value != null) {
      this.EquipmentsService.ChangeCount(this.delivery, equipment, event.value, 0, () => {
        this.OrdersService.SaveOrder(this.delivery);
      });

    }
  }

  OnDisabledChnage($event, equipment: Equipment) {
    equipment.Count = this.GlobalEquipmentCount;
    if ($event.checked) {
      equipment.IsGlobal = false;
      this.EquipmentsService.Add(this.delivery, equipment, this.GlobalEquipmentCount);
    } else {
      equipment.IsGlobal = true;
      this.EquipmentsService.ChangeCount(this.delivery, equipment, this.GlobalEquipmentCount, 1, () => {
        this.OrdersService.SaveOrder(this.delivery);
      });
    }

  }


}
