import { FieldType } from "./field-types.enum";
import { NumberInvalidFieldValue } from "./InvalidFields/number-invalid-filed-value.model";
import { ModalField } from "./modal-field.modal";

export class ModalFieldBuilder {



    static BuildText(title: string, min: number, max: number, value: string = "",
        defaultValues: Array<string> = [],) {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Min = min;
        field.Max = max;
        field.Type = FieldType.Text;
        field.Value = value;
        field.DefaultValues = defaultValues;

        return field;
    }

    static BuildNumber(title: string, min: number, max: number, value: number = 0,
        defaultValues: Array<number>,
        invalidValues: Array<NumberInvalidFieldValue> = new Array<NumberInvalidFieldValue>()) {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Min = min;
        field.Max = max;
        field.Type = FieldType.Number;
        field.InvalidValues = invalidValues;
        field.Value = value;
        field.DefaultValues = defaultValues;

        return field;
    }

    static BuildDropdown(title: string, values: Array<any>, value: string = "") {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Values = values;
        field.Type = FieldType.Dropdown;
        field.OnChangeValue = (event: any) => {
            field.ValueId = event.item.id;
        };
        field.Value = value;
        return field;
    }

    static BuildAutocomplete(title: string, values: Array<any>, value: string = "") {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Values = values;
        field.Type = FieldType.Autocomplete;
        field.OnChangeValue = (event: any) => {
            field.ValueId = event.item.id;
        };
        field.Value = value;
        return field;
    }

    static BuildTabs(title: string, values: Array<any>, value: string = "") {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Values = values;
        field.Type = FieldType.Tabs;
        field.OnChangeValue = (event: any) => {
            field.ValueId = event.item.id;
        };
        field.Value = value;
        return field;
    }

    static BuildDatePicker(title: string, value: Date = new Date()) {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Type = FieldType.Date;
        field.Value = value;

        return field;
    }

    static BuildTimePicker(title: string) {
        let field: ModalField = new ModalField();
        field.Title = title;
        field.Type = FieldType.Time;

        return field;
    }



}