<div class="banquet_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="row" fxLayoutGap="40px">

    <div class="panel">

        <div class="create_new_banquet" (click)="Create()">Создать Банкет</div>
        <app-banquet-quick-access-panel></app-banquet-quick-access-panel>

    </div>
    <div class="content" *ngIf="BanquetsService.ActiveBanquet!=null">
        <ibm-tabs cacheActive="true">
            <ibm-tab heading="Всё">
                <app-banquet-main></app-banquet-main>
            </ibm-tab>
            <ibm-tab heading="Оплаты">
                <app-banquet-payment-reading>
                </app-banquet-payment-reading>
            </ibm-tab>
            <!-- <ibm-tab heading="Блюда">
                <app-banquet-products-reading>
                </app-banquet-products-reading>
            </ibm-tab>
            <ibm-tab heading="Скидки">
                <app-banquet-discounts-reading></app-banquet-discounts-reading>
            </ibm-tab> -->
            <ibm-tab heading="Заметки">
                <app-banquet-comment-reading></app-banquet-comment-reading>
            </ibm-tab>
            <!-- <ibm-tab heading="Столы" disabled="true">

            </ibm-tab> -->
        </ibm-tabs>

        <div class="reopen-wrapper" *ngIf="BanquetsService.IsReopenedBanquet"
            [ngClass]="{'opened': BanquetsService.IsReopenedBanquet}"></div>
    </div>
</div>


<div class="action_button" (click)="SaveBanquet()">
    <div class="action_button_content">
        Сохранить банкет
    </div>
</div>