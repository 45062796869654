import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { Product } from '../../../../models/store/product.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { NotificationService } from '../../../../services/notification.service';
import { ClipboardService } from '../../../../services/clipboard.service';
import { Order } from '../../../../models/store/order.model';

@Component({
  selector: 'app-order-main-stage-editable',
  templateUrl: './order-main-stage-editable.component.html',
  styleUrls: ['./order-main-stage-editable.component.scss']
})
export class OrderMainStageEditableComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;



  @Input('shippingArea') set shippingArea(delivery: any) {

  }

  items: Array<any> = [
    { content: "Александровский район", id: "Александровский район", selected: false },
    { content: "Вознесеновский район", id: "Вознесеновский район", selected: false },
    { content: "Днепровский район", id: "Днепровский район", selected: false },
    { content: "Заводский район", id: "Заводский район", selected: false },
    { content: "Коммунарский район", id: "Коммунарский район", selected: false },
    { content: "Космический район", id: "Космический район", selected: false },
    { content: "Хортицкий район", id: "Хортицкий район", selected: false },
    { content: "Шевченковский район", id: "Шевченковский район", selected: false },
  ];

  public CardPayment: boolean = false;


  constructor(public ProductsService: ProductsService, public OrdersService: OrdersService,
    public NotificationService: NotificationService, public ClipboardService: ClipboardService) { }

  ngOnInit() {

  }

  onClose(event) {

  }

  selected(event) {
    this.delivery.ShippingArea = event.item.id;
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnOrderChange() {
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangeCardPayment(event) {
    this.CardPayment = event.checked;
    this.delivery.CardPayment = event.checked;
    if (this.delivery.CardPayment == false) {
      this.delivery.Paymented = false;
    }
    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangePaymented(event) {
    this.delivery.Paymented = event.checked;
    this.OrdersService.SaveOrder(this.delivery);
  }


}
