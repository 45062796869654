import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Note } from '../../models/store/note.model';
import { Order } from '../../models/store/order.model';
import { System } from '../../models/tools/system.model';

@Injectable({
  providedIn: 'root'
})
export class OrderNotesService {


  public IsOpenedAddingNoteModal: boolean = false;
  public AddedNoteTitlte: string = "";
  public AddedNoteComment: string = "";
  public SelectedId: number = null;
  public EditingNote: Note = null;

  public Notes: Array<Note>;
  public delivery: Order;
  public orderTypeId: Order;
  public showOnly: boolean = false;

  constructor(private http: HttpClient) {

  }

  AddModalAddingNote() {
    if (this.AddedNoteComment == "") {
      return;
    }
    let entity = {
      order_id: this.delivery.Id,
      order_status_id: this.orderTypeId,
      title: this.AddedNoteTitlte,
      comment: this.AddedNoteComment,
    };
    var form_data = this.EntityToFormData(entity);
    this.http.post(`https://ochagzp.com.ua/index.php?route=api/v2/order-note/AddNote&token=${System.getInstance().Property.Token}`, form_data).subscribe(
      (data) => {
        if (data["status"] == "700") {
          let addedNote = new Note(data["addedId"], this.AddedNoteTitlte, this.AddedNoteComment);
          this.Notes.push(addedNote);
          this.ClearAddedForm();
        }
      }
    );
  }

  RemoveNote(note) {
    this.http.get(`https://ochagzp.com.ua/index.php?route=api/v2/order-note/RemoveNote&token=${System.getInstance().Property.Token}&note_id=${note.Id}`).subscribe(
      (data) => {
        if (data["status"] == "700") {
          
        }
      }
    );
  }


  EditModalAddingNote() {
    let entity = {
      order_id: this.delivery.Id,
      order_status_id: this.orderTypeId,
      title: this.AddedNoteTitlte,
      comment: this.AddedNoteComment,
      note_id: this.EditingNote.Id,
    };
    var form_data = this.EntityToFormData(entity);
    this.http.post(`https://ochagzp.com.ua/index.php?route=api/v2/order-note/EditNote&token=${System.getInstance().Property.Token}`, form_data).subscribe(
      (data) => {
        if (data["status"] == "700") {
          this.EditingNote.Title = this.AddedNoteTitlte;
          this.EditingNote.Comment = this.AddedNoteComment;
          this.ClearAddedForm();
        }
      }
    );
  }

  CloseModalAddingNote() {
    this.IsOpenedAddingNoteModal = false;
  }

  ClearAddedForm() {
    this.IsOpenedAddingNoteModal = false;
    this.AddedNoteTitlte = "";
    this.AddedNoteComment = "";
    this.SelectedId = null;
    this.EditingNote = null;
  }

  EntityToFormData(entity) {
    var formData = new FormData();
    for (var key in entity) {
      formData.append(key, entity[key]);
    }
    return formData;
  }

}
