import { Identifiable } from "../tools/identifiable.model";

export class OchagboxSupply extends Identifiable {


    private count: number;
    public get Count(): number {
        return this.count;
    }
    public set Count(count: number) {
        this.count = count;
    }


    private description: string;
    public get Description(): string {
        return this.description;
    }
    public set Description(description: string) {
        this.description = description;
    }


    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }


    constructor(id: number, description: string, count: number, dateAdded: Date) {
        super(id)
        this.Count = count;
        this.Description = description;
        this.DateAdded = dateAdded;
    }

}