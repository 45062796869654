import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { ClipboardService } from '../../../services/clipboard.service';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';
import { NavigationActionStatus } from 'src/app/models/navigation/navigation-action-status.enum';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {

  public get NavigationActionStatus(): typeof NavigationActionStatus {
    return NavigationActionStatus;
  }

  constructor(public HeaderService: HeaderService, public ClipboardService: ClipboardService) { }

  ngOnInit() {

  }

  public ClickAction(action: NavigationAction) {
    if (action.Status == NavigationActionStatus.Activated) {
      action.Action(action);
    }
  }

}
