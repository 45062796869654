import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private onAuthorizationEvents: Array<any> = new Array<any>();

  constructor() {
    
  }

  ClearOnAuthorizationEvent() {
    
    this.onAuthorizationEvents = new Array<any>();
  }

  AddOnAuthorizationEvent(event: any) {
    this.onAuthorizationEvents.push(event);
  }

  OnAuthorization() {
    this.onAuthorizationEvents.forEach(authEvent => {
      authEvent();
    });
  }
}
