import { Component, Input, OnInit } from '@angular/core';
import { OrderDateManager } from 'src/app/models/store/order-date-manager';
import { Order } from 'src/app/models/store/order.model';
import { OrdersService } from '../../../../services/order/orders.service';

@Component({
  selector: 'order-date-time-picker',
  templateUrl: './order-date-time-picker.component.html',
  styleUrls: ['./order-date-time-picker.component.scss']
})
export class OrderDateTimePickerComponent implements OnInit {

  @Input('delivery') delivery: Order;

  public ShippingDate: Date = new Date();
  public ShippingPresentDate: Array<Date> = new Array<Date>();
  public OrderDateManager: OrderDateManager = new OrderDateManager();

  public ActiveDeliveryTimeMode: number = 6;

  public TimeModes: Array<any> = [
    { hour: 0, minute: 30 },
    { hour: 1, minute: 0 },
    { hour: 1, minute: 30 },
    { hour: 1, minute: 40 },
    { hour: 2, minute: 0 },
    { hour: 2, minute: 30 },
  ];

  constructor(public OrdersService: OrdersService,) {

  }

  ngOnInit(): void {
    this.ShippingPresentDate = new Array<Date>();
    this.ShippingPresentDate.push(this.delivery.ShippingDate);

    if (this.delivery.IsReadinessShipping) {
      this.ActiveDeliveryTimeMode = 2;
    } else {
      this.ActiveDeliveryTimeMode = 6;
    }

  }

  SetDeliveryTime(ActiveDeliveryTimeMode: number) {
    this.ActiveDeliveryTimeMode = ActiveDeliveryTimeMode;
    if (ActiveDeliveryTimeMode < 6) {
      let hours = this.TimeModes[ActiveDeliveryTimeMode].hour;
      let minutes = this.TimeModes[ActiveDeliveryTimeMode].minute;
      this.UpdateShippingDateTimeFromCurrentDate(hours, minutes);
      this.delivery.IsReadinessShipping = true;
    } else {
      this.delivery.IsReadinessShipping = false;
    }


    this.OrdersService.SaveOrder(this.delivery);
  }

  ChangeDateToCustom() {
    let times = this.delivery.CustomerShippingTime.split(':');
    this.delivery.ShippingDate = new Date(this.delivery.CustomerShippingDate);
    this.delivery.ShippingHour = Number(times[0]);
    this.delivery.ShippingMinutes = Number(times[1]);

    this.UpdateShippingDateTimeFromCurrentDate();

    this.delivery.IsReadinessShipping = false;

    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangeShippingDate(event) {
    let date = event[0];
    this.delivery.ShippingDate = new Date(date + " UTC");
    this.UpdateShippingDateTimeFromCurrentDate();
    this.OrdersService.SaveOrder(this.delivery);
  }




  OnChangeHours(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 23) {
      this.delivery.ShippingHour = 23;
    } else {
      this.delivery.ShippingHour = event.value;
    }

    //this.UpdateShippingDateTimeFromCurrentDate();

    this.OrdersService.SaveOrder(this.delivery);
  }

  OnChangeMinutes(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 59) {
      this.delivery.ShippingMinutes = 59;
    } else {
      this.delivery.ShippingMinutes = event.value;
    }
    //this.UpdateShippingDateTimeFromCurrentDate();

    this.OrdersService.SaveOrder(this.delivery);
  }





  OnChangeOrderReadyHours(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 23) {
      this.delivery.OrderReadyHour = 23;
    } else {
      this.delivery.OrderReadyHour = event.value;
    }

    //this.UpdateShippingDateTimeFromCurrentDate();

    this.OrdersService.SaveOrder(this.delivery);
  }


  OnChangeOrderReadyMinutes(event) {
    if (event.value == null) {
      return;
    }
    if (event.value > 59) {
      this.delivery.OrderReadyMinutes = 59;
    } else {
      this.delivery.OrderReadyMinutes = event.value;
    }
    //this.UpdateShippingDateTimeFromCurrentDate();

    this.OrdersService.SaveOrder(this.delivery);
  }








  UpdateShippingDateTimeFromAddedDate(hours: number = 0, minutes: number = 0) {
    let newDate = this.OrderDateManager.
      GenerateDifferenceDateFromAddedDate(this.delivery, hours, minutes);

    let newDateReady = this.OrderDateManager.
      GenerateDifferenceDateFromAddedDate(this.delivery, hours, minutes - 30);


    this.UpdateShippingDateTime(newDate);
    this.UpdateOrderReadyDateTime(newDateReady);


  }

  UpdateShippingDateTimeFromCurrentDate(hours: number = 0, minutes: number = 0) {
    let newDate = this.OrderDateManager.
      GenerateDifferenceDateFromCurrentDate(this.delivery, hours, minutes);

    let newDateReady = this.OrderDateManager.
      GenerateDifferenceDateFromCurrentDate(this.delivery, hours, minutes - 30);

    this.UpdateShippingDateTime(newDate);
    this.UpdateOrderReadyDateTime(newDateReady);
  }

  UpdateShippingDateTime(newDate: Date) {
    this.ShippingDate = newDate;

    this.delivery.ShippingHour = newDate.getHours();
    this.delivery.ShippingMinutes = newDate.getMinutes();
    this.delivery.ShippingDate = newDate;

    this.ShippingPresentDate = new Array<Date>();
    this.ShippingPresentDate.push(this.delivery.ShippingDate);
  }

  UpdateOrderReadyDateTime(newDate: Date) {
    this.delivery.OrderReadyHour = newDate.getHours();
    this.delivery.OrderReadyMinutes = newDate.getMinutes();
  }




}
