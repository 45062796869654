import { Component, Input, OnInit } from '@angular/core';
import { Banquet } from 'src/app/models/banquet/banquet';
import { Order } from 'src/app/models/store/order.model';

@Component({
  selector: 'app-banquet-panel-card',
  templateUrl: './banquet-panel-card.component.html',
  styleUrls: ['./banquet-panel-card.component.scss']
})
export class BanquetPanelCardComponent implements OnInit {

  @Input('banquet') banquet: Banquet;

  constructor() { }

  ngOnInit(): void {
  }

}
