import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryService } from '../../../services/delivery.service';
import { OrdersService } from '../../../services/order/orders.service';
import { OrderGroup } from '../../../models/store/order-group.model';
import { HeaderService } from '../../../services/header.service';
import { System } from '../../../models/tools/system.model';
import { Order } from '../../../models/store/order.model';
import { OrderStatus } from '../../../models/store/orser-status.model';

import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { NavigationAction } from 'src/app/models/navigation/navigation-action.model';

@Component({
  selector: 'app-courier-delivery-form',
  templateUrl: './courier-delivery-form.component.html',
  styleUrls: ['./courier-delivery-form.component.scss']
})
export class CourierDeliveryFormComponent implements OnInit {

  public OrderGroupId: number = 0;
  public OrderId: number = 0;
  public CurrentDelivery: OrderGroup = null;
  public CurrentOrder: Order = null;
  public map: any;


  public get StatusId(): number {
    if (this.CurrentOrder == null) {
      return 0;
    } else {
      return this.CurrentOrder.StatusId;
    }
  }

  constructor(private route: ActivatedRoute, private router: Router,
    public HeaderService: HeaderService, public DeliveryService: DeliveryService,
    public OrdersService: OrdersService, public ModalAddingService: ModalAddingService) { }

  ngOnInit(): void {
    this.HeaderService.ClearAll();
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("...", "/deliveries");

    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);
    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });


    this.route.params.subscribe(params => {
      this.OrderGroupId = params['id'];
      this.OrderId = params['order'];
      this.HeaderService.BackButton.Text = `Доставка №${this.OrderGroupId}`;
      this.HeaderService.BackButton.Link = `/courier-open/${this.OrderGroupId}`;
      this.HeaderService.AddBreadcrumb(`Заказ №${this.OrderId}`, "/deliveries", true);
      if (this.DeliveryService.ActiveOrderGroups.length == 0) {
        this.DeliveryService.AddLoadDeliveryEvents(
          () => {
            this.CurrentDelivery = this.DeliveryService.ActiveOrderGroups.find(element => element.Id == this.OrderGroupId);
            this.CurrentOrder = this.CurrentDelivery.Orders.find(element => element.Id == this.OrderId);

            if (this.CurrentOrder.StatusId == 15) {
              this.HeaderService.AddSubActions( new NavigationAction("Отменить заказ", "trash", () => {
                this.CancelDelivery();
              }));
            }

            this.HeaderService.WindowNameClipboard = this.CurrentOrder.ShippingAdress;
            this.HeaderService.WindowName = this.CurrentOrder.ShippingAdress;
          }
        );
      } else {
        this.CurrentDelivery = this.DeliveryService.ActiveOrderGroups.find(element => element.Id == this.OrderGroupId);
        this.CurrentOrder = this.CurrentDelivery.Orders.find(element => element.Id == this.OrderId);
        if (this.CurrentOrder.StatusId == 15) {
          this.HeaderService.AddSubActions( new NavigationAction("Отменить заказ", "trash", () => {
            this.CancelDelivery();
          }));
        }

        this.HeaderService.WindowNameClipboard = this.CurrentOrder.ShippingAdress;
        this.HeaderService.WindowName = this.CurrentOrder.ShippingAdress;
      }
    });



    
    

    
  }

  CancelDelivery() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.OrdersService.ChangeStatus(this.CurrentOrder, OrderStatus.Get(18), this.ModalAddingService.Fields[0].Value);
      this.CurrentOrder.StatusId = 18;
      this.HeaderService.ClearSubActions();
    });



    let commentProduct = ModalFieldBuilder.BuildText("Причина отказа", 0, 200);
    commentProduct.Required = true;
    commentProduct.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };

    this.ModalAddingService.Show(
      new ModalData("Отменить заказ", "", "Отменить", "Отмена"),
      [
        commentProduct,
      ]
    );
    
  }

  FinishOrder() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.OrdersService.ChangeStatus(this.CurrentOrder, OrderStatus.Get(5));
    });

    let finishOrderModel = new ModalData("Завершить заказ", "", "Завершить", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show( finishOrderModel, [] );
    
  }

}
