<div class="short_info_wrapper">

    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Статус</div>
            <div class="short_info_value">{{delivery.Status.Name}}</div>
        </div>
    </div>


    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Время поступления</div>
            <div class="short_info_value">{{delivery.DateAdded | date:'HH:mm'}}</div>
        </div>
        <div class="short_info_item">
            <div class="short_info_lable">Время готовности</div>
            <div class="short_info_value">{{delivery.OrderReadyHour | twotime}}:{{delivery.OrderReadyMinutes |
                twotime}}</div>
        </div>
        <div class="short_info_item">
            <div class="short_info_lable">Время доставки</div>
            <div class="short_info_value">{{delivery.ShippingHour | twotime}}:{{delivery.ShippingMinutes |
                twotime}}</div>
        </div>
    </div>



    <div class="short_info_row">

    </div>



    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Номер телефона</div>
            <div class="short_info_value">
                <a href="tel:{{delivery.Customer.Phone}}" class="short_info_link">{{delivery.Customer.Phone}}</a>
                <div (click)="ClipboardService.Copy(delivery.Customer.Phone)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" fill="#194699">
                        <use xlink:href="#copy"></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Заказов с номера</div>
            <div class="short_info_value">{{delivery.PastOrders.length}}</div>
        </div>
        <div class="short_info_item">
            <div class="short_info_lable">Сумма заказа</div>
            <div class="short_info_value">{{delivery.Total | number:'1.0-0'}}₴</div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item" (click)="ToggleShippingMethod()">
            <div class="short_info_lable">Метод доставки</div>
            <div class="short_info_value">
                <div>
                    <span *ngIf="delivery.ShippingMethod == 'Бесплатная доставка'">Доставка</span>
                    <span *ngIf="delivery.ShippingMethod == 'Самовывоз'">Самовывоз</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#vert-sync"></use>
                    </svg>
                </div>
            </div>
        </div>
        <div class="short_info_item" (click)="TogglePaymentMethod()">
            <div class="short_info_lable">Метод оплаты</div>
            <div class="short_info_value">
                <div>
                    <span *ngIf="delivery.PaymentMethod == 'Оплата наличными'">Наличные</span>
                    <span *ngIf="delivery.PaymentMethod == 'Оплата картой'">Карта</span>
                    <span *ngIf="delivery.PaymentMethod == 'Терминал'">Терминал</span>
                    <span *ngIf="delivery.PaymentMethod == 'Без оплаты'">Без оплаты</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#vert-sync"></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <div class="short_info_row" *ngIf="delivery.CustomerNames.length > 0">
        <div class="short_info_item">
            <div class="short_info_lable">Контактные имена</div>
            <div class="short_info_value">
                <div fxLayout="row wrpa" fxLayoutGap="8px">
                    <div *ngFor="let name of delivery.CustomerNames" class="names_chips">
                        {{name}}</div>
                </div>
            </div>

        </div>
    </div>



</div>



<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">

    <div class="block_wrapper">

        <div class="form_wrapper paymented_wrapper" fxLayout="row" fxLayoutGap="32px" *ngIf="delivery.PaymentMethod == 'Оплата картой'" style="width: max-content;">
            <div class="form_item">
                <ibm-toggle [onText]="'Оплата произведена'" [offText]="'Оплата не произведена'" (change)="OnChangePaymented($event)" [(ngModel)]="delivery.Paymented">
                </ibm-toggle>
            </div>
        </div>



        <div class="form_wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px" fxLayoutGap="32px" *ngIf="delivery.ShippingMethod == 'Бесплатная доставка'">
            <div class="form_item">

                <ibm-dropdown label="Район" (selected)="OnChangeAreas($event)" [(ngModel)]="delivery.ShippingArea" value="content" theme="light">
                    <ibm-dropdown-list [items]="areas"></ibm-dropdown-list>
                </ibm-dropdown>
            </div>
            <div class="form_item">
                <ibm-label>
                    Адресс
                    <input [(ngModel)]="delivery.ShippingAdress" ibmText placeholder="Адресс доставки" theme="light" autocomplete="off" (change)="OnOrderChange()" (keyup.enter)="keytab(entranceInput)">
                </ibm-label>
            </div>
        </div>






        <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px" *ngIf="delivery.ShippingMethod == 'Бесплатная доставка'" fxLayoutGap.lt-md="12px">
            <div class="form_item">
                <ibm-label>
                    Подъезд
                    <input #entranceInput [(ngModel)]="delivery.Entrance" ibmText theme="light" autocomplete="off" (change)="OnOrderChange()" (keyup.enter)="keytab(floorInput)">
                </ibm-label>
            </div>
            <div class="form_item">
                <ibm-label>
                    Квартира
                    <input #floorInput [(ngModel)]="delivery.Floor" ibmText theme="light" (change)="OnOrderChange()" autocomplete="off" (keyup.enter)="keytab(apartmentInput)">
                </ibm-label>
            </div>
        </div>

        <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px" *ngIf="delivery.ShippingMethod == 'Бесплатная доставка'" fxLayoutGap.lt-md="12px">
            <div class="form_item">
                <ibm-label>
                    Этаж
                    <input #apartmentInput [(ngModel)]="delivery.Apartment" ibmText theme="light" (change)="OnOrderChange()" (keyup.enter)="keytab(homeCodeInput)" autocomplete="off">
                </ibm-label>
            </div>
            <div class="form_item">
                <ibm-label>
                    Код
                    <input #homeCodeInput [(ngModel)]="delivery.HomeCode" ibmText theme="light" (change)="OnOrderChange()" (keyup.enter)="keytab(firstnameInput)" autocomplete="off">
                </ibm-label>
            </div>
        </div>

        <div class="form_wrapper" fxLayout="row" style="margin-bottom: 16px;">
            <ibm-label>
                Телефон
                <input [(ngModel)]="delivery.Customer.Phone" ibmText theme="light" placeholder="Первый номер" (change)="OnOrderChange()">
            </ibm-label>
        </div>

        <div class="form_wrapper" fxLayout="row" style="margin-bottom: 16px;">
            <ibm-label>
                Второй телефон
                <input [(ngModel)]="delivery.Customer.SecondPhone" ibmText theme="light" placeholder="Второй номер" (change)="OnOrderChange()">
            </ibm-label>
        </div>


        <div class="form_wrapper" fxLayout="row">
            <ibm-label>
                Комментарий
                <textarea [(ngModel)]="delivery.Comment" ibmTextArea theme="light" placeholder="Содержимое заметки" autocomplete="off" cols="120" class="comment" (change)="OnOrderChange()"></textarea>
            </ibm-label>
        </div>


    </div>






    <div class="block_wrapper">
        <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px">
            <div class="form_item">
                <ibm-label>
                    Имя
                    <input #firstnameInput [(ngModel)]="delivery.Customer.Name" ibmText theme="light" autocomplete="off" (change)="OnOrderChange()" (keyup.enter)="keytab(lastnameInput)">
                </ibm-label>
            </div>
            <div class="form_item">
                <ibm-label>
                    Фамилия
                    <input #lastnameInput [(ngModel)]="delivery.Customer.Lastname" ibmText theme="light" autocomplete="off" (change)="OnOrderChange()" (keyup.enter)="blur($event)">
                </ibm-label>
            </div>
        </div>


    </div>



    <div class="block_wrapper">
        <order-date-time-picker [(delivery)]="delivery"></order-date-time-picker>
    </div>


    <div class="block_wrapper">
        <app-order-note [(notes)]="delivery.DeliveryNotes" [(delivery)]="delivery" type="2"></app-order-note>
    </div>


    <div class="block_wrapper">
        <div class="main_title">История</div>
        <div class="accordion">
            <div *ngFor="let history of delivery.Histories; let i = index" class="accordion_item_wrapper" [ngClass]="{'opened': history.IsOpened}">
                <div class="accordion_item" (click)="ToggleAccordion(history)" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap.lt-md="8px">
                    <div class="history_name">
                        {{history.Status.Name}}
                    </div>
                    <div class="history_date">
                        {{history.DateAdded | date:"HH:mm:ss"}}
                    </div>
                    <div class="history_item_more_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#arrow-down"></use>
                        </svg>
                    </div>
                </div>
                <div class="accordion_content">

                    <div class="history_comment">
                        {{history.Comment}}
                    </div>

                    <div class="accordion_content_title">Общая продолжительность</div>

                    <div class="accordion_content_text">
                        <span *ngIf="i==0">
                            {{ history.DateAdded - delivery.DateAdded + PropertyService.GetTimezoneOffset() |
                            date:"H:mm:ss"
                            }}
                        </span>
                        <span *ngIf="i>0">
                            {{ history.DateAdded - delivery.Histories[i-1].DateAdded +
                            PropertyService.GetTimezoneOffset() |
                            date:"H:mm:ss" }}
                        </span>

                    </div>

                    <div class="accordion_content_title">Времени от поступления</div>
                    <div class="accordion_content_text">{{ history.DateAdded - delivery.DateAdded +
                        PropertyService.GetTimezoneOffset() | date:"H:mm:ss" }}</div>
                </div>
            </div>
        </div>
    </div>



    <div class="express-message" *ngIf="(OchagboxService.TotalCount - OchagboxHistoryService.Objects.Values.length) > 0 && delivery.Total >= 500">
        В заказ нужно добавить
        ОЧАГBOX</div>

</div>