import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { DeliveryService } from '../../../services/delivery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../../models/store/product.model';
import { System } from '../../../models/tools/system.model';
import { OrderStatus } from '../../../models/store/orser-status.model';
import { ConfirmationService } from '../../../services/confirmation.service';
import { PropertyService } from '../../../services/property.service';
import { OrderBuilder } from '../../../models/store/order-builder.model';
import { ProductsService } from '../../../services/products.service';
import { OrderNotesService } from '../../../services/order/order-notes.service';
import { Vendor } from '../../../services/vendor.service';
import { NavigationAction } from '../../../models/navigation/navigation-action.model';
import { NavigationActionStatus } from 'src/app/models/navigation/navigation-action-status.enum';
import { OchagboxService } from '../../../services/ochagbox.service';
import { OchagboxHistoryService } from 'src/app/services/ochagbox-history.service';


@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {



  steps: Array<any> = [
    {
      id: 0,
      name: "Данные заказа",
      state: "current",
    },
    {
      id: 2,
      name: "Комплектация",
      state: "complete",
    },
    {
      id: 3,
      name: "Доставка",
      state: "complete",
    },

  ];

  products: Array<Product> = new Array<Product>();


  CurrentStep: any;
  current: number = 1;

  CurrentDelivery: any = null;
  CurrentOriginalDelivery: any = null;

  public IsFilterOpened: boolean = false;
  keyword = 'name';

  private selectedCategory;
  public get SelectedCategory(): any {
    return this.selectedCategory;
  }
  public set SelectedCategory(category: any) {
    this.selectedCategory = category;
    this.ProductsService.ReloadProducts(category);
  }




  public currentDate: Date = new Date();



  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    public DeliveryService: DeliveryService,
    private route: ActivatedRoute, private router: Router,
    public ConfirmationService: ConfirmationService,
    public PropertyService: PropertyService,
    public ProductsService: ProductsService,
    public OrderNotesService: OrderNotesService,
    private changeDetectorRef: ChangeDetectorRef,
    public OchagboxService: OchagboxService, public OchagboxHistoryService: OchagboxHistoryService,) {


  }

  ngOnInit() {

    this.CurrentStep = this.steps[0];



    this.route.params.subscribe(params => {
      this.currentDate = new Date();
      this.TryLoadCurrentOrder(params['id']);
    });


    this.HeaderService.ClearAll();
    this.HeaderService.BackButton.Text = "Список доставки";
    this.HeaderService.BackButton.Link = "/deliveries";
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Заказы", "/deliveries");
    this.HeaderService.AddBreadcrumb("Просмотр заказа", "", true);

    this.HeaderService.AddAction("review", () => {
      this.HeaderService.ToggleReviewElement();
    });

    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.ToggleNotificationsElement();
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });


    let orderSubaction = new NavigationAction("Заказы", "collapse", () => {
      this.IsFilterOpened = !this.IsFilterOpened;
    });
    orderSubaction.IsDesktop = false;
    orderSubaction.IsMobile = true;
    orderSubaction.Tooltip = "Открыть заказы";
    this.HeaderService.AddSubActions(orderSubaction);



    if (this.PropertyService.UserGroupId == 13) {
      this.HeaderService.AddSubActions(new NavigationAction("Отменить", "trash", () => {
        this.ConfirmationService.Reset();
        this.ConfirmationService.OpenModal("Отменить заказ?", "Вы уверены, что хотите отменить заказ?", "", "Не отменять", "Отменить заказ");
        this.ConfirmationService.AddConfirmEvent((answer: string) => {

          this.OrdersService.Cancel(this.CurrentDelivery, answer);
          this.CurrentDelivery.IsDifferenceOverdue = false;
          let findedElementIndex = this.OrdersService.ObjectsInProgressShip.Values.findIndex(element => element.Id == this.CurrentDelivery.Id);
          this.OrdersService.Objects.Values.splice(findedElementIndex, 1);

          this.router.navigate(['/deliveries']);
        });
      }));
    }





  }





  TryLoadCurrentOrder(id) {
    this.CurrentDelivery = this.OrdersService.Objects.Values.find(element => element.Id == Number.parseInt(id));
    this.CurrentOriginalDelivery = this.OrdersService.ObjectsOriginal.Values.find(element => element.Id == Number.parseInt(id));

    if (this.CurrentDelivery != null) {
      if (this.CurrentDelivery.StatusId == 1 && this.PropertyService.UserGroupId == 13) {
        this.OpenOrder();
      }
      this.HeaderService.WindowName = "Просмотр заказа №" + this.CurrentDelivery.Id;
    } else {
      setTimeout(() => {
        this.TryLoadCurrentOrder(id);
      }, 1000);
    }


  }
















  CloseFilter() {
    this.IsFilterOpened = false;
  }

  OnClickProgressIndicator(step) {
    this.CurrentStep.state = "complete";
    this.CurrentStep = step;
    this.CurrentStep.state = "current";
  }


  OpenOrder() {
    this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(2));
  }



  StartOrder() {
    this.ConfirmationService.AddConfirmEvent(
      (answer) => {
        this.CurrentDelivery.IsDifferenceOverdue = false;
        this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(3));
      });
    this.ConfirmationService.OpenModal(
      "Подтвердить заказ",
      "Подтвердите, что вы обработали заказ и сборщик может начать работать с ним",
      "",
      "Отменить",
      "Подтвердить");
  }

  AddToKeeperOrder() {
    this.ConfirmationService.AddConfirmEvent(
      (answer) => {
        this.CurrentDelivery.IsDifferenceOverdue = false;
        this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(19));
      });
    this.ConfirmationService.OpenModal(
      "Добавление в Keeper",
      "Вы подтверждаете, что внесли заказ в Keeper?",
      "",
      "Не добавил",
      "Добавил");
  }


  StartDeliveryOrder() {

    this.ConfirmationService.AddConfirmEvent(
      (answer: string) => {

        if (answer != null) {
          this.CurrentDelivery.Total = answer;
          this.OrdersService.SaveOrder(this.CurrentDelivery);
        }

        if (this.CurrentDelivery.ShippingMethod == "Самовывоз") {
          this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(20));
        } else {
          this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(14));
        }
        this.ConfirmationService.Answer = null;
      });

    let descriptionNotification: string = "";
    if (this.CurrentDelivery.Total > 590 &&
      (this.OchagboxService.TotalCount - this.OchagboxHistoryService.Objects.Values.length) > 0) {
      descriptionNotification = "Не забудте положить ОЧАГBOX!";
    }

    let type: string = "empty";


    this.CurrentDelivery.Products.forEach(product => {
      if (product.IsWeightable) {
        type = "number";
      }
    });




    this.ConfirmationService.OpenModal(
      "Отправить заказ на распредиление доставки",
      "Подтвердите, что вы хотите отправить заказ на распредиление доставки",
      descriptionNotification,
      "Отменить",
      "Отправить",
      type);

  }


  EndDeliveryOrder() {
    this.ConfirmationService.AddConfirmEvent(
      (answer) => {
        this.OrdersService.ChangeStatus(this.CurrentDelivery, OrderStatus.Get(5));
      });
    this.ConfirmationService.OpenModal(
      "Самовывоз",
      "Вы подтверждаете, что клиент забрал свой заказ-самовывоз?",
      "",
      "Отмена",
      "Подтвердить");
  }







}
