<div class="tables_block_wrapper">
    <div fxLayout="column" fxLayoutGap="24px">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <ibm-date-picker dateFormat="d.m.Y" theme="light" label="Дата проведения" language="ru"
                (valueChange)="DateChange($event)" [(ngModel)]="BanquetDate">
            </ibm-date-picker>


        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="IsReadOnly == false">
            <div>
                <ibm-number theme="light" label="Часы" min="0" max="23" (change)="OnChangeHours($event)"
                    [(ngModel)]="Banquet.DateHour">
                </ibm-number>
                <div fxLayout="row wrap" fxLayoutGap="4px" style="margin-top: 12px">
                    <ibm-tag type="cool-gray" (click)="SetHours(16)">16:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(17)">17:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(18)">18:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(19)">19:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(20)">20:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(21)">21:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(22)">22:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetHours(23)">23:{{Banquet.DateMinutes == 0 ? "00" :
                        Banquet.DateMinutes}}</ibm-tag>
                </div>
            </div>

            <div>
                <ibm-number theme="light" label="Минуты" min="0" max="59" (change)="OnChangeMinutes($event)"
                    [(ngModel)]="Banquet.DateMinutes">
                </ibm-number>
                <div fxLayout="row wrap" fxLayoutGap="4px" style="margin-top: 12px">
                    <ibm-tag type="cool-gray" (click)="SetMinutes(0)">{{Banquet.DateHour}}:00</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetMinutes(15)">{{Banquet.DateHour}}:15</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetMinutes(30)">{{Banquet.DateHour}}:30</ibm-tag>
                    <ibm-tag type="cool-gray" (click)="SetMinutes(45)">{{Banquet.DateHour}}:45</ibm-tag>
                </div>
            </div>

        </div>

    </div>
</div>


<div class="tables_block_wrapper">


    <ibm-dropdown theme="light" class="area-dropdown" *ngIf="BanquetZoneService.IsAreaLoaded" label="Выберите зал"
        placeholder="Зал" value="content" (selected)="OnAreaChange($event)" [(ngModel)]="Area">
        <ibm-dropdown-list [items]="AreaFields"></ibm-dropdown-list>
    </ibm-dropdown>

    <div class="reserve_all_tables" *ngIf="BanquetsService.IsAllReservReady && !IsReadOnly"
        (click)="ReserveAllTables()">
        Зарезервировать весь зал</div>
    <div class="unreserve_all_tables"
        *ngIf="!BanquetsService.IsAllReservReady && !BanquetsService.IsReserveOtherUser && !IsReadOnly"
        (click)="UnreserveAllTables()">
        Снять бронь со всего зала</div>


    <div class="banquet_time_toggle">
        <div class="time_toggle" [ngClass]="{'active': BanquetTimeId == 1}" (click)="ToggleBanquetTime(1)">День</div>
        <div class="time_toggle" [ngClass]="{'active': BanquetTimeId == 2}" (click)="ToggleBanquetTime(2)">Вечер</div>
    </div>


    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="tables-title">Столы — <span
                *ngIf="SelectedDropdownElement != null">{{SelectedDropdownElement.content}}</span></h3>
        <div class="quick-tables-controls" fxLayout="row" fxLayoutGap="16px">
            <div class="quick-tables-controls-prev" (click)="PrevArea()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-left"></use>
                </svg>
            </div>
            <div class="quick-tables-controls-next" (click)="NextArea()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                    <use xlink:href="#right-arrow"></use>
                </svg>
            </div>
        </div>
    </div>



    <div class="tables-wrapper" fxLayout="row wrap" fxLayoutGap="24px" fxLayoutGap.lt-md="12px">


        <div class="table-item" (click)="SelectTable(table)" [ngClass]="{'free': table.status == 'free', 
                        'busy': table.status == 'busy', 
                        'busy-without-payment': table.status == 'busy_without_payment', 
                        'reserved': table.status == 'reserved', 
                        'reserved-without-payment': table.status == 'reserved_without_payment', 
                        'selected': table.status == 'selected', 
                        'selected-other-user': table.status == 'selected_other_user', 
                        'selected-busy': table.status == 'select_busy',
                        'disabled': table.is_showed == false}" *ngFor="let table of BanquetsService.ReservedTables">

            <div class="table-item-guests" *ngIf="table.guests > 0">
                {{table.guests}}
                |
                {{table.children}}
            </div>
            {{table.name}}

        </div>


    </div>

    <h3 class="tables-title" *ngIf="BanquetsService.ReservedGroupTables.length > 0">Группы столов</h3>

    <div class="tables-wrapper" fxLayout="row wrap" fxLayoutGap="12px">
        <div class="table-item" (click)="SelectGroupTable(table)" [ngClass]="{'free': table.status == 'free', 
                'busy': table.status == 'busy', 
                'busy-without-payment': table.status == 'busy_without_payment', 
                'reserved': table.status == 'reserved', 
                'reserved-without-payment': table.status == 'reserved_without_payment', 
                'selected': table.status == 'selected', 
                'selected-other-user': table.status == 'selected_other_user', 
                'selected-busy': table.status == 'select_busy'}"
            *ngFor="let table of BanquetsService.ReservedGroupTables">
            {{table.name}}
        </div>
    </div>

</div>