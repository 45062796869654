import { Injectable } from '@angular/core';
import { OnModalCancelEvent, OnModalConfirmEvent } from 'src/app/models/tools/events.model';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalField } from 'src/app/models/tools/fields/modal-field.modal';

@Injectable({
  providedIn: 'root'
})
export class ModalAddingService {


  private isOpened: boolean = false;
  public get IsOpened(): boolean {
    return this.isOpened;
  }

  private modalTitle: string = "";
  public get ModalTitle(): string {
    return this.modalTitle;
  }

  private modalText: string = "";
  public get ModalText(): string {
    return this.modalText;
  }

  private confirmText: string = "";
  public get ConfirmText(): string {
    return this.confirmText;
  }

  private cancelText: string = "";
  public get CancelText(): string {
    return this.cancelText;
  }

  private fields: Array<ModalField> = new Array<ModalField>();
  public get Fields(): Array<ModalField> {
    return this.fields;
  }



  private onConfirmEvents: Array<OnModalConfirmEvent> = new Array<OnModalConfirmEvent>();
  private onCancelEvents: Array<OnModalCancelEvent> = new Array<OnModalCancelEvent>();

  public Answer: any;
  public IsRequired: boolean = false;





  constructor() {

  }

  Show(data: ModalData, fields: Array<ModalField>) {
    this.isOpened = true;
    this.cancelText = data.Cancel;
    this.confirmText = data.Confirmation;
    this.modalTitle = data.Title;
    this.modalText = data.Text;

    this.fields = fields;

  }

  AddConfirmEvent(event: OnModalConfirmEvent) {
    this.onConfirmEvents.push(event);
  }

  AddCancelEvent(event: OnModalCancelEvent) {
    this.onCancelEvents.push(event);
  }

  Reset() {
    this.onConfirmEvents = new Array<OnModalConfirmEvent>();
    this.onCancelEvents = new Array<OnModalCancelEvent>();
    this.isOpened = false;
    this.IsRequired = false;
  }

  OnConfirm() {
    if (this.IsRequired) {
      this.onConfirmEvents.forEach(event => {
        event(this.Fields);
      });
      this.Reset();
    } else {

    }
    
  }

  OnCancel() {
    this.onCancelEvents.forEach(event => {
      event(this.Fields);
    });
    this.Reset();
  }

  CheckRequired() {
    let bufferRequired = true;
    this.Fields.forEach(field => {
      if (bufferRequired) {
        field.CheckInvalid()
        if (field.IsInvalid) {
          bufferRequired = false;
        }
      }
      
    });

    this.IsRequired = bufferRequired;
  }

}
