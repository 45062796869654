import { Component, OnInit } from '@angular/core';
import { DeliveryService } from '../../../services/delivery.service';

@Component({
  selector: 'app-courier-started-list',
  templateUrl: './courier-started-list.component.html',
  styleUrls: ['./courier-started-list.component.scss']
})
export class CourierStartedListComponent implements OnInit {

  constructor(public DeliveryService: DeliveryService) { }

  ngOnInit(): void {
  }

}
