import { Component, OnInit } from '@angular/core';
import { ReviewService } from '../../../services/review.service';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '../../../services/confirmation.service';
import { PropertyService } from '../../../services/property.service';
import { OrdersService } from '../../../services/order/orders.service';
import { Review } from '../../../models/tools/review.model';
import { System } from '../../../models/tools/system.model';

@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrls: ['./reviews-list.component.scss']
})
export class ReviewsListComponent implements OnInit {

  constructor(
    public HeaderService: HeaderService,
    private route: ActivatedRoute, private router: Router,
    public ConfirmationService: ConfirmationService,
    public PropertyService: PropertyService,
    public OrdersService: OrdersService,
    public ReviewService: ReviewService) { }

  ngOnInit(): void {

    this.HeaderService.ClearAll();

    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Список отзывов", "", true);

    this.HeaderService.WindowName = "Список отзывов";

    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
      //this.HeaderService.UpdateBadgeCount( "noty", 0 );
    });

    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);

    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });

  }

  OpenReview(event, review) {
    if (event.srcElement.localName != "a") {
      this.router.navigate([`/reviews-open/${review.id}`]);
    }
  }

}
