import { FieldType } from "./field-types.enum";
import { InvalidFieldValue } from "./invalid-field-value.modal";
import { NumberInvalidFieldValue } from "./InvalidFields/number-invalid-filed-value.model";

export class ModalField {
    Type: FieldType = FieldType.Text;
    Title: string = "";
    Min: number = 0;
    Max: number = 100;
    Values: Array<any> = new Array<any>();

    Name: string = "";
    DefaultValues: Array<any> = new Array<any>();

    public ValueId: number;

    private value: any;
    public get Value(): any {
        return this.value;
    }
    public set Value(value: any) {
        this.value = value;
        this.CheckInvalid();
    }

    public AdditionalValue: any;

    OnChangeValue: any;

    InvalidValues: Array<InvalidFieldValue> = Array<InvalidFieldValue>();

    InvalidText: string = "";

    IsInvalid: boolean = false;

    IsShowed: boolean = true;
    Required: boolean = false;

    public CheckInvalid() {
        this.InvalidText = "";
        this.IsInvalid = false;
        this.InvalidValues.forEach(
            (invalidValue: InvalidFieldValue) => {
                if (invalidValue.GetValue() == this.Value) {
                    this.InvalidText = invalidValue.Error;
                    this.IsInvalid = true;

                }
            }
        );
        if (this.Required && (this.Value == "" || this.Value ==  null)) {
            this.InvalidText = "Значение должно быть задано";
            this.IsInvalid = true;
        }
    }



}