import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { ConfirmationService } from '../../../../services/confirmation.service';;
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { BanquetPaymentsService } from '../../../../services/banquet/banquet-payments.service';
import { BanquetCommentService } from '../../../../services/banquet/banquet-comment.service'
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { HttpClient } from '@angular/common/http';
import { BanquetComment } from 'src/app/models/banquet/banquet-comment';

@Component({
  selector: 'app-banquet-comment-reading',
  templateUrl: './banquet-comment-reading.component.html',
  styleUrls: ['./banquet-comment-reading.component.scss']
})
export class BanquetCommentReadingComponent implements OnInit {

  public CommentTypes: Array<any> = [
    { content: "Комментарий", id: "1", selected: true },
    { content: "Заметка", id: "2", selected: false },
  ];

  constructor(public BanquetsService: BanquetsService, public ModalAddingService: ModalAddingService,
    private http: HttpClient, public BanquetPaymentsService: BanquetPaymentsService,
    public ConfirmationService: ConfirmationService, public BanquetCommentService: BanquetCommentService) {

  }

  ngOnInit(): void {

  }

  OpenAddCommentModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetsService.AddComment(
        this.ModalAddingService.Fields[1].Value,
        this.ModalAddingService.Fields[0].ValueId
      );
    });

    this.ModalAddingService.Show(
      new ModalData("Добавить заметку", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildDropdown("Тип примечания", this.BanquetCommentService.CommentTypes),
        ModalFieldBuilder.BuildText("Комментарий", 0, 100000),
      ]
    );
  }


  OpenEditCommentModal(comment: BanquetComment) {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {

      comment.Text = this.ModalAddingService.Fields[1].Value;
      comment.CommentTypeId = this.ModalAddingService.Fields[0].ValueId;
      comment.Type = this.ModalAddingService.Fields[0].Value;

      this.BanquetsService.EditComment(comment);
    });


    this.ModalAddingService.Show(
      new ModalData("Редактировать заметку", "", "Сохранить", "Отмена"),
      [
        ModalFieldBuilder.BuildDropdown("Тип примечания", this.BanquetCommentService.CommentTypes, comment.Type),
        ModalFieldBuilder.BuildText("Комментарий", 0, 100000, comment.Text),
      ]
    );
  }

  OpenModalRemoveComment(id: number) {
    this.ConfirmationService.Reset();
    this.ConfirmationService.OpenModal("Удалить заметку?", "Вы уверены, что хотите удалить заметку?", "", "Нет", "Удалить");
    this.ConfirmationService.AddConfirmEvent((answer: string) => {
      this.BanquetsService.DeleteComment(id);
    });
  }



}
