import { Injectable } from '@angular/core';
import { Identifiable } from '../models/tools/identifiable.model';


export class Vendor {
  public LastId: number = -1;
  public FirstId: number = -1;

  public Last: any = null;
  public First: any = null;

  public Values: Array<Identifiable> = new Array<Identifiable>();

  public PushToEnd(object: Identifiable) {
    this.LastId = object.Id;
    this.Last = object;
    this.Values.push(object);
  }

  public PushToStart(object: Identifiable) {
    this.FirstId = object.Id;
    this.First = object;
    this.Values.unshift(object);
  }

  Clear() {
    this.Values = new Array<Identifiable>();
  }

  public Get(id: number) {
    return this.Values.find((object: Identifiable) => {
      return object.Id == id;
    });
  }
}
