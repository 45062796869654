import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourierActiveGroupViewListComponent } from './components/courier-components/courier-active-group-view-list/courier-active-group-view-list.component';
import { SmartReviewsComponent } from './pages/admin/smart-reviews/smart-reviews.component';
import { BanquetAddingFormComponent } from './pages/banquet/banquet-adding-form/banquet-adding-form.component';
import { BanquetFormComponent } from './pages/banquet/banquet-form/banquet-form.component';
import { BanquetFreeTablesComponent } from './pages/banquet/banquet-free-tables/banquet-free-tables.component';
import { BanquetKeeperListComponent } from './pages/banquet/banquet-keeper-list/banquet-keeper-list.component';
import { BanquetListComponent } from './pages/banquet/banquet-list/banquet-list.component';
import { BanquetMenuComponent } from './pages/banquet/banquet-menu/banquet-menu.component';
import { BanquetSliderKitchenComponent } from './pages/banquet/banquet-slider-kitchen/banquet-slider-kitchen.component';
import { BanquetTransactionListComponent } from './pages/banquet/banquet-transaction-list/banquet-transaction-list.component';
import { BanquetVaultComponent } from './pages/banquet/banquet-vault/banquet-vault.component';
import { CourierDeliveryFormComponent } from './pages/courier/courier-delivery-form/courier-delivery-form.component';
import { CourierFormComponent } from './pages/courier/courier-form/courier-form.component';
import { CourierListComponent } from './pages/courier/courier-list/courier-list.component';
import { WhatsNewComponent } from './pages/information/whats-new/whats-new.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OchagboxManagementComponent } from './pages/ochagbox/ochagbox-management/ochagbox-management.component';
import { OchagboxMenuComponent } from './pages/ochagbox/ochagbox-menu/ochagbox-menu.component';
import { OchagboxReceivedComponent } from './pages/ochagbox/ochagbox-received/ochagbox-received.component';
import { OrderFormComponent } from './pages/order/order-form/order-form.component';
import { OrderFormationCourierComponent } from './pages/order/order-formation-courier/order-formation-courier.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderNewComponent } from './pages/order/order-new/order-new.component';
import { ReviewsFormComponent } from './pages/reviews/reviews-form/reviews-form.component';
import { ReviewsListComponent } from './pages/reviews/reviews-list/reviews-list.component';
import { SettingsComponent } from './pages/settings/settings.component';

const routes: Routes = [


  { path: '', component: MenuComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'deliveries', component: OrderListComponent },
  { path: 'delivery/:id', component: OrderFormComponent },
  { path: 'delivery-courier', component: OrderFormationCourierComponent },
  { path: 'delivery-new', component: OrderNewComponent },
  { path: 'delivery-courier-view', component: CourierActiveGroupViewListComponent },


  { path: 'banquet-menu', component: BanquetMenuComponent },
  { path: 'banquet-adding', component: BanquetAddingFormComponent },
  { path: 'banquets', component: BanquetListComponent },
  { path: 'banquet/:id', component: BanquetFormComponent },

  { path: 'banquet-transactions', component: BanquetTransactionListComponent },

  { path: 'banquet-summary-vault', component: BanquetVaultComponent },
  { path: 'banquet-free-tables', component: BanquetFreeTablesComponent },

  { path: 'banquet-slider-kitchen', component: BanquetSliderKitchenComponent },
  { path: 'banquet-keeper', component: BanquetKeeperListComponent },


  { path: 'ochagbox-menu', component: OchagboxMenuComponent },
  { path: 'ochagbox-recived', component: OchagboxReceivedComponent },
  { path: 'ochagbox-management', component: OchagboxManagementComponent },


  { path: 'login', component: LoginComponent },


  { path: 'reviews-list', component: ReviewsListComponent },
  { path: 'reviews-open/:id', component: ReviewsFormComponent },


  { path: 'courier-list', component: CourierListComponent },

  { path: 'courier-open/:id', component: CourierFormComponent },
  { path: 'courier-open-order/:id/:order', component: CourierDeliveryFormComponent },//CourierOrderOpenComponent },


  { path: 'settings', component: SettingsComponent },
  { path: 'whats-news/:id', component: WhatsNewComponent },
  { path: 'smart-reviews', component: SmartReviewsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
