<div class="smart_reviews_wrapper" fxLayout="row" fxLayoutGap="40px">
    <div class="filter">
        <app-tabs>
            <app-tab tabTitle="Данные">
                <google-chart type="PieChart" [width]="250" [data]="myData"></google-chart>
            </app-tab>
            <app-tab tabTitle="Фильтр">
                <div class="filter_standart_layout">
                    <fieldset class="bx--fieldset">
                        <div class="filter-date-picker-container">
                            <ibm-date-picker dateFormat="d.m.Y" label="Дата транзакции" language="ru"
                                (valueChange)="OnChangeFilterStartDate($event)" [(ngModel)]="ReviewStartDateVisual">
                            </ibm-date-picker>
                        </div>
                        <div class="filter-date-picker-container">
                            <ibm-date-picker dateFormat="d.m.Y" label="Конец " language="ru"
                                (valueChange)="OnChangeFilterEndDate($event)" [(ngModel)]="ReviewEndDateVisual">
                            </ibm-date-picker>
                        </div>
                    </fieldset>
                </div>
            </app-tab>
        </app-tabs>
    </div>

    <div class="content">

        <div class="list_wrapper">
            <div>
                <div class="reviews_list" fxLayout="column" fxLayoutGap="32px">
                    <div class="review_item" *ngFor="let review of VizualizeReviews; index as i">
                        <div class="review_item_date" *ngIf="review.answers.length > 0">{{review.DateAdded |
                            date:'dd.MM.yy'}}</div>
                        <div class="review_item_phone" *ngIf="review.answers.length > 0">{{review.answers[0].phone}}
                        </div>
                        <div class="question_item" *ngFor="let answer of review.answers">
                            <div class="question_text">{{answer.question_description}}</div>
                            <div class="answer_text"
                                [ngClass]="{'green': answer.answer_id == 1, 'yellow': answer.answer_id == 2, 'orange': answer.answer_id == 3, 'red': answer.answer_id == 4}">
                                {{answer.answer_title}}
                            </div>
                        </div>
                        <div class="answers_index">{{i+1}}</div>
                    </div>
                </div>
            </div>
            <!-- <button *ngIf="!IsFiltered" class="load_more_button" (click)="LoadMore()">Еще</button> -->
        </div>
    </div>
</div>

<div class="shadow_haze"
    [ngClass]="{'showed_filter': IsFilterOpened || IsLoading, 'show_only': PropertyService.UserGroupId != 1}"
    fxLayout="row" fxLayoutAlign="center center" (click)="IsFilterOpened = false">
    <ibm-loading [isActive]="IsLoading"></ibm-loading>
</div>