<div>

    <div class="dete_time_picker" fxLayout="column" fxLayoutGap="12px">
        <ibm-date-picker dateFormat="d.m.Y" language="ru" label="Дата доставки" theme="light" (valueChange)="OnChangeShippingDate($event)" [(ngModel)]="ShippingPresentDate">
        </ibm-date-picker>

        <div class="tags_wrapper" fxLayout="row wrap" fxLayoutGap="8px">
            <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 2}" (click)="SetDeliveryTime(2)">По готовности</span>
        </div>

        <div class="other_time_tag">
            <span class="time_chips" [ngClass]="{'active': ActiveDeliveryTimeMode == 6}" (click)="SetDeliveryTime(6)">Выбрать конкретное время</span>
        </div>






        <div class="time_title">Время готовности заказа</div>

        <div class="time_picker" fxLayout="row" *ngIf="ActiveDeliveryTimeMode != 6">
            <p>{{delivery.OrderReadyHour | twotime}}:{{delivery.OrderReadyMinutes | twotime}}</p>
        </div>

        <div class="time_picker" fxLayout="row" fxLayoutGap="8px" *ngIf="ActiveDeliveryTimeMode == 6">
            <ibm-number label="Часы" (change)="OnChangeOrderReadyHours($event)" [(ngModel)]="delivery.OrderReadyHour" theme="light">
            </ibm-number>
            <ibm-number label="Минуты" (change)="OnChangeOrderReadyMinutes($event)" [(ngModel)]="delivery.OrderReadyMinutes" theme="light">
            </ibm-number>
        </div>






        <div class="time_title">Время доставки</div>

        <div class="time_picker" fxLayout="row" *ngIf="ActiveDeliveryTimeMode != 6">
            <p>{{delivery.ShippingHour | twotime}}:{{delivery.ShippingMinutes | twotime}}</p>
        </div>

        <div class="time_picker" fxLayout="row" fxLayoutGap="8px" *ngIf="ActiveDeliveryTimeMode == 6">
            <ibm-number label="Часы доставки" (change)="OnChangeHours($event)" [(ngModel)]="delivery.ShippingHour" theme="light">
            </ibm-number>
            <ibm-number label="Минуты доставки" (change)="OnChangeMinutes($event)" [(ngModel)]="delivery.ShippingMinutes" theme="light">
            </ibm-number>
        </div>



    </div>

</div>