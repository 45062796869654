import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';

@Component({
  selector: 'app-smart-reviews',
  templateUrl: './smart-reviews.component.html',
  styleUrls: ['./smart-reviews.component.scss']
})
export class SmartReviewsComponent implements OnInit {

  public Reviews: Array<any> = new Array<any>();
  public VizualizeReviews: Array<any> = new Array<any>();
  public IsLoading: boolean = true;
  public IsFilterOpened: boolean = false;



  public ReviewStartDate: Date = new Date();
  public ReviewEndDate: Date = new Date();

  public ReviewStartDateVisual: Date = new Date();
  public ReviewEndDateVisual: Date = new Date();

  myData = [
    ['London', 8136000],
    ['New York', 8538000],
    ['Paris', 2244000],
    ['Berlin', 3470000],
    ['Kairo', 19500000],
  ];


  constructor(public PropertyService: PropertyService, private route: ActivatedRoute,
    private router: Router, public HeaderService: HeaderService, private http: HttpClient,) {

  }



  ngOnInit(): void {
    this.IsLoading = false;

    this.HeaderService.WindowName = "Отзывы из приложения";
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";


    this.ReviewStartDate.setDate(1);
    this.ReviewEndDate.setDate(1);
    this.ReviewEndDate.setMonth(this.ReviewStartDate.getMonth() + 1);



    this.ReviewStartDateVisual.setDate(1);
    this.ReviewEndDateVisual.setDate(1);
    this.ReviewEndDateVisual.setMonth(this.ReviewStartDate.getMonth() + 1);




    var request = `https://ochagzp.com.ua/index.php?route=api/smart-review&token=${this.PropertyService.Token}`;

    this.http.get(request).subscribe(
      (data: any) => {
        data.forEach(answers => {
          if (answers.length > 0) {
            this.Reviews.push(
              {
                answers: answers,
                DateAdded: new Date(answers[0]["added_at"])
              }
            );
          }
        });

        this.UpdateVisibleReviews();
      });

  }

  OnChangeFilterStartDate(event) {
    this.ReviewStartDate = event[0];
    this.UpdateVisibleReviews();
  }

  OnChangeFilterEndDate(event) {
    this.ReviewEndDate = event[0];
    this.UpdateVisibleReviews();
  }

  UpdateVisibleReviews() {
    this.VizualizeReviews = new Array<any>();
    this.Reviews.forEach(review => {
      if (review.answers.length > 0) {
        let reviewDate = new Date(review.answers[0].added_at);
        if (reviewDate.getTime() >= this.ReviewStartDate.getTime() &&
          reviewDate.getTime() <= this.ReviewEndDate.getTime()) {
          this.VizualizeReviews.push(review);
        }
      }
    });
  }

}
