import { Component, OnInit, Input } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { OrdersService } from '../../../services/order/orders.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-courier-ungroup-list',
  templateUrl: './courier-ungroup-list.component.html',
  styleUrls: ['./courier-ungroup-list.component.scss']
})
export class CourierUngroupListComponent implements OnInit {



  @Input("selected") selectedIds: Array<any> = new Array<any>();

  constructor(public HeaderService: HeaderService,
    public OrdersService: OrdersService,
    private route: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {

  }

  selected(event) {
    if (event.selected) {
      this.selectedIds.push(event.value);
    } else {

      let findedElementIndex = this.selectedIds.findIndex(element => element == event.value);
      this.selectedIds.splice(findedElementIndex, 1);
    }
  }

  OnGroupClick() {
    this.OrdersService.AddShipToGroup(this.selectedIds);
    this.selectedIds = new Array<any>();
  }

}
