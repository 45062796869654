import { Identifiable } from '../tools/identifiable.model';

export class BanquetCustomer extends Identifiable {

    private firstName: string;
    public get FirstName(): string {
        return this.firstName;
    }
    public set FirstName(firstName: string) {
        this.firstName = firstName;
    }


    private lastName: string;
    public get LastName(): string {
        return this.lastName;
    }
    public set LastName(lastName: string) {
        this.lastName = lastName;
    }


    private email: string;
    public get Email(): string {
        return this.email;
    }
    public set Email(email: string) {
        this.email = email;
    }


    private phone: string;
    public get Phone(): string {
        return this.phone;
    }
    public set Phone(phone: string) {
        this.phone = phone;
    }


}