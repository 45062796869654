import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service'

@Component({
  selector: 'app-banquet-quick-access-panel',
  templateUrl: './banquet-quick-access-panel.component.html',
  styleUrls: ['./banquet-quick-access-panel.component.scss']
})
export class BanquetQuickAccessPanelComponent implements OnInit {

  constructor(public BanquetsService: BanquetsService,
    private router: Router,
    public ModalAddingService: ModalAddingService,) { }

  ngOnInit(): void {

  }

  OpenBanquet(id) {
    this.router.navigate(['/banquet/' + id]);
  }



}
