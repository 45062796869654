<div class="whats-news" [routerLink]="['/whats-news/3-11']">

    <div class="whats-news-top">
        <div class="whats-news-title">Новая версия</div>
        <div class="whats-news-date">02.12.2021</div>
    </div>
    <div class="whats-news-description">Просмотреть, что нового в версии 3.11</div>
</div>

<div class="menu_wrapper" [ngClass]="{'showed_filter': IsFilterOpened}" fxLayout="column">
    <h2>Основное меню </h2>

    <div class="menu_items_wrapper" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="32px">



        <ibm-tile [routerLink]="['/deliveries']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 13 || PropertyService.UserGroupId == 1 || PropertyService.UserGroupId == 15">
            <div class="menu_item_name">
                Заказы
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>

        <ibm-tile [routerLink]="['/delivery-courier-view']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 1 || PropertyService.UserGroupId == 15">
            <div class="menu_item_name">
                Активные развозы
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>

        <ibm-tile [routerLink]="['/delivery-courier']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 13">
            <div class="menu_item_name">
                Формирование доставки
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/banquet-menu']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Банкет
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>





        <ibm-tile [routerLink]="['/courier-list']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 12">
            <div class="menu_item_name">
                Курьерская доставка
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/reviews-list']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 13 || PropertyService.UserGroupId == 14 || PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Предложения и неисправности
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>



        <ibm-tile [routerLink]="['/settings']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Параметры системы
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>


        <ibm-tile [routerLink]="['/ochagbox-menu']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Очаг Box
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>

        <ibm-tile [routerLink]="['/smart-reviews']" class="menu_item" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="PropertyService.UserGroupId == 1">
            <div class="menu_item_name">
                Отзывы из приложения
            </div>
            <div class="menu_item_icon" fxLayout="column" fxLayoutAlign="end center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                    <use xlink:href="#arrow-right-up"></use>
                </svg>
            </div>
        </ibm-tile>





    </div>
</div>