import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyService } from '../property.service';
import { HeaderService } from '../header.service';
import { NotificationService } from '../notification.service';
import { BanquetPaymentHistoryBuilder } from '../../models/banquet/banquet-payment-history-builder';
import { Observable } from 'rxjs';
import { BanquetFilter } from '../../models/banquet/banquet-filter';
import { BanquetPaymentHistory } from '../../models/banquet/banquet-payment-history';
import { BanquetsService } from './banquets.service';
import { BaseService } from '../base.service';
import { Vendor } from '../vendor.service';
import { Banquet } from 'src/app/models/banquet/banquet';
import { ModalAddingService } from './modal/modal-adding.service';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

@Injectable({
  providedIn: 'root'
})
export class BanquetPaymentsService extends BaseService {

  public ActivePayment: BanquetPaymentHistory;

  public IsFilterAfterUpload: boolean = false;

  public OnLoadPaymentEvents: Array<any> = new Array<any>();
  public DateFilteredObjects: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();

  public IsOpenedAddingModal: boolean = false;
  public IsOpenedRemovingModal: boolean = false;
  public IsOpenedConfirmingModal: boolean = false;
  public IsOpenedUnconfirmingModal: boolean = false;
  public IsOpenedManyConfirmingModal: boolean = false;
  public IsOpenedFilterConfirmingModal: boolean = false;


  public AddedPaymentsSourceId: number = 1;
  public AddedPaymentsTypeId: number = 1;
  public AddedValue: number = 0;

  public FilterData: BanquetFilter = new BanquetFilter;


  private allFilteredPaymentValue: number = 0;

  public get AllFilteredPaymentValue(): number {
    return this.allFilteredPaymentValue;
  }


  private allFilteredDepositPaymentValue: number = 0;

  public get AllFilteredDepositPaymentValue(): number {
    return this.allFilteredDepositPaymentValue;
  }


  private allFilteredProgramPaymentValue: number = 0;

  public get AllFilteredProgramPaymentValue(): number {
    return this.allFilteredProgramPaymentValue;
  }



  private unsignedPaymentValue: number = 0;
  private unsignedIncomePaymentValue: number = 0;
  private unsignedCostPaymentValue: number = 0;

  public get UnsignedPaymentValue(): number {
    return this.unsignedPaymentValue;
  }
  public get UnsignedIncomePaymentValue(): number {
    return this.unsignedIncomePaymentValue;
  }
  public get UnsignedCostPaymentValue(): number {
    return this.unsignedCostPaymentValue;
  }



  private unsignedFilteredPaymentValue: number = 0;
  private unsignedFilteredIncomePaymentValue: number = 0;
  private unsignedFilteredCostPaymentValue: number = 0;

  private signedFilteredIncomePaymentValue: number = 0;
  private signedFilteredCostPaymentValue: number = 0;

  private filteredIncomePaymentValue: number = 0;
  private filteredCostPaymentValue: number = 0;

  private countOfGuestsByPaymants: number = 0;
  private countOfChildsByPayments: number = 0;

  public get UnsignedFilteredPaymentValue(): number {
    return this.unsignedFilteredPaymentValue;
  }
  public get UnsignedFilteredIncomePaymentValue(): number {
    return this.unsignedFilteredIncomePaymentValue;
  }
  public get UnsignedFilteredCostPaymentValue(): number {
    return this.unsignedFilteredCostPaymentValue;
  }


  public get SignedFilteredIncomePaymentValue(): number {
    return this.signedFilteredIncomePaymentValue;
  }
  public get SignedFilteredCostPaymentValue(): number {
    return this.signedFilteredCostPaymentValue;
  }


  public get FilteredIncomePaymentValue(): number {
    return this.filteredIncomePaymentValue;
  }
  public get FilteredCostPaymentValue(): number {
    return this.filteredCostPaymentValue;
  }


  public get CountOfGuestsByPaymants(): number {
    return this.countOfGuestsByPaymants;
  }
  public get CountOfChildsByPayments(): number {
    return this.countOfChildsByPayments;
  }






  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router, public BanquetsService: BanquetsService, public ModalService: ModalAddingService) {
    super();
  }




  public RecalculateFilteredGuests(Objects) {
    this.countOfGuestsByPaymants = 0;
    this.countOfChildsByPayments = 0;

    let banquets: Array<Banquet> = new Array<Banquet>();

    if (Objects != null) {
      Objects.forEach((value: BanquetPaymentHistory) => {
        if (value.Banquet.PaymentStatus != "canceled") {
          banquets[value.Banquet.Id] = value.Banquet;
        }
      });

      banquets.forEach(banquet => {
        this.countOfGuestsByPaymants += Number(banquet.Guests);
        this.countOfChildsByPayments += Number(banquet.Children);
      });
    }

  }

  public RecalculateFilteredUnsigned(Objects) {
    this.unsignedFilteredPaymentValue = 0;
    this.unsignedFilteredIncomePaymentValue = 0;
    this.unsignedFilteredCostPaymentValue = 0;
    this.allFilteredPaymentValue = 0;
    this.allFilteredDepositPaymentValue = 0;
    this.allFilteredProgramPaymentValue = 0;
    this.signedFilteredIncomePaymentValue = 0;
    this.signedFilteredCostPaymentValue = 0;
    this.filteredIncomePaymentValue = 0;
    this.filteredCostPaymentValue = 0;

    if (Objects != null) {
      Objects.forEach((value: BanquetPaymentHistory) => {
        if (value.IsIncome) {
          this.allFilteredPaymentValue += Number(value.Value);
          this.filteredIncomePaymentValue += Number(value.Value);
          if (value.Stream == 1) {
            this.allFilteredDepositPaymentValue += Number(value.Value);
          }
          if (value.Stream == 2) {
            this.allFilteredProgramPaymentValue += Number(value.Value);
          }
          if (value.Confirmed == false) {
            this.unsignedFilteredPaymentValue += Number(value.Value);
            this.unsignedFilteredIncomePaymentValue += Number(value.Value);
          }
          if (value.Confirmed) {
            this.signedFilteredIncomePaymentValue += Number(value.Value);
          }
        } else {
          this.allFilteredPaymentValue -= Number(value.Value);
          this.filteredCostPaymentValue += Number(value.Value);
          if (value.Stream == 1) {
            this.allFilteredDepositPaymentValue -= Number(value.Value);
          }
          if (value.Stream == 2) {
            this.allFilteredProgramPaymentValue -= Number(value.Value);
          }
          if (value.Confirmed == false) {
            this.unsignedFilteredPaymentValue -= Number(value.Value);
            this.unsignedFilteredCostPaymentValue += Number(value.Value);
          }
          if (value.Confirmed) {
            this.signedFilteredCostPaymentValue += Number(value.Value);
          }
        }
      });
    }
  }




  public RecalculateUnsigned() {
    this.unsignedPaymentValue = 0;
    this.unsignedIncomePaymentValue = 0;
    this.unsignedCostPaymentValue = 0;
    if (this.Objects.Values != null) {
      this.Objects.Values.forEach((value: any) => {
        if (value.Confirmed == false) {
          if (value.IsIncome) {
            this.unsignedPaymentValue += Number(value.Value);
            this.unsignedIncomePaymentValue += Number(value.Value);
          } else {
            this.unsignedPaymentValue -= Number(value.Value);
            this.unsignedCostPaymentValue += Number(value.Value);
          }
        }
      });
    }
  }












  public LoadPayments() {
    this.IsLoading = true;

    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/getBy&token=${this.property.Token}&page=${this.Page}`;

    let data = {
      "name": "banquet_payment_history",
      "alias": "bph",

      "select": ["banquet_payment_history_id", "bph.date_current_banquet", "bph.banquet_payment_type_id", "banquet_payment_source_id", "banquet_payment_stream_id", "banquet_id", "card_date_text", "value", "user_id", "confirmed", "confirmed_transfer", "bph.date_added", "bph.date_modified"],
      "orderBy": {
        "name": "date_added",
        "type": "DESC"
      },
      "expands": [
        {
          "name": "banquet_payment_type",
          "alias": "bpt",
          "on": "bph.banquet_payment_type_id = bpt.banquet_payment_type_id"
        },
        {
          "name": "type",
          "alias": "bpt",
          "on": "banquet_payment_type_id",
          "on_to": "banquet_payment_type_id"
        },
        {
          "name": "banquet",
          "alias": "be",
          "on": "banquet_id",
          "on_to": "event_id"
        },
        {
          "name": "user",
          "alias": "usr",
          "on": "user_id",
          "on_to": "user_id"
        }
      ],
      // "filter": {
      //   "banquet_payment_source_id": {
      //     "value": 3,
      //     "operation": "="
      //   },
      //   "date_added": {
      //     "before": "2021-04-10",
      //     "after": "2021-04-10"
      //   },
      // }
    }



    this.http.post(request, data).subscribe(
      (data: any) => {
        let builder: BanquetPaymentHistoryBuilder = new BanquetPaymentHistoryBuilder();

        if (data["error"] != null) {

        } else {
          this.CustomersAutocomplete = new Array<any>();
          data["body"]["data"]["objects"]["data"].forEach(element => {
            let buildingBanquet: BanquetPaymentHistory = builder.Build(element);
            buildingBanquet.Banquet = this.BanquetsService.GetBanquetById(buildingBanquet.BanquetId);

            if (buildingBanquet.Banquet != null) {
              if (!(buildingBanquet.Banquet.Date.getMonth() == buildingBanquet.DateCurrentBanquet.getMonth() && buildingBanquet.Banquet.Date.getDate() == buildingBanquet.DateCurrentBanquet.getDate() && buildingBanquet.Banquet.Date.getFullYear() == buildingBanquet.DateCurrentBanquet.getFullYear())) {
                buildingBanquet.IsTransfered = true;
              }
            }



            if (buildingBanquet.Value > 0) {
              this.Objects.PushToEnd(buildingBanquet);
              if (buildingBanquet.Banquet != null) {
                this.CustomersAutocomplete.push(
                  {
                    id: buildingBanquet.Banquet.Id,
                    name: buildingBanquet.Banquet.ContactCustomer.LastName + " " + buildingBanquet.Banquet.ContactCustomer.FirstName,
                  });
              }

            }

          });



          this.IsLoaded = true;
          this.IsLoading = false;
          this.Page++;
          this.LoadMorePayments();
          this.OnLoadPaymentEvents.forEach(event => {
            event();
          });

          this.RecalculateUnsigned();
        }
      }
    );
  }


  public LoadMorePayments() {
    if (this.IsLoading) {
      return;
    }

    if (this.Page === this.PageOfVisible / 10) {
      this.LoadPayments();
      return;
    }

    for (let index = this.PageOfVisible * 10; index < this.PageOfVisible * 10 + 10; index++) {
      if (this.Objects.Values[index] != null) {
        this.VisibleObjects.PushToEnd(this.Objects.Values[index]);
      }
    }
    this.PageOfVisible++;
    this.RecalculateUnsigned();
  }



  OpenAddPaymentModal() {
    this.IsOpenedAddingModal = true;
  }

  CloseAddPaymentModal() {
    this.IsOpenedAddingModal = false;
  }

  public AddPayment(value: any, addedPaymentsTypeId: any, addedPaymentsSourceId: any, addedPaymentsStartTerminalDateText: any, addedPaymentsEndTerminalDateText: any, addedPaymentsCardDateText: any, addedPaymentsStream: any) {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/addPayment&token=${this.property.Token}`;

    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateCurrentBanquet;
    dateCurrentBanquet = this.BanquetsService.ActiveBanquet.Date.getFullYear() + '-' +
      pad(this.BanquetsService.ActiveBanquet.Date.getMonth() + 1) + '-' +
      pad(this.BanquetsService.ActiveBanquet.Date.getDate()) + ' ' +
      '00:00:00';

    let paymentData =
    {
      data: {
        "value": value,
        "banquet_payment_type_id": addedPaymentsTypeId,
        "banquet_payment_source_id": addedPaymentsSourceId,
        "banquet_payment_stream_id": addedPaymentsStream,
        "date_current_banquet": dateCurrentBanquet,
        "banquet_id": this.BanquetsService.ActiveBanquet.Id,
        "user_id": this.property.UserId,
        "card_date_text": addedPaymentsCardDateText,
        "card_start_date_text": addedPaymentsStartTerminalDateText,
        "card_end_date_text": addedPaymentsEndTerminalDateText,
      }
    }


    this.http.post(request, paymentData).subscribe(
      (data: any) => {
        let banquetPayments = data["body"]["data"]["objects"]["data"];

        let payment = new BanquetPaymentHistory(banquetPayments["banquet_payment_history_id"]);
        payment.Name = banquetPayments["name"];
        payment.Value = banquetPayments["value"];
        payment.DateAdded = new Date(banquetPayments["date_added"].replace(/-/g, "/"));
        payment.Confirmed = banquetPayments["confirmed"] == 1 ? true : false;
        payment.ConfirmedTransfer = banquetPayments["confirmed_transfer"] == 1 ? true : false;
        payment.IsIncome = banquetPayments["is_income"] == 1 ? true : false;
        payment.BanquetContact = this.BanquetsService.ActiveBanquet.ContactCustomer.FirstName + " " + this.BanquetsService.ActiveBanquet.ContactCustomer.LastName;
        payment.PaymentTypeId = banquetPayments["banquet_payment_type_id"];
        payment.PaymentSourceId = banquetPayments["banquet_payment_source_id"];
        payment.CardDate = banquetPayments["card_date_text"];

        switch (banquetPayments["banquet_payment_stream_id"]) {
          case "1":
            payment.Stream = 1;
            break;
          case "2":
            payment.Stream = 2;
            break;
        }


        if (banquetPayments["user"] != null) {
          payment.Manager.FirstName = banquetPayments["user"]["firstname"];
          payment.Manager.LastName = banquetPayments["user"]["lastname"];
        }
        payment.Banquet = this.BanquetsService.ActiveBanquet;

        this.BanquetsService.ActiveBanquet.Payments.unshift(payment);
        this.Objects.Values.unshift(payment);
        this.VisibleObjects.Values.unshift(payment);

        this.RecalculateUnsigned();

        payment.Banquet.GetSumPayment();

      }
    );

    this.RecalculateUnsigned();

  }




  public AddModalAddingPayment() {

    this.IsOpenedAddingModal = false;

    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/addPayment&token=${this.property.Token}`;

    let paymentData =
    {
      data: {
        "value": this.AddedValue,
        "banquet_payment_type_id": this.AddedPaymentsTypeId,
        "banquet_payment_source_id": this.AddedPaymentsSourceId,
        "banquet_id": this.BanquetsService.ActiveBanquet.Id
      }
    }

    this.AddedPaymentsTypeId = 1;
    this.AddedPaymentsSourceId = 1;
    this.AddedValue = 0;

    this.http.post(request, paymentData).subscribe(
      (data: any) => {
        let banquetPayments = data["body"]["data"]["objects"]["payment"];
        let payment = new BanquetPaymentHistory(banquetPayments["banquet_payment_history_id"]);
        payment.Name = banquetPayments["name"];
        payment.Value = banquetPayments["value"];
        payment.DateAdded = new Date(banquetPayments["date_added"].replace(/-/g, "/"));
        payment.Confirmed = banquetPayments["confirmed"];
        payment.IsIncome = banquetPayments["is_income"];
        this.BanquetsService.ActiveBanquet.Payments.unshift(payment);
      }
    );

  }




  public ClearPayments() {
    this.Page = 0;
    this.PageOfVisible = 0;

    this.Objects = new Vendor();
    this.ObjectsOriginal = new Vendor();
    this.VisibleObjects = new Vendor();
  }




  public OpenModalRemovePayment(payment: BanquetPaymentHistory) {
    this.ActivePayment = payment;
    this.IsOpenedRemovingModal = true;
  }
  public CloseModalRemovePayment() {
    this.IsOpenedRemovingModal = false;
  }
  public ApplyModalRemovePayment() {
    this.IsOpenedRemovingModal = false;
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/deletePayment&token=${this.property.Token}&id=${this.ActivePayment.Id}`;
    this.http.get(request).subscribe(
      (data: any) => {
        if (data["status"] == 200) {
          this.RemoveObjectById(this.ActivePayment.Id);

          let banquetOfRemovingPayment = this.BanquetsService.GetBanquetById(this.ActivePayment.BanquetId);
          banquetOfRemovingPayment.RemovePayment(this.ActivePayment.Id);

        }
      },
      (data) => {

      },
    );
  }




  public OpenModalConfirmPayment(payment: BanquetPaymentHistory) {
    if (this.property.UserGroupId != 1) {
      return;
    }
    this.ActivePayment = payment;
    this.IsOpenedConfirmingModal = true;
  }
  public CloseModalConfirmPayment() {
    this.IsOpenedConfirmingModal = false;
  }
  public ApplyModalConfirmPayment() {
    this.IsOpenedConfirmingModal = false;
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/confirmPayment&token=${this.property.Token}&id=${this.ActivePayment.Id}`;

    this.http.get(request).subscribe(
      (data: any) => {
        if (data["status"] == 201) {
          this.ActivePayment.Confirmed = true;
          this.ActivePayment.ConfirmedTransfer = true;
          let banquetOfRemovingPayment = this.BanquetsService.GetBanquetById(this.ActivePayment.BanquetId);
          if (banquetOfRemovingPayment) {
            banquetOfRemovingPayment.ConfirmPayment(this.ActivePayment.Id);
          }
          this.RecalculateUnsigned();

        }
      },
    );
  }


  public OpenModalUnconfirmPayment(payment: BanquetPaymentHistory) {
    if (this.property.UserGroupId != 1) {
      return;
    }
    this.ActivePayment = payment;
    this.IsOpenedUnconfirmingModal = true;
  }
  public CloseModalUnconfirmPayment() {
    this.IsOpenedUnconfirmingModal = false;
  }
  public ApplyModalUnconfirmPayment() {
    this.IsOpenedUnconfirmingModal = false;
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/disconfirmPayment&token=${this.property.Token}&id=${this.ActivePayment.Id}`;

    this.http.get(request).subscribe(
      (data: any) => {
        if (data["status"] == 201) {
          this.ActivePayment.Confirmed = false;
          let banquetOfRemovingPayment = this.BanquetsService.GetBanquetById(this.ActivePayment.BanquetId);
          if (banquetOfRemovingPayment) {
            banquetOfRemovingPayment.UnconfirmPayment(this.ActivePayment.Id);
          }
          this.RecalculateUnsigned();
        }
      },
      (data) => {
      },
    );
  }




  public OpenModalConfirmTransferPayment(payment: BanquetPaymentHistory) {
    if (this.property.UserGroupId != 1) {
      return;
    }
    this.ActivePayment = payment;
    this.ModalService.Reset();
    this.ModalService.AddConfirmEvent((fields: Array<any>) => {
      var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/confirmTransferPayment&token=${this.property.Token}&id=${this.ActivePayment.Id}`;

      this.http.get(request).subscribe(
        (data: any) => {
          if (data["status"] == 201) {
            this.ActivePayment.ConfirmedTransfer = true;
            let banquetOfConfirmaedTransferPayment = this.BanquetsService.GetBanquetById(this.ActivePayment.BanquetId);
            if (banquetOfConfirmaedTransferPayment) {
              banquetOfConfirmaedTransferPayment.ConfirmTransformPayment(this.ActivePayment.Id);
            }
            this.RecalculateUnsigned();
          }
        }
      );
    });

    this.ModalService.IsRequired = true;
    let changeTransactionModel = new ModalData("Подтверждение", "Подтвердить перенос оплаты", "Подписать", "Отмена");
    this.ModalService.Show(changeTransactionModel, []);
  }




  public OpenModalConfirmFilteredPayment() {
    if (this.property.UserGroupId != 1) {
      return;
    }
    this.IsOpenedFilterConfirmingModal = true;
  }

  public CloseModalConfirmFilteredPayment() {
    this.IsOpenedFilterConfirmingModal = false;
  }

  public ApplyModalConfirmFilteredPayment() {
    this.IsOpenedFilterConfirmingModal = false;
    let ids: Array<number> = new Array<number>();
    let processedPayment: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();
    this.VisibleObjects.Values.forEach((payment: BanquetPaymentHistory) => {
      if (payment.Confirmed == false) {
        ids.push(payment.Id);
        processedPayment.push(payment);
      }
    });

    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/confirmManyPayment&token=${this.property.Token}&ids=${ids.join()}`;
    this.http.get(request).subscribe(
      (data: any) => {
        if (data["status"] == 201) {
          processedPayment.forEach(payment => {
            payment.Confirmed = true;
            let banquetOfRemovingPayment = this.BanquetsService.GetBanquetById(payment.BanquetId);
            if (banquetOfRemovingPayment != null) {
              banquetOfRemovingPayment.ConfirmPayment(payment.Id);
            }
            this.RecalculateUnsigned();
            this.RecalculateFilteredUnsigned(this.VisibleObjects);
          });
        }
      },
      (data) => {

      },
    );
  }






  public OpenModalConfirmManyPayment() {
    if (this.property.UserGroupId != 1) {
      return;
    }
    this.IsOpenedManyConfirmingModal = true;
  }

  public CloseModalConfirmManyPayment() {
    this.IsOpenedManyConfirmingModal = false;
  }

  public ApplyModalConfirmManyPayment() {
    this.IsOpenedManyConfirmingModal = false;
    let ids: Array<number> = new Array<number>();
    let processedPayment: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();
    this.Objects.Values.forEach((payment: BanquetPaymentHistory) => {
      if (payment.Confirmed == false) {
        ids.push(payment.Id);
        processedPayment.push(payment);
      }
    });


    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-payment/confirmManyPayment&token=${this.property.Token}&ids=${ids.join()}`;
    this.http.get(request).subscribe(
      (data: any) => {
        if (data["status"] == 201) {
          processedPayment.forEach(payment => {
            payment.Confirmed = true;
            let banquetOfRemovingPayment = this.BanquetsService.GetBanquetById(payment.BanquetId);
            if (banquetOfRemovingPayment != null) {
              banquetOfRemovingPayment.ConfirmPayment(payment.Id);
            }
            this.RecalculateUnsigned();
            this.RecalculateFilteredUnsigned(this.VisibleObjects);
          });
        }
      },
      (data) => {

      },
    );
  }


  public CustomersAutocomplete: Array<any> = new Array<any>();




}
