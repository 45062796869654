<div class="payments-wrapper" fxLayout="column" fxLayoutGap="16px">

    <button ibmButton="primary" (click)="OpenPaymentModal()">Добавить оплату</button>
    <button ibmButton="danger" (click)="RemoveAllTransaction()">Вернуть все оплаты</button>

    <div class="empty-wrapper" *ngIf="BanquetsService.ActiveBanquet.Payments.length == 0">
        В данном банкете отсутствуют финансовые транзакции
    </div>

    <div *ngFor="let payment of BanquetsService.ActiveBanquet.Payments">
        <div class="banquet-payment-item" [ngClass]="{'income': payment.IsIncome==1, 'unincome': payment.IsIncome==0}">
            <div>

                <div class="banquet-payment-item__manager-name">{{payment.Manager.LastName}}</div>
                <div>
                    <span *ngIf="payment.Stream == 1">Задаток</span>
                    <span *ngIf="payment.Stream == 2">Программа</span>

                </div>
                <div class="banquet-payment-item__source">
                    <img *ngIf="payment.PaymentSourceId==1" src="../../../../assets/icons/cash.svg">
                    <img *ngIf="payment.PaymentSourceId==2" src="../../../../assets/icons/card.svg">
                    <img *ngIf="payment.PaymentSourceId==3" src="../../../../assets/icons/terminal.svg">
                    <span *ngIf="payment.PaymentSourceId==1">Наличные</span>
                    <span *ngIf="payment.PaymentSourceId==2">Карта</span>
                    <span *ngIf="payment.PaymentSourceId==3">Терминал</span>
                    <span *ngIf="payment.PaymentSourceId==4">Перевод</span>
                </div>
                <div class="card-payment-source">{{payment.CardDate}}</div>
                <div class="banquet-payment-item__value income">
                    <img *ngIf="payment.IsIncome==0" src="../../../../assets/icons/consumption-arrow.svg">
                    <img *ngIf="payment.IsIncome==1" src="../../../../assets/icons/income-arrow.svg">
                    {{payment.Value}}
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between end">
                <div fxLayout="column" fxLayoutAlign="space-between end">
                    <div class="banquet-payment-item__date">{{payment.DateAdded| date:'d MMM, y'}}</div>

                    <div class="banquet-payment-item__status"
                        [ngClass]="{'confirmed': payment.Confirmed==1, 'unconfirmed': payment.Confirmed==0}">
                        <span *ngIf="payment.Confirmed==1"
                            (click)="BanquetPaymentsService.OpenModalUnconfirmPayment(payment)">
                            <span>Подтверждён</span>
                            <img src="../../../../assets/icons/access-check.svg">
                        </span>
                        <span *ngIf="payment.Confirmed==0"
                            (click)="BanquetPaymentsService.OpenModalConfirmPayment(payment)">
                            <span>Не подтверждён</span>
                            <img src="../../../../assets/icons/not-confirmed.svg">
                        </span>
                    </div>
                </div>
                <div class="banquet-payment-item__actions"
                    (click)="BanquetPaymentsService.OpenModalRemovePayment(payment)"
                    *ngIf="PropertyService.UserGroupId == 1 && payment.Confirmed==0">
                    <img src="../../../../assets/icons/trash.svg">
                </div>
            </div>
        </div>
    </div>
</div>