import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryService } from '../../../services/delivery.service';
import { OrdersService } from '../../../services/order/orders.service';

@Component({
  selector: 'app-courier-active-group-list',
  templateUrl: './courier-active-group-list.component.html',
  styleUrls: ['./courier-active-group-list.component.scss']
})
export class CourierActiveGroupListComponent implements OnInit {

  constructor(public DeliveryService: DeliveryService, private router: Router, public OrdersService: OrdersService) { }

  ngOnInit(): void {
    //this.DeliveryService.ActiveOrderGroups[0].StatusId
  }


  ToggleDelivery(event, order) {

    if (event.srcElement.localName != "a") {
      // setTimeout(
      //   () => {
      //     this.router.navigate([`/delivery/${order.id}`]);
      //   }, 50);
      order.IsToggleInGroup = !order.IsToggleInGroup;
    }

  }

  OnChangePaymented(event, order) {
    order.Paymented = event.checked;
    this.OrdersService.SaveOrder(order);
  }


}
