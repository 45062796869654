import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';

@Injectable({
  providedIn: 'root'
})
export class CouriersService {

  public Couriers: Array<any> = new Array<any>();
  public ActiveCourier = null;
  public OnLoadCouriersEvents: Array<any> = new Array<any>();

  constructor(private http: HttpClient, private property: PropertyService,) {

  }

  LoadCouriers() {
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order/LoadCouriers&token=${this.property.Token}`);

    this.http.get(request).subscribe(
      (data: any) => {
        data.body.data.objects.data.forEach(element => {
          this.Couriers.push(element);
        });

        this.ActiveCourier = this.Couriers[0];

        this.OnLoadCouriersEvents.forEach(event => {
          event();
        });
      }
    );
  }

}
