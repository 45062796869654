import { Identifiable } from "../tools/identifiable.model";

export class OchagboxHistory extends Identifiable {


    private orderId: number;
    public get OrderId(): number {
        return this.orderId;
    }
    public set OrderId(orderId: number) {
        this.orderId = orderId;
    }



    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }


    constructor(id: number, orderId: number, dateAdded: Date) {
        super(id)
        this.OrderId = orderId;
        this.DateAdded = dateAdded;
    }

}