import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';

import { BanquetPaymentHistory } from 'src/app/models/banquet/banquet-payment-history';
import { BanquetsService } from '../../../services/banquet/banquets.service';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { NavigationAction } from 'src/app/models/navigation/navigation-action.model';
import { Vendor } from 'src/app/services/vendor.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Radio } from 'carbon-components-angular';

@Component({
  selector: 'app-banquet-transaction-list',
  templateUrl: './banquet-transaction-list.component.html',
  styleUrls: ['./banquet-transaction-list.component.scss']
})
export class BanquetTransactionListComponent implements AfterViewInit, OnInit {

  public IsFilterOpened: boolean = false;
  public IsFiltered: boolean = false;


  public PaymentsSources: Array<any> = [
    { content: "Все", id: -1, selected: true },
    { content: "Наличные", id: 1, selected: false },
    { content: "Карта", id: 2, selected: false },
    { content: "Терминал", id: 3, selected: false },
    { content: "Перевод", id: 4, selected: false },
  ];

  public TransactionStatuses: Array<any> = [
    { content: "Все", id: -1, selected: true },
    { content: "Подписан", id: 1, selected: false },
    { content: "Не подписан", id: 0, selected: false },
  ];

  public TransactionTargets: Array<any> = [
    { content: "Все", id: -1, selected: true },
    { content: "Задатки", id: 1, selected: true },
    { content: "Программа", id: 2, selected: false },
  ];
  public TransactionResultStatus: string = "Все";
  public TransactionResultSource: string = "Все";
  public TransactionResultTarge: string = "Все";

  keyword = 'name';


  constructor(public BanquetPaymentsService: BanquetPaymentsService,
    public PropertyService: PropertyService,
    public BanquetsService: BanquetsService, private route: ActivatedRoute,
    private router: Router, public HeaderService: HeaderService,
    protected formBuilder: FormBuilder, private ref: ChangeDetectorRef) {

  }


  ngOnInit(): void {



    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Задатки";
    this.HeaderService.BackButton.Text = "Меню банкетов";
    this.HeaderService.BackButton.Link = "/banquet-menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Тразакции", "/banquet-transactions", true);

    if (this.BanquetPaymentsService.IsLoaded) {
      this.BanquetPaymentsService.ClearPayments();
      this.BanquetPaymentsService.LoadPayments();
    }

    let filterSubaction = new NavigationAction("Фильтр", "collapse", () => {
      this.IsFilterOpened = !this.IsFilterOpened;
    });
    filterSubaction.IsMobile = true;
    filterSubaction.IsDesktop = false;
    this.HeaderService.AddSubActions(filterSubaction);

    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });


  }

  ValueChange(event) {
    //this.OrdersService.Filter(event);
    this.Name = event;
    if (this.TransactionBanquetDate == null) {
      this.FIlterPaymentsWithoutDate();
    } else {
      this.FIlterPayments();
    }
  }

  Clear() {

  }



  OpenPayment(event, payment: BanquetPaymentHistory) {

    if (this.PropertyService.UserGroupId != 1) {
      return;
    }

    let isClassFinded = false;

    for (let index = 0; index < event.target.classList.length; index++) {
      const element = event.target.classList[index];
      if (element == "x--overflow-menu__icon" ||
        element == "bx--overflow-menu__icon" ||
        element == "bx--overflow-menu") {
        isClassFinded = true;
      }
    }

    if (!isClassFinded) {

      if (payment.IsTransfered) {
        if (payment.Confirmed && payment.ConfirmedTransfer == false) {
          this.BanquetPaymentsService.OpenModalConfirmTransferPayment(payment);
        } else {
          if (payment.Confirmed == false) {
            this.BanquetPaymentsService.OpenModalConfirmPayment(payment);
          } else {
            this.BanquetPaymentsService.OpenModalUnconfirmPayment(payment);
          }
        }
      } else {
        if (payment.Confirmed == false) {
          this.BanquetPaymentsService.OpenModalConfirmPayment(payment);
        } else {
          this.BanquetPaymentsService.OpenModalUnconfirmPayment(payment);
        }
      }



    }

  }

  OpenConfirmManyPaymentModal(event) {
    this.BanquetPaymentsService.OpenModalConfirmManyPayment();
  }

  OpenConfirmFilterPaymentModal(event) {
    this.BanquetPaymentsService.OpenModalConfirmFilteredPayment();
  }





  LoadMore() {
    this.BanquetPaymentsService.LoadMorePayments();
  }

  OpenBanquetByPayment(payment: BanquetPaymentHistory) {
    this.BanquetsService.OpenBanquetById(payment.BanquetId);
  }

  RemovePayment(payment: BanquetPaymentHistory) {
    this.BanquetPaymentsService.OpenModalRemovePayment(payment);
  }


  public Name: string = "";
  public PaymentTypeId: number = -1;
  public TransactionStatusId: number = -1;
  public TransactionTargetId: number = -1;

  public PaymentType: string = "Все";
  public TransactionStatus: string = "Все";
  public TransactionTarget: string = "Все";

  public PaymentTypeRadio: Radio;
  public TransactionStatusRadio: Radio;

  public TransactionBanquetDate: Date = null;
  public TransactionBanquetDateForFilter: Date = null;
  public IsBanquetTransfer: boolean = false;



  OnChangeFilterPaymentType(event) {
    this.PaymentTypeRadio = event.source;
    this.PaymentType = event.value;
    this.PaymentTypeId = this.PaymentsSources.find((item) => { return item.content == this.PaymentType }).id;
    this.ref.detectChanges();
    if (this.TransactionBanquetDateForFilter == null) {
      this.FIlterPaymentsWithoutDate();
    } else {
      this.FIlterPayments();
    }
  }

  OnChangeFilterTransactionStatus(event) {
    this.TransactionStatusRadio = event.source;
    this.TransactionStatus = event.value;
    this.TransactionStatusId = this.TransactionStatuses.find((item) => { return item.content == this.TransactionStatus }).id;

    this.ref.detectChanges();
    if (this.TransactionBanquetDateForFilter == null) {
      this.FIlterPaymentsWithoutDate();
    } else {
      this.FIlterPayments();
    }
  }

  OnChangeFilterTransactionTarget(event) {
    this.TransactionTarget = event.value;
    this.TransactionTargetId = this.TransactionTargets.find((item) => { return item.content == this.TransactionTarget }).id;

    this.ref.detectChanges();
    if (this.TransactionBanquetDateForFilter == null) {
      this.FIlterPaymentsWithoutDate();
    } else {
      this.FIlterPayments();
    }
  }






  OnChangeFilterBanquetDate(event) {
    this.TransactionBanquetDate = event[0];
    this.TransactionBanquetDateForFilter = event[0];
    this.FIlterPayments();
  }

  onChangeBanquetTransfer(event) {
    this.IsBanquetTransfer = event.checked;
    this.FIlterPayments();
  }

  private banquetDate: Date = new Date();

  public set BanquetDate(date: Date) {
    this.banquetDate = date;
    this.FIlterPayments();
  }

  public get BanquetDate(): Date {
    return this.banquetDate;
  }


  FIlterPaymentsWithoutDate() {

    this.IsFilterOpened = true;

    let BufferArray: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();
    this.BanquetPaymentsService.VisibleObjects.Clear();


    BufferArray = this.BanquetPaymentsService.Objects.Values as Array<BanquetPaymentHistory>;
    if (this.PaymentTypeId != -1) {
      BufferArray = this.FilterByPaymentType(BufferArray);
    }

    if (this.TransactionStatusId != -1) {
      BufferArray = this.FilterByPaymentStatus(BufferArray);
    }

    if (this.TransactionTargetId != -1) {
      BufferArray = this.FilterByPaymentTarget(BufferArray);
    }

    BufferArray = this.FilterByCustomerName(BufferArray);

    this.BanquetPaymentsService.VisibleObjects.Values = BufferArray;

    this.IsFilterOpened = false;

  }

  AddPaymentToVisible(payment) {
    if (this.BanquetPaymentsService.VisibleObjects.Values.length < 10) {
      this.BanquetPaymentsService.VisibleObjects.PushToEnd(payment);
    }

  }

  FIlterPayments() {
    this.IsFilterOpened = true;
    let BufferArray: Array<BanquetPaymentHistory> = new Array<BanquetPaymentHistory>();

    this.BanquetPaymentsService.DateFilteredObjects = new Array<BanquetPaymentHistory>();
    this.BanquetPaymentsService.VisibleObjects.Clear();

    BufferArray = this.BanquetPaymentsService.Objects.Values as Array<BanquetPaymentHistory>;

    if (this.PaymentTypeId != -1) {
      BufferArray = this.FilterByPaymentType(BufferArray);
    }

    if (this.TransactionStatusId != -1) {
      BufferArray = this.FilterByPaymentStatus(BufferArray);
    }

    if (this.TransactionTargetId != -1) {
      BufferArray = this.FilterByPaymentTarget(BufferArray);
    }

    BufferArray = this.FilterByPaymentDate(BufferArray);
    BufferArray = this.FilterByCustomerName(BufferArray);

    this.BanquetPaymentsService.VisibleObjects.Values = BufferArray;

    this.BanquetPaymentsService.Objects.Values.forEach(
      (payment: BanquetPaymentHistory) => {
        let isBanquetDateChecked = this.CheckBanquetDateForFilter(payment);
        let isTransferBanquetDateChecked = false;
        if (this.IsBanquetTransfer) {
          isTransferBanquetDateChecked = this.CheckTransferBanquetDateForFilter(payment);
        }
        if (payment.Banquet != null) {
          if ((isBanquetDateChecked || isTransferBanquetDateChecked)) {
            this.BanquetPaymentsService.DateFilteredObjects.push(payment);
          }
        }
      }
    );


    this.IsFiltered = true;
    this.BanquetPaymentsService.RecalculateFilteredUnsigned(this.BanquetPaymentsService.DateFilteredObjects);
    this.BanquetPaymentsService.RecalculateFilteredGuests(this.BanquetPaymentsService.DateFilteredObjects);
    this.IsFilterOpened = false;
  }

  FilterByPaymentStatus(payments: Array<BanquetPaymentHistory>): Array<BanquetPaymentHistory> {
    let filteredPayments: Array<BanquetPaymentHistory> = Array<BanquetPaymentHistory>();
    payments.forEach(
      (payment: BanquetPaymentHistory) => {
        if (payment.Banquet != null) {
          if (payment.Confirmed == (this.TransactionStatusId == 1 ? true : false)) {
            filteredPayments.push(payment);
          }
        }
      }
    );
    return filteredPayments;
  }

  FilterByPaymentType(payments: Array<BanquetPaymentHistory>): Array<BanquetPaymentHistory> {
    let filteredPayments: Array<BanquetPaymentHistory> = Array<BanquetPaymentHistory>();

    payments.forEach(
      (payment: BanquetPaymentHistory) => {
        if (payment.Banquet != null) {
          if (payment.PaymentSourceId == this.PaymentTypeId) {
            filteredPayments.push(payment);
          }
        }
      }
    );

    return filteredPayments;
  }

  FilterByPaymentTarget(payments: Array<BanquetPaymentHistory>): Array<BanquetPaymentHistory> {
    let filteredPayments: Array<BanquetPaymentHistory> = Array<BanquetPaymentHistory>();

    payments.forEach(
      (payment: BanquetPaymentHistory) => {
        if (payment.Banquet != null) {
          if (payment.Stream == this.TransactionTargetId) {
            filteredPayments.push(payment);
          }
        }
      }
    );

    return filteredPayments;
  }

  FilterByCustomerName(payments: Array<BanquetPaymentHistory>): Array<BanquetPaymentHistory> {
    let filteredPayments: Array<BanquetPaymentHistory> = Array<BanquetPaymentHistory>();
    payments.forEach(
      (payment: BanquetPaymentHistory) => {
        if (payment.Banquet != null) {
          let searchInNameResult = payment.BanquetContact.toLowerCase().indexOf(this.Name);
          let searchInCardDateResult = payment.CardDate.toLowerCase().indexOf(this.Name);
          let searchInSumResult = payment.Value.toString() == this.Name;
          if (searchInNameResult != -1 || searchInCardDateResult != -1 || searchInSumResult) {
            filteredPayments.push(payment);
          }
        }
      }
    );
    return filteredPayments;
  }

  FilterByPaymentDate(payments: Array<BanquetPaymentHistory>): Array<BanquetPaymentHistory> {
    let filteredPayments: Array<BanquetPaymentHistory> = Array<BanquetPaymentHistory>();

    payments.forEach(
      (payment: BanquetPaymentHistory) => {
        let isBanquetDateChecked = this.CheckBanquetDateForFilter(payment);
        let isTransferBanquetDateChecked = false;
        if (this.IsBanquetTransfer) {
          isTransferBanquetDateChecked = this.CheckTransferBanquetDateForFilter(payment);
        }
        if (payment.Banquet != null) {
          if (isBanquetDateChecked || isTransferBanquetDateChecked) {
            filteredPayments.push(payment);
          }
        }

      }
    );

    return filteredPayments;
  }








  CheckBanquetDateForFilter(payment: BanquetPaymentHistory) {
    if (payment.Banquet == null) {
      return false;
    } else {
      return payment.Banquet.Date.getDate() == this.TransactionBanquetDateForFilter.getDate()
        && payment.Banquet.Date.getMonth() == this.TransactionBanquetDateForFilter.getMonth()
        && payment.Banquet.Date.getFullYear() == this.TransactionBanquetDateForFilter.getFullYear();
    }

  }

  CheckTransferBanquetDateForFilter(payment: BanquetPaymentHistory) {
    if (payment.DateCurrentBanquet != null) {
      return payment.DateCurrentBanquet.getDate() == this.TransactionBanquetDateForFilter.getDate()
        && payment.DateCurrentBanquet.getMonth() == this.TransactionBanquetDateForFilter.getMonth()
        && payment.DateCurrentBanquet.getFullYear() == this.TransactionBanquetDateForFilter.getFullYear();
    } else {
      return false;
    }

  }


  public formGroup: FormGroup;

  ClearFilter() {

    this.TransactionBanquetDate = null;
    this.TransactionBanquetDateForFilter = null;


    this.TransactionStatusId = -1;
    this.PaymentTypeId = -1;

    this.TransactionStatus = "Все";
    this.PaymentType = "Все";

    this.formGroup.get("formGroupPaymentType").setValue("Все");
    this.formGroup.get("formGroupStatusType").setValue("Все");
    this.formGroup.reset({
      formGroupStatusType: "Все"
    });

    this.FIlterPaymentsWithoutDate();
    this.IsFiltered = false;
  }

  ngAfterViewInit() {
    this.formGroup = this.formBuilder.group({
      formGroupPaymentType: new FormControl(),
      formGroupStatusType: new FormControl(),
      formGroupStatusTarget: new FormControl(),

    });
    this.formGroup.get("formGroupPaymentType").setValue("Все");
    this.formGroup.get("formGroupStatusType").setValue("Все");
    this.formGroup.get("formGroupStatusTarget").setValue("Все");
  }

  onFocused(e) {
    // do something when input is focused
  }

}
