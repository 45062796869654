<div class="payment_wrapper"
    [ngClass]="{'showed_filter': IsFilterOpened, 'show_only': PropertyService.UserGroupId != 1}" fxLayout="row"
    fxLayoutGap="40px" *ngIf="BanquetPaymentsService.IsLoaded && BanquetsService.IsLoaded">

    <div class="filter">

        <app-tabs>
            <app-tab tabTitle="Данные">
                <div *ngIf="TransactionBanquetDate != null">

                    <div class="filter_standart_layout">
                        <div class="filter_title">Данные на {{TransactionBanquetDate | date:'dd.MM.yy'}}</div>

                        <button ibmButton="primary" (click)="OpenConfirmFilterPaymentModal($event)">Принять все на
                            {{TransactionBanquetDate | date:'dd.MM'}}</button>

                    </div>


                    <div class="data_table_wrapper">
                        <div class="data_table_row">
                            <div class="data_table_item">
                                <div class="data_table_lable">Вся полученная сумма на {{TransactionBanquetDate |
                                    date:'dd.MM.yy'}}</div>
                                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredPaymentValue |
                                    largeNumber}}₴
                                </div>
                            </div>
                        </div>
                        <div class="data_table_row">
                            <div class="data_table_item">
                                <div class="data_table_lable">Задатки</div>
                                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredDepositPaymentValue |
                                    largeNumber}}₴</div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable">Программа</div>
                                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredProgramPaymentValue |
                                    largeNumber}}₴</div>
                            </div>
                        </div>


                        <div class="data_table_row bottom">
                            <div class="data_table_item">
                                <div class="data_table_lable">Взрослые</div>
                                <div class="data_table_value">{{BanquetPaymentsService.CountOfGuestsByPaymants}}</div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable">Дети</div>
                                <div class="data_table_value">{{BanquetPaymentsService.CountOfChildsByPayments}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="filter_subtitle">Задатки</div>

                    <div class="data_table_wrapper">
                        <div class="data_table_row">
                            <div class="data_table_item">
                                <div class="data_table_lable">Задатки</div>
                                <div class="data_table_value income">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-up"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.FilteredIncomePaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable">Возвраты</div>
                                <div class="data_table_value cost">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-down"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.FilteredCostPaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="data_table_row">
                            <div class="data_table_item">
                                <div class="data_table_lable confirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#checkmark-filled"></use>
                                    </svg>
                                    <span>Подписаны</span>
                                </div>
                                <div class="data_table_value income">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-up"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.SignedFilteredIncomePaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable confirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#checkmark-filled"></use>
                                    </svg>
                                    <span>Подписаны</span>
                                </div>
                                <div class="data_table_value cost">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-down"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.SignedFilteredCostPaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                        </div>
                        <div class="data_table_row bottom">
                            <div class="data_table_item">
                                <div class="data_table_lable unconfirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#close-filled"></use>
                                    </svg>
                                    <span>Не подписаны</span>
                                </div>
                                <div class="data_table_value income">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-up"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.UnsignedFilteredIncomePaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable unconfirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#close-filled"></use>
                                    </svg>
                                    <span>Не подписаны</span>
                                </div>
                                <div class="data_table_value cost">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-down"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.UnsignedFilteredCostPaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="TransactionBanquetDate == null">

                    <div class="filter_standart_layout">
                        <button ibmButton="primary" (click)="OpenConfirmManyPaymentModal($event)">Подтвердить
                            все</button>
                    </div>

                    <div class="data_table_wrapper">
                        <div class="data_table_row">
                            <div class="data_table_item">
                                <div class="data_table_lable">Неподписанные транзакции</div>
                                <div class="data_table_value">{{BanquetPaymentsService.UnsignedPaymentValue |
                                    largeNumber}}₴
                                </div>
                            </div>
                        </div>
                        <div class="data_table_row bottom">
                            <div class="data_table_item">
                                <div class="data_table_lable unconfirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#close-filled"></use>
                                    </svg>
                                    <span>Не подписаны</span>
                                </div>
                                <div class="data_table_value income">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-up"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.UnsignedIncomePaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                            <div class="data_table_item">
                                <div class="data_table_lable unconfirmed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#close-filled"></use>
                                    </svg>
                                    <span>Не подписаны</span>
                                </div>
                                <div class="data_table_value cost">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                        class="data_table_lable_icon">
                                        <use xlink:href="#caret-down"></use>
                                    </svg>
                                    <span>{{BanquetPaymentsService.UnsignedCostPaymentValue |
                                        largeNumber}}₴</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </app-tab>
            <app-tab tabTitle="Фильтр">
                <button class="clear_filter_button" ibmButton="primary" size="normal"
                    (click)="ClearFilter()">Сбросить</button>
                <div class="filter_standart_layout">
                    <fieldset class="bx--fieldset">
                        <div class="filter-date-picker-container">
                            <ibm-date-picker dateFormat="d.m.Y" label="Дата транзакции" language="ru"
                                (valueChange)="OnChangeFilterBanquetDate($event)" [(ngModel)]="TransactionBanquetDate">
                            </ibm-date-picker>
                        </div>

                        <ibm-checkbox checked="false" [(ngModel)]="IsBanquetTransfer"
                            (change)="onChangeBanquetTransfer($event)"
                            [disabled]="TransactionBanquetDateForFilter == null">
                            Включить переносы
                        </ibm-checkbox>

                    </fieldset>


                    <form [formGroup]="formGroup">
                        <fieldset class="bx--fieldset">
                            <legend class="bx--label">Способ оплаты</legend>
                            <ibm-radio-group aria-label="radiogroup" orientation="vertical"
                                [labelPlacement]="labelPlacement" [(ngModel)]="PaymentType"
                                (change)="OnChangeFilterPaymentType($event)" formControlName="formGroupPaymentType">
                                <ibm-radio *ngFor="let source of PaymentsSources" [value]="source.content">
                                    {{source.content}}
                                </ibm-radio>
                            </ibm-radio-group>
                        </fieldset>

                        <fieldset class="bx--fieldset">
                            <legend class="bx--label">Статус оплаты</legend>
                            <ibm-radio-group aria-label="radiogroup" orientation="vertical"
                                [labelPlacement]="labelPlacement" [(ngModel)]="TransactionStatus"
                                (change)="OnChangeFilterTransactionStatus($event)"
                                formControlName="formGroupStatusType">
                                <ibm-radio *ngFor="let source of TransactionStatuses" [value]="source.content">
                                    {{source.content}}
                                </ibm-radio>
                            </ibm-radio-group>
                        </fieldset>

                        <fieldset class="bx--fieldset">
                            <legend class="bx--label">Тип оплаты</legend>
                            <ibm-radio-group aria-label="radiogroup" orientation="vertical"
                                [labelPlacement]="labelPlacement" [(ngModel)]="TransactionTarget"
                                (change)="OnChangeFilterTransactionTarget($event)"
                                formControlName="formGroupStatusTarget">
                                <ibm-radio *ngFor="let source of TransactionTargets" [value]="source.content">
                                    {{source.content}}
                                </ibm-radio>
                            </ibm-radio-group>
                        </fieldset>
                    </form>

                </div>
            </app-tab>
        </app-tabs>

    </div>


    <!-- <div *ngFor="let item of BanquetPaymentsService.CustomersAutocomplete">{{item.name}}</div> -->
    <div class="content">
        <!-- <ibm-search size="sm" (valueChange)="ValueChange($event)" (clear)="Clear()" theme="light">
        </ibm-search> -->

        <div class="ng-autocomplete fullwidth">
            <ng-autocomplete [data]="BanquetPaymentsService.CustomersAutocomplete" [searchKeyword]="keyword"
                (selected)='selectEvent($event)' (inputChanged)='ValueChange($event)' (inputFocused)='onFocused($event)'
                placeholder="Строка поиска по имени" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
        </div>

        <div class="list_wrapper">

            <div>
                <ibm-structured-list>
                    <ibm-list-header>
                        <ibm-list-column>№</ibm-list-column>
                        <ibm-list-column>Подт.</ibm-list-column>
                        <ibm-list-column>Оплата</ibm-list-column>
                        <ibm-list-column>Банкет</ibm-list-column>
                        <!-- <ibm-list-column>Название</ibm-list-column> -->
                        <ibm-list-column>Значение</ibm-list-column>
                        <ibm-list-column>Контакт</ibm-list-column>
                        <ibm-list-column>Гости</ibm-list-column>
                        <ibm-list-column>Тип</ibm-list-column>
                        <ibm-list-column>Оплаты</ibm-list-column>
                        <ibm-list-column>Менеджер</ibm-list-column>

                        <ibm-list-column>Действия</ibm-list-column>
                    </ibm-list-header>


                    <ibm-list-row *ngFor="let payment of BanquetPaymentsService.VisibleObjects.Values"
                        class="payment_item"
                        [ngClass]="{'income': payment.IsIncome, 'outcome': payment.IsIncome==false, 'unconfirmed': !payment.Confirmed, 'transfered_unconfirmed': (payment.IsTransfered && !payment.ConfirmedTransfer && payment.Confirmed), 'confirmed': payment.Confirmed}"
                        (click)="OpenPayment($event, payment)">

                        <ibm-list-column>№{{payment.Id}}<span
                                *ngIf="payment.Banquet != null && payment.Banquet.CustomNumber != null && payment.Banquet.CustomNumber != ''">
                                |
                            </span><span *ngIf="payment.Banquet != null">{{payment.Banquet.CustomNumber}}</span>

                        </ibm-list-column>

                        <ibm-list-column>
                            <svg *ngIf="(payment.IsTransfered && payment.ConfirmedTransfer && payment.Confirmed) || (!payment.IsTransfered && payment.Confirmed)"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                class="confirmed-icon confirmed">
                                <use xlink:href="#checkmark-filled"></use>
                            </svg>
                            <svg *ngIf="!payment.Confirmed" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                viewBox="0 0 32 32" class="confirmed-icon unconfirmed">
                                <use xlink:href="#close-filled"></use>
                            </svg>
                            <svg *ngIf="(payment.IsTransfered && !payment.ConfirmedTransfer && payment.Confirmed)"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                                class="confirmed-icon transfered_unconfirmed">
                                <use xlink:href="#transfered"></use>
                            </svg>
                        </ibm-list-column>

                        <ibm-list-column>{{payment.DateAdded | date:'dd.MM.yy'}} </ibm-list-column>
                        <ibm-list-column>
                            <div *ngIf="payment.Banquet!=null">
                                <div>{{payment.Banquet.Date | date:'dd.MM.yy'}}</div>
                                <div class="old_banquet_date" *ngIf="payment.IsTransfered">

                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            viewBox="0 0 32 32">
                                            <use xlink:href="#left-top-arrow"></use>
                                        </svg>
                                    </span>
                                    <span>{{payment.DateCurrentBanquet | date:'dd.MM.yy'}}</span>

                                </div>

                            </div>

                        </ibm-list-column>
                        <!-- <ibm-list-column class="payment_name">
                                        
                                        {{payment.Name}}
                                    </ibm-list-column> -->

                        <ibm-list-column class="payment_name payment_value">
                            <div class="payment_value_wrapper"
                                [ngClass]="{'income': payment.IsIncome, 'outcome': payment.IsIncome==false}">
                                <svg *ngIf="payment.IsIncome==0" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 32 32">
                                    <use xlink:href="#caret-down"></use>
                                </svg>
                                <svg *ngIf="payment.IsIncome==1" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 32 32">
                                    <use xlink:href="#caret-up"></use>
                                </svg>
                                {{payment.Value}}₴
                            </div>
                        </ibm-list-column>
                        <ibm-list-column class="">
                            {{payment.BanquetContact}}
                        </ibm-list-column>




                        <ibm-list-column class="">
                            <span class="no-wrap">Гостей: {{payment.Banquet.Guests}}</span>
                            <br>
                            <span class="no-wrap">Детей:{{payment.Banquet.Children}}</span>
                        </ibm-list-column>
                        <ibm-list-column class="">
                            {{payment.StreamName}}
                        </ibm-list-column>
                        <ibm-list-column class="">
                            <div fxLayout="row" fxLayoutGap="8px">
                                <img *ngIf="payment.PaymentSourceId==1" src="../../../../assets/icons/cash-color.svg">
                                <img *ngIf="payment.PaymentSourceId==2" src="../../../../assets/icons/card-color.svg">
                                <img *ngIf="payment.PaymentSourceId==3"
                                    src="../../../../assets/icons/terminal-color.svg">
                                <span class="cash-payment-source" *ngIf="payment.PaymentSourceId==1">Наличные</span>
                                <span class="card-payment-source" *ngIf="payment.PaymentSourceId==2">Карта</span>
                                <span class="terminal-payment-source" *ngIf="payment.PaymentSourceId==3">Терминал</span>
                                <span class="terminal-payment-source" *ngIf="payment.PaymentSourceId==4">Перевод</span>
                            </div>
                            <div class="card-payment-source">{{payment.CardDate}}</div>


                        </ibm-list-column>

                        <ibm-list-column class="">
                            {{payment.Manager.LastName}}
                        </ibm-list-column>


                        <ibm-list-column class="">

                            <ibm-overflow-menu [offset]="{ x: -120, y: 144 }">
                                <ibm-overflow-menu-option (click)="OpenBanquetByPayment(payment)">
                                    Открыть банкет
                                </ibm-overflow-menu-option>

                                <ibm-overflow-menu-option *ngIf="!payment.Confirmed" type="danger"
                                    (click)="RemovePayment(payment)">
                                    Удалить
                                </ibm-overflow-menu-option>
                            </ibm-overflow-menu>

                        </ibm-list-column>
                    </ibm-list-row>



                </ibm-structured-list>
            </div>



            <button *ngIf="!IsFiltered" class="load_more_button" (click)="LoadMore()">Еще</button>
        </div>
    </div>
</div>

<div class="shadow_haze"
    [ngClass]="{'showed_filter': IsFilterOpened || BanquetPaymentsService.IsLoading, 'show_only': PropertyService.UserGroupId != 1}"
    fxLayout="row" fxLayoutAlign="center center">
    <ibm-loading [isActive]="BanquetPaymentsService.IsLoading || IsFilterOpened"></ibm-loading>
</div>