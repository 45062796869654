import { Order } from './order.model';

import { Customer } from './customer.model';
import { Product } from './product.model';
import { Note } from './note.model';
import { Equipment } from './equipment.model';
import { OrderHistory } from './history.model';

export class OrderBuilder {

    private order: Order;

    constructor() {

    }


    // id: element["order_id"],
    // date: element["date_added"],
    // customer: {
    //     name: element[""],
    //     phone: element["telephone"],
    //     email: element["email"]
    // },
    // shippingAdress: element["shipping_address_1"],
    // paymentMethod: element["payment_method"],
    // orderStatus: element["order_status_id"],
    // dateAdded: element["date_added"],
    // total: element["total"],
    // comment: element["comment"],
    // 



    Build(jsonObject): Order {



        this.order = new Order(jsonObject["order_id"]);
        this.BuildCustomer(jsonObject);
        this.BuildComment(jsonObject);
        this.BuildDate(jsonObject);
        this.BuildStatus(jsonObject);
        this.BuildTotal(jsonObject);
        this.BuildProducts(jsonObject);
        this.BuildShippingAdress(jsonObject);
        this.BuildShippingArea(jsonObject);
        this.BuildShippingMethod(jsonObject);

        this.BuildCustomerShippingDate(jsonObject);
        this.BuildCustomerShippingTime(jsonObject);
        this.BuildShippingDate(jsonObject);

        this.BuildDateAdded(jsonObject);
        this.BuildEndedDate(jsonObject);


        this.BuildKitchenNotes(jsonObject);
        this.BuildDeliveryNotes(jsonObject);
        this.BuildEquipments(jsonObject);
        this.BuildGloablEquipmentCount(jsonObject);
        this.BuildGroupId(jsonObject);
        this.BuildPriority(jsonObject);
        this.BuildIsNotCall(jsonObject);
        this.BuildIsReadinessShipping(jsonObject);
        this.BuildReadiness(jsonObject);
        this.BuildHistory(jsonObject);

        this.BuildCardPayment(jsonObject);
        this.BuildPaymentMethod(jsonObject);
        this.BuildPastOrders(jsonObject);
        this.BuildReviews(jsonObject);
        this.BuildPaymented(jsonObject);
        this.BuildCourier(jsonObject);
        this.BuildTimer();

        this.BuildEntrance(jsonObject);
        this.BuildFloor(jsonObject);
        this.BuildApartment(jsonObject);
        this.BuildHomeCode(jsonObject);
        this.BuildCustomerNames(jsonObject);


        this.BuildCustomName(jsonObject);
        //this.BuildShipping();
        //this.BuildTimer();


        return this.order;
    }

    UpdateOrder(order: Order, jsonObject: any) {

        this.order = order;
        this.BuildCustomer(jsonObject);
        this.BuildComment(jsonObject);
        this.BuildDate(jsonObject);
        this.BuildStatus(jsonObject);
        this.BuildTotal(jsonObject);
        this.BuildProducts(jsonObject);
        this.BuildShippingAdress(jsonObject);
        this.BuildShippingArea(jsonObject);
        this.BuildShippingMethod(jsonObject);

        this.BuildCustomerShippingDate(jsonObject);
        this.BuildCustomerShippingTime(jsonObject);
        this.BuildShippingDate(jsonObject);

        this.BuildDateAdded(jsonObject);
        this.BuildEndedDate(jsonObject);


        this.BuildKitchenNotes(jsonObject);
        this.BuildDeliveryNotes(jsonObject);
        this.BuildEquipments(jsonObject);
        this.BuildGloablEquipmentCount(jsonObject);
        this.BuildGroupId(jsonObject);
        this.BuildPriority(jsonObject);
        this.BuildIsNotCall(jsonObject);
        this.BuildIsReadinessShipping(jsonObject);
        this.BuildReadiness(jsonObject);
        this.BuildHistory(jsonObject);

        this.BuildCardPayment(jsonObject);
        this.BuildPaymentMethod(jsonObject);
        this.BuildPastOrders(jsonObject);
        this.BuildReviews(jsonObject);
        this.BuildPaymented(jsonObject);
        this.BuildCourier(jsonObject);
        this.BuildTimer();

        this.BuildEntrance(jsonObject);
        this.BuildFloor(jsonObject);
        this.BuildApartment(jsonObject);
        this.BuildHomeCode(jsonObject);
        this.BuildCustomerNames(jsonObject);


        this.BuildCustomName(jsonObject);
    }



    private BuildCustomName(jsonObject) {
        this.order.Customer.CustomName = (jsonObject["custom_name"] == null ? "" : jsonObject["custom_name"]);
    }


    private BuildEntrance(jsonObject) {
        this.order.Entrance = (jsonObject["entrance"] == null ? "" : jsonObject["entrance"]);
    }

    private BuildFloor(jsonObject) {
        this.order.Floor = (jsonObject["floor"] == null ? "" : jsonObject["floor"]);
    }

    private BuildApartment(jsonObject) {
        this.order.Apartment = (jsonObject["apartment"] == null ? "" : jsonObject["apartment"]);
    }

    private BuildHomeCode(jsonObject) {
        this.order.HomeCode = (jsonObject["home_code"] == null ? "" : jsonObject["home_code"]);
    }




    private BuildMinimal(jsonObject): Order {
        let minimalOrder = new Order(jsonObject["order_id"]);



        minimalOrder.Customer = new Customer(1, jsonObject["firstname"], jsonObject["telephone"], jsonObject["email"]);
        minimalOrder.Comment = jsonObject["comment"];



        minimalOrder.DateAdded = new Date(jsonObject["date_added"]);
        minimalOrder.ShippingAdress = jsonObject["shipping_address_1"];
        minimalOrder.ShippingArea = jsonObject["shipping_company"];



        minimalOrder.StatusId = jsonObject["order_status_id"];
        minimalOrder.Total = jsonObject["total"];
        minimalOrder.GloablEquipmentCount = jsonObject["gloablEquipmentCount"];
        minimalOrder.GroupId = jsonObject["order_group_id"];
        minimalOrder.Priority = jsonObject["order_priority"];
        minimalOrder.Readiness = jsonObject["readiness"];
        minimalOrder.CancelMessage = jsonObject["canceling_message"];
        minimalOrder.CustomerShippingTime = jsonObject["shipping_time"];
        minimalOrder.CustomerShippingDate = jsonObject["shipping_date"];

        if (jsonObject["contact_shipping_date"] != null && jsonObject["contact_shipping_date"] != "") {

            let shippingDate = new Date(jsonObject["contact_shipping_date"]);

            minimalOrder.ShippingDate = new Date(shippingDate);
            minimalOrder.ShippingHour = shippingDate.getHours();
            minimalOrder.ShippingMinutes = shippingDate.getMinutes();
        } else {
            if (minimalOrder.CustomerShippingTime != null) {
                let times = minimalOrder.CustomerShippingTime.split(':');
                minimalOrder.ShippingDate = new Date(minimalOrder.CustomerShippingDate);
                minimalOrder.ShippingHour = Number(times[0]);
                minimalOrder.ShippingMinutes = Number(times[1]);
            }
        }

        minimalOrder.UpdateTimer();

        if (jsonObject["reviews"] != null) {
            jsonObject["reviews"].forEach(review => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );
                minimalOrder.Reviews.push(
                    {
                        Id: review["order_reviews_id"],
                        Comment: review["comment"],
                        StatusId: review["order_reviews_type_id"],
                        AddedDate: review["date_added"],
                        Name: review["name"]
                    }
                );
            });
        }


        return minimalOrder;
    }


    private BuildCourier(jsonObject) {
        this.order.Courier = jsonObject["courier"];
    }
    private BuildTimer() {
        this.order.UpdateTimer();
    }
    private BuildCustomer(jsonObject) {
        this.order.Customer = new Customer(1, jsonObject["firstname"], jsonObject["lastname"], jsonObject["telephone"], jsonObject["telephone_2"], jsonObject["email"]);
    }
    private BuildComment(jsonObject) {
        this.order.Comment = jsonObject["comment"];
    }
    private BuildDateAdded(jsonObject) {
        this.order.DateAdded = new Date(jsonObject["date_added"].replace(/-/g, "/"));
    }
    private BuildShippingAdress(jsonObject) {
        this.order.ShippingAdress = jsonObject["shipping_address_1"];
    }
    private BuildShippingArea(jsonObject) {
        this.order.ShippingArea = jsonObject["shipping_company"];
    }
    private BuildShippingMethod(jsonObject) {
        this.order.ShippingMethod = jsonObject["shipping_method"];
    }
    private BuildDate(jsonObject) {
        this.order.DateAdded = jsonObject["date_added"];
    }
    private BuildCancelMessage(jsonObject) {
        this.order.CancelMessage = jsonObject["canceling_message"];
    }
    private BuildStatus(jsonObject) {
        this.order.StatusId = jsonObject["order_status_id"];
    }
    private BuildTotal(jsonObject) {
        this.order.Total = jsonObject["total"];
    }
    private BuildPaymented(jsonObject) {
        this.order.Paymented = jsonObject["paymented"] == "1" ? true : false;
    }
    private BuildIsNotCall(jsonObject) {
        this.order.IsNotCall = jsonObject["is_not_call_checkbox"] == "1" ? true : false;
    }
    private BuildIsReadinessShipping(jsonObject) {
        this.order.IsReadinessShipping = jsonObject["is_readiness_shipping"] == "1" ? true : false;
    }
    private BuildGloablEquipmentCount(jsonObject) {
        this.order.GloablEquipmentCount = jsonObject["gloablEquipmentCount"];
    }
    private BuildGroupId(jsonObject) {
        this.order.GroupId = jsonObject["order_group_id"];
    }
    private BuildPriority(jsonObject) {
        this.order.Priority = jsonObject["order_priority"];
    }
    private BuildCardPayment(jsonObject) {
        if (jsonObject["payment_method"] == "Оплата наличными") {
            this.order.CardPayment = false;
        }
        if (jsonObject["payment_method"] == "Оплата картой") {
            this.order.CardPayment = true;
        }
    }
    private BuildPaymentMethod(jsonObject) {
        this.order.PaymentMethod = jsonObject["payment_method"];
    }

    private BuildShippingDate(jsonObject) {
        if (jsonObject["contact_shipping_date"] != null && jsonObject["contact_shipping_date"] != "") {

            let shippingDate = new Date(jsonObject["contact_shipping_date"]);
            let orderReadyDate = new Date(jsonObject["contact_order_ready_date"]);

            this.order.ShippingDate = new Date(shippingDate);
            this.order.ShippingHour = shippingDate.getHours();
            this.order.ShippingMinutes = shippingDate.getMinutes();


            this.order.OrderReadyDate = new Date(orderReadyDate);
            this.order.OrderReadyHour = orderReadyDate.getHours();
            this.order.OrderReadyMinutes = orderReadyDate.getMinutes();


        } else {
            let shippingDate = new Date(jsonObject["shipping_date"] + " " + jsonObject["shipping_time"] + ":00");
            this.order.ShippingDate = new Date(shippingDate);
            this.order.ShippingHour = shippingDate.getHours();
            this.order.ShippingMinutes = shippingDate.getMinutes();

        }
    }


    BuildCustomerShippingTime(jsonObject: any) {
        this.order.CustomerShippingTime = jsonObject["shipping_time"];

    }
    BuildCustomerShippingDate(jsonObject: any) {
        this.order.CustomerShippingDate = jsonObject["shipping_date"];

    }



    private BuildEndedDate(jsonObject) {
        if (jsonObject["ended_date"] != null) {
            let dateTimeParts = jsonObject["ended_date"].split(/[- :]/);
            dateTimeParts[1]--;
            //dateTimeParts[2]--;
            this.order.EndedDate = new Date(
                Date.UTC(
                    dateTimeParts[0], dateTimeParts[1], dateTimeParts[2],
                    dateTimeParts[3], dateTimeParts[4], dateTimeParts[5]
                )
            );
            this.order.EndedDate.setHours(dateTimeParts[3]);
            this.order.EndedDate.setMinutes(dateTimeParts[4]);
        } else {
            this.order.EndedDate = null;
        }
    }


    private BuildReadiness(jsonObject) {
        this.order.Readiness = jsonObject["readiness"];
    }



    private BuildProducts(jsonObject) {
        this.order.Products = [];
        if (jsonObject["products"] != null) {
            jsonObject["products"].forEach(product => {
                let newProduct: Product = new Product(product["product_id"], product["name"],
                    product["price"], product["count"], product["comment"]);
                newProduct.IsWeightable = jsonObject["price_suffix"] != null || jsonObject["price_suffix"] != '' ? true : false;

                this.order.Products.push(newProduct);
            });
        }
    }


    private BuildCustomerNames(jsonObject) {
        this.order.CustomerNames = [];
        if (jsonObject["customerNames"] != null) {
            jsonObject["customerNames"].forEach(customerName => {
                this.order.CustomerNames.push(customerName["custom_name"]);
            });
        }
    }


    private BuildPastOrders(jsonObject) {
        this.order.PastOrders = [];
        if (jsonObject["pastOrders"] != null) {
            jsonObject["pastOrders"].forEach(order => {
                let pastOrder = this.BuildMinimal(order);
                this.order.PastOrders.push(pastOrder);
            });
        }
    }
    private BuildReviews(jsonObject) {
        this.order.Reviews = [];
        if (jsonObject["reviews"] != null) {
            jsonObject["reviews"].forEach(review => {
                //this.order.Reviews.push( new OrderReview( review["id"], review["comment"] ) );
                this.order.Reviews.push(
                    {
                        Id: review["id"],
                        Comment: review["comment"],
                        StatusId: review["order_reviews_type_id"],
                        AddedDate: review["date_added"],
                        Name: review["name"]
                    }
                );
            });
        } else {
            this.order.Reviews = [];
        }
    }
    private BuildKitchenNotes(jsonObject) {
        if (jsonObject["kitchenNotes"] != null) {
            jsonObject["kitchenNotes"].forEach(note => {
                this.order.KitchenNotes.push(new Note(note["id"], note["title"], note["comment"]));
            });
        } else {
            this.order.KitchenNotes = [];
        }
    }

    private BuildDeliveryNotes(jsonObject) {
        this.order.DeliveryNotes = [];
        if (jsonObject["deliveryNotes"] != null) {
            jsonObject["deliveryNotes"].forEach(note => {
                this.order.DeliveryNotes.push(new Note(note["id"], note["title"], note["comment"]));
            });
        } else {
            this.order.DeliveryNotes = [];
        }
    }

    private BuildHistory(jsonObject) {
        this.order.Histories = [];
        if (jsonObject["histories"] != null) {
            jsonObject["histories"].forEach(history => {
                this.order.Histories.push(
                    new OrderHistory(history["order_history_id"], history["order_id"], history["comment"], history["order_status_id"], new Date(history["date_added"].replace(/-/g, "/")))
                );
            });
        } else {
            this.order.Histories = [];
        }


    }

    private BuildEquipments(jsonObject) {
        this.order.Equipments = [];
        if (jsonObject["equipments"] != null) {
            jsonObject["equipments"].forEach(equipment => {
                this.order.Equipments.push(new Equipment(equipment["id"], equipment["name"], equipment["count"], equipment["isGlobal"]));
            });
        } else {
            this.order.Equipments = [];
        }
    }




}