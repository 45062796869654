import { Component, OnInit } from '@angular/core';
import { EquipmentService } from '../../../services/equipment.service';
import { OrdersService } from '../../../services/order/orders.service';

@Component({
  selector: 'app-settings-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  public AddingEquipment: boolean = false;
  public NewEquipmentName: string = "";
  public selectedIds: Array<any> = new Array<any>();

  constructor(public EquipmentService: EquipmentService, public OrdersService: OrdersService,) { }

  ngOnInit(): void {
  }

  OpenModalAddingEquipment() {
    this.AddingEquipment = true;
  }

  CloseModalAddingEquipment() {
    this.AddingEquipment = false;
    this.NewEquipmentName = "";
  }

  AddModalAddingEquipment() {
    this.AddingEquipment = false;
    this.EquipmentService.AddEquipment(this.NewEquipmentName);
    this.NewEquipmentName = "";

    //TODO: Сделать обнуление в сервисе
    this.OrdersService.Page = 0;
    this.OrdersService.PageOfVisible = 0;
    this.OrdersService.LoadOrders();
  }

  selected(event) {
    if (event.selected) {
      this.selectedIds.push(event.value);
    } else {

      let findedElementIndex = this.selectedIds.findIndex(element => element == event.value);
      this.selectedIds.splice(findedElementIndex, 1);
    }
  }

  RemoveEquipment() {
    this.EquipmentService.RemoveEquipment(this.selectedIds);
    this.selectedIds = new Array<any>();

    //TODO: Сделать обнуление в сервисе
    this.OrdersService.Page = 0;
    this.OrdersService.PageOfVisible = 0;
    this.OrdersService.LoadOrders();
  }

}
