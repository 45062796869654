import { Injectable } from '@angular/core';
import { BackButton } from '../models/navigation/back-button.model';
import { NavigationAction } from '../models/navigation/navigation-action.model';
import { FloatingAction } from '../models/tools/floating-action.modal';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public BackButton: BackButton = new BackButton();

  public WindowName: string = "";
  public WindowNameLink: string = "";
  public WindowNameClipboard: string = "";

  public Actions: Array<any> = new Array<any>();
  public SubActions: Array<any> = new Array<any>();

  public Breadcrumbs: Array<any> = new Array<any>();

  public IsNotificationsListOpened: boolean = false;
  public IsReviewOpened: boolean = false;
  public IsUserOpened: boolean = false;

  public FloatingActions: Array<FloatingAction> = new Array<FloatingAction>();

  constructor() { }

  public ClearAll() {
    this.WindowNameLink = ""
    this.WindowNameClipboard = ""
    this.BackButton = new BackButton();
    this.FloatingActions = new Array<FloatingAction>();
    this.ClearActions();
    this.ClearSubActions();
    this.ClearBreadcrumb();
  }

  public ClearActions() {
    //this.Actions = new Array<any>();
    this.Actions.splice(0, this.Actions.length)
  }

  public ClearSubActions() {
    //this.SubActions = new Array<any>();
    this.SubActions.splice(0, this.SubActions.length)
  }

  public AddBreadcrumb(name: string, link: string, isCurrent: boolean = false) {
    this.Breadcrumbs.push({
      name: name,
      link: link,
      isCurrent: isCurrent
    });
  }

  public AddSubActions(NavigationAction: NavigationAction) {
    this.SubActions.push(NavigationAction);
  }

  public AddFloatingAction(FloatingAction: FloatingAction) {
    this.FloatingActions.push(FloatingAction);
  }

  public AddAction(Icon: string, Action: any, BadgeCount: number = 0) {
    this.Actions.push({
      Icon: Icon,
      Action: Action,
      BadgeCount: BadgeCount
    });
  }

  public UpdateBadgeCount(name: string, badgeCount: number) {
    let action = this.Actions.find(action => action.Icon == name);
    if (action != null) {
      action.BadgeCount = badgeCount;
    }

  }

  public ClearBreadcrumb() {
    this.Breadcrumbs = new Array<any>();
  }

  HideAllElements() {
    this.IsNotificationsListOpened = false;
    this.IsReviewOpened = false;
    this.IsUserOpened = false;
  }

  ToggleReviewElement() {
    this.IsNotificationsListOpened = false;
    this.IsReviewOpened = !this.IsReviewOpened;
    this.IsUserOpened = false;
  }

  ToggleNotificationsElement() {
    this.IsNotificationsListOpened = !this.IsNotificationsListOpened;
    this.IsReviewOpened = false;
    this.IsUserOpened = false;
  }

  ToggleUserElement() {
    this.IsNotificationsListOpened = false;
    this.IsReviewOpened = false;
    this.IsUserOpened = !this.IsUserOpened;
  }

}
