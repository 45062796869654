import { Identifiable } from '../tools/identifiable.model';
import { Order } from './order.model';

export class OrderGroup extends Identifiable {

  public StatusId: number = 1;
  public Status: string = "";
  public Orders: Array<Order> = new Array<Order>();
  public DateAdded: Date = new Date();
  public Courier: any = null;

  constructor(id: number, statusId: number, DateAdded: Date, courier: any, orders: Array<Order>) {
    super(id);

    this.Orders = orders;
    this.StatusId = statusId;

    if (this.StatusId == 3) {
      this.Status = "В пути";
    }

    if (this.StatusId == 4) {
      this.Status = "Ожидает";
    }

    this.DateAdded = DateAdded;

    this.Courier = courier;

  }

  SortOrders() {
    this.Orders.sort(
      (first: Order, second: Order) => {
        if (first.Priority > second.Priority) {
          return -1;
        }
        if (first.Priority < second.Priority) {
          return 1;
        }
        return 0;
      }
    );
  }

}