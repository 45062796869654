<div class="banquet-vault-list-wrapper" *ngIf="BanquetsService.IsLoaded">

    <div class="banquet_filter_wrapper">
        <app-banquet-filter></app-banquet-filter>
    </div>

    <div class="list_wrapper">
        <ibm-structured-list>
            <ibm-list-header>

                <ibm-list-column>Дата</ibm-list-column>
                <ibm-list-column>Подтв.</ibm-list-column>
                <ibm-list-column>Фамилия</ibm-list-column>

                <ibm-list-column>Задаток</ibm-list-column>
                <ibm-list-column>Гости</ibm-list-column>
                <ibm-list-column>Дети</ibm-list-column>
                <ibm-list-column>Стол</ibm-list-column>
                <ibm-list-column>Время</ibm-list-column>

                <ibm-list-column>Комментарий</ibm-list-column>
                <ibm-list-column>№</ibm-list-column>


            </ibm-list-header>


            <ibm-list-row class="banquet_row {{banquet.PaymentStatus}}"
                *ngFor="let banquet of BanquetsService.VisibleObjects.Values" (click)="ToggleKitchenStatus(banquet)">



                <ibm-list-column>{{banquet.Date| date:'dd.MM'}} ({{banquet.TimeId == 1 ? "День" : "Вечер"}})
                </ibm-list-column>
                <ibm-list-column>
                    <svg *ngIf="banquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 32 32" class="confirmed-icon confirmed">
                        <use xlink:href="#checkmark-filled"></use>
                    </svg>
                    <svg *ngIf="!banquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 32 32" class="confirmed-icon unconfirmed">
                        <use xlink:href="#close-filled"></use>
                    </svg>
                </ibm-list-column>
                <ibm-list-column>{{banquet.ContactCustomer.LastName}}</ibm-list-column>
                <ibm-list-column>{{banquet.GetSumPayment()}}</ibm-list-column>

                <ibm-list-column>{{banquet.Guests}} {{ToolsService.NumericalDeclensions(banquet.Children,
                    ['чел.', 'чел.', 'чел.'])}}</ibm-list-column>
                <ibm-list-column>{{banquet.Children}} {{ToolsService.NumericalDeclensions(banquet.Children,
                    ['реб.', 'реб.', 'дет.'])}}</ibm-list-column>


                <ibm-list-column>
                    <div *ngIf="!banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa"
                        fxLayoutGap="4px">
                        <div class="banquet_table" *ngFor="let table of banquet.ShowerListTables">
                            <div>{{table.Name}}</div>
                        </div>
                        <div class="banquet_table_show" (click)="ActivateAllTablesShowing($event, banquet)">Показать
                            все
                        </div>
                    </div>

                    <div *ngIf="banquet.IsAllTablesShowed" class="banquet_tables" fxLayout="row wrpa" fxLayoutGap="4px">
                        <div class="banquet_table" *ngFor="let table of banquet.Tables">
                            <div>{{table.Name}}</div>
                        </div>
                    </div>


                </ibm-list-column>

                <ibm-list-column>{{banquet.Date| date:'HH:mm'}}</ibm-list-column>
                <ibm-list-column>{{banquet.Comment}}</ibm-list-column>

                <ibm-list-column>№{{banquet.Id}}<span
                        *ngIf="banquet.CustomNumber != null && banquet.CustomNumber != ''">
                        | </span>{{banquet.CustomNumber}}</ibm-list-column>




            </ibm-list-row>
        </ibm-structured-list>
    </div>



    <button class="load_more_button" (click)="LoadMoreBanquet()">Еще</button>


</div>