import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyService } from './property.service';
import { NotificationAlertType } from '../models/tools/notification.model';
import { NotificationService } from './notification.service';
import { HeaderService } from './header.service';
import { Review } from '../models/tools/review.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  public Comment: string = "";
  public Reviews: Array<Review> = new Array<Review>();
  public ReviewStatusId: number = 1;
  public Link: string = "";
  public IsAddedModalOpened: boolean = false;

  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router) {

  }

  public LoadReviews() {
    let request = `https://ochagzp.com.ua/index.php?route=api/review/load&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {
          data["data"].forEach(element => {
            this.Reviews.push(new Review(
              element["review_id"],
              element["name"],
              element["comment"],
              element["date_added"],
              element["system_review_type_id"],
              element["link"]));
          });
        }
      });
  }

  public GetReview(id): Observable<any> {
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/review/get&token=${this.property.Token}&id=${id}`;
    return this.http.get(request);
  }


  public Send() {
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/review/add&token=${this.property.Token}`);
    let entity = {
      comment: this.Comment,
      link: this.Link,
      status: this.ReviewStatusId,
    };
    var form_data = new FormData();
    for (var key in entity) {
      form_data.append(key, entity[key]);
    }

    this.http.post(request, form_data).subscribe(
      (data: any) => {
        if (data["status"] == "700") {
          this.NotificationService.Add(`Отзыв отправлен`, `Вы отправили отзыв для администратора`, new Date(), NotificationAlertType.Default);
          let element = data["review"];
          this.Reviews.unshift(new Review(
            element["review_id"],
            element["name"],
            element["comment"],
            element["date_added"],
            element["system_review_type_id"],
            element["link"]));

        }
      }
    );
    this.Comment = "";
    this.ReviewStatusId = 1;
    this.Link = "";
    this.IsAddedModalOpened = false;
  }


  public OpenAddingModal(status: number) {
    this.ReviewStatusId = status;
    this.Link = this.router.url;
    this.IsAddedModalOpened = true;
  }

  public OnCancel() {
    this.Comment = "";
    this.ReviewStatusId = 1;
    this.Link = "";
    this.IsAddedModalOpened = false;
  }
}
