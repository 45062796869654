import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuSettingsComponent implements OnInit {



  @Output() OnChangeActiveMenu = new EventEmitter<any>();

  public ActiveMenu: number = 0;

  constructor() { }

  ngOnInit(): void {

  }

  ChangeActiveMenu(menuId) {
    this.ActiveMenu = menuId;
    this.OnChangeActiveMenu.emit(menuId);
  }
}
