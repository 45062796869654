import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BanquetPaymentsService } from '../../../services/banquet/banquet-payments.service';
import { PropertyService } from '../../../services/property.service';
import { HeaderService } from '../../../services/header.service';
import { BanquetPaymentHistory } from 'src/app/models/banquet/banquet-payment-history';
import { OchagboxService } from '../../../services/ochagbox.service';
import { OchagboxHistoryService } from 'src/app/services/ochagbox-history.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { NavigationAction } from 'src/app/models/navigation/navigation-action.model';
import { NumberInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/number-invalid-filed-value.model';


@Component({
  selector: 'app-ochagbox-management',
  templateUrl: './ochagbox-management.component.html',
  styleUrls: ['./ochagbox-management.component.scss']
})
export class OchagboxManagementComponent implements OnInit {

  public IsFilterOpened: boolean = false;

  public ochagboxList: Array<any> = [];

  constructor(public PropertyService: PropertyService, private route: ActivatedRoute,
    private router: Router, public HeaderService: HeaderService,
    public OchagboxService: OchagboxService, public OchagboxHistoryService: OchagboxHistoryService,
    public ModalAddingService: ModalAddingService,) { }

  ngOnInit(): void {

    this.HeaderService.ClearAll();

    this.HeaderService.WindowName = "Управление Очаг BOX";
    this.HeaderService.BackButton.Text = "Меню Очаг BOX";
    this.HeaderService.BackButton.Link = "/ochagbox-menu";

    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню Очаг BOX", "/ochagbox-menu");
    this.HeaderService.AddBreadcrumb("Управление Очаг BOX", "/ochagbox-management", true);


    let addSupply = new NavigationAction("Добавить", "add", () => {
      this.OpenAddOchagboxModal();
    });
    addSupply.IsMobile = true;
    addSupply.IsDesktop = true;


    let removeSupply = new NavigationAction("Отнять", "trash", () => {
      this.OpenRemoveOchagboxModal();
    });
    removeSupply.IsMobile = true;
    removeSupply.IsDesktop = true;


    this.HeaderService.AddSubActions(removeSupply);
    this.HeaderService.AddSubActions(addSupply);
  }

  OpenAddOchagboxModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.OchagboxService.AddSupply(this.ModalAddingService.Fields[0].Value, this.ModalAddingService.Fields[1].Value);

    });

    this.ModalAddingService.Show(
      new ModalData("Добавить партию Очаг BOX", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildText("Описание", 0, 450),
        ModalFieldBuilder.BuildNumber("Колиечстао Очаг BOX", 0, 100000, 0, [],
          [
            new NumberInvalidFieldValue(0, "0 — недопустимое значение")
          ]),
      ]
    );
  }

  OpenRemoveOchagboxModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.OchagboxService.RemoveSupply(this.ModalAddingService.Fields[0].Value, this.ModalAddingService.Fields[1].Value);

    });

    this.ModalAddingService.Show(
      new ModalData("Отнять партию Очаг BOX", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildText("Описание", 0, 450),
        ModalFieldBuilder.BuildNumber("Колиечстао Очаг BOX", 0, 100000, 0, [],
          [
            new NumberInvalidFieldValue(0, "0 — недопустимое значение")
          ]),
      ]
    );
  }

  LoadMore() {
    this.OchagboxService.LoadMoreSupply();
  }



}
