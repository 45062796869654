<div class="banquet_name" fxLayout="row" fxLayoutAlign="space-between start">
    <span>Заказ №{{banquet.Id}}</span>
</div>
<div class="banquet_status" fxLayout="row" fxLayoutAlign="space-between start">
    <div>{{banquet.ContactCustomer.FirstName}} {{banquet.ContactCustomer.LastName}}</div>
</div>
<div class="banquet_status" fxLayout="row" fxLayoutAlign="space-between start">
    <div>{{banquet.CategoryName}}</div>
</div>

<div class="banquet_bottom" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="banquet_time">{{banquet.Date| date:'d MMM, y'}} — {{banquet.Date| date:'HH:mm:ss'}}</div>
    <div class="banquet_icon">

    </div>
</div>