<div *ngIf="TransactionBanquetDate != null">



    <div class="data_table_wrapper">
        <div class="data_table_row">
            <div class="data_table_item">
                <div class="data_table_lable">Вся полученная сумма на {{TransactionBanquetDate |
                    date:'dd.MM.yy'}}</div>
                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredPaymentValue |
                    largeNumber}}₴
                </div>
            </div>
        </div>
        <div class="data_table_row">
            <div class="data_table_item">
                <div class="data_table_lable">Задатки</div>
                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredDepositPaymentValue |
                    largeNumber}}₴</div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable">Программа</div>
                <div class="data_table_value">{{BanquetPaymentsService.AllFilteredProgramPaymentValue |
                    largeNumber}}₴</div>
            </div>
        </div>


        <div class="data_table_row bottom">
            <div class="data_table_item">
                <div class="data_table_lable">Взрослые</div>
                <div class="data_table_value">{{BanquetPaymentsService.CountOfGuestsByPaymants}}</div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable">Дети</div>
                <div class="data_table_value">{{BanquetPaymentsService.CountOfChildsByPayments}}</div>
            </div>
        </div>
    </div>

    <div class="filter_subtitle">Задатки</div>

    <div class="data_table_wrapper">
        <div class="data_table_row">
            <div class="data_table_item">
                <div class="data_table_lable">Задатки</div>
                <div class="data_table_value income">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-up"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.FilteredIncomePaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable">Возвраты</div>
                <div class="data_table_value cost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-down"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.FilteredCostPaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
        </div>
        <div class="data_table_row">
            <div class="data_table_item">
                <div class="data_table_lable confirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#checkmark-filled"></use>
                    </svg>
                    <span>Подписаны</span>
                </div>
                <div class="data_table_value income">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-up"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.SignedFilteredIncomePaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable confirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#checkmark-filled"></use>
                    </svg>
                    <span>Подписаны</span>
                </div>
                <div class="data_table_value cost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-down"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.SignedFilteredCostPaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
        </div>
        <div class="data_table_row bottom">
            <div class="data_table_item">
                <div class="data_table_lable unconfirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#close-filled"></use>
                    </svg>
                    <span>Не подписаны</span>
                </div>
                <div class="data_table_value income">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-up"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.UnsignedFilteredIncomePaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable unconfirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#close-filled"></use>
                    </svg>
                    <span>Не подписаны</span>
                </div>
                <div class="data_table_value cost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-down"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.UnsignedFilteredCostPaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="TransactionBanquetDate == null">


    <div class="data_table_wrapper">
        <div class="data_table_row">
            <div class="data_table_item">
                <div class="data_table_lable">Неподписанные транзакции</div>
                <div class="data_table_value">{{BanquetPaymentsService.UnsignedPaymentValue |
                    largeNumber}}₴
                </div>
            </div>
        </div>
        <div class="data_table_row bottom">
            <div class="data_table_item">
                <div class="data_table_lable unconfirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#close-filled"></use>
                    </svg>
                    <span>Не подписаны</span>
                </div>
                <div class="data_table_value income">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-up"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.UnsignedIncomePaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
            <div class="data_table_item">
                <div class="data_table_lable unconfirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#close-filled"></use>
                    </svg>
                    <span>Не подписаны</span>
                </div>
                <div class="data_table_value cost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"
                        class="data_table_lable_icon">
                        <use xlink:href="#caret-down"></use>
                    </svg>
                    <span>{{BanquetPaymentsService.UnsignedCostPaymentValue |
                        largeNumber}}₴</span>
                </div>
            </div>
        </div>
    </div>


</div>