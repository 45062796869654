import { Component, OnInit, Input } from '@angular/core';
import { DeliveryService } from '../../../services/delivery.service';
import { CouriersService } from '../../../services/order/couriers.service';
import { OrdersService } from '../../../services/order/orders.service';
import { OrderGroup } from '../../../models/store/order-group.model';
import { Order } from '../../../models/store/order.model';

@Component({
  selector: 'app-courier-group-list',
  templateUrl: './courier-group-list.component.html',
  styleUrls: ['./courier-group-list.component.scss']
})
export class CourierGroupListComponent implements OnInit {
  @Input("selected") selectedGroupsIds: Array<any> = new Array<any>();

  constructor(public DeliveryService: DeliveryService, private OrdersService: OrdersService,
    public CouriersService: CouriersService) { }

  ngOnInit(): void {

  }

  selected(event) {
    if (event.selected) {
      this.selectedGroupsIds.push(event.value);
    } else {
      let findedElementIndex = this.selectedGroupsIds.findIndex(element => element == event.value);
      this.selectedGroupsIds.splice(findedElementIndex, 1);
    }
  }

  selectedGroups(event) {
    if (event.selected) {
      this.selectedGroupsIds.push(event.value);
    } else {
      let findedElementIndex = this.selectedGroupsIds.findIndex(element => element == event.value);
      this.selectedGroupsIds.splice(findedElementIndex, 1);
    }
  }

  SelectChange(event, order, delivery: OrderGroup) {
    this.OrdersService.SaveOrder(order);
    delivery.SortOrders();
  }


  Ungroup(event, delivery: OrderGroup) {
    event.stopPropagation();
    this.DeliveryService.Ungroup(delivery);
  }


  UpPriority(event, order: Order, delivery: OrderGroup) {
    event.stopPropagation()
    order.Priority++;
    this.OrdersService.SaveOrder(order);
    delivery.SortOrders();
  }

  DownPriority(event, order: Order, delivery: OrderGroup) {
    order.Priority--;
    this.OrdersService.SaveOrder(order);
    delivery.SortOrders();
  }



  SelectCourier(event, courier: any) {
    this.CouriersService.ActiveCourier = courier;
  }


}
