import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { OrdersService } from '../../../services/order/orders.service';
import { System } from '../../../models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-banquet-menu',
  templateUrl: './banquet-menu.component.html',
  styleUrls: ['./banquet-menu.component.scss']
})
export class BanquetMenuComponent implements OnInit {

  public IsFilterOpened: boolean = false;

  constructor(public HeaderService: HeaderService, public PropertyService: PropertyService,
    private route: ActivatedRoute, private router: Router, public OrdersService: OrdersService) {

  }

  ngOnInit() {
    this.HeaderService.WindowName = "Меню банкетов";


    this.HeaderService.ClearAll();
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню банкетов", "/banquet-menu", true);
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";



    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);


    this.HeaderService.AddAction("person", () => {
      this.HeaderService.IsUserOpened = true;
    });



  }

}
