<div class="courier_adeed_wrapper" fxLayout="row wrap" fxLayoutGap="8px">
    <ibm-tile class="order_group_wrapper" *ngFor="let delivery of DeliveryService.AddedOrderGroups"
        [value]="delivery.Id">

        <div class="order_group_name">Развоз №{{delivery.Id}}</div>

        <div class="order_wrapper" *ngFor="let order of delivery.Orders">
            <div class="order_name">Заказ №{{order.id}}</div>
            <div class="order_adress">
                <div>{{order.ShippingAdress}}</div>
            </div>

            <div class="order_bottom" fxLayout="row" fxLayoutAlign="space-between start">
                <div class="order_time">{{order.dateAdded | date:'h:mm'}}</div>
            </div>
        </div>

    </ibm-tile>
</div>