<div class="adding-banquet-wrapper" *ngIf="EmptyBanquet!=null && BanquetId != -1">


    <banquet-tables-form [id]="BanquetId" [banquet]="EmptyBanquet"></banquet-tables-form>

    <div class="block_wrapper">
        <div fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                <ibm-label class="adding-banquet-field" *ngIf="EmptyBanquet != null">
                    Фамилия
                    <input theme="light" (change)="OnChangeFileds()" [(ngModel)]="EmptyBanquet.ContactCustomer.LastName" (keyup.enter)="keytab(firstnameInput)" ibmText>
                </ibm-label>

                <ibm-label class="adding-banquet-field" *ngIf="EmptyBanquet != null">
                    Имя
                    <input #firstnameInput theme="light" (change)="OnChangeFileds()" (keyup.enter)="keytab(phoneInput)" [(ngModel)]="EmptyBanquet.ContactCustomer.FirstName" ibmText>
                </ibm-label>
            </div>
            <ibm-label *ngIf="EmptyBanquet != null">
                Телефон
                <input #phoneInput theme="light" (change)="OnChangeFileds()" (keyup.enter)="blur($event)" [(ngModel)]="EmptyBanquet.ContactCustomer.Phone" ibmText>
            </ibm-label>
        </div>
    </div>


    <div class="block_wrapper">
        <div fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                <ibm-label class="adding-banquet-field" *ngIf="EmptyBanquet != null">
                    Номер банкета
                    <input theme="light" (change)="OnChangeFileds()" [(ngModel)]="EmptyBanquet.CustomNumber" ibmText>
                </ibm-label>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                <ibm-dropdown theme="light" class="area-dropdown" value="content" label="Категория" (selected)="OnBanquetCategoryChange($event)" [(ngModel)]="BanquetsService.BanquetTypes[0].content">
                    <ibm-dropdown-list [items]="BanquetsService.BanquetTypes"></ibm-dropdown-list>
                </ibm-dropdown>
            </div>


            <div *ngIf="EmptyBanquet.CategoryName == 'День Рождения'">
                <div class="banquet-card-item">
                    <ibm-label class="adding-banquet-field">
                        Имя именинника
                        <input theme="light" (change)="OnChangeTargetName()" [(ngModel)]="EmptyBanquet.Target" ibmText>
                    </ibm-label>
                </div>
            </div>





            <div *ngIf="EmptyBanquet.CategoryName == 'Свадьба'">
                <div class="banquet-card-item">
                    <ibm-label class="adding-banquet-field">
                        Имя жениха
                        <input theme="light" (change)="OnChangeTargetName()" [(ngModel)]="EmptyBanquet.Target" ibmText>
                    </ibm-label>
                </div>
                <div class="banquet-card-item">
                    <ibm-label class="adding-banquet-field">
                        Имя невесты
                        <input theme="light" (change)="OnChangeTargetSecondName()" [(ngModel)]="EmptyBanquet.TargetSecond" ibmText>
                    </ibm-label>
                </div>
            </div>




            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                <ibm-label class="adding-banquet-field" *ngIf="EmptyBanquet != null">
                    Количество гостей
                    <ibm-number theme="light" min="0" [(ngModel)]="EmptyBanquet.Guests" (change)="ChnageGuestsCount($event)">
                    </ibm-number>
                </ibm-label>

                <ibm-label class="adding-banquet-field" *ngIf="EmptyBanquet != null">
                    Количество детей
                    <ibm-number theme="light" min="0" [(ngModel)]="EmptyBanquet.Children" (change)="ChnageChildrenCount($event)">
                    </ibm-number>
                </ibm-label>

            </div>
        </div>
    </div>



    <div class="block_wrapper">
        <div fxLayout="column" fxLayoutGap="24px">
            <div class="banquet-card-row">
                <div class="banquet-card-item">
                    <ibm-label class="adding-banquet-field">
                        Комментарий
                        <input theme="light" (change)="ChnageComment()" [(ngModel)]="EmptyBanquet.Comment" ibmText>
                    </ibm-label>
                </div>
            </div>
        </div>
    </div>







    <div class="create_new_banquet" [ngClass]="{'disabled': IsDisabledCreateButton, 'creating': IsCreatingBanquet}" (click)="Create()">

        <span *ngIf="IsCreatingBanquet == false">Создать Банкет</span>

        <span *ngIf="IsCreatingBanquet">Создать Банкет</span>
        <ibm-loading *ngIf="IsCreatingBanquet" size="sm"></ibm-loading>

    </div>


</div>