<div class="subheader_wrapper">
    <div class="left">
        <div class="back_wrapper" [ngClass]="{'showed': HeaderService.BackButton.Text.length > 0}">
            <a class="back" [routerLink]="[HeaderService.BackButton.PreventLink == '' ? HeaderService.BackButton.Link : HeaderService.BackButton.PreventLink]" routerLinkActive="router-link-active">
                <div class="back_name" *ngIf="HeaderService.BackButton.Text.length > 0">{{ HeaderService.BackButton.Text }}
                </div>
                <div class="back_icon" *ngIf="HeaderService.BackButton.Text.length > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#arrow-left"></use>
                    </svg>
                </div>
            </a>
        </div>
        <div class="title_wrapper" [ngClass]="{'top': HeaderService.BackButton.Text.length == 0}">

            <div *ngIf="HeaderService.WindowNameLink.length == 0 && HeaderService.WindowNameClipboard.length == 0" class="title_value">{{ HeaderService.WindowName }}</div>
            <div *ngIf="HeaderService.WindowNameClipboard.length > 0" class="title_value" (click)="ClipboardService.Copy(HeaderService.WindowNameClipboard)">{{ HeaderService.WindowName }}</div>
            <a *ngIf="HeaderService.WindowNameLink != ''" class="title_value" href="{{HeaderService.WindowNameLink}}" target="_blank">{{ HeaderService.WindowName }}</a>

            <!-- <div class="title_breadcrumb">
                <div class="breadcrumb" fxLayout="row" fxLayoutGap="8px">
                    <div class="breadcrumb_item" *ngFor="let breadcrumb of HeaderService.Breadcrumbs"
                        [ngClass]="{'current': breadcrumb.isCurrent}" [routerLink]="breadcrumb.link">{{breadcrumb.name}}
                    </div>
                </div>
            </div> -->

        </div>
    </div>
    <div class="right">
        <div class="actions" *ngIf="HeaderService.SubActions.length > 0">
            <div class="actions_name">Действия</div>
            <div class="actions_list">

                <div class="action_item" *ngFor="let action of HeaderService.SubActions" (click)="ClickAction(action)" [ngClass]="{'disabled': action.Status == NavigationActionStatus.Disabled, 'disabled': !action.IsDesktop}" [ngClass.lt-md]="{'disabled': !action.IsMobile}">


                    <ibm-tooltip-icon *ngIf="action.Tooltip != ''" [content]="action.Tooltip" placement="bottom" alignment="center">
                        <span fxShow="{{action.IsDesktop}}" fxShow.lt-md="{{action.IsMobile}}" fxLayout="row" fxLayoutAlign="center center">
                            <svg *ngIf="action.Icon == 'view'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#notification"></use>
                            </svg>
                            <svg *ngIf="action.Icon == 'add'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#add"></use>
                            </svg>

                            <svg *ngIf="action.Icon == 'view'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#view"></use>
                            </svg>
                            <svg *ngIf="action.Icon == 'viewOff'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#view-off"></use>
                            </svg>
                            <svg *ngIf="action.Icon == 'filter'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#filter"></use>
                            </svg>
                            <svg *ngIf="action.Icon == 'collapse'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#collapse-categories"></use>
                            </svg>

                            <svg *ngIf="action.Icon == 'update'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#renew"></use>
                            </svg>
                            <svg *ngIf="action.Icon == 'trash'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <use xlink:href="#trash-can"></use>
                            </svg>
                            <div class="text" fxHide.lt-md>{{action.Text}}</div>
                        </span>

                    </ibm-tooltip-icon>


                    <span *ngIf="action.Tooltip == ''" fxShow="{{action.IsDesktop}}" fxShow.lt-md="{{action.IsMobile}}" fxLayout="row" fxLayoutAlign="center center">
                        <svg *ngIf="action.Icon == 'view'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#notification"></use>
                        </svg>
                        <svg *ngIf="action.Icon == 'add'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#add"></use>
                        </svg>

                        <svg *ngIf="action.Icon == 'view'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#view"></use>
                        </svg>
                        <svg *ngIf="action.Icon == 'viewOff'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#view-off"></use>
                        </svg>
                        <svg *ngIf="action.Icon == 'filter'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#filter"></use>
                        </svg>
                        <svg *ngIf="action.Icon == 'collapse'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#collapse-categories"></use>
                        </svg>

                        <svg *ngIf="action.Icon == 'update'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#renew"></use>
                        </svg>
                        <svg *ngIf="action.Icon == 'trash'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                            <use xlink:href="#trash-can"></use>
                        </svg>
                        <div class="text" fxHide.lt-md>{{action.Text}}</div>
                    </span>



                </div>


            </div>
        </div>
    </div>
</div>