<div class="delivery_main" fxLayout="column" fxLayoutGap="16px">
    <div class="main_title">Общие данные (Заказ №{{delivery.Id}})</div>

    <ibm-toggle *ngIf="deliveryOriginal != null" label="Данные клиента" (change)="OnDeliveryOriginalShowed($event)">
    </ibm-toggle>

    <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
        <div class="form_item">
            <ibm-label>
                Дата
                <p>{{delivery.CustomerShippingDate}}</p>
            </ibm-label>
        </div>
        <div class="form_item">
            <ibm-label>
                Время
                <p>{{delivery.CustomerShippingTime}}</p>
            </ibm-label>
        </div>
    </div>

    <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
        <div class="form_item">
            <ibm-label>
                Район
                <p>{{delivery.ShippingArea == null ? "Не указан" : delivery.ShippingArea}}</p>
            </ibm-label>
        </div>
        <div class="form_item">
            <ibm-label>
                Адрес
                <p *ngIf="!IsDeliveryOriginalShowed">{{delivery.ShippingAdress == "" ? "Не указан" :
                    delivery.ShippingAdress}}</p>
                <p *ngIf="IsDeliveryOriginalShowed">{{deliveryOriginal.ShippingAdress}}</p>
            </ibm-label>
            <button class="button_light copy_button"
                (click)="ClipboardService.Copy(delivery.ShippingAdress)">Копировать</button>
        </div>
    </div>

    <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
        <div class="form_item">
            <ibm-label>
                Подъезд
                <p>{{delivery.Entrance == '' ? "—" : delivery.Entrance}}</p>
            </ibm-label>
        </div>
        <div class="form_item">
            <ibm-label>
                Квартира
                <p>{{delivery.Floor == '' ? "—" : delivery.Floor}}</p>
            </ibm-label>
        </div>
    </div>

    <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
        <div class="form_item">
            <ibm-label>
                Этаж
                <p>{{delivery.Apartment == '' ? "—" : delivery.Apartment}}</p>
            </ibm-label>
        </div>
        <div class="form_item">
            <ibm-label>
                Код
                <p>{{delivery.HomeCode == '' ? "—" : delivery.HomeCode}}</p>
            </ibm-label>
        </div>
    </div>



    <div *ngIf="delivery.Customer.Name != '' || delivery.Customer.Lastname != ''" class="form_wrapper" fxLayout="row"
        fxLayoutGap="32px">
        <div class="form_item">
            <ibm-label>
                Имя
                <p>{{delivery.Customer.Name == '' ? "—" : delivery.Customer.Name}}</p>
            </ibm-label>
        </div>
        <div class="form_item">
            <ibm-label>
                Фамилия
                <p>{{delivery.Customer.Lastname == '' ? "—" : delivery.Customer.Lastname}}</p>
            </ibm-label>
        </div>
    </div>




    <app-separator></app-separator>

    <ibm-toggle *ngIf="deliveryOriginal != null" label="Данные клиента" (change)="OnPhoneOriginalShowed($event)">
    </ibm-toggle>

    <div class="form_wrapper">
        <div class="phone_wrapper">
            <ibm-label>
                Телефон
                <p *ngIf="!IsPhoneOriginalShowed">
                    <a [href]="'tel:+'+delivery.Customer.Phone">{{delivery.Customer.Phone}}</a>
                </p>
                <p *ngIf="IsPhoneOriginalShowed">
                    <a [href]="'tel:+'+delivery.Customer.Phone">{{deliveryOriginal.Customer.Phone}}</a>
                </p>
            </ibm-label>
            <button class="button_light copy_button"
                (click)="ClipboardService.Copy(delivery.Customer.Phone)">Копировать</button>
        </div>


        <div class="phone_wrapper" *ngIf="delivery.Customer.SecondPhone!=null && delivery.Customer.SecondPhone!=''">
            <ibm-label>
                Второй телефон
                <p>{{delivery.Customer.SecondPhone}}</p>
            </ibm-label>
            <button class="button_light copy_button"
                (click)="ClipboardService.Copy(delivery.Customer.SecondPhone)">Копировать</button>
        </div>



    </div>

    <app-separator *ngIf="delivery.Comment != ''"></app-separator>

    <ibm-toggle *ngIf="deliveryOriginal != null" label="Данные клиента" (change)="OnCommentOriginalShowed($event)">
    </ibm-toggle>

    <div *ngIf="delivery.Comment != ''" class="form_wrapper" fxLayout="row">
        <ibm-label>
            Комментарий
            <p *ngIf="!IsCommentOriginalShowed">{{delivery.Comment}}</p>
            <p *ngIf="IsCommentOriginalShowed">{{deliveryOriginal.Comment}}</p>
        </ibm-label>
    </div>


    <app-separator></app-separator>

    <div class="block_title">Корзина</div>
    <app-order-cart [(products)]="delivery.Products"
        [(originals)]="deliveryOriginal == null ? [] : deliveryOriginal.Products" [(delivery)]="delivery"
        showOnly="true"></app-order-cart>

    <!-- <div class="total_price">Общая стоимость: {{delivery.Total | number:'1.0-0'}}₴</div> -->

    <div class="total_wrapper" fxLayout="row" fxLayoutAlign="space-between center">
        <span *ngIf="!delivery.CardPayment">Оплата наличными</span>
        <span *ngIf="delivery.CardPayment">Оплата картой</span>
        <div class="total_price">{{delivery.Total | number:'1.0-0'}}₴</div>
    </div>

    <ibm-label *ngIf="delivery.CardPayment">
        <p class="payment_status positive" *ngIf="delivery.Paymented">Оплачено</p>
        <p class="payment_status negative" *ngIf="!delivery.Paymented">Неоплачено</p>
    </ibm-label>


</div>