<div class="comments-wrapper" fxLayout="column" fxLayoutGap="16px">
    <button ibmButton="primary" (click)="OpenAddCommentModal()">Добавить</button>

    <div class="empty-wrapper" *ngIf="BanquetsService.ActiveBanquet.Comments.length == 0">
        В данном банкете отсутствуют комментарии
    </div>

    <div *ngFor="let comment of BanquetsService.ActiveBanquet.Comments">
        <div class="banquet-comment-item">
            <div class="banquet-comment-item__type">
                <div>{{comment.Type}}</div>
                <div class="banquet-area-item__actions" fxLayout="row" fxLayoutGap="8px">


                    <svg class="purchase_icon" (click)="OpenEditCommentModal(comment)"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                        <use xlink:href="#edit"></use>
                    </svg>
                    <img (click)="OpenModalRemoveComment(comment.Id)" src="../../../../assets/icons/trash.svg">


                </div>
            </div>

            <div class="banquet-comment-item__text">{{comment.Text}}</div>
        </div>
    </div>
</div>