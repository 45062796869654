<div class="add_payment_button" (click)="OpenPaymentModal()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0f62fe">
        <use xlink:href="#add"></use>
    </svg>
    Добавить оплату
</div>

<div class="banquet_short_wrapper short_info_wrapper">
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Сумма залога</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.PaymentTotal}}₴</div>
        </div>
        <div class="short_info_item"
            *ngIf="BanquetsService.ActiveBanquet.Date.getMonth() + 1 == 12 && BanquetsService.ActiveBanquet.Date.getDate() > 16">
            <div class="short_info_lable">Сумма программа</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.GetSumProgramPayment()}}₴</div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item" (click)="ToggleKitchenSendedStatus()">
            <div class="short_info_lable">Бегунок на кухню</div>
            <div class="short_info_value">
                <svg *ngIf="BanquetsService.ActiveBanquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg"
                    width="18" height="18" fill="#24A148">
                    <use xlink:href="#checkmark-filled"></use>
                </svg>
                <svg *ngIf="!BanquetsService.ActiveBanquet.SliderKitchenSended" xmlns="http://www.w3.org/2000/svg"
                    width="18" height="18" fill="#FA4D56">
                    <use xlink:href="#close-filled"></use>
                </svg>
            </div>
        </div>
        <div class="short_info_item" (click)="ToggleKeeperSendedStatus()">
            <div class="short_info_lable">Внесение в Keeper</div>
            <div class="short_info_value">
                <svg *ngIf="BanquetsService.ActiveBanquet.KepperSended" xmlns="http://www.w3.org/2000/svg" width="18"
                    height="18" fill="#24A148">
                    <use xlink:href="#checkmark-filled"></use>
                </svg>
                <svg *ngIf="!BanquetsService.ActiveBanquet.KepperSended" xmlns="http://www.w3.org/2000/svg" width="18"
                    height="18" fill="#FA4D56">
                    <use xlink:href="#close-filled"></use>
                </svg>
            </div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Имя заказчика</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.ContactCustomer.FirstName}}
                {{BanquetsService.ActiveBanquet.ContactCustomer.LastName}}</div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Телефон</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.ContactCustomer.Phone}}</div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Взрослые</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.Guests}}
                {{ToolsService.NumericalDeclensions(BanquetsService.ActiveBanquet.Guests,
                ['человек', 'человека', 'человек'])}}</div>
        </div>
        <div class="short_info_item">
            <div class="short_info_lable">Дети</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.Children}}
                {{ToolsService.NumericalDeclensions(BanquetsService.ActiveBanquet.Children,
                ['ребёнок', 'ребёнка', 'детей'])}}</div>
        </div>
    </div>
    <div class="short_info_row">
        <div class="short_info_item">
            <div class="short_info_lable">Зал</div>
            <div class="short_info_value">
                <div fxLayout="row">
                    <div *ngIf="BanquetsService.ActiveBanquet.Area != null" class="banquet_area_chips">
                        {{BanquetsService.ActiveBanquet.Area.Name}}</div>
                    <span *ngIf="BanquetsService.ActiveBanquet.Area == null">—</span>
                </div>
            </div>
        </div>
        <div class="short_info_item">
            <div class="short_info_lable">Столы</div>
            <div class="short_info_value">
                <div class="banquet_tables" fxLayout="row wrpa" fxLayoutGap="4px">
                    <div class="banquet_table" *ngFor="let table of BanquetsService.ActiveBanquet.Tables">
                        <div>{{table.Name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="short_info_row" *ngIf="BanquetsService.ActiveBanquet.Comment != ''">
        <div class="short_info_item">
            <div class="short_info_lable">Комментарий</div>
            <div class="short_info_value">{{BanquetsService.ActiveBanquet.Comment}}</div>
        </div>
    </div>
</div>












<div class="block_wrapper">
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <ibm-label>
                Имя
                <input theme="light" [(ngModel)]="BanquetsService.ActiveBanquet.ContactCustomer.FirstName" ibmText
                    placeholder="Имя" (change)="OnBanquetChange()" autocomplete="off">
            </ibm-label>
        </div>
        <div class="banquet-card-item">
            <ibm-label>
                Фамилия
                <input theme="light" [(ngModel)]="BanquetsService.ActiveBanquet.ContactCustomer.LastName" ibmText
                    placeholder="Фамилия" (change)="OnBanquetChange()" autocomplete="off">
            </ibm-label>
        </div>
    </div>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <ibm-label>
                Телефон
                <input theme="light" (change)="OnBanquetChange()"
                    [(ngModel)]="BanquetsService.ActiveBanquet.ContactCustomer.Phone" ibmText autocomplete="off">
            </ibm-label>
        </div>
    </div>
</div>

<div class="block_wrapper">
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <ibm-label>
                Номер банкета
                <input theme="light" [(ngModel)]="BanquetsService.ActiveBanquet.CustomNumber" ibmText
                    (change)="OnBanquetChange()" autocomplete="off">
            </ibm-label>
        </div>
    </div>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Гостей</div>
            <div class="banquet-card-item__value">
                <ibm-number theme="light" min="0" [(ngModel)]="BanquetsService.ActiveBanquet.Guests"
                    (change)="ChnageGuestsCount($event)">
                </ibm-number>
            </div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__description">Детей</div>
            <div class="banquet-card-item__value">
                <ibm-number theme="light" min="0" [(ngModel)]="BanquetsService.ActiveBanquet.Children"
                    (change)="ChnageChildrenCount($event)">
                </ibm-number>
            </div>
        </div>
    </div>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <ibm-dropdown theme="light" class="area-dropdown" value="content" label="Категория"
                (selected)="OnBanquetCategoryChange($event)">
                <ibm-dropdown-list [items]="BanquetsService.BanquetTypes"></ibm-dropdown-list>
            </ibm-dropdown>
        </div>
    </div>
    <div class="banquet-card-row" *ngIf="BanquetsService.ActiveBanquet.CategoryName == 'День Рождения'">
        <div class="banquet-card-item">
            <ibm-label class="adding-banquet-field">
                Имя именинника
                <input theme="light" (change)="OnChangeTargetName()" [(ngModel)]="BanquetsService.ActiveBanquet.Target"
                    ibmText autocomplete="off">
            </ibm-label>
        </div>
    </div>

    <div class="banquet-card-row" *ngIf="BanquetsService.ActiveBanquet.CategoryName == 'Свадьба'">
        <div class="banquet-card-item">
            <ibm-label class="adding-banquet-field">
                Имя жениха
                <input theme="light" (change)="OnChangeTargetName()" [(ngModel)]="BanquetsService.ActiveBanquet.Target"
                    ibmText autocomplete="off">
            </ibm-label>
        </div>
        <div class="banquet-card-item">
            <ibm-label class="adding-banquet-field">
                Имя невесты
                <input theme="light" (change)="OnChangeTargetName()"
                    [(ngModel)]="BanquetsService.ActiveBanquet.TargetSecond" ibmText autocomplete="off">
            </ibm-label>
        </div>
    </div>

    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <ibm-label class="adding-banquet-field">
                Комментарий
                <input theme="light" (change)="OnChangeTargetName()" [(ngModel)]="BanquetsService.ActiveBanquet.Comment"
                    ibmText autocomplete="off">
            </ibm-label>
        </div>
    </div>
</div>

<banquet-tables-form [id]="BanquetsService.ActiveBanquet.Id" [banquet]="BanquetsService.ActiveBanquet"
    [time]="BanquetsService.ActiveBanquet.TimeId">
</banquet-tables-form>


<div class="banquet-card-block">
    <h5>Заметки</h5>
    <div class="banquet-comments" fxLayout="column" fxLayoutGap="16px">
        <div *ngFor="let comment of BanquetsService.ActiveBanquet.Comments">
            <div class="banquet-comment-item">
                <div class="banquet-comment-item__type">
                    <div>{{comment.Type}}</div>
                    <div class="banquet-area-item__actions" fxLayout="row" fxLayoutGap="8px">

                    </div>
                </div>

                <div class="banquet-comment-item__text">{{comment.Text}}</div>
            </div>
        </div>
    </div>

</div>

<div class="banquet-card-block">
    <h5>Поступление заказа</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateAdded| date:'d
                MMM y'}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateAdded| date:'HH:mm:ss'}}</div>
        </div>
    </div>
</div>

<div class="banquet-card-block">
    <div class="banquet-card-row">
        <div>
            <h5>Алкоголь</h5>
            <ibm-checkbox [(ngModel)]="BanquetsService.ActiveBanquet.Alcohol" (change)="OnChangeAlcohol($event)">
                Свой алкоголь
            </ibm-checkbox>
        </div>
    </div>
</div>



<div class="banquet-card-block">
    <h5>Дата подтверждения</h5>
    <div class="banquet-card-row">
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateConfirmed == null
                ? '—' :
                (BanquetsService.ActiveBanquet.DateConfirmed | date:'d MMM
                y')}}</div>
        </div>
        <div class="banquet-card-item">
            <div class="banquet-card-item__value">{{BanquetsService.ActiveBanquet.DateConfirmed == null
                ? '—' :
                (BanquetsService.ActiveBanquet.DateConfirmed|
                date:'HH:mm:ss')}}</div>
        </div>
    </div>
</div>