export class OrderStatus{
    
    private id: number;
    private name: string;

    public get Id(): number{
        return this.id;
    }
    public get Name(): string{
        return this.name;
    }



    static Get(id: any): OrderStatus{
        
        let status: OrderStatus;
        
        switch ( Number.parseInt(id) ) {
            case 1:
                status = new OrderStatus(id, "Новый заказ");
                break;
            case 2:
                status = new OrderStatus(id, "Открыт оператором");
                break;
            case 3:
                status = new OrderStatus(id, "Обработан оператором");
                break;
            case 5:
                status = new OrderStatus(id, "Сделка завершена");
                break;
            case 7:
                status = new OrderStatus(id, "Отменено");
                break;
            case 8:
                status = new OrderStatus(id, "Возврат");
                break;
            case 9:
                status = new OrderStatus(id, "Отмена и аннулирование");
                break;
            case 10:
                status = new OrderStatus(id, "Неудавшийся");
                break;
            case 11:
                status = new OrderStatus(id, "Возмещенный");
                break;
            case 12:
                status = new OrderStatus(id, "Полностью измененный");
                break;
            case 13:
                status = new OrderStatus(id, "Полный возврат");
                break;
            case 14:
                status = new OrderStatus(id, "Формирование развоза");
                break;
            case 15:
                status = new OrderStatus(id, "Доставляется");
                break;
            case 16:
                status = new OrderStatus(id, "Принят курьером");
                break;
            case 17:
                status = new OrderStatus(id, "Доставлен менеджеру");
                break;
            case 18:
                status = new OrderStatus(id, "Отменён курьером");
                break;
            case 19:
                status = new OrderStatus(id, "Добавлен в Keeper");
                break;
            case 20:
                status = new OrderStatus(id, "Ожидает самовывоз");
                break;
        }

        return status;
    }

    private constructor(id: number, name: string){
        this.id = id;
        this.name = name;
    }

}