import { Customer } from './customer.model';
import { OrderStatus } from './orser-status.model';
import { Product } from './product.model';
import { Identifiable } from '../tools/identifiable.model';
import { Note } from './note.model';
import { Equipment } from './equipment.model';
import { OrderHistory } from './history.model';
import { System } from '../tools/system.model';

export class Order extends Identifiable {

    private comment: string;

    public get Comment(): string {
        return this.comment;
    }
    public set Comment(comment: string) {
        this.comment = comment;
    }

    private shippingAdress: string;
    public get ShippingAdress(): string {
        return this.shippingAdress;
    }
    public set ShippingAdress(shippingAdress: string) {
        this.shippingAdress = shippingAdress;
    }



    private shippingArea: string;
    public get ShippingArea(): string {
        return this.shippingArea;
    }
    public set ShippingArea(shippingArea: string) {
        this.shippingArea = shippingArea;
    }



    private shippingMethod: string;
    public get ShippingMethod(): string {
        return this.shippingMethod;
    }
    public set ShippingMethod(shippingMethod: string) {
        this.shippingMethod = shippingMethod;
    }



    private paymentMethod: string;
    public get PaymentMethod(): string {
        return this.paymentMethod;
    }
    public set PaymentMethod(paymentMethod: string) {
        this.paymentMethod = paymentMethod;
    }



    private cancelMessage: string;
    public get CancelMessage(): string {
        return this.cancelMessage;
    }
    public set CancelMessage(cancelMessage: string) {
        this.cancelMessage = cancelMessage;
    }









    private entrance: string;
    public get Entrance(): string {
        return this.entrance;
    }
    public set Entrance(entrance: string) {
        this.entrance = entrance;
    }


    private floor: string;
    public get Floor(): string {
        return this.floor;
    }
    public set Floor(floor: string) {
        this.floor = floor;
    }


    private apartment: string;
    public get Apartment(): string {
        return this.apartment;
    }
    public set Apartment(apartment: string) {
        this.apartment = apartment;
    }


    private homeCode: string;
    public get HomeCode(): string {
        return this.homeCode;
    }
    public set HomeCode(homeCode: string) {
        this.homeCode = homeCode;
    }














    private customerShippingTime: string;
    public get CustomerShippingTime(): string {
        return this.customerShippingTime;
    }
    public set CustomerShippingTime(customerShippingTime: string) {
        this.customerShippingTime = customerShippingTime;
    }



    private customerShippingDate: string;
    public get CustomerShippingDate(): string {
        return this.customerShippingDate;
    }
    public set CustomerShippingDate(customerShippingDate: string) {
        this.customerShippingDate = customerShippingDate;
    }







    public Debug: string = "";


    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }


    private total: number;
    public get Total(): number {
        return this.total;
    }
    public set Total(total: number) {
        this.total = total;
    }

    private gloablEquipmentCount: number;
    public get GloablEquipmentCount(): number {
        return this.gloablEquipmentCount;
    }
    public set GloablEquipmentCount(gloablEquipmentCount: number) {
        this.gloablEquipmentCount = gloablEquipmentCount;
    }

    private groupId: number;
    public get GroupId(): number {
        return this.groupId;
    }
    public set GroupId(groupId: number) {
        this.groupId = groupId;
    }


    private paymented: boolean;
    public get Paymented(): boolean {
        return this.paymented;
    }
    public set Paymented(paymented: boolean) {
        this.paymented = paymented;
    }


    private statusId: number;
    public get StatusId(): number {
        return this.statusId;
    }
    public set StatusId(statusId: number) {
        this.statusId = statusId;
    }

    public get Status(): OrderStatus {
        return OrderStatus.Get(this.statusId);
    }


    private priority: number;
    public get Priority(): number {
        return this.priority;
    }
    public set Priority(priority: number) {
        this.priority = priority;
    }



    private readiness: boolean;
    public get Readiness(): boolean {
        return this.readiness;
    }
    public set Readiness(readiness: boolean) {
        this.readiness = readiness;
    }

    private cardPayment: boolean;
    public get CardPayment(): boolean {
        return this.cardPayment;
    }
    public set CardPayment(cardPayment: boolean) {
        this.cardPayment = cardPayment;
    }

    private isNotCall: boolean;
    public get IsNotCall(): boolean {
        return this.isNotCall;
    }
    public set IsNotCall(isNotCall: boolean) {
        this.isNotCall = isNotCall;
    }

    private isReadinessShipping: boolean;
    public get IsReadinessShipping(): boolean {
        return this.isReadinessShipping;
    }
    public set IsReadinessShipping(isReadinessShipping: boolean) {
        this.isReadinessShipping = isReadinessShipping;
    }




    private endedDate: Date = new Date();
    public get EndedDate(): Date {
        return this.endedDate;
    }
    public set EndedDate(endedDate: Date) {
        this.endedDate = endedDate;
    }

    private shippingDate: Date = new Date();
    private orderReadyDate: Date = new Date();


    public get ShippingDate(): Date {
        return this.shippingDate;
    }

    public set ShippingDate(shippingDate: Date) {
        if (this.shippingDate != null && !isNaN(this.shippingDate.getTime())) {
            shippingDate.setHours(this.ShippingHour);
            shippingDate.setMinutes(this.ShippingMinutes);
        }
        this.shippingDate = shippingDate;
    }




    public get OrderReadyDate(): Date {
        return this.orderReadyDate;
    }

    public set OrderReadyDate(orderReadyDate: Date) {
        if (this.orderReadyDate != null && !isNaN(this.orderReadyDate.getTime())) {
            orderReadyDate.setHours(this.OrderReadyHour);
            orderReadyDate.setMinutes(this.OrderReadyMinutes);
        }
        this.orderReadyDate = orderReadyDate;
    }






    public get ShippingHour(): number {

        return this.shippingDate.getHours();
    }
    public set ShippingHour(shippingHour: number) {
        if (this.shippingDate != null && !isNaN(this.shippingDate.getTime())) {
            if (shippingHour > 23) {
                this.shippingDate.setHours(23);
            } else {
                this.shippingDate.setHours(shippingHour);
            }
        }
    }


    public get ShippingMinutes(): number {
        return this.shippingDate.getMinutes();
    }
    public set ShippingMinutes(shippingMinute: number) {
        if (this.shippingDate != null && !isNaN(this.shippingDate.getTime())) {
            if (shippingMinute > 59) {
                this.shippingDate.setMinutes(59);
            } else {
                this.shippingDate.setMinutes(shippingMinute);
            }
        }
    }










    public get OrderReadyHour(): number {
        return this.orderReadyDate.getHours();
    }
    public set OrderReadyHour(shippingHour: number) {
        if (this.orderReadyDate != null && !isNaN(this.orderReadyDate.getTime())) {
            if (shippingHour > 23) {
                this.orderReadyDate.setHours(23);
            } else {
                this.orderReadyDate.setHours(shippingHour);
            }
        }
    }


    public get OrderReadyMinutes(): number {
        return this.orderReadyDate.getMinutes();
    }
    public set OrderReadyMinutes(shippingMinute: number) {
        if (this.orderReadyDate != null && !isNaN(this.orderReadyDate.getTime())) {
            if (shippingMinute > 59) {
                this.orderReadyDate.setMinutes(59);
            } else {
                this.orderReadyDate.setMinutes(shippingMinute);
            }
        }
    }




    public Courier: any;

    public Customer: Customer;

    public Products: Array<Product> = new Array<Product>();

    public KitchenNotes: Array<Note> = new Array<Note>();
    public DeliveryNotes: Array<Note> = new Array<Note>();


    public Histories: Array<OrderHistory> = new Array<OrderHistory>();

    public Equipments: Array<Equipment> = new Array<Equipment>();

    public PastOrders: Array<Order> = new Array<Order>();


    public Reviews: Array<any> = new Array<any>();
    public CustomerNames: Array<any> = new Array<any>();


    public IsToggleInGroup: boolean = false;


    //public Timer: Date = new Date();
    public TimeDifferenceDate: Date = new Date();
    public IsDifferenceOverdue: boolean = false;

    public get TimeDifference(): string {
        return this.OnGetTimeDifference();
    }

    public OnGetCurrentTime: any = () => { };
    public OnGetTimeDifference: any = () => { };

    constructor(id: number) {
        super(id);
        //this.ShippingHour = new Date(0).getHours();
        //this.ShippingMinutes = new Date(0).getMinutes();


        //   setInterval(
        //     this.GetCurrentTime.bind(this), 1000
        //   );
    }

    UpdateTimer() {
        if (this.StatusId == 1) {
            let DateAddedWithAllowance = new Date();
            DateAddedWithAllowance.setMinutes(DateAddedWithAllowance.getMinutes() - 5);
            this.TimeDifferenceDate = new Date(this.DateAdded.getTime() - DateAddedWithAllowance.getTime());
            //this.TimeDifferenceDate = new Date();
            if (this.TimeDifferenceDate.getTime() <= 0) {
                this.TimeDifferenceDate = new Date(new Date(0).getTime() - this.TimeDifferenceDate.getTime());
                this.IsDifferenceOverdue = true;
            }
            //this.TimeDifferenceDate = new Date();
            this.OnGetCurrentTime = this.GetCurrentTimeForNewOrder;
            this.OnGetTimeDifference = this.GetTimeDifferenceForNewOrder;
        }
        if (this.StatusId == 2) {
            this.OnGetCurrentTime = this.GetCurrentTimeForOpenedOrder;
            this.OnGetTimeDifference = this.GetTimeDifferenceForOpenedOrder;
        }
        if (this.StatusId == 5) {
            this.TimeDifferenceDate = new Date(this.ShippingDate.getTime() - this.EndedDate.getTime());


            if (this.TimeDifferenceDate.getTime() <= 0) {
                this.TimeDifferenceDate = new Date(new Date(0).getTime() - this.TimeDifferenceDate.getTime());
                this.IsDifferenceOverdue = true;
            }
            this.OnGetCurrentTime = this.GetCurrentTimeForEndedOrder;
            this.OnGetTimeDifference = this.GetTimeDifferenceForEndedOrder;
        }
        if (this.StatusId == 9 || this.StatusId == 18) {
            this.IsDifferenceOverdue = false;
            this.OnGetCurrentTime = this.GetCurrentTimeForOpenedOrder;
            this.OnGetTimeDifference = this.GetTimeDifferenceForOpenedOrder;
        }

        if (this.StatusId == 9) {

        }


        if (this.StatusId > 2 && this.StatusId != 5 && this.StatusId != 9) {



            this.TimeDifferenceDate = new Date(this.ShippingDate.getTime() - new Date().getTime());

            if (this.TimeDifferenceDate.getTime() <= 0) {
                this.TimeDifferenceDate = new Date(new Date(0).getTime() - this.TimeDifferenceDate.getTime());
                this.IsDifferenceOverdue = true;
            }

            this.OnGetCurrentTime = this.GetCurrentTimeForStartedOrder;
            this.OnGetTimeDifference = this.GetTimeDifferenceForStartedOrder;



        }
    }

    GetCurrentTime(): void {
        this.OnGetCurrentTime();
    }

    GetCurrentTimeForNewOrder() {
        if (this.IsDifferenceOverdue) {
            this.TimeDifferenceDate.setSeconds(this.TimeDifferenceDate.getSeconds() + 1);
        } else {
            this.TimeDifferenceDate.setSeconds(this.TimeDifferenceDate.getSeconds() - 1);
        }
        if (this.TimeDifferenceDate.getTime() <= 0) {
            this.IsDifferenceOverdue = true;
        }
    }
    GetCurrentTimeForOpenedOrder() {
        this.IsDifferenceOverdue = false;
    }
    GetCurrentTimeForStartedOrder() {
        if (this.IsDifferenceOverdue) {
            this.TimeDifferenceDate.setSeconds(this.TimeDifferenceDate.getSeconds() + 1);
        } else {
            this.TimeDifferenceDate.setSeconds(this.TimeDifferenceDate.getSeconds() - 1);
        }


        if (this.TimeDifferenceDate.getTime() <= 0) {
            this.IsDifferenceOverdue = true;
        }
    }
    GetCurrentTimeForEndedOrder() {
        //this.IsDifferenceOverdue = false;
    }

    GetTimeDifferenceForNewOrder(): string {

        let TimeDifferenceDate = this.TimeDifferenceDate.getUTCDate() - 1;
        let TimeDifferenceDateHours = this.TimeDifferenceDate.getUTCHours();
        let TimeDifferenceDateMinutes = this.TimeDifferenceDate.getUTCMinutes();
        let TimeDifferenceDateSeconds = this.TimeDifferenceDate.getUTCSeconds();

        return `${this.IsDifferenceOverdue ? "-" : " "}
        ${TimeDifferenceDateHours < 10 ? "0" + TimeDifferenceDateHours : TimeDifferenceDateHours}:
        ${TimeDifferenceDateMinutes < 10 ? "0" + TimeDifferenceDateMinutes : TimeDifferenceDateMinutes}:
        ${TimeDifferenceDateSeconds < 10 ? "0" + TimeDifferenceDateSeconds : TimeDifferenceDateSeconds}`;
    }
    GetTimeDifferenceForOpenedOrder(): string {
        return ``;
    }
    GetTimeDifferenceForStartedOrder() {
        let TimeDifferenceDate = this.TimeDifferenceDate.getUTCDate() - 1;
        let TimeDifferenceDateHours = this.TimeDifferenceDate.getUTCHours();
        let TimeDifferenceDateMinutes = this.TimeDifferenceDate.getUTCMinutes();
        let TimeDifferenceDateSeconds = this.TimeDifferenceDate.getUTCSeconds();

        return `${this.IsDifferenceOverdue ? "-" : " "}
        ${TimeDifferenceDate > 0 ? TimeDifferenceDate + " дней " : ""}
        ${TimeDifferenceDateHours < 10 ? "0" + TimeDifferenceDateHours : TimeDifferenceDateHours}:
        ${TimeDifferenceDateMinutes < 10 ? "0" + TimeDifferenceDateMinutes : TimeDifferenceDateMinutes}:
        ${TimeDifferenceDateSeconds < 10 ? "0" + TimeDifferenceDateSeconds : TimeDifferenceDateSeconds}`;
    }
    GetTimeDifferenceForEndedOrder() {
        let TimeDifferenceDate = this.TimeDifferenceDate.getUTCDate() - 1;
        let TimeDifferenceDateHours = this.TimeDifferenceDate.getUTCHours();
        let TimeDifferenceDateMinutes = this.TimeDifferenceDate.getUTCMinutes();
        let TimeDifferenceDateSeconds = this.TimeDifferenceDate.getUTCSeconds();

        return `${this.IsDifferenceOverdue ? "-" : " "}
        ${TimeDifferenceDate > 0 ? TimeDifferenceDate + " дней " : ""}
        ${TimeDifferenceDateHours < 10 ? "0" + TimeDifferenceDateHours : TimeDifferenceDateHours}:
        ${TimeDifferenceDateMinutes < 10 ? "0" + TimeDifferenceDateMinutes : TimeDifferenceDateMinutes}:
        ${TimeDifferenceDateSeconds < 10 ? "0" + TimeDifferenceDateSeconds : TimeDifferenceDateSeconds}`;
    }
}