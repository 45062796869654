import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { Product } from '../../../../models/store/product.model';
import { OrdersService } from '../../../../services/order/orders.service';
import { NotificationService } from '../../../../services/notification.service';
import { ClipboardService } from '../../../../services/clipboard.service';

@Component({
  selector: 'app-order-main-stage-reading',
  templateUrl: './order-main-stage-reading.component.html',
  styleUrls: ['./order-main-stage-reading.component.scss']
})
export class OrderMainStageReadingComponent implements OnInit {

  @Input('readonly') IsReadOnly: boolean = false;

  @Input('delivery') delivery: any;
  @Input('deliveryOriginal') deliveryOriginal: any;


  public IsDeliveryOriginalShowed: boolean = false;
  public IsPhoneOriginalShowed: boolean = false;
  public IsCommentOriginalShowed: boolean = false;

  public OnDeliveryOriginalShowed(event) {
    this.IsDeliveryOriginalShowed = event.checked;
  }
  public OnPhoneOriginalShowed(event) {
    this.IsPhoneOriginalShowed = event.checked;
  }
  public OnCommentOriginalShowed(event) {
    this.IsCommentOriginalShowed = event.checked;
  }





  items: Array<any> = [
    { content: "Александровский район", id: 0 },
    { content: "Вознесеновский район", id: 1 },
    { content: "Днепровский район", id: 2 },
    { content: "Заводской район", id: 3 },
    { content: "Коммунарский район", id: 4 },
    { content: "Космический район", id: 5 },
    { content: "Хортицкий район", id: 6 },
    { content: "Шевченковский район", id: 7 },
  ];



  constructor(public ProductsService: ProductsService, public DeliveryService: OrdersService,
    public NotificationService: NotificationService, public ClipboardService: ClipboardService) { }

  ngOnInit() {
  }

  onClose(event) {

  }

  selected(event) {

  }

  OnOrderChange() {
    this.DeliveryService.SaveOrder(this.delivery);
  }

}
