<div class="review_wrapper" fxLayout="row" fxLayoutGap="40px">

    <div class="panel">

    </div>
    <div class="content">
        <div *ngIf="CurrentReview != null">
            <ibm-label>
                Заказ №
                <p>{{ CurrentReview.Id }}</p>
            </ibm-label>
            <ibm-label>
                Комментарий
                <p>{{ CurrentReview.Comment }}</p>
            </ibm-label>
        </div>

    </div>
</div>