import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { BanquetZoneService } from '../../../../services/banquet/banquet-zone.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-banquet-filter',
  templateUrl: './banquet-filter.component.html',
  styleUrls: ['./banquet-filter.component.scss']
})
export class BanquetFilterComponent implements OnInit {


  public ConfirmStatus: any;
  public PaymentType: any;

  public GuestCountOperation: any;
  public ChildCountOperation: any;
  public TotalOperation: any;
  public DepositOperation: any;

  public GuestCountValue: any;
  public ChildCountValue: any;
  public TotalValue: any;
  public DepositValue: any;
  public Name: string = "";
  public CreateMethod: any;

  public TableFields: Array<any> = new Array<any>();

  public IsMinimizeFilter: boolean = false;

  items: Array<any> = [
    { content: "Тест 1", id: "Тест 1", selected: true },
    { content: "Тест 2", id: "Тест 2", selected: false },
  ];

  createMethod: Array<any> = [
    { content: "Все", id: "-1", selected: true },
    { content: "Менеджер", id: "2", selected: false },
    { content: "Клиенты", id: "3", selected: false },
  ];


    
  KitchenStatusItems: Array<any> = [
    { content: "Не задан", id: "-1", selected: true },
    { content: "Активирован", id: "1", selected: false },
    { content: "Не активирован", id: "0", selected: false },
  ];

  KeeperStatusItems: Array<any> = [
    { content: "Не задан", id: "-1", selected: true },
    { content: "Активирован", id: "1", selected: false },
    { content: "Не активирован", id: "0", selected: false },
  ];


  OperationItems: Array<any> = [
    { content: "Не задан", id: "-1", selected: true },
    { content: "Равно", id: "=", selected: false },
    { content: "Больше", id: ">", selected: false },
    { content: "Меньше", id: "<", selected: false },
  ];

  ConfirmItems: Array<any> = [
    { content: "Любой", id: "-1", selected: true },
    { content: "Подтверждён", id: "2", selected: false },
    { content: "Не подтверждён", id: "1", selected: false },
    { content: "Не внесён", id: "0", selected: false },
  ];

  PaymentTypeItems: Array<any> = [
    { content: "Любой", id: "-1", selected: true },
    { content: "Наличные", id: "1", selected: false },
    { content: "Кредитка", id: "2", selected: false },
    { content: "Терминал", id: "3", selected: false },
  ];

  constructor(public BanquetZoneService: BanquetZoneService, public BanquetsService: BanquetsService,
    public CookiesService: CookieService) {
    this.IsMinimizeFilter = this.CookiesService.get("minimize-filter") == "1" ? true : false;
  }

  ngOnInit(): void {
    this.BanquetZoneService.OnTableLoaded.push(
      () => {
        this.BanquetZoneService.Tables.forEach(element => {
          this.TableFields.push({ id: element.Id, content: element.Name, selected: false });
        });
      }
    );


  }

  FilterNameChange(event) {
    this.BanquetsService.FilterData.SetFilterName(event);
  }
  FilterCustomNumber(event) {
    this.BanquetsService.FilterData.SetFilterCustomNumber(event.target.value);
  }
  GuestCountValueChange(event) {
    this.BanquetsService.FilterData.SetCountValue(event.value);
  }
  ChildCountValueChange(event) {
    this.BanquetsService.FilterData.SetChildCountValue(event.value);
  }
  TotalValueChange(event) {
    this.BanquetsService.FilterData.SetTotalValue(event.value);
  }
  DepositValueChange(event) {
    this.BanquetsService.FilterData.SetDepositValue(event.value);
  }


  @Output("dateChange") dateChange = new EventEmitter<Date>();

  FilterDateChange(event) {
    let SelectedDate = event[0];
    SelectedDate = new Date(SelectedDate);
    SelectedDate.setDate(SelectedDate.getDate());

    let TomorrowDate = new Date(SelectedDate);
    TomorrowDate.setDate(TomorrowDate.getDate() + 1);

    this.BanquetsService.FilterData.SetDate(SelectedDate, SelectedDate);

    this.dateChange.emit(SelectedDate);
  }

  FilterAddedDateChange(event) {
    this.BanquetsService.FilterData.SetAddedDate(event[0], event[1]);
  }

  FilterConfirmStatus(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetConfirmStatus(value);
  }

  FilterTable(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetTable(value);
  }

  FilterAlcoholStatus(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetAlcoholStatus(value);
  }

  FilterPaymentType(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetPaymentType(value);
  }





  FilterDepositOperation(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetDepositOperation(value);
  }
  FilterGuestCountOperation(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetGuestsCountOperation(value);
  }
  FilterChildCountOperation(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetChildCountOperation(value);
  }
  FilterTotalOperation(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetTotalOperation(value);
  }
  FilterCreateMethod(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetCreateMethod(value);
  }
  FilterKeeperStatus(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetKeeperStatus(value);
  }
  FilterKitchenStatus(event) {
    let value = event.item.id == -1 ? null : event.item.id;
    this.BanquetsService.FilterData.SetKitchenStatus(value);
  }
  FilterTotalPrice(event) {
    this.BanquetsService.FilterData.SetTotalValue(event.target.value);
  }

  











  UploadFilteredBanquet() {
    this.BanquetsService.ClearBqneuts();
    this.BanquetsService.LoadBanquets();
  }

  ClearFilter() {
    this.ConfirmStatus = 0;
    this.BanquetsService.FilterData.SetConfirmStatus(null);

    this.PaymentType = 0;
    this.BanquetsService.FilterData.SetPaymentType(null);

    this.Name = "";
    this.BanquetsService.FilterData.SetFilterName("");

    this.BanquetsService.FilterData.SetKeeperStatus(null);
    this.BanquetsService.FilterData.SetKitchenStatus(null);
    this.BanquetsService.FilterData.SetDate(null, null);
    this.BanquetsService.FilterData.SetPaymentType(null);
    this.BanquetsService.FilterData.SetTotalValue(-1);

    this.BanquetsService.ClearBqneuts();
    this.BanquetsService.LoadBanquets();
  }


  ToggleMinimizeFilter() {
    this.IsMinimizeFilter = !this.IsMinimizeFilter;
    this.CookiesService.set("minimize-filter", this.IsMinimizeFilter ? "1" : "0");
  }
}
