<div class="courier_order_wrapper" *ngIf="CurrentOrder != null">
    <app-order-main-stage-reading [delivery]="CurrentOrder"></app-order-main-stage-reading>
</div>

<span *ngIf="CurrentOrder != null">
    <div class="action_button" (click)="FinishOrder()" *ngIf="CurrentOrder.StatusId == 15 && ((CurrentOrder.Paymented && CurrentOrder.PaymentMethod == 'Оплата картой') || CurrentOrder.PaymentMethod != 'Оплата картой')">
        <div class="action_button_content">
            Подтвердить заказ
        </div>
    </div>
</span>


<app-history-review-action [(delivery)]="CurrentOrder"></app-history-review-action>