import { Component, OnInit } from '@angular/core';
import { BanquetZoneService } from '../../../services/banquet/banquet-zone.service';
import { ModalAddingService } from '../../../services/banquet/modal/modal-adding.service';
import { ConfirmationService } from '../../../services/confirmation.service';;
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

@Component({
  selector: 'app-banquet-area',
  templateUrl: './banquet-area.component.html',
  styleUrls: ['./banquet-area.component.scss']
})
export class BanquetAreaComponent implements OnInit {

  constructor(public BanquetZoneService: BanquetZoneService,
    public ConfirmationService: ConfirmationService, public ModalAddingService: ModalAddingService,) { }

  ngOnInit(): void {

  }

  OpenAddingBanquetAreaModal() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetZoneService.AddArea(
        this.ModalAddingService.Fields[0].Value
      );
    });

    this.ModalAddingService.Show(
      new ModalData("Добавить платёж", "", "Добавить", "Отмена"),
      [
        ModalFieldBuilder.BuildText("Название", 0, 100000),
      ]
    );
  }

  OpenModalRemoveBanquetArea(id: number) {
    this.ConfirmationService.Reset();
    this.ConfirmationService.OpenModal("Отменить заказ?", "Вы уверены, что хотите отменить заказ?", "", "Нет", "Удалить");
    this.ConfirmationService.AddConfirmEvent((answer: string) => {
      this.BanquetZoneService.DeleteArea(id);
    });
  }
}
