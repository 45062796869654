import { Injectable } from '@angular/core';
import { Equipment } from '../models/store/equipment.model';
import { HttpClient } from '@angular/common/http';
import { PropertyService } from './property.service';
import { NotificationAlert } from '../models/tools/notification.model';
import { Order } from '../models/store/order.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  private equipments: Array<Equipment> = new Array<Equipment>();

  public get Equipments(): Array<Equipment> {
    return this.equipments;
  }

  constructor(private http: HttpClient, private property: PropertyService,) {

  }

  public AddEquipment(name: string) {
    this.equipments.push(new Equipment(1, name));
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/AddEquipment&token=${this.property.Token}&name=${name}`);
    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {

        }
      }
    );
  }

  public LoadEquipments() {
    var request = `https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/LoadMoreEquipment&token=${this.property.Token}`;
    this.http.get(request).subscribe(
      (data: any) => {
        this.equipments = new Array<Equipment>();
        data["data"].forEach(equipment => {
          this.equipments.push(new Equipment(equipment["equipment_id"], equipment["name"]));
        });
      }
    );
  }


  public Add(order: Order, equipment: Equipment, count: number) {
    var request = `https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/ChangeEquipmentCount&token=${this.property.Token}&order_id=${order.Id}&equipment_id=${equipment.Id}&count=${count}&isGlobal=0`;
    this.http.get(request).subscribe(
      (data: any) => {
        order.Equipments.push(new Equipment(equipment.Id, equipment.Name, count));
      }
    );
  }

  public ChangeCount(order: Order, equipment: Equipment, count: number, isGlobal: number = 0, onUpdated: any = () => { }) {
    var request = `https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/ChangeEquipmentCount&token=${this.property.Token}&order_id=${order.Id}&equipment_id=${equipment.Id}&count=${count}&isGlobal=${isGlobal}`;
    this.http.get(request).subscribe(
      (data: any) => {
        onUpdated();
        if (isGlobal == 1) {
          order.Equipments = order.Equipments.filter(function (obj) {
            return obj.Id !== equipment.Id;
          });
        }
      }
    );
  }

  public ChangeGloablCount(order: Order, count: number, onUpdated: any = () => { }) {
    var request = `https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/ChnageGlobalEquipmentCount&token=${this.property.Token}&order_id=${order.Id}&count=${count}`;
    this.http.get(request).subscribe(
      (data) => {
        onUpdated();
      }, (error) => {

      }
    );
  }

  public RemoveEquipment(equipments: Array<string>) {
    let equipmentsIds: string = "";
    equipments.forEach(equipment => {
      equipmentsIds += (equipment + " ");
    });
    equipmentsIds = equipmentsIds.substring(0, equipmentsIds.length - 1)
    let request = encodeURI(`https://ochagzp.com.ua/index.php?route=api/v2/order-equipment/RemoveEquipment&token=${this.property.Token}&equipments=${equipmentsIds}`);
    this.http.get(request).subscribe(
      (data) => {
        if (data["status"] == "700") {
          equipments.forEach(equipment => {
            let findedElementIndex = this.equipments.findIndex(element => element.Id == Number.parseInt(equipment));
            this.equipments.splice(findedElementIndex, 1);
          });
        }
      }
    );
  }



}
