import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'src/app/models/store/order.model';
import { OrdersService } from '../../../../services/order/orders.service';

@Component({
  selector: 'app-order-history-stage-editable',
  templateUrl: './order-history-stage-editable.component.html',
  styleUrls: ['./order-history-stage-editable.component.scss']
})
export class OrderHistoryStageEditableComponent implements OnInit {

  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;


  public Comment: string = "";
  public StatusReviewId: number = 1;

  public isWarning(order: Order): boolean {

    let finded = order.Reviews.find(element => element.StatusId == 1);
    if (finded == null) {
      return false;
    } else {
      return true;
    }


  }

  constructor(public OrdersService: OrdersService,private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {

  }

  OnOrderChange() {
    this.OrdersService.SaveOrder(this.delivery);
  }

  OpenDelivery(event, delivery) {
    if (event.srcElement.localName != "a") {
      setTimeout(
        () => {
          this.router.navigate([`/delivery/${delivery.id}`]);
        }, 50);
    }

  }

}
