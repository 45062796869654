<div class="delivery_main" fxLayout="column" fxLayoutGap="32px">
    <div class="main_title">Доставка(Необязательно)</div>

    <ibm-toggle *ngIf="deliveryOriginal != null && PropertyService.UserGroupId == 1" label="Данные клиента"
        (change)="OnDeliveryOriginalShowed($event)">
    </ibm-toggle>

    <div class="form_wrapper" fxLayout="row" fxLayoutGap="32px">
        <ibm-label>
            Район
            <p>«В разработке»</p>
        </ibm-label>
        <ibm-label>
            Адрес
            <p *ngIf="!IsDeliveryOriginalShowed">{{delivery.ShippingAdress}}</p>
            <p *ngIf="IsDeliveryOriginalShowed">{{deliveryOriginal.ShippingAdress}}</p>
        </ibm-label>
    </div>

    <app-separator></app-separator>

    <div class="dete_time_picker" fxLayout="column" fxLayoutGap="12px">
        <ibm-label>
            Дата доставки
            <p>{{ delivery.ShippingDate | date:'MM/dd/yyyy' }}</p>
        </ibm-label>
        <div class="time_picker" fxLayout="row">
            <ibm-label>
                Время
                <p>{{delivery.ShippingHour | twotime}}:{{delivery.ShippingMinutes | twotime}}</p>
            </ibm-label>
        </div>
    </div>

    <app-separator></app-separator>

    <app-order-note [(notes)]="delivery.DeliveryNotes" [(delivery)]="delivery" type="2"></app-order-note>

</div>