<div class="" fxLayout="column" fxLayoutGap="24px">
    <div class="filter_row" fxLayout="row" fxLayoutGap="12px">

        <div class="filter_wrapper" fxLayout="column" fxLayoutGap="8px">
            <div class="filter_wrapper__title">Выберите день</div>

            <div class="inputs" fxLayout="row" fxLayoutGap="12px">

                <ibm-toggle label="Свободные столы" [(ngModel)]="IsAvaible" (checkedChange)="OnAvaibleChange($event)">
                </ibm-toggle>

                <ibm-date-picker dateFormat="d.m.Y" label="Выберите день" theme="light" [(ngModel)]="SelectedDate"
                    (valueChange)="FilterDateChange($event)">
                </ibm-date-picker>

                <ibm-dropdown *ngIf="BanquetZoneService.IsAreaLoaded" label="Выберите зал" placeholder="Зал"
                    value="content" theme="light" (selected)="OnAreaChange($event)" [(ngModel)]="Area">
                    <ibm-dropdown-list [items]="AreaFields"></ibm-dropdown-list>
                </ibm-dropdown>

            </div>
        </div>

    </div>
</div>