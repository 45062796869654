import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { System } from '../../../app/models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/order/orders.service';
import { EquipmentService } from '../../services/equipment.service';
import { DeliveryService } from '../../services/delivery.service';
import { AuthorizationService } from '../../services/authorization.service';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public ActiveIndex: number = 0;

  public NextButttonText: string = "Далее";

  public IsCheckingData: boolean = false;

  private username: string = "";
  public get Username(): string {
    return this.username;
  };
  public set Username(username: string) {
    this.username = username;
    this.IsError = false;
    setTimeout(
      () => {
        this.Errors = new Array<string>();
      }, 200);
  };

  private password: string = "";
  public get Password(): string {
    return this.password;
  };
  public set Password(password: string) {
    this.password = password;
    this.IsError = false;
    setTimeout(
      () => {
        this.Errors = new Array<string>();
      }, 200);
  };

  public IsError: boolean = false;
  public Errors: Array<string> = new Array<string>();



  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public OrdersService: OrdersService,
    private EquipmentsService: EquipmentService,
    public DeliveryService: DeliveryService,
    private cookieService: CookieService,
    public AuthorizationService: AuthorizationService) {

  }



  ngOnInit() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.getElementById("login_wrapper").style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.getElementById("login_wrapper").style.setProperty('--vh', `${vh}px`);
    });


  }

  OnNextButtonClick() {
    if (this.IsCheckingData) {
      return;
    }
    this.IsCheckingData = true;
    if (this.ActiveIndex == 0 && this.Username != "") {
      let request = `https://ochagzp.com.ua/index.php?route=api/login/checklogin&username=${this.Username}`;

      this.http.get(request).subscribe(
        (data: any) => {

          this.Errors = new Array<string>();
          this.IsError = false;

          if (data["status"] == "700") {
            this.ActiveIndex++;
          }
          if (data["status"] == "701") {
            this.IsError = true;
            this.Errors.push("Такого пользователя не существует");
          }

          this.IsCheckingData = false;
        });

    }

    if (this.ActiveIndex == 1 && this.Password != "") {
      let request = `https://ochagzp.com.ua/index.php?route=api/login/auth&username=${this.Username}&password=${this.Password}`;

      this.http.get(request).subscribe(
        (data: any) => {
          this.Errors = new Array<string>();
          this.IsError = false;
          if (data["status"] == "700") {
            System.getInstance().Property.Token = data["token"];
            System.getInstance().Property.UserGroupId = data["userGroupId"];
            System.getInstance().Property.UserId = data["userId"];
            System.getInstance().Property.Account.firstname = data["firstname"];
            System.getInstance().Property.Account.lastname = data["lastname"];

            this.cookieService.set("user-firstname", data["firstname"]);
            this.cookieService.set("user-lastname", data["lastname"]);

            this.AuthorizationService.OnAuthorization();
            this.router.navigate(['/menu']);
          }
          if (data["status"] == "701") {
            this.IsError = true;
            this.Errors.push("Неверный пароль");
          }
          this.IsCheckingData = false;
        });

    }



    if (this.ActiveIndex == 0 && this.Username == "") {
      this.Errors = new Array<string>();
      this.Errors.push("Введите имя пользователя");
      this.IsError = true;
      this.IsCheckingData = false;
    }

    if (this.ActiveIndex == 1 && this.Password == "") {
      this.Errors = new Array<string>();
      this.Errors.push("Введите пароль");
      this.IsError = true;
      this.IsCheckingData = false;
    }


  }

  OnBackButtonClick() {
    this.ActiveIndex--;
  }

}