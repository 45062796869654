import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-general-stage-editable',
  templateUrl: './order-general-stage-editable.component.html',
  styleUrls: ['./order-general-stage-editable.component.scss']
})
export class OrderGeneralStageEditableComponent implements OnInit {

  @Input('delivery') delivery: any;
  @Input('deliveryOriginal') deliveryOriginal: any;

  items: Array<any> = [
    { content: "Александровский район", id: 0 },
    { content: "Вознесеновский район", id: 1 },
    { content: "Днепровский район", id: 2 },
    { content: "Заводской район", id: 3 },
    { content: "Коммунарский район", id: 4 },
    { content: "Космический район", id: 5 },
    { content: "Хортицкий район", id: 6 },
    { content: "Шевченковский район", id: 7 },
  ];

  constructor() { }

  ngOnInit() {
  }

  onClose(event) {

  }

  selected(event) {

  }

}
