import { Component, OnInit, Input } from '@angular/core';
import { OrdersService } from '../../../../services/order/orders.service';
import { Order } from '../../../../models/store/order.model';

@Component({
  selector: 'app-order-delivery-stage-editable',
  templateUrl: './order-delivery-stage-editable.component.html',
  styleUrls: ['./order-delivery-stage-editable.component.scss']
})
export class OrderDeliveryStageEditableComponent implements OnInit {


  @Input('delivery') delivery: Order;
  @Input('deliveryOriginal') deliveryOriginal: Order;

  public items: Array<any> = [
    { content: "Александровский район", id: "Александровский район", selected: false },
    { content: "Вознесеновский район", id: "Вознесеновский район", selected: false },
    { content: "Днепровский район", id: "Днепровский район", selected: false },
    { content: "Заводский район", id: "Заводский район", selected: false },
    { content: "Коммунарский район", id: "Коммунарский район", selected: false },
    { content: "Космический район", id: "Космический район", selected: false },
    { content: "Хортицкий район", id: "Хортицкий район", selected: false },
    { content: "Шевченковский район", id: "Шевченковский район", selected: false },
  ];

  public Hours: number = 0;
  public Minutes: number = 0;
  public Readiness: boolean = false;
  public ShippingDate: Date = new Date();
  public ShippingPresentDate: Array<Date> = new Array<Date>();

  public ActiveDeliveryTimeMode: number = -1;

  public TimeModes: Array<any> = [
    { hour: 1, minute: 0 },
    { hour: 1, minute: 30 },
    { hour: 2, minute: 0 },
    { hour: 2, minute: 30 },
  ];

  constructor(public OrderService: OrdersService) { }

  ngOnInit() {
    this.delivery.ShippingDate.setDate(this.delivery.ShippingDate.getDate() - 1);
    this.ShippingPresentDate.push(this.delivery.ShippingDate);
  }

  OnChnageReadiness(event) {
    this.Readiness = event.checked;
    this.delivery.Readiness = event.checked;
    this.OrderService.SaveOrder(this.delivery);
  }

  OnChangeShippingDate(event) {
    this.ShippingDate = event[0];
    this.ShippingDate.setHours(this.Hours);
    this.ShippingDate.setMinutes(this.Minutes);
    this.delivery.ShippingDate = this.ShippingDate;
    this.OrderService.SaveOrder(this.delivery);
  }

  OnChangeHours(event) {
    if (event.value > 23) {
      this.Hours = 23;
    } else {
      this.OrderService.SaveOrder(this.delivery);
    }
  }

  OnChangeMinutes(event) {
    if (event.value > 59) {
      this.Minutes = 59;
    } else {
      this.OrderService.SaveOrder(this.delivery);
    }
  }

  SetDeliveryTime(ActiveDeliveryTimeMode: number) {
    this.ActiveDeliveryTimeMode = ActiveDeliveryTimeMode;
    if (ActiveDeliveryTimeMode < 4) {
      this.ShippingDate = new Date();
      this.ShippingDate.setHours(new Date().getHours() + this.TimeModes[ActiveDeliveryTimeMode].hour);
      this.ShippingDate.setMinutes(new Date().getMinutes() + this.TimeModes[ActiveDeliveryTimeMode].minute);

      this.delivery.ShippingDate = new Date();
      this.delivery.ShippingHour = new Date().getHours() + this.TimeModes[ActiveDeliveryTimeMode].hour;
      this.delivery.ShippingMinutes = new Date().getMinutes() + this.TimeModes[ActiveDeliveryTimeMode].minute;

      this.ShippingPresentDate = new Array<Date>();
      this.ShippingPresentDate.push(this.delivery.ShippingDate);

      this.OrderService.SaveOrder(this.delivery);
    } else {

    }
  }

  selected(event) {
    this.delivery.ShippingArea = event.item.id;
    this.OrderService.SaveOrder(this.delivery);
  }

}


