import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { OrdersService } from '../../../../services/order/orders.service';
import { NotificationService } from '../../../../services/notification.service';
import { PropertyService } from '../../../../services/property.service';
import { AddressService } from 'src/app/services/order/address.service';

@Component({
  selector: 'app-order-main-stage',
  templateUrl: './order-main-stage.component.html',
  styleUrls: ['./order-main-stage.component.scss']
})
export class OrderMainStageComponent implements OnInit {

  @Input('delivery') delivery: any;
  @Input('deliveryOriginal') deliveryOriginal: any;
  @Input('shippingArea') shippingArea: any;



  constructor(public ProductsService: ProductsService, public DeliveryService: OrdersService,
    public NotificationService: NotificationService, public PropertyService: PropertyService,
  public AddressService: AddressService) { }

  ngOnInit() {
  }



}
