import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'twotime'
})
export class TwoTimePipe implements PipeTransform {
    
    constructor(private domSanitizer: DomSanitizer) {

    }

    transform(time: number) {
        if(time <10){
            return "0" + time;
        } else {
            return time;
        }
        
    }
}