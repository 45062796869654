import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { PropertyService } from '../../../services/property.service';
import { OrdersService } from '../../../services/order/orders.service';
import { System } from '../../../models/tools/system.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ochagbox-menu',
  templateUrl: './ochagbox-menu.component.html',
  styleUrls: ['./ochagbox-menu.component.scss']
})
export class OchagboxMenuComponent implements OnInit {

  constructor(public HeaderService: HeaderService, public PropertyService: PropertyService,
    private route: ActivatedRoute, private router: Router, public OrdersService: OrdersService) {

  }

  ngOnInit() {
    this.HeaderService.WindowName = "Меню Очаг Box";


    this.HeaderService.ClearAll();
    this.HeaderService.AddBreadcrumb("Меню", "/menu");
    this.HeaderService.AddBreadcrumb("Меню Очаг Box", "/ochagbox-menu", true);
    this.HeaderService.BackButton.Text = "Меню";
    this.HeaderService.BackButton.Link = "/menu";



    this.HeaderService.AddAction("review", () => {
      this.HeaderService.IsReviewOpened = true;
    });
    this.HeaderService.AddAction("noty", () => {
      this.HeaderService.IsNotificationsListOpened = true;
      this.HeaderService.UpdateBadgeCount("noty", 0);
    }, this.OrdersService.CountOfNewOrders);


    this.HeaderService.AddAction("logout", () => {
      System.getInstance().Property.Token = "";
      System.getInstance().Property.UserGroupId = 0;
      System.getInstance().Property.ClearCookies();
      this.router.navigate(['/login']);
    });



  }

}
