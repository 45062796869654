import { Identifiable } from '../tools/identifiable.model';
import { BanquetCustomer } from './banquet-customer';

export class BanquetComment extends Identifiable {


    private text: string;
    public get Text(): string {
        return this.text;
    }
    public set Text(text: string) {
        this.text = text;
    }

    private dateAdded: Date;
    public get DateAdded(): Date {
        return this.dateAdded;
    }
    public set DateAdded(dateAdded: Date) {
        this.dateAdded = dateAdded;
    }

    private commentTypeId: number;
    public get CommentTypeId(): number {
        return this.commentTypeId;
    }
    public set CommentTypeId(commentTypeId: number) {
        this.commentTypeId = commentTypeId;
    }

    private type: string;
    public get Type(): string {
        return this.type;
    }
    public set Type(type: string) {
        this.type = type;
    }



}