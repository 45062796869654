import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-group',
  templateUrl: './order-group.component.html',
  styleUrls: ['./order-group.component.scss']
})
export class OrderGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
