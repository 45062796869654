import { Order } from "./order.model";

export class OrderDateManager {
    GenerateDifferenceDateFromAddedDate(order: Order, addingHours: number, addingMinutes: number) {
        let ShippingDate = new Date(order.DateAdded);
        ShippingDate.setHours(order.DateAdded.getHours() + addingHours);
        ShippingDate.setMinutes(order.DateAdded.getMinutes() + addingMinutes);
        return ShippingDate;
    }


    GenerateDifferenceDateFromCurrentDate(order: Order, addingHours: number, addingMinutes: number) {
        let ShippingDate = new Date();
        ShippingDate.setHours(ShippingDate.getHours() + addingHours);
        ShippingDate.setMinutes(ShippingDate.getMinutes() + addingMinutes);
        return ShippingDate;
    }



}