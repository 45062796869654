import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';

@Injectable({
  providedIn: 'root'
})
export class BanquetVaultService {

  public VisivleTable: Array<any> = new Array<any>();
  public IsAvaible: boolean = true;

  constructor(private http: HttpClient, private property: PropertyService,) {

  }


  LoadVault(areaId: number, isAvaible: boolean, dateStart: Date, dateEnd: Date) {

    this.IsAvaible = isAvaible;

    let vaultAvaibleTableQuery = {
      name: "banquet_table",
      alias: "bt",
      select: ["bt.banquet_table_id", "bt.name"],
      expands: [
        {
          name: "banquet_table_group",
          alias: "btg ",
          on: "btg.banquet_table_group_id = bt.banquet_table_group_id"
        }
      ],
      where: [
        {
          alias: "bt",
          name: "banquet_table_id ",
          operation: (isAvaible ? "NOT IN" : "IN"),
          query: {
            name: "banquet_to_banquet_table",
            alias: "bbt",
            select: ["bt.banquet_table_id"],
            where: [
              {
                alias: "be",
                name: "date",
                operation: ">",
                before: dateStart,
                after: dateEnd
              }
            ],
            expands: [
              {
                name: "banquet_event",
                alias: "be",
                on: "be.event_id = bbt.banquet_id"
              },
              {
                name: "banquet_table",
                alias: "bt",
                on: "bt.banquet_table_id = bbt.banquet_table_id"
              }
            ]
          }
        },
        {
          alias: "bt",
          name: "banquet_table_group_id ",
          operation: "IN",
          query: {
            name: "banquet_table_group",
            alias: "btg",
            select: ["btg.banquet_table_group_id"],
            where: [
              {
                alias: "btg",
                name: "banquet_area_id",
                operation: "=",
                value: areaId
              }
            ]
          }
        }
      ]
    }
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/zone/table/getBy&token=${this.property.Token}`;
    this.http.post(request, vaultAvaibleTableQuery).subscribe(
      (data: any) => {
        this.VisivleTable = data["body"]["data"]["objects"]["data"];
      });



  }


}
