<div class="order_card" fxLayout="row" fxLayoutAlign="space-between start">

    <div class="ready_time" *ngIf="delivery.IsReadinessShipping == false">{{delivery.OrderReadyHour | twotime}}:{{delivery.OrderReadyMinutes |
        twotime}}</div>
    <div class="ready_time" *ngIf="delivery.IsReadinessShipping">По готовности</div>


    <div fxLayout="row" fxLayoutGap="8px">
        <svg class="purchase_icon" [ngClass]="{'warning': delivery.CardPayment && !delivery.Paymented}" *ngIf="delivery.CardPayment" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
            <use xlink:href="#card"></use>
        </svg>
    </div>

</div>



<div class="order_card" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="delivery_name">Заказ №{{delivery.Id}}</div>


</div>



<div fxLayout="row" fxLayoutAlign="space-between start">
    <div class="delivery_address">{{delivery.ShippingMethod == "Самовывоз" ? "Самовывоз" : delivery.ShippingAdress}}</div>
    <div fxLayout="row" fxLayoutGap="8px">

    </div>
</div>
<div *ngIf="delivery.Courier!=''" class="delivery_courier">
    <div>{{delivery.Courier}}</div>
</div>
<div class="delivery_status" fxLayout="row" fxLayoutAlign="space-between start">
    <div>{{delivery.Status.Name}}</div>
</div>


<div class="delivery_bottom" fxLayout="row" fxLayoutAlign="space-between end">
    <div class="delivery_time"> {{delivery.TimeDifference}}</div>
    <div class="delivery_price" fxLayout="row" fxLayoutAlign="space-between start">
        <div>{{delivery.Total | number: '1.0-0'}}₴</div>
    </div>
    <!-- <div class="delivery_icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
            <use xlink:href="#right-arrow"></use>
        </svg>
    </div> -->
</div>