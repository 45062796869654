import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-equipment-stage-editable',
  templateUrl: './order-equipment-stage-editable.component.html',
  styleUrls: ['./order-equipment-stage-editable.component.scss']
})
export class OrderEquipmentStageEditableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
