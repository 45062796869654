import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BanquetsService } from '../../../../services/banquet/banquets.service';
import { ModalAddingService } from '../../../../services/banquet/modal/modal-adding.service';
import { ModalFieldBuilder } from 'src/app/models/tools/fields/modal-field.builder';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';

import { PropertyService } from '../../../../services/property.service';

import { BanquetPaymentsService } from '../../../../services/banquet/banquet-payments.service';

import { HttpClient } from '@angular/common/http';
import { NumberInvalidFieldValue } from 'src/app/models/tools/fields/InvalidFields/number-invalid-filed-value.model';

@Component({
  selector: 'app-banquet-payment-reading',
  templateUrl: './banquet-payment-reading.component.html',
  styleUrls: ['./banquet-payment-reading.component.scss']
})
export class BanquetPaymentReadingComponent implements OnInit {

  public PaymentsSources: Array<any> = [
    { content: "Наличные", id: 1, selected: true },
    { content: "Карта", id: 2, selected: false },
    { content: "Терминал", id: 3, selected: false },
    { content: "Перевод", id: 4, selected: false },
  ];

  public PaymentsTypes: Array<any> = [
    { content: "Внесение залога", id: 1, selected: true },
    { content: "Возврат залога", id: 2, selected: false },
    { content: "Возврат средств", id: 3, selected: false },
    { content: "Внесение суммы", id: 4, selected: false },
  ];

  public PaymentsStreamSources: Array<any> = [
    { content: "Задаток", id: 1, selected: true },
    { content: "Программа", id: 2, selected: false },
  ];

  constructor(public BanquetsService: BanquetsService, public ModalAddingService: ModalAddingService,
    private http: HttpClient, public PropertyService: PropertyService,
    public BanquetPaymentsService: BanquetPaymentsService,) { }

  ngOnInit(): void {

  }


  OpenPaymentModal() {

    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      this.BanquetPaymentsService.AddPayment(
        this.ModalAddingService.Fields[0].Value,
        1,
        this.ModalAddingService.Fields[1].ValueId,
        "",
        "",
        this.ModalAddingService.Fields[2].Value,
        this.ModalAddingService.Fields[3].ValueId,
      );
    });

    let dateOfCardPayment = ModalFieldBuilder.BuildText("Дата перевода", 0, 200);
    dateOfCardPayment.IsShowed = false;
    dateOfCardPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };

    let dropDown = ModalFieldBuilder.BuildDropdown("Способ оплаты", this.PaymentsSources, "Наличные");
    dropDown.ValueId = 1;
    dropDown.OnChangeValue = (event) => {
      dropDown.ValueId = event.item.id;
      if (event.item.id == 2) {
        dateOfCardPayment.IsShowed = true;
        dateOfCardPayment.Required = true;
      } else {
        dateOfCardPayment.IsShowed = false;
        dateOfCardPayment.Required = false;
      }
      this.ModalAddingService.CheckRequired();
    };

    let dropDownPaymentStream = ModalFieldBuilder.BuildDropdown("Тип задатка", this.PaymentsStreamSources, "Задаток");
    dropDownPaymentStream.ValueId = 1;
    dropDownPaymentStream.OnChangeValue = (event) => {
      dropDownPaymentStream.ValueId = event.item.id;
      this.ModalAddingService.CheckRequired();
    };

    let sumPayment = ModalFieldBuilder.BuildNumber("Сумма платежа", 0, 100000, 0, [500, 600, 800, 1000, 1500, 2000]);
    sumPayment.Required = true;
    sumPayment.InvalidValues.push(new NumberInvalidFieldValue(0, "Нужно задать значение суммы платежа"));
    sumPayment.OnChangeValue = (event) => {
      this.ModalAddingService.CheckRequired();
    };

    this.ModalAddingService.Show(
      new ModalData("Добавить платёж", "", "Добавить", "Отмена"),
      [
        sumPayment,
        dropDown,
        dateOfCardPayment,
        dropDownPaymentStream
      ]
    );
  }



  RemoveAllTransaction() {
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {

      this.BanquetPaymentsService.AddPayment(
        this.BanquetsService.ActiveBanquet.GetSumPayment(),
        2,
        this.ModalAddingService.Fields[0].ValueId,
        "",
        "",
        "",
        1,
      );

      this.BanquetPaymentsService.AddPayment(
        this.BanquetsService.ActiveBanquet.GetSumProgramPayment(),
        2,
        this.ModalAddingService.Fields[0].ValueId,
        "",
        "",
        "",
        2,
      );

    });

    let dropDown = ModalFieldBuilder.BuildDropdown("Способ оплаты", this.PaymentsSources, "Наличные");
    dropDown.ValueId = 1;

    let removeAllTransactionModel = new ModalData("Вернуть все транзакции", "", "Вернуть", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(
      removeAllTransactionModel,
      [
        dropDown,
      ]
    );
  }



}
