import { Injectable } from '@angular/core';
import { LanguageCode } from '../models/tools/language-code.model';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Account } from '../models/account/account.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  public account: Account = new Account();
  public get Account(): Account {
    return this.account;
  }
  public set Account(account: Account) {
    this.account = account;
  }


  public token: string = "";
  public get Token(): string {
    return this.token;
  }
  public set Token(token: string) {
    this.token = token;
    this.cookieService.set("user-token", this.token);
  }


  public fbmToken: string = "";
  public get FbmToken(): string {
    return this.fbmToken;
  }
  public set FbmToken(fbmToken: string) {
    this.fbmToken = fbmToken;
    this.cookieService.set("fbm-token", this.fbmToken);
  }


  public userGroupId: number = 0;
  public get UserGroupId(): number {
    return this.userGroupId;
  }
  public set UserGroupId(userGroupId: number) {
    this.userGroupId = userGroupId;
    this.cookieService.set("user-group-id", this.userGroupId.toString());
  }


  public userId: number = 0;
  public get UserId(): number {
    return this.userId;
  }
  public set UserId(userId: number) {
    this.userId = userId;
    this.cookieService.set("user-id", this.userId.toString());
  }


  private language: string = "ru";
  public get Language(): string {
    return this.language;
  }

  public SetLanguage(language: LanguageCode) {
    switch (language) {
      case LanguageCode.Russian:
        this.language = "ru";
        break;
      case LanguageCode.Ukraine:
        this.language = "ua";
        break;
      case LanguageCode.English:
        this.language = "en";
        break;
    }
  }

  constructor(private http: HttpClient, private cookieService: CookieService) {
    if (this.cookieService.check("user-token")) {
      this.Token = this.cookieService.get("user-token");
    }
    if (this.cookieService.check("user-group-id")) {
      this.userGroupId = Number.parseInt(this.cookieService.get("user-group-id"));
    }
    if (this.cookieService.check("user-id")) {
      this.userId = Number.parseInt(this.cookieService.get("user-id"));
    }
    if (this.cookieService.check("user-firstname")) {
      this.Account.firstname = this.cookieService.get("user-firstname");
    }
    if (this.cookieService.check("user-lastname")) {
      this.Account.lastname = this.cookieService.get("user-lastname");
    }
  }

  public ClearCookies() {
    this.cookieService.deleteAll('/');
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";
    }
    document.cookie = "";
  }

  public GetTime(): number {
    return new Date().getTime() - (new Date().getTimezoneOffset() * 60000);
  }

  public GetTimezoneOffset(): number {
    return (new Date().getTimezoneOffset() * 60000);
  }

  public EndingWordOfNumber(n, text_forms) {
    n = Math.abs(n) % 100; var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
  }


  public IsLoadingCircleShowed: boolean = false;
    

}
